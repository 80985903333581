/*


1. Interval wise css
@media (max-width : 1700px){

    // inspection station css starts
    ......
    // inspection station css ends

    
}



*/


// @media (max-width: 596px) {
//   .livis-usecase-tag-selection {
//     .livis-stepper-body {
//       max-height: 39rem !important;
//     }
//   }
// }

//Max width Only queries starts

@media (max-width: 420px) {


  // data management css starts
  .livis-manage-user-filter.livis-manage-data-filter{
    min-width: 45% !important;
    min-height: 4rem !important;
  }
  //data management css ends

  //user management css starts

  .livis-manage-user-legend-wrap {
    display: none !important;
  }

  .livis-manage-user-header,
  .livis-manage-user-add-pagination-wrap {
    flex-direction: column !important;
  }

  .livis-manage-user-header>div {
    width: 100% !important;
    display: flex !important;
  }

  .livis-manage-user-header.livis-manage-operator-layout-header {
    flex-direction: row !important;
    align-items: center !important;

    .livis-manage-user-add-pagination-wrap {
      margin: 0px !important;
    }
  }

  .livis-manage-user-filters-wrap {
    width: 100% !important;
    justify-content: space-between !important;
  }

  .livis-manage-user-filter {
    width: 40% !important;
    min-width: 40% !important;
    max-width: 40% !important;
  }

  .livis-manage-user-add-pagination-wrap {
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem !important;
  }

  .livis-manage-user-header.livis-manage-inspection-header {
    margin-inline: 1rem !important;
    padding-inline: 0px !important;
  }

  .livis-manage-user-pagination-wrap .livis-inspection-pagination-button-wrap {
    margin-top: 0px !important;
  }

  //user management css ends

  //operator panel css starts

  .livis-manage-operator-layout-header>div {
    // width: fit-content !important;
  }

  //operator panel css ends

  //inspection station css starts 

  .livis-station-header-search-wrap-small {
    margin-inline: 1rem !important;
    width: calc(100% - 2rem) !important;
    margin-block: 3rem !important;

    .livis-inspection-station-search-input {
      width: 100% !important;
      max-width: 100% !important;
    }
  }

    body .livis-station-header-search-wrap-small .livis-inspection-station-search-input {
      width: 100% !important;
      max-width: 100% !important;
    }

  //inspection station css ends  


  //tag management css starts
  .livis-tag-management-header-search-container {
    margin-right: 0px !important;
  }

  .livis-tag-management-header-search-container .MuiInputBase-root {
    width: calc(100vw - 50px) !important;
    margin-top: 2rem !important;
  }

  .livis-tag-management-tag-grid {
    min-width: 100% !important;
  }

  .livis-tag-management-tag-img-wrap, .livis-tag-management-add-new-tag-wrap img {
    height: 10rem !important;
  }

  .livis-tag-management-header-add-tag-container {
    margin-top: 1rem !important;
  }

  .livis-tag-management-header-search-wrap {
    justify-content: flex-end !important;
  }

  .livis-tag-management-csv-container {
    display: none !important;
  }

  .livis-tag-management-csv-container.livis-tag-management-csv-container-mobile {
    display: block !important;
    width: 100% !important;
  }

  .livis-tag-management-csv-container.livis-tag-management-csv-container-mobile button {
    width: 100% !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  //tag management css ends

  //reports css starts

  .livis-reports-pagination-frequency {
    display: none !important;
  }

  //reports css ends

  //custom css starts
  .livis-landing-header-container {
    // flex-direction: column !important;
    justify-content: space-between !important;
  }

  // .livis-landing-header-container>div {
  //   width: 100% !important;
  // }

  .livis-landing-header-language-select-container {
    max-width: 120px !important;
  }

  .livis-landing-header-right-container {
    justify-content: space-between !important;
  }

  .livis-landing-header-right-container {
    flex-direction: row !important;
    // margin-top: 2rem !important;
  }

  .livis-landing-header-language-select-container {
    margin-top: 0rem !important;
  }

  .livis-landing-header-logo-wrap img {
    max-width: 25rem !important;
  }

  .livis-landing-header-hamburger-wrap img {
    max-width: 1.8rem !important;
  }

  .livis-landing-header-right-container .livis-landing-header-settings-container {
    margin-top: 0rem !important;
  }

  .livis-auth-header-right-container .livis-landing-header-language-select-container {
    margin-top: 1rem !important;
  }


  .livis-landing-header-logo-wrap img {
    max-width: 11rem !important;
  }

  .livis-landing-header-left-container {
    margin-top: 1rem !important;
  }

  //custom css ends

  //training css starts

  .livis-training-configure-icon{
    display: none;
  }

  //training css ends

  //data analytics css starts

  .livis-data-analytics-legend .livis-data-analytics-legend-wrap{
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
  }

  .livis-data-analytics-header-wrap {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: space-between !important;
    height: 7rem !important;
  }

  .livis-inspection-filer-div {
    width: 50% !important;
  }

  //usecase css starts

  .livis-usecase-page-header .livis-manage-user-filter {
    margin-right: 0rem !important;
  }

  //usecase css ends
}

@media (max-width: 485px ){
  //training css
  .livis-modal-sub-header-right {
    display: none;
  }
}

@media (max-width: 597px) {

  //inspection station starts

  .livis-highchart-custom *,
  .livis-inspection-donut * {
    height: 22rem !important;
  }

  .livis-training-video-wrap {
    display: none;
  }

  //inspection station ends

  //custom css starts

  .livis-modal-slide-animation {
    // width: 85% !important;
  }



  .livis-stepper-steps {
    flex-direction: column !important;
    align-items: flex-start !important;
    margin: 1rem !important;
    background-color: #f5f5fa !important;
    background: rgba(245, 245, 250, 0.4);
    border-radius: 1rem !important;
    padding: 1rem !important;
    margin: 0rem 0rem 2rem 0rem !important;
  }

  .livis-stepper-steps .MuiStep-horizontal {
    padding-block: 0.5rem !important;
  }

  .livis-stepper-title {
    padding-left: 1rem !important;
  }

  .MuiStepConnector-horizontal {
    width: 2px !important;
    height: 15px !important;
    background: rgba(0, 0, 0, 0.2) !important;
    margin-left: 18px !important;
  }

  //custom css ends
}

@media (max-width: 599px) {

  //tag management css starts
  .livis-tag-management-tag-img-wrap {
    height: 5rem !important;
  }

  .livis-tag-management-tag-info-wrap .livis-tag-management-tag-created-wrap {
    font-size: 8px !important;
  }

  .livis-tag-management-add-new-tag-wrap img {
    width: 32px !important;
    height: 32px !important;
  }

  .livis-tag-management-tag-more img {
    width: 16px !important;
    height: 16px !important;
  }

  .livis-tag-management-header-search-wrap {
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 1rem !important;
  }

  .livis-tag-management-header-search-container .MuiInputBase-root {
    width: 50vw !important;
    min-height: 3.5rem !important;
    max-height: 3.5rem !important;
  }

  .livis-tag-management-filter-container {
    margin-left: 0px !important;
  }

  .livis-import-tag-btn .MuiAvatar-root {
    width: 24px !important;
    height: 24px !important;
  }

  .livis-tag-management-hamburger-industry-wrap {
    min-width: calc(100% - 2rem) !important;
    border: 1px solid #d3e0ff !important;
    padding: 0.75rem 1rem !important;
    border-radius: 0.75rem !important;
    justify-content: flex-start !important;
    flex-wrap: wrap !important;
    margin-block: 0.5rem !important;
  }

  .livis-tag-management-hamburger-type.active {
    border: none !important;
  }

  .livis-tag-management-hamburger-type {
    min-width: 100% !important;
    padding: 0px !important;
  }

  .livis-tag-management-hamburger-name {
    margin: 0px !important;
    margin-top: 0.5rem !important;
  }

  .livis-tag-management-hamburger-gt {
    display: none !important;
  }

  .livis-tag-management-hamburger-gt-mobile {
    display: block !important;
    margin-left: 3vw;
    transform: rotate(90deg);
  }

  .livis-tag-management-tag-tags-wrap {
    display: none !important;
  }

  .livis-tag-management-hamburger-industry-wrap.active {
    background: #eeeef680 !important;
  }

  //tag management css ends

  //usecase css starts


  .livis-usecase-menu-wrap {
    display: none !important;
  }

  //usecase css ends

  //training css starts

  .livis-training-menu-wrap {
    width: 100% !important;
    // height: 5rem !important;
  }

  .livis-training-usecase-card img {
    width: 2.5rem;
  }

  .livis-training-content-wrap {
    margin-inline: 1rem !important;
    width: calc(100% - 2rem) !important;
  }

  .livis-training-wrapper-container {
    margin: 0px !important;
    width: calc(100%) !important;
  }

  .livis-training-stats-header {
    height: fit-content !important;
  }

  .livis-training-stats-header-content-box {
    padding: 1rem !important;
  }

  .livis-training-credit-header-content-box-mobile {
    height: 6.5rem !important;
    margin-top: 1rem !important;
  }

  .livis-training-wrapper {
    padding: 0.5rem !important;
    padding-top: 2rem !important;
  }

  .livis-training-anomaly-supervised-container {
    height: 30rem !important;
  }

  .livis-training-container-no-data img {
    width: 5rem;
  }

  .livis-training-container-no-data .livis-training-detail {
    margin-inline: 1rem !important;
  }

  .livis-retrain-ready-deploy-text-wrap {
    display: none !important;
  }

  .livis-training-card-details-summary {
    padding: 1rem !important;
  }

  .livis-training-usecase-card {
    padding: 1rem !important;
  }

  .livis-training-inference-result-modal {
    left: 0px !important;
  }

  .livis-training-summary-trained-at {
    justify-content: flex-start !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .livis-training-defect-chip {
    margin-block: 0.5rem !important;
  }

  .livis-training-summary-feature-chips {
    flex-wrap: wrap !important;
  }

  .livis-training-wrapper-anomoly {
    margin-bottom: 10rem !important;
  }

  //training css ends

  //trash css starts

  .livis-trash-buttons {
    min-width: calc(46% - 1rem) !important;
    margin-top: 1rem !important;
    padding-block: 1rem !important;
  }

  .livis-trash-header-button-container {
    display: flex;
    flex-wrap: wrap;
  }

  //trash css ends

  //settings css starts

  .livis-settings-menu-heading.invisible {
    display: block !important;
    visibility: visible !important;
    min-width: 100% !important;
  }

  .livis-settings-sidebar-wrap .livis-settings-tab-wrap {
    min-width: calc(100% - 2rem) !important;
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .livis-settings-sidebar-wrap,
  .livis-menu-list-wrap {
    margin: 0px !important;
    margin-top: 1rem !important;
  }

  .livis-settings-tab-wrap>div.livis-settings-heading {
    width: 100% !important;
    display: block !important;
  }

  .livis-settings-tab-wrap>div.livis-settings-heading h2 {
    padding-left: 1rem !important;
    margin-block: 0.75rem !important;
  }

  .livis-settings-part-main-wrap {
    padding: 0.5rem !important;
  }

  //settings css ends

  //components css starts

  .livis-modal-slide-medium {
    width: 85% !important;
  }

  .livis-manage-data-count {
    margin-top: 0px !important;
  }

  .livis-landing-header-btns-mobile {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
  }

  .livis-landing-header-btns {
    display: none !important;
  }

  .livis-landing-search-wrap div {
    max-width: 100% !important;
  }

  .livis-create-btn-wrap {
    display: none !important;
  }

  .livis-create-btn-wrap_mobile {
    display: flex !important;
    justify-content: flex-end !important;
  }

  //components css ends

  //recipe css starts

  .livis-recipe-plc-table-header {
    visibility: hidden !important;
  }

  .livis-recipe-header-container {
    position: relative;
  }

  .livis-recipe-builder-add-recipe-btn {
    position: absolute;
    right: 1rem !important;
  }

  .livis-recipe-builder-search-input {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .livis-add-recipe-choose-part-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .livis-add-recipe-choose-part-container .livis-peripheral-device-select-part-dropdown {
    padding-left: 1rem !important;
    margin: 0px !important;
    min-width: 70% !important;
  }

  .livis-peripheral-device-container {
    max-height: fit-content !important;
    min-height: fit-content !important;
  }

  //recipe css ends

  //inspection station css starts

  .livis-inspection-station-count {
    margin-top: 0px !important;
  }

  .livis-inspection-legend-wrap {
    justify-content: flex-start !important;
  }

  .livis-inspection-diplay-wrap {
    display: none !important;
  }

  .livis-inspection-graph-header .livis-defect-frequency-text {
    font-size: 16px !important;
    margin-bottom: 0.075rem !important;
  }

  .livis-frequency-select-filter {
    margin-block: 1rem !important;
    float: right !important;
  }

  .livis-defect-frequency-legend p {
    font-size: 13px !important;
  }

  .livis-frequency-select-form {
    font-size: 14px !important;
    max-width: 10rem !important;
    // max-height: 2.0625rem !important;
  }

  .livis-major-defect-action a {
    font-size: 15px !important;
  }

  .livis-station-header-search-wrap-small {
    margin-inline: 1rem !important;
    width: calc(100% - 2rem) !important;
    margin-block: 3rem !important;

    .livis-inspection-station-search-input {
      width: 45% !important; 
      max-width: 50%;
    }
  }

  //inspection station css ends

  //reports css starts

  .livis-reports-pagination-wrap {
    display: flex !important;
  }

  .livis-defect-shortby-wrap {
    display: none !important;
  }

  .livis-defect-frequency-legend {
    justify-content: flex-start !important;
  }

  .livis-reports-analytics-wrap {
    padding-inline: 1rem !important;
  }

  .statistics-count-wrap-one,
  .statistics-count-wrap-two,
  .statistics-count-wrap-three {
    min-width: calc(100% - 6rem) !important;
    margin-top: 1rem !important;
  }

  .reports-card-wrap {
    padding-inline: 1rem !important;
  }

  .livis-reports-tabs-wrap div {
    padding-block: 1.5rem !important;
  }

  .livis-reports-part-seach-input-wrap {
    margin: 0px !important;
    margin-top: 1rem !important;
  }

  .livis-report-header-tab-wrap .livis-donwload-wrap {
    justify-content: space-between !important;
  }

  .livis-report-header-tab-wrap .livis-donwload-wrap button {
    min-width: 45% !important;
    max-width: 45% !important;
    padding: 0px !important;
    padding-block: 1rem !important;
  }

  .livis-filter-modal .livis-report-filter-dropdown,
  .livis-filter-modal .livis-report-filter-dropdown-date-range {
    min-width: 100% !important;
  }

  .livis-filter-modal .livis-report-filter-dropdown-date-range .calender {
    max-width: 100% !important;
  }

  .livis-filter-modal .livis-reports-part-search-input {
    padding-block: 0.25rem !important;
  }

  .livis-filter-modal .livis-reports-part-search-input div {
    width: calc(100% - 1rem) !important;
  }

  .livis-inspection-diplay-wrap.livis-reports-pagination-wrap {
    align-items: center;
    margin-top: 1rem !important;
  }

  .livis-filter-wrap .livis-report-filter-btn {
    display: block !important;
    background-color: transparent !important;
    border: 1px solid #0857de !important;
    color: #0857de !important;
    padding: 0px !important;
  }

  .livis-report-all-filter-wrap {
    display: none !important;
  }

  .livis-operator-batch-size.livis-report-details-batch-wrap {
    display: flex !important;
  }

  .livis-report-details-status-wrap {
    display: none !important;
  }

  .livis-operator-report-card {
    padding-right: 0px !important;
  }

  .livis-report-details-image-wrap {
    height: 20rem !important;
    width: 100% !important;
  }
  .livis-reports-details-verified-tag{
    position: static !important;
    font-size: 1.25rem;
    border-radius: 0.5rem;
  }
  .livis-reports-details-part-date-wrap{
    display: flex;
    justify-content: space-between !important;
    padding-right: 1.25rem !important;
  }

  .livis-report-details-image-wrap .livis-operator-report-list-image {
    object-fit: cover !important;
    width: 100% !important;
    border-radius: 0.5rem 0.5rem 0 0 !important;
  }

  .livis-reports-data-wrap {
    padding-inline: 1rem !important;
  }

  .livis-reports-details-part-date-wrap {
    padding-left: 2rem !important;
    margin-block: 1rem !important;
  }

  .livis-report-details-batch-wrap {
    padding-left: 1rem !important;
    margin-block: 1rem !important;
  }

  .livis-operator-list-action-button-wrap {
    margin-block: 1rem !important;
  }

  .livis-operator-list-action-button-wrap button {
    min-width: 80% !important;
  }

  .livis-operator-list-action-button-container {
    justify-content: center;
  }

  .livis-apply-filter-btn button {
    width: 100% !important;
    padding-block: 1rem !important;
    font-size: 1.15rem !important;
  }

  #menu-day_wise_filter .MuiPopover-paper,
  #menu-status .MuiPopover-paper,
  #menu-verification .MuiPopover-paper,
  .menu-verification_status .MuiPopover-paper,
  #menu-defect .MuiPopover-paper,
  #menu-feature .MuiPopover-paper,
  #menu-sample_percent .MuiPopover-paper {
    width: 100% !important;
  }

  .livis-reports-batch-search-input {
    justify-content: flex-start !important;
  }

  .livis-select-date-text .livis-frequency-select-form {
    padding-block: 0.5rem !important;
  }

  .livis-report-filter-dropdown-date-range {
    min-height: 2rem !important;
  }

  .livis-report-filter-wrap.livis-filter-modal {
    margin-block: 2rem !important;
  }

  .livis-report-preview-part-name-wrap,
  .livis-report-preview-part-no-wrap {
    margin-block: 1rem !important;
    width: 100% !important;
    max-width: 100% !important;
  }

  .livis-reports-preview-hman-verification-img {
    height: 7.5rem !important;
  }

  .livis-reports-preview-hman-verification-verify {
    min-width: 100% !important;
    margin-top: 1rem !important;
  }

  .livis-report-preivew-camera-feed-wrap .livis-report-preview-feed,
  .livis-report-preivew-camera-feed-wrap {
    max-height: 30vh !important;
  }

  .livis-report-preview-position-wise-accordian {
    display: block !important;
  }

  .livis-report-preview-image-overlay-items {
    display: none !important;
  }

  //reports css ends

  //data management css starts

  .livis-data-management-legend-wrap {
    justify-content: flex-start !important;
  }

  .livis-manage-data-filter-wrap {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .livis-manage-data-position-filter-wrap {
    padding-left: 0rem !important;
  }

  .manage-data-form-usecase-card {
    min-width: 98% !important;
    padding: 0px !important;
  }

  .livis-part-data-import-images:has(.manage-data-form-usecase-card-wrapper) .livis-stepper-body {
    max-height: 19rem !important;
  }

  .livis-part-data-import-images .manage-data-form-usecase-card-wrapper {
    max-height: 77vh !important;
  }

  .livis-manage-testing-header>div {
    display: block !important;

    .livis-manage-user-pagination-wrap .livis-inspection-pagination-button-wrap {
      margin-top: 1rem !important;
    }
  }

  .livis-manage-data-mark-unmark-btns-wrap {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 100% !important;
  }

  .livis-manage-data-tab .MuiButtonBase-root.MuiTab-root {
    padding-inline: 0.25rem !important;
  }

  .livis-data-management-annotation-header-image-count {
    justify-content: flex-start !important;
  }

  .livis-manage-data-mark-unmark-btns-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap !important;
    margin-left: 0px !important;
    width: 100% !important;

    button {
      margin-left: 1rem !important;
    }
  }
  .livis-data-management-add-data-btn{
    justify-content: flex-end !important;
    
    .livis-manage-user-add-user-wrap , button {
      margin : 0px !important;
      width: 100% !important;
    }
  }

  //data management css ends

  // .livis-menu-list-wrap {
  //   margin-inline: 1rem !important;
  // }

  //part css starts

  .livis-part-main-side-wrap {
    flex-direction: column;
  }

  .livis-part-main-wrap {
    position: static;
    min-width: 100% !important;
  }

  .livis-part-data-wrap {
    margin-inline: 1rem !important;
  }

  //part css ends



  //custom css starts

  .livis-sidebar-menu-wrap {
    position: static;
    min-width: 100% !important;
    // to be removed to show sidebar
    // display: none;
  }

  .MuiPopover-paper {
    min-width: 12rem !important;
  }

  .livis-form-table-data {
    font-family: "Noto Sans Bold" !important;
  }

  .MuiTypography-body1 {
    font-size: 12px !important;
  }

  .livis-modal-main-content {
    // margin-top: 2rem !important;
    // margin-inline: 1rem !important;
    // min-height: calc(100% - 5rem) !important;
    max-height: 77vh !important;
    overflow: auto;
  }

  .livis-landing-header-logo-wrap img {
    max-width: 20rem;
  }

  .livis-landing-header-hamburger-wrap img {
    max-width: 10rem;
  }

  .livis-landing-header-container {
    align-items: flex-start !important;
  }

  .livis-landing-header-right-container {
    flex-direction: column-reverse !important;
  }

  .livis-landing-header-language-select-container {
    margin-top: 2rem !important;
  }

  .livis-landing-header-right-container .livis-landing-header-settings-container {
    margin-top: 0.5rem !important;
  }

  .livis-auth-header-right-container {
    flex-direction: column-reverse !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }

  .livis-auth-header-container {
    align-items: flex-start !important;
    justify-content: space-between;
    align-items: center;
  }

  .livis-auth-header-logo-wrap img {
    margin-inline: 0px !important;
  }

  .livis-auth-header-right-container .livis-landing-header-language-select-container {
    margin-inline: 0px !important;
  }

  //custom css ends


  //annotate css starts

  .livis-annotate-main-body-wrap .jss6 .jss8 {
    margin-inline: 0px !important;
  }


  //annotate css ends

  //analytics css starts

  .livis-analytics-wrapper {
    margin-right: 0rem !important;
  }

  //analytics css ends

  //user management css starts

  .livis-manage-role-description {
    display: none !important;
  }

  .livis-manage-user-header {
    align-items: flex-start !important;
    padding-inline: 1rem !important;
    margin-top: 2rem !important;
  }

  .livis-manage-user-add-user-wrap .livis-create-btn-wrap {
    display: block !important;
  }

  // .livis-user-card-grid-size{
  //   max-height: 30rem !important;
  // }

  .livis-manage-user-header.livis-manage-inspection-header {
    margin-inline: 1rem !important;
    padding-inline: 0px !important;
  }

  //user management css ends

  //audit log css starts
  .livis-audit-log-wrap.card .livis-audit-log-event {
      min-width: calc(100% - 1.5rem) !important;
      max-width: calc(100% - 1.5rem) !important;
  }
  //audit log css ends
  
  //testing css starts
  .livis-testing-main-layout {
    margin-inline: 1rem !important;
  }

  //testing css ends
  
}

@media (max-width: 768px) {
  //reports and analytics css starts

  .livis-drilldown-defect-feature-graph-wrap {
    flex-wrap: wrap !important;
  }

  .livis-drilldown-defect-feature-graph-wrap div {
    min-width: 100% !important;
  }

  .recharts-responsive-container:nth-child(1) {
    border-radius: 25px 25px 0px 0px !important;
  }

  .recharts-responsive-container:nth-child(2) {
    border-radius: 0px 0px 25px 25px !important;
  }

  //reports and analytics css ends

  //audit log css starts

  .livis-audit-log-wrap.card .livis-audit-log-event {
      min-width: 45% !important;
      max-width: 45% !important;
      flex-direction: column !important;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      font-size: 16px !important;
      margin-left: 1.5rem !important;
      margin-block: 0rem 1rem !important;
  }
  
  .livis-permission-card-name-title {
      font-size: 14px !important;
  }
  
  .livis-audit-list-wrap {
      justify-content: flex-start !important;
  }
  
  .livis-audit-log-wrap.card .livis-audit-log-info,
  .livis-audit-log-wrap.card .livis-audit-log-details {
      margin-bottom: 3rem !important;
  }
  
  //audit log css ends
}

@media (max-width: 786px) {

  //user management css starts

  // .livis-manage-user-header>div {
  //   display: block;
  // }

  .livis-manage-testing-header>div {
    display: flex !important;

    .livis-manage-user-pagination-wrap .livis-inspection-pagination-button-wrap {
      margin-top: 0px !important;
    }
  }

  .livis-manage-user-header>div .livis-inspection-legend-wrap {
    justify-content: flex-start !important;
  }

  .livis-manage-user-header .livis-manage-user-legend-wrap {
    margin: 0px !important;
    margin-top: 1.15rem !important;
  }

  // .livis-manage-user-pagination-wrap .livis-inspection-pagination-button-wrap {
  //   margin-top: 1.15rem !important;
  // }

  .livis-manage-user-header.livis-manage-operator-layout-header .livis-manage-user-add-pagination-wrap {
    justify-content: flex-end !important;
  }

  .livis-manage-user-add-user-wrap .MuiAvatar-root {
    // width: 24px !important;
    // height: 24px !important;
  }

  .livis-user-list-card-grid.role-grid.permission-grid,
  .livis-part-body-wrap {
    padding: 0px !important;
  }

  .livis-permission-accordian-header {
    font-size: 1.5rem !important;
    font-family: "Noto Sans SemiBold" !important;
  }

  //user management css ends

  //trash css starts
  .livis-trash-calender-btn {
    margin-left: 0px !important;
    margin-right: auto !important;
  }

  //trash css ends

  //inspection station starts
  .livis-station-header-search-wrap-small {
    .livis-inspection-station-search-input {
      width: 50% !important;
    }
  }

  //inspection station ends

  //data management css starts
  .livis-manage-data-image-grid-sub-header-content {
    flex-direction: column-reverse !important;

    .livis-manage-data-buckets-tabs{
      width: 100% !important;
      margin-top: 2rem !important;

      button {
        width: calc(100% / 3) !important;
      }

    }
  }

  .livis-manage-data-image-grid-sub-header-content>div {
    min-width: 100% !important;
    justify-content: center !important;
    align-items: center;
  }

  .livis-manage-data-image-grid-sub-header-content>div.livis-manage-data-sortby-pagination-wrap{
    justify-content: space-between !important;
    padding-left: 1rem !important;
    margin-top: 1rem !important;
  }

  .livis-manage-data-mark-unmark-btns-wrap button {
    width: calc(25% - 1rem) !important;
  }

  .livis-manage-data-image-grid-sub-header-content .livis-manage-data-mark-unmark-btns-wrap {
    margin-left: 1rem !important;
    max-width: calc(100% - 5rem) !important;
    width: calc(100% - 5rem) !important;
    min-width: calc(100% - 5rem) !important;
  }

  .livis-manage-data-mark-unmark-btns-wrap,
  .livis-manage-data-tab {
    width: 100% !important;
  }

  //data management css ends

  //header css starts
  .livis-landing-header-logo-wrap img {
    max-width: 20rem;
  }

  .livis-manage-operator-layout-header {
    .livis-manage-user-legend-wrap {
      display: none !important;
    }

    .livis-manage-user-add-user-wrap .livis-create-btn-wrap button {
      padding: 0.5rem !important;
      font-size: 11px !important;
      span {

      }
    }
  }
  //header css ends
}

@media(max-width: 800px){
  .livis-training-scratch-learning-content-avatar{
    width: 30px !important;
    height: 30px !important;
  }
    .livis-usecase-tag-selection {
      .livis-stepper-body {
        max-height: 59rem;
      }
    }
  
    .livis-tag-select-wrapper {
      max-height: 33vh;
    }
  
    .livis-usecase-tag-selection.livis-stepper-body {
      max-height: 29rem !important;
    }
  
}

@media (max-width: 899px) {

  //dashboard css starts
  .livis-dashboard-mini-and-avg-graph-container {
    flex-direction: column-reverse !important;
  }

  .livis-dashboard-card .livis-frequency-select-form {
    min-width: 100% !important;
    max-width: 100% !important;
    margin-top: 1rem !important;
  }

  .livis-dashboard-mini-card-drodown-wrap {
    display: none !important;
  }

  .livis-dashboard-mini-card-drodown-wrap.mobile {
    display: block !important;
  }

  .livis-dashboard-mini-card-container.left {
    padding-block: 0px !important;
  }

  .livis-dashboard-mini-card-container {
    margin-top: 1rem !important;
    padding-block: 0px !important;
  }

  .livis-dashboard-header-right-box-date-refreshed-wrap,
  .livis-dashboard-header-right-box-sync-max-wrap {
    display: none !important;
  }

  //dashboard css ends

  //audit logs css starts

  .livis-audit-log-wrap.card .livis-audit-log-event {
    min-width: 45% !important;
    max-width: 45% !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    font-size: 16px !important;
    margin-left: 1.5rem !important;
    margin-block: 0rem 1rem !important;
  }

  //audit logs css ends
}

@media (max-width: 900px) {

  //custom css starts

  .livis-modal-confirm-cancel-wrap button {
    min-width: 100% !important;
    margin-block: 0.5rem;
  }

  .livis-stepper-table-wrap {
    display: none !important;
  }

  .livis-stepper-table-wrap-mobile {
    display: flex !important;
    margin-bottom: 0.625rem;
  }

  .livis-modal-sub-header {
    display: none !important;
  }


  .livis-hide-grid {
    display: block !important;
  }

  .livis-user-status-bar {
    position: absolute;
  }

  //custom css ends

  //inspection station css starts

  .livis-inspection-dashboard-card-wrap {
    margin: 1rem !important;
  }

  .livis-defect-frequency-graph {
    width: 95%;
    height: 16rem;
    margin: auto;
  }

  .livis-semi-donut-training-graph {
    display: none !important;
  }

  .livis-total-inspection-counts-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem !important;
    flex-wrap: wrap;
  }

  .livis-inspection-create-modal.livis-operator-builder-preview-modal {
    width: 95% !important;
  }

  //inspection station css ends

  //reports css starts

  .livis-report-header-tab-wrap {
    justify-content: space-between !important;
    align-items: center !important;
  }

  .livis-report-details-batch-wrap {
    display: none !important;
  }

  .livis-report-preview-upper-body-wrap {
    margin-top: 1rem !important;
  }


  //reports css ends

  //training css start
  
  .livis-training-summary-modal-full-width{
    width: 90% !important;
  }
  //training css ends

  .livis-tag-select-wrapper {
    max-height: 21vh ;
  }

}


@media (max-width: 901px) {

  //custom css starts
  .livis-modal-confirm-cancel-wrap button {
    min-width: 100% !important;
    margin-block: 0.5rem;
  }

  .livis-stepper-table-wrap {
    display: none !important;
  }

  .livis-stepper-table-wrap-mobile {
    display: flex !important;
    margin-bottom: 0.625rem;
  }

  //custom css ends

  //inspection station css starts

  .livis-inspection-list-recent-user-wrap,
  .livis-inspection-list-location-wrap,
  .livis-inspection-list-chip-wrap {
    display: none !important;
  }

  .livis-inspection-station-dasboard-accordian-details {
    padding-top: 0px !important;
  }

  //inspection station css ends

  //use case css starts

  .livis-usecase-industry-info-wrap,
  .livis-inspection-labeling-wrap {
    display: none !important;
  }

  .livis-usecase-statics-grid {
    justify-content: center !important;
  }

  //use case css ends
}

@media (max-width: 999px) {

  //data management css starts
  .livis-manage-data-header>div {
   flex-wrap: wrap !important;
  }

  .livis-data-management-legend-wrap.livis-inspection-legend-wrap {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  //data management css ends

  //user management css starts
  .livis-manage-user-legend-wrap.livis-manage-data-legend-wrap {
    margin-left: 0px !important;
    margin-top: 0.5rem !important;
  }

  //user management css ends
}

@media (max-width: 1024px) {

  //part css starts
  .livis-add-part-parameter-table-wrap td,
  .livis-add-part-parameter-table-wrap th {
    width: 6rem !important;
  }

  .livis-add-part-parameter-table-wrap td:first-child:hover .add_defect_active {
    margin-bottom: -0.5rem !important;
  }

  //part css ends

  //user management css starts
  .livis-role-card-bottom-wrap {
    padding-inline: 1.5rem 0.5rem !important;
  }
  
  .livis-role-card-indicator {
    left: -1.5rem;
  }
  
  //user management css ends

  //inspection station css starts
  .livis-station-header-search-wrap-small {
    .livis-inspection-station-search-input {
        width: 40% !important;
      }
  }

  .livis-landing-header-hamburger-wrap {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .livis-landing-header-routes-container {
    display: none !important;
  }

  .livis-landing-header-logo-wrap {
    margin-right: 1rem !important;
  }

  //inspection station css ends

  //dashboard css starts

  .livis-dashboard-mini-card-container .livis-dashboard-card {
    padding-inline: 0.5rem !important;
  }

  // .livis-dashboard-sub-details-card{
  //   padding: 1rem !important
  // }


  .livis-dashboard-dropdown-mini-card-month-title {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--livis-gray-font-color);
    margin-bottom: 0.5rem !important;
    font-family: "Noto Sans Regular";  
    font-size: 0.8rem !important;
    white-space: nowrap;
  }
  

  //dashboard css ends

  //reports and analytics css starts
  
  .livis-reports-analytics-text-three {
    font-size: 12px !important;
  }
  
  //reports and analytics css ends

  //audit log media query starts
  .livis-audit-log-event {
    min-width: 10rem !important;
    max-width: 10rem !important;
  }

  .livis-audit-log-time {
    padding-right: 2rem !important;
    min-width: 10rem !important;
    max-width: 10rem !important;
  }

  .livis-audit-log-info {
    min-width: 15rem !important;
    max-width: 15rem !important;
  }

  .livis-audit-log-details {
    min-width: 17.5rem !important;
    max-width: 17.5rem !important;
  }

  //audit log media query ends

  //stepper css starts



  //stepper css ends


  


}

@media (max-width: 1150px) {
  //parts css starts

  .livis-parts-display-wrap {
    display: none !important;
  }

  //parts css ends
}

@media (max-width: 1200px) {

  // data managaement css 
  .livis-manage-data-image-grid-sub-header{
    margin-inline: 1rem !important;
  }

  //training css starts

  .livis-traning-data-analytics {
    margin-top: 0px !important;
  }

  .livis-traning-menu-item,
  .livis-trash-menu-item {
    margin-top: 0px !important;
  }


  .livis-training-data-status {
    display: none !important;
  }

  .livis-traning-menu-item,
  .livis-trash-menu-item {
    min-width: 15rem !important;
    margin-left: 1rem !important;
  }

  .livis-traning-data-analytics {
    min-width: 15rem !important;
  }

  //training css ends

  //tag management css starts

  .livis-tag-management-tag-created-wrap {
    font-size: 10px !important;
  }

  .livis-tag-management-tag-img-wrap {
    height: 7.5rem !important;
  }

  .livis-tag-management-add-new-tag-wrap img {
    width: 40px !important;
    height: 40px !important;
  }

  .livis-tag-management-tag-more img {
    width: 20px !important;
    height: 20px !important;
  }

  .livis-tag-management-main {
    margin-right: 0px !important;
  }

  //tag management css ends

  //custom css starts

  .livis-page-title {
    display: none !important;
  }

  .livis-operator-page-title {
    display: none !important;
  }

  .livis-landing-hamburger-wrap {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding-left: 2rem !important;
  }

  .livis-configure-btn {
    opacity: 0.6 !important;
    pointer-events: none !important;
  }


  .livis-sidebar-divider {
    display: none !important;
  }

  .livis-sidebar-manage-capture-navigator {
    display: none !important;
  }

  //custom css ends

  //inspection css starts

  .livis-inspection-create-wrap {
    justify-content: flex-start !important;
  }

  //inspection css ends




  //usecase css starts

  .livis-usecase-list-wrap .livis-usecase-statics div {
    margin-right: 1rem;
  }

  .livis-usecase-filter-wrap,
  .livis-inspection-pagination-wrap {
    justify-content: flex-end !important;
  }

  .livis-usecase-responsive-filter-wrap {
    justify-content: flex-start !important;
  }

  .livis-usecase-responsive-create-btn-wrap {
    justify-content: flex-end !important;
  }

  .livis-usecase-responsive-filter-wrap {
    justify-content: flex-start !important;
  }

  .livis-usecase-responsive-create-btn-wrap {
    justify-content: flex-end !important;
  }

  //usecase css ends

  //recipe builder css starts

  .livis-recipie-preview-button {
    display: none !important;
  }

  //recipe builder css ends

  //part css starts

  .livis-part-filter-wrap {
    margin-right: 1rem !important;
  }

  .livis-add-part-parameter-table-wrap {
    min-width: 100% !important;
  }

  .livis-add-part-parameter-select-defect-feature-wrap {
    flex-wrap: wrap !important;
  }

  .livis-part-main-wrap,
  .livis-sidebar-menu-wrap {
    position: relative;
  }

  .livis-part-landing-wrap {
    .livis-menu-list-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-right: 2.25rem;
    }

    .livis-usecase-menu-wrap {
      width: 12rem;
    }

    .livis-training-menu-wrap {
      width: calc(100% - 13rem - 20px);
      justify-content: flex-start !important;
      display: flex;
      flex-direction: row;
      padding: 10px;
      overflow-x: auto;
      overflow-y: hidden;
      margin-top: 0px !important;
      z-index: 10 !important;
    }

    
    .livis-settings-menu-wrap .MuiAccordion-root.livis-sidebar-accordian {
      width: 10rem !important;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d3e0ff;
      border-radius: 0.75rem !important;
      margin-right: 0.5rem !important;
      position: relative;
      margin: 0px !important;
      margin-right: 0.5rem !important;
    }

    .livis-operator-panel-menu-items {
      height: 100% !important;
      display: flex;
      // justify-content: center;
      align-items: center;
    }

    .livis-operator-panel-single-menu-item {
      border: 1px solid #d3e0ff !important;
      border-radius: 1rem !important;
    }

    .livis-menu-list-wrap .livis-operator-panel-single-menu-item-active,
    .livis-menu-list-wrap .livis-operator-panel-single-menu-item {
      height: 100% !important;
      margin: 0px !important;
      padding: 0px !important;
      width: max-content !important;
      min-width: max-content !important;
      padding-inline: 0.5rem !important;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px !important;
      margin-block: 0px !important;
      margin-inline: 0px !important;
      margin-right: 0.75rem !important;
      text-align: center;
      font-size: 1rem !important;
    }

    .livis-operator-panel-single-menu-item img {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    .livis-settings-menu-wrap .MuiAccordionSummary-content {
      flex-direction: column;
    }

    .livis-sidebar-accordian .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
      position: absolute !important;
      top: 5rem;
      background-color: #fff;
      z-index: 100000000 !important;
    }

    .livis-settings-menu-wrap .MuiAccordion-root.horizontal-accordion {
      display: flex;
      align-items: center;
      width: fit-content !important;
      height: 100%;
    }

    .horizontal-accordion .MuiCollapse-hidden {
      display: none !important;
    }

    .livis-operator-panel-single-menu-item.accordion-summary {
      display: flex;
      align-items: center;
      cursor: pointer;
      flex-direction: row !important;
      padding-inline: 1rem !important;
    }

    .expand-icon {
      transition: transform 0.3s;
    }

    .expand-icon.open {
      transform: rotate(90deg);
    }

    .menu-item-image {
      margin-right: 8px;
    }

    .operator-panel-accordion-heading {
      flex-grow: 1;
    }

    .accordion-details {
      display: flex;
      padding-left: 16px;
      height: 100%;
    }

    .menu-item-details {
      display: flex;
      align-items: center;
    }

    .livis-operator-panel-single-menu-item.operator-panel-accordion-sub-menu,
    .livis-operator-panel-single-menu-item-active.operator-panel-accordion-sub-menu {
      margin-right: 16px;
      cursor: pointer;
      padding-block: 1.5rem !important;
    }

    .livis-sidebar-accoridan-desktop {
      display: none !important;
    }

    .livis-sidebar-accoridan-mobile {
      display: block !important;
    }
  }

  //part css ends

  //testing css starts

  .livis-testing-inference-body {
    flex-direction: column-reverse !important;
    flex-wrap: wrap !important;
    min-height: 78vh;
  }
  .livis-testing-inference-result-image-container{
    min-height: 50vh;
  }

  //testing css ends

  //reports css starts


  .livis-report-header-tab-wrap {
    justify-content: space-between !important;
    align-items: center !important;
  }

  .livis-report-header-tab-wrap:nth-child(1) {
    margin-bottom: 1.5rem !important;
  }

  .livis-donwload-wrap {
    justify-content: flex-start !important;
  }

  .livis-donwload-wrap button {
    min-width: 10rem !important;
  }

  .livis-report-details-defect-feature-block {
    display: none !important;
  }

  .livis-report-preview-part-no-wrap {
    margin-top: 2rem !important;
  }

  .livis-reports-preview-hman-verification-verify .MuiInputBase-root {
    max-height: 3rem !important;
    min-height: 3rem !important;
    width: 100% !important;
  }

  .livis-frequency-select-form.livis-reports-preview-hman-verification-verify {
    min-width: 100% !important;
  }

  .reports-card-wrap,
  .livis-reports-data-wrap {
    padding-inline: 1rem !important;
  }

  //reports css ends

  //trash css starts


  .livis-trash-card-wrap {
    margin-right: 1rem !important;
  }

  .livis-trash-table-container {
    margin-right: 1.5rem !important;
  }

  //trash css ends

  //settings css starts

  .livis-settings-part-main-wrap {
    padding-inline: 1.5rem !important;
  }

  //settings css ends

  //user management css starts
  .livis-manage-user-header {
    margin-inline: 1rem !important;
  }

  .livis-manage-user-pagination-wrap .livis-operator-pagination-frequency {
    display: none !important;
  }

  .livis-manage-user-add-user-wrap {
    margin-right: 1.5rem !important;
  }

  .livis-manage-user-list-grid-wrap {
    display: none !important;
  }

  .livis-role-card-profile-wrap {
    min-width: 15rem !important;
    max-width: 15rem !important;
  }

  .livis-permission-card-profile-wrap {
    min-width: 25rem !important;
    max-width: 25rem !important;
  }

  .livis-permission-card-desc-wrap {
    min-width: 22.5rem !important;
    max-width: 22.5rem !important;
  }

  .livis-permission-card-app-wrap {
    min-width: 12.5rem !important;
    max-width: 12.5rem !important;
  }

  .livis-permission-card-name-title {
    color: #00000060;
    font-size: 1rem !important;
    font-family: "Noto Sans SemiBold";
  }

  .livis-permission-card-name {
    padding-top: 0px !important;
    word-break: break-all !important;
  }

  .livis-permission-card-indicator {
    height: 200% !important;
    top: -100% !important;
  }

  .livis-user-card-more-icon {
    top: 2rem !important;
  }

  .livis-manage-inspection-header {
    margin-inline: 2rem !important;
  }

  //user management css ends

    //dashboard css starts
  .livis-dashboard-header-title {
    font-size: 14px !important;
  }

  .livis-dashboard-header-right-box-date-refreshed-wrap {
    font-size: 12px !important;
  }

  .livis-dashboard-dropdown-title {
    font-size: 14px !important;
  }

  .livis-dashboard-dropdown-mini-card-title {
    font-size: 10px !important;
  }

  .livis-dashboard-dropdown-mini-card-value {
    font-size: 16px !important;
  }


  .livis-dasboard-card-sub-details-wrap>div {
    padding-inline: 0.25rem !important;
  }

  .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-value {
    font-size: 16px !important;
  }

  .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-title {
    font-size: 10px !important;
  }

  //dashboard css ends

  //reports and analytics css starts

  .livis-reports-analytics-text-two {
    font-size: 16px !important;
  }

  //reports and analytics css ends

  //audit log css starts

  .livis-audit-log-event {
    min-width: 12rem !important;
    max-width: 12rem !important;
  }

  //audit log css ends


}

@media (max-width: 1300px) {

  //inspection station css starts
  .livis-manage-inspection-header {
    .livis-station-header-search-wrap {
      display: none !important;
    }

    .livis-manage-user-list-grid-wrap {
      margin-inline: 3rem !important;
    }
  }

  .livis-station-header-search-wrap-small {
    display: flex !important;
    justify-content: flex-end !important;
    width: calc(100% - 6rem) !important;
    margin-inline: 3rem !important;
    margin-block: 1.5rem 3rem !important;

    .livis-inspection-station-search-input {
      margin: 0px !important;
      width: 30% !important;
    }
  }

  //inspection station css ends
}

@media (max-width: 1366px) {

  //dashboard css starts
  .livis-dashboard-header-title {
    font-size: 14px !important;
  }

  .livis-dashboard-header-right-box-date-refreshed-wrap {
    font-size: 12px !important;
  }

  .livis-dashboard-dropdown-title {
    font-size: 14px !important;
  }

  .livis-dashboard-dropdown-mini-card-title {
    font-size: 12px !important;
  }

  .livis-dashboard-dropdown-mini-card-value {
    font-size: 18px !important;
  }

  .livis-dashboard-dropdown-mini-card-value img {
    max-width: 24px !important;
    height: 24px !important;
  }

  .livis-dashboard-dropdown-mini-card-title-value-wrap .livis-dashboard-dropdown-mini-card-value {
    font-size: 22px !important;
  }

  .livis-dasboard-card-sub-details-wrap>div {
    padding-inline: 0.25rem !important;
  }

  .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-value {
    font-size: 18px !important;
  }

  .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-title {
    font-size: 12px !important;
  }

  .livis-dashboard-counts-wrap {
    display: none !important;
  }

  //dashboard css ends

  //reports and analytics css starts
  .livis-reports-analytics-text-two {
    font-size: 18px !important;
  }

  //reports and analytics css ends
}

@media (min-width: 1500px){
  //profile css starts
  .livis-profile-information-card {
    margin-left: 0px !important;
  }
  //profile css ends
  
}

  @media (max-width: 1500px) {
    //reports css starts
    
    .livis-inspection-create-modal.livis-operator-builder-preview-modal {
        width: 93.375rem !important;
    }

    //reports css ends
  }

@media (max-width: 1512px) {

  //settings css starts
  .livis-settings-sidebar-menu-wrap {
    min-width: 100% !important;
    max-width: 100% !important;
    position: static !important;
  }

  .livis-settings-main-side-wrap {
    flex-wrap: wrap !important;
  }

  .livis-settings-part-main-wrap {
    min-width: 100% !important;
    max-width: 100% !important;
    position: static !important;
  }

  .livis-settings-sidebar-wrap .livis-settings-menu-wrap {
    width: 10rem !important;
  }

  .livis-settings-sidebar-wrap {
    display: flex !important;
    margin-inline: 1.5rem !important;
  }

  .livis-settings-sidebar-wrap .livis-settings-tab-wrap {
    min-width: calc(100% - 10rem - 0.5rem) ;
    max-width: calc(100% - 10rem - 0.5rem);
    margin-left: 0.5rem !important;
    margin-top: 0px !important;
    padding: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    padding-inline: 0.375rem !important;
    height: auto !important;
    flex-wrap: wrap;
  }

  .livis-settings-sidebar-wrap .livis-settings-menu-wrap {
    height: auto !important;
  }

  .livis-sidebar-accoridan-desktop {
    display: none !important;
  }

  .livis-sidebar-accoridan-mobile {
    display: block !important;
    min-width: fit-content !important;
  }

  .livis-settings-sidebar-wrap .livis-settings-tab-wrap .livis-operator-panel-menu-items {
    width: auto !important;
  }

  .livis-settings-sidebar-wrap .livis-operator-panel-single-menu-item,
  .livis-settings-sidebar-wrap .livis-operator-panel-single-menu-item-active {
    margin-block: 0.75rem !important;
    max-width: fit-content !important;
    min-width: max-content !important;
    flex-direction: column !important;
    padding-inline: 1.5rem !important;
    padding-block: 0.75rem !important;
    margin-inline: 0.375rem !important;
  }

  .livis-mobile-sidebar-accordian-title-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .livis-settings-sidebar-wrap .livis-settings-menu-wrap-image {
    height: auto !important;
    object-fit: contain !important;
  }

  //settings css ends

  //operator panel css starts

  .livis-operator-panel-menu-items {
    display: flex !important;
  }

  .livis-operator-panel-single-menu-item {
    border: 1px solid #d3e0ff;
    border-radius: 1rem !important;
  }

  //operator panel css ends


  //user management css starts

  .livis-user-card-profile-wrap {
    display: flex;
    max-width: 25rem !important;
    min-width: 25rem !important;
  }

  .livis-user-card-details-main-container {
    height: calc(100% - 13rem) !important;
  }

  .livis-user-card-list .livis-user-card-bottom-details-container {
    max-width: fit-content !important;
  }

  .livis-role-card-name {
    font-size: 18px !important;
    padding-top: 16px !important;
  }

  //user management css ends

  //dashboard css starts
    .livis-dashboard-header-title {
      font-size: 16px !important;
    }
  
    .livis-dashboard-header-right-box-date-refreshed-wrap {
      font-size: 14px !important;
    }
  
    .livis-dashboard-dropdown-title {
      font-size: 15px !important;
    }
  
    .livis-dashboard-dropdown-mini-card-value {
      font-size: 20px !important;
    }
  
    .livis-dashboard-dropdown-mini-card-value img {
      max-width: 24px !important;
      height: 24px !important;
    }
  
    .livis-dashboard-dropdown-mini-card-title-value-wrap .livis-dashboard-dropdown-mini-card-value {
      font-size: 22px !important;
    }
  
    .livis-dasboard-card-sub-details-wrap>div {
      padding-inline: 0.25rem !important;
    }
  
    .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-value {
      font-size: 18px !important;
    }
  
    .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-title {
      font-size: 14px !important;
    }
  
    //dashboard css ends
  
    //reports and analytics css starts
  
    .livis-reports-analytics-text-two {
      font-size: 18px !important;
    }
  
    //reports and analytics css ends
}

@media (max-width: 1700px) {

  //settings css starts
  .livis-settings-sidebar-wrap {
    margin-left: 1rem !important;
  }

  .livis-settings-sidebar-menu-wrap {
    min-width: 14rem ;
    max-width: 14rem ;
  }

  .livis-settings-part-main-wrap {
    padding-right: 0.75rem !important;
    padding-left: 2.25rem !important;
  }

  //settings css ends

  //user management css starts
  .livis-user-card-profile-wrap {
    display: flex;
    max-width: 20rem !important;
    min-width: 20rem !important;
  }

  .livis-usermanagement-profile-img>img {
    width: 100px;
    height: 100px;
  }

  .livis-user-card-name {
    font-size: 1rem !important;
  }

  .livis-user-card-role {
    font-size: 0.875rem !important;
  }

  .livis-user-card-details-container span {
    font-size: 0.875rem !important;
  }

  .livis-user-card-details-wrap.grid {
    margin-bottom: 1rem !important;
  }

  .livis-user-card-list .livis-user-card-bottom-details-container {
    max-width: 12rem !important;
  }

  .livis-permission-card-profile-wrap {
    min-width: 20rem !important;
    max-width: 20rem !important;
    word-break: break-all !important;
  }

  .livis-manage-inspection-header {
    .livis-manage-user-list-grid-wrap {
      margin-inline: 1rem !important;
    }
  }

  //dashboard css starts 

  .livis-dasboard-container {
    padding: 1.25rem !important;
  }

  //dashboard css ends

  //user management css ends

  .livis-testing-inference-body{
    min-height: 77vh !important;
  }
}

@media (min-width: 1200px) {
  .livis-inference-canvas-wrap {
    overflow: hidden;
  }
  
  .livis-testing-inference-data-wrapper {
    overflow: auto !important; 
    max-height: 78vh !important;
  }
  
  .livis-testing-inference-result-image-container {
    min-height: 70vh !important;
    max-height: 78vh !important;
  }
  .livis-testing-inference-body {
    max-height: 78vh;
    overflow: hidden !important;
  }
}

@media (min-width: 2500px) {
  .livis-tag-select-wrapper{
    max-height: 27vh !important;
  }
}

@media (max-height: 750px) {
  .livis-add-part-modal .livis-stepper-body {
    max-height: 16rem !important;
  }
}


@media (max-width: 420px){
  .livis-station-header-search-wrap-small {
    margin-inline: 1rem !important;
    width: 90% !important;
  }
}

// @media (max-width: 599px) {

//   //data management css starts
//   .livis-manage-testing-header>div {
//     display: block !important;

//     .livis-manage-user-pagination-wrap .livis-inspection-pagination-button-wrap {
//       margin-top: 1rem !important;
//     }
//   }

//   .livis-manage-data-mark-unmark-btns-wrap {
//     justify-content: center;
//     display: flex;
//     flex-wrap: wrap;
//     width: 100% !important;
//   }

//   .livis-manage-data-tab .MuiButtonBase-root.MuiTab-root {
//     padding-inline: 0.25rem !important;
//   }

//   //data management css ends

//   //settings css starts

//   .livis-settings-sidebar-wrap .livis-settings-tab-wrap {
//     min-width: calc(100% - 2rem) !important;
//     margin-left: 0.75rem !important;
//     margin-right: 0.75rem !important;
//   }

//   .livis-settings-sidebar-wrap,
//   .livis-menu-list-wrap {
//     margin: 0px !important;
//     margin-top: 1rem !important;
//   }

//   .livis-settings-tab-wrap>div.livis-settings-heading {
//     width: 100% !important;
//     display: block !important;
//   }

//   .livis-settings-tab-wrap>div.livis-settings-heading h2 {
//     padding-left: 1rem !important;
//     margin-block: 0.75rem !important;
//   }

//   .livis-settings-part-main-wrap {
//     padding: 0.5rem !important;
//   }

//   //settings css ends

//   //user management css starts

//   .livis-manage-user-add-user-wrap .livis-create-btn-wrap {
//     display: block !important;
//   }

//   // .livis-user-card-grid-size{
//   //   max-height: 30rem !important;
//   // }



//   .livis-manage-user-header.livis-manage-inspection-header {
//     margin-inline: 1rem !important;
//     padding-inline: 0px !important;
//   }

//   //user management css ends

//   //inspection station css starts

//   .livis-station-header-search-wrap-small {
//     margin-inline: 1rem !important;
//     width: calc(100% - 2rem) !important;
//     margin-block: 3rem !important;

//     .livis-inspection-station-search-input {
//       width: 65% !important;
//       max-width: 65% !important;
//     }
//   }

//   //inspection station css ends

//   //testing css starts
//   .livis-testing-main-layout {
//     margin-inline: 1rem !important;
//   }

//   //testing css ends

//   //custom css starts

//   .livis-modal-main-content {
//     // margin-top: 2rem !important;
//     // margin-inline: 1rem !important;
//     // min-height: calc(100% - 5rem) !important;
//     max-height: 75vh !important;
//     overflow: auto;
//   }

//   .livis-landing-header-logo-wrap img {
//     max-width: 20rem !important;
//   }

//   .livis-landing-header-hamburger-wrap img {
//     max-width: 10rem !important;
//   }

//   .livis-landing-header-container {
//     align-items: flex-start !important;
//   }

//   .livis-landing-header-right-container {
//     flex-direction: column-reverse !important;
//   }

//   .livis-landing-header-language-select-container {
//     margin-top: 2rem !important;
//   }

//   .livis-landing-header-right-container .livis-landing-header-settings-container {
//     margin-top: 0.5rem !important;
//   }

//   .livis-auth-header-right-container {
//     flex-direction: column-reverse !important;
//     justify-content: flex-end !important;
//     align-items: flex-end !important;
//   }

//   .livis-auth-header-container {
//     align-items: flex-start !important;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .livis-auth-header-logo-wrap img {
//     margin-inline: 0px !important;
//   }

//   .livis-auth-header-right-container .livis-landing-header-language-select-container {
//     margin-inline: 0px !important;
//   }

//   //custom css ends
// }


// @media (max-width: 899px) {

//   //dashboard css starts
//   .livis-dashboard-mini-and-avg-graph-container {
//     flex-direction: column-reverse !important;
//   }

//   .livis-dashboard-card .livis-frequency-select-form {
//     min-width: 100% !important;
//     max-width: 100% !important;
//     margin-top: 1rem !important;
//   }

//   .livis-dashboard-mini-card-drodown-wrap {
//     display: none !important;
//   }

//   .livis-dashboard-mini-card-drodown-wrap.mobile {
//     display: block !important;
//   }

//   .livis-dashboard-mini-card-container.left {
//     padding-block: 0px !important;
//   }

//   .livis-dashboard-mini-card-container {
//     margin-top: 1rem !important;
//     padding-block: 0px !important;
//   }

//   .livis-dashboard-header-right-box-date-refreshed-wrap,
//   .livis-dashboard-header-right-box-sync-max-wrap {
//     display: none !important;
//   }

//   //dashboard css ends

//   //audit logs css starts

//   .livis-audit-log-wrap.card .livis-audit-log-event {
//     min-width: 45% !important;
//     max-width: 45% !important;
//     flex-direction: column !important;
//     justify-content: flex-start !important;
//     align-items: flex-start !important;
//     font-size: 16px !important;
//     margin-left: 1.5rem !important;
//     margin-block: 0rem 1rem !important;
//   }

//   //audit logs css ends
// }

// @media (max-width: 1024px) {

//   //user management css starts
//   .livis-role-card-bottom-wrap {
//     padding-inline: 1.5rem 0.5rem !important;
//   }

//   .livis-role-card-indicator {
//     left: -1.5rem;
//   }

//   //user management css ends

//   //inspection station css starts
//   .livis-station-header-search-wrap-small {
//   .livis-inspection-station-search-input {
//       width: 40% !important;
//     }
//   }

//   .livis-landing-header-hamburger-wrap {
//     display: flex !important;
//     justify-content: center;
//     align-items: center;
//   }

//   .livis-landing-header-routes-container {
//     display: none !important;
//   }

//   .livis-landing-header-logo-wrap {
//     margin-right: 1rem !important;
//   }

//   //inspection station css ends
// }

// @media (max-width: 1200px) {

//   //settings css starts

//   .livis-settings-part-main-wrap {
//     padding-inline: 1.5rem !important;
//   }

//   //settings css ends

//   //user management css starts
//   .livis-manage-user-header {
//     margin-inline: 1rem !important;
//   }

//   .livis-manage-user-pagination-wrap .livis-operator-pagination-frequency {
//     display: none !important;
//   }

//   .livis-manage-user-add-user-wrap {
//     margin-right: 1.5rem !important;
//   }

//   .livis-manage-user-list-grid-wrap {
//     display: none !important;
//   }

//   .livis-role-card-profile-wrap {
//     min-width: 15rem !important;
//     max-width: 15rem !important;
//   }

//   .livis-permission-card-profile-wrap {
//     min-width: 25rem !important;
//     max-width: 25rem !important;
//   }

//   .livis-permission-card-desc-wrap {
//     min-width: 22.5rem !important;
//     max-width: 22.5rem !important;
//   }

//   .livis-permission-card-app-wrap {
//     min-width: 12.5rem !important;
//     max-width: 12.5rem !important;
//   }

//   .livis-permission-card-name-title {
//     color: #00000060;
//     font-size: 1rem !important;
//     font-family: "Noto Sans SemiBold";
//   }

//   .livis-permission-card-name {
//     padding-top: 0px !important;
//     word-break: break-all !important;
//   }

//   .livis-permission-card-indicator {
//     height: 200% !important;
//     top: -100% !important;
//   }

//   .livis-user-card-more-icon {
//     top: 2rem !important;
//   }

//   .livis-manage-inspection-header {
//     margin-inline: 2rem !important;
//   }

//   //user management css ends
// }


// @media (max-width: 1024px) {

//   //dashboard css starts

//   .livis-dashboard-mini-card-container .livis-dashboard-card {
//     padding-inline: 0.5rem !important;
//   }

//   // .livis-dashboard-sub-details-card{
//   //   padding: 1rem !important
//   // }

//   //dashboard css ends

//   //reports and analytics css starts

//   .livis-reports-analytics-text-three {
//     font-size: 12px !important;
//   }

//   //reports and analytics css ends
// }

// @media (max-width: 1200px) {

//   //dashboard css starts
//   .livis-dashboard-header-title {
//     font-size: 14px !important;
//   }

//   .livis-dashboard-header-right-box-date-refreshed-wrap {
//     font-size: 12px !important;
//   }

//   .livis-dashboard-dropdown-title {
//     font-size: 14px !important;
//   }

//   .livis-dashboard-dropdown-mini-card-title {
//     font-size: 10px !important;
//   }

//   .livis-dashboard-dropdown-mini-card-value {
//     font-size: 16px !important;
//   }


//   .livis-dasboard-card-sub-details-wrap>div {
//     padding-inline: 0.25rem !important;
//   }

//   .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-value {
//     font-size: 16px !important;
//   }

//   .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-title {
//     font-size: 10px !important;
//   }

//   //dashboard css ends

//   //reports and analytics css starts


//   .livis-reports-analytics-text-two {
//     font-size: 16px !important;
//   }

//   //reports and analytics css ends
// }

// @media (max-width: 1512px) {

//   //dashboard css starts
//   .livis-dashboard-header-title {
//     font-size: 16px !important;
//   }

//   .livis-dashboard-header-right-box-date-refreshed-wrap {
//     font-size: 14px !important;
//   }

//   .livis-dashboard-dropdown-title {
//     font-size: 15px !important;
//   }

//   .livis-dashboard-dropdown-mini-card-value {
//     font-size: 20px !important;
//   }

//   .livis-dashboard-dropdown-mini-card-value img {
//     max-width: 24px !important;
//     height: 24px !important;
//   }

//   .livis-dashboard-dropdown-mini-card-title-value-wrap .livis-dashboard-dropdown-mini-card-value {
//     font-size: 22px !important;
//   }

//   .livis-dasboard-card-sub-details-wrap>div {
//     padding-inline: 0.25rem !important;
//   }

//   .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-value {
//     font-size: 18px !important;
//   }

//   .livis-dasboard-card-sub-details-wrap .livis-dashboard-dropdown-mini-card-title {
//     font-size: 14px !important;
//   }

//   //dashboard css ends

//   //reports and analytics css starts

//   .livis-reports-analytics-text-two {
//     font-size: 18px !important;
//   }

//   //reports and analytics css ends
// }

// @media (max-width: 1700px) {

//   //dashboard css starts 
//   .livis-dasboard-container {
//     padding: 1.25rem !important;
//   }

//   //dashboard css ends
// }


// @media (max-width: 599px) {

//   //audit log css
//   .livis-audit-log-wrap.card .livis-audit-log-event {
//     min-width: calc(100% - 1.5rem) !important;
//     max-width: calc(100% - 1.5rem) !important;
//   }
// }

// @media (max-width: 768px) {

//   //audit log css starts

//   .livis-audit-log-wrap.card .livis-audit-log-event {
//     min-width: 45% !important;
//     max-width: 45% !important;
//     flex-direction: column !important;
//     justify-content: flex-start !important;
//     align-items: flex-start !important;
//     font-size: 16px !important;
//     margin-left: 1.5rem !important;
//     margin-block: 0rem 1rem !important;
//   }

//   .livis-permission-card-name-title {
//     font-size: 14px !important;
//   }

//   .livis-audit-list-wrap {
//     justify-content: flex-start !important;
//   }

//   .livis-audit-log-wrap.card .livis-audit-log-info,
//   .livis-audit-log-wrap.card .livis-audit-log-details {
//     margin-bottom: 3rem !important;
//   }

//   //audit log css ends
// }

// @media (max-width: 899px) {

//   //audit logs css starts
//   .livis-audit-log-wrap.card .livis-audit-log-event {
//     min-width: 45% !important;
//     max-width: 45% !important;
//     flex-direction: column !important;
//     justify-content: flex-start !important;
//     align-items: flex-start !important;
//     font-size: 16px !important;
//     margin-left: 1.5rem !important;
//     margin-block: 0rem 1rem !important;
//   }

//   //audit logs css ends
// }

// @media (max-width: 1024px) {
//   // .livis-audit-log-wrap.card .livis-audit-log-event {
//   //   min-width: 50% !important;
//   //   max-width: 50% !important;
//   //   flex-direction: column !important;
//   //   justify-content: flex-start;
//   //   align-items: flex-start;
//   // }

//   //audit log media query starts
//   .livis-audit-log-event {
//     min-width: 10rem !important;
//     max-width: 10rem !important;
//   }

//   .livis-audit-log-time {
//     padding-right: 2rem !important;
//     min-width: 10rem !important;
//     max-width: 10rem !important;
//   }

//   .livis-audit-log-info {
//     min-width: 15rem !important;
//     max-width: 15rem !important;
//   }

//   .livis-audit-log-details {
//     min-width: 17.5rem !important;
//     max-width: 17.5rem !important;
//   }

//   //audit log media query ends
// }

// @media (max-width: 1200px) {

//   //audit log media query starts
//   .livis-audit-log-event {
//     min-width: 12rem !important;
//     max-width: 12rem !important;
//   }
// }

//Max width Only queries ends


//Queries with inervals starts

@media (min-width: 320px) and (max-width: 480px) {

  //custom css starts
  .livis-modal-slide-small {
    width: 85% !important;
  }

  .livis-category-type-card,
  .livis-category-type-card-selected {
    height: 13rem !important;
  }

  .livis-category-type-card button img,
  .livis-category-type-card-selected button img {
    max-width: 110px !important;
    max-height: 60px !important;
  }

  .MuiTextField-root>label {
    font-size: 15px !important;
  }

  .livis-stepper-title {
    font-size: 16px !important;
  }

  .livis-form-table-data {
    font-family: "Noto Sans Bold" !important;
  }

  .MuiTypography-body1 {
    font-size: 12px !important;
  }

  .livis-landing-header-btns-mobile {
    display: flex !important;
    justify-content: end !important;
    align-items: center !important;
  }

  .livis-landing-header-btns {
    display: none !important;
  }

  .livis-auth-footer {
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .livis-training-video-wrap {
    display: none;
  }

  .livis-demo-text-show {
    display: block;
  }

  .demo-text-hide {
    display: none;
    font-size: 1rem !important;
    text-transform: capitalize !important;
  }

  .livis-contact-us-text {
    display: none;
  }

  .livis-inspection-card-grid {
    min-width: 100% !important;
  }

  .livis-defect-frequency-legend {
    justify-content: flex-start !important;
  }

  .livis-defect-shortby-text {
    margin-top: 0rem !important;
  }

  .livis-frequency-select-filter {
    margin-top: 0rem !important;
  }



  .livis-landing-logo-wrap {
    display: flex;
    justify-content: center !important;
  }

  // .livis-search-field {
  //   width: 20rem !important;
  // }

  // .livis-search-field input {
  //   height: 2rem !important;
  // }

  .livis-filter-header-sticky {
    padding-inline: 2rem !important;
    width: calc(100% - 4rem) !important;
  }

  .livis-inspection-create-wrap {
    // justify-content: flex-end !important;
    // display: none !important;
  }

  .livis-auth-form-wrap {
    margin-top: 1rem !important;
  }

  .livis-form-table {
    display: flex !important;
    justify-content: space-between !important;
  }

  .livis-form-table-data-header {
    display: flex !important;
    flex-direction: column !important;
    padding-block: 1rem !important;
  }

  .livis-form-table-data {
    display: flex !important;
    flex-direction: column !important;
    // padding-block: 1rem !important;
    flex-wrap: wrap !important;
    overflow-wrap: anywhere !important;
  }

  .livis-intro-modal {
    width: 24rem !important;
    // height: 468px !important;
    border-radius: 0.75rem !important;
  }

  .livis-intro-art-work {
    width: 18.93rem !important;
    padding: 0px !important;
    padding-top: 1rem !important;
  }

  .livis-intro-heading {
    padding: 0rem !important;
  }

  .livis-intro-title {
    max-width: 260px !important;
  }

  .livis-intro-button-wrap {
    max-width: 129px !important;
  }

  .livis-intro-button-wrap button {
    padding: 1rem 1.5rem !important;
    border-radius: 50px !important;
    max-height: 40px;
    margin-block: 2rem 1.625rem !important;
  }

  .livis-landing-header {
    padding: 1rem !important;
  }

  .livis-auth-header-wrap {
    padding-bottom: 0rem !important;
  }

  .livis-landing-logo-wrap {
    padding-left: 0rem !important;
    justify-content: flex-start !important;
  }

  .livis-landing-logo-wrap img {
    width: 15rem !important;
    height: 5rem !important;
  }

  .livis-landing-hamburger-wrap img {
    width: 2rem !important;
    height: 2rem !important;
  }

  .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
  .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    font-size: 15px !important;
  }

  .livis-modal-main-content {
    margin-top: 0.5rem !important;
    margin-inline: 0rem !important;
    max-height: 84vh !important;
  }

  .livis-stepper-steps {
    margin-bottom: 1.5rem !important;
  }

  .livis-stepper-title {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .livis-stepper-form .css-1bkkh3m-MuiGrid-root>.MuiGrid-item {
    padding-top: 0.5rem !important;
  }

  .livis-stepper-form .css-14r86qh-MuiGrid-root {
    margin: 0px !important;
  }

  .MuiStepConnector-horizontal {
    margin-block: -5px !important;
    height: 18px !important;
  }

  .livis-stepper-steps .MuiStep-horizontal {
    padding-block: 0px !important;
  }

  .MuiStepLabel-label.MuiStepLabel-active {
    font-size: 15px !important;
    font-weight: 600 !important;
  }

  .MuiStepLabel-label.MuiStepLabel-completed,
  .MuiStepLabel-label {
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .livis-modal-header-text {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    // line-height: 40px !important;
    letter-spacing: 0.1px !important;
    color: var(--livis-color-primary-gray);
  }

  // .livis-stepper-button {
  //   margin-top: 5rem !important;
  // }

  .livis-station-type-card-selected img,
  .livis-station-type-card img {
    height: 80px !important;
    border-radius: 12px !important;
    margin-top: 1rem !important;
  }

  .css-46bh2p-MuiCardContent-root,
  .livis-station-card-content {
    padding: 0px !important;
    padding-block: 4px !important;
    text-align: center !important;
  }

  // .livis-station-type-card {
  //   border: none !important;
  //   border-radius: 0rem !important;
  // }

  .livis-stepper-form {
    // max-height: 62vh !important;
    overflow: auto !important;
  }

  .livis-option-btn {
    font-size: 12px !important;
    padding: 15px 10px !important;
  }

  .livis-controller-tab-icon {
    width: 16px !important;
    height: 16px !important;
  }

  .livis-confirm-close-modal {
    // width: 25rem !important;
    width: calc(100% - 4rem) !important;
    // height: 15rem !important;
  }

  .livis-menu-list-wrap {
    margin-inline: 1rem !important;
  }

  .livis-auth-header-logo-wrap img {
    width: 150px !important;
  }

  //custom css ends

  //tag management css starts
  .livis-tag-select-step-number-text .MuiTypography-body1 {
    margin-bottom: 1.43rem !important;
  }

  .livis-tag-select-search-wrap .livis-search-field>div {
    min-height: 32px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  //tag management css ends

  .livis-category-type-scrollable-wrap {
    min-height: 60vh !important;
  }


  //use case css starts 
  .livis-usecase-no-data {
    margin-top: 9rem !important;
  }

  .livis-usecase-no-data p:first-child {
    font-size: 2rem !important;
    display: none !important;
  }

  .livis-usecase-no-data p:last-child {
    font-size: 1.8rem !important;
  }

  .livis-usecase-no-data p:nth-child(2) {
    font-size: 1.45rem !important;
  }

  .livis-usecase-no-data img {
    width: 8rem !important;
    height: 7.5rem !important;
  }

  .livis-usecase-preview-wrap {
    width: 16.5rem !important;
  }

  .livis-usecase-title p {
    font-size: 16px !important;
  }

  .livis-usecase-statics div p:nth-child(1) {
    font-size: 16px !important;
  }

  .livis-usecase-statics div p:nth-child(2) {
    font-size: 13px !important;
  }

  .livis-usecase-upper-wrap {
    margin-left: 0px !important;
  }

  //use case css ends 

  //inspection station css starts

  .livis-inspection-header-wrap {
    padding-inline: 1rem !important;
  }

  .livis-inspection-filter-wrap {
    // margin-inline: 2rem !important;
  }

  .livis-inspection-filter-wrap {
    margin: 1rem;
  }

  .livis-inspection-donut {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 0.625rem !important;
  }

  .livis-inspection-data-wrap,
  .livis-usecase-data-wrap {
    margin-inline: 1rem !important;
  }

  .livis-inspection-part-deployment-wrap-mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .livis-inspection-part-deployment-wrap {
    display: none !important;
  }

  .livis-inspection-static-data p:nth-child(1) {
    margin-bottom: 1rem !important;
    text-align: center;
  }

  .livis-inspection-static-data p:nth-child(2) {
    margin-bottom: 0.5rem !important;
    text-align: center;
  }

  .livis-inspection-static-data p:nth-child(3) {
    margin-bottom: 1rem !important;
    text-align: center;
  }

  .livis-inspection-type-text p {
    font-size: 13px !important;
  }

  .livis-inspection-title {
    font-size: 16px !important;
  }

  .livis-inspection-statics-wrap div p:nth-child(1) {
    font-size: 18px !important;
  }

  .livis-inspection-statics-wrap div p:nth-child(2) {
    font-size: 13px !important;
  }

  .livis-inspection-location-wrap p:nth-child(1),
  .livis-inspection-location-wrap p:nth-child(2) {
    font-size: 13px !important;
  }

  .livis-inspection-view-wrap button,
  .livis-usecase-action-wrap button,
  .inspection-station-button button {
    min-width: 73px !important;
    min-height: 36px !important;
  }

  .livis-inspection-station-recet-user-divider-mobile,
  .livis-inspection-station-recet-user-mobile {
    display: none !important;
  }

  .livis-inspection-statics-wrap {
    justify-content: flex-start !important;
  }

  .livis-inspection-station-quality-score-divider-mobile {
    margin-inline: 3rem !important;
  }

  .livis-inspection-station-count p {
    font-size: 14px !important;
  }

  .livis-inspection-legend-wrap p {
    font-size: 13px !important;
  }

  .livis-create-btn-wrap button {
    font-size: 14px !important;
  }

  .livis-inspection-dashboard-card-wrap {
    min-height: 21rem !important;
    max-height: 21rem !important;
  }

  .livis-inspection-dashboard-card-wrap>div {
    min-height: 21.5rem !important;
    max-height: 21.5rem !important;
  }

  .livis-defect-frequency-graph {
    margin-top: 0rem !important;
  }

  .livis-inspection-graph-header .livis-defect-frequency-text {
    margin-top: 0px !important;
  }

  .livis-defect-frequency-legend p {
    margin-top: 10px !important;
  }

  .livis-defect-frequency-graph {
    height: 15rem !important;
  }

  .livis-donut-frequency-graph {
    height: 15rem !important;
    margin-top: 1rem !important;
  }

  .livis-inspection-create-modal {
    // width: 27rem !important;
    width: calc(100% - 3rem) !important;
    min-height: 30rem !important;
    // max-height: 59rem !important;
    padding-inline: 1.5rem !important;
  }

  .livis-station-type-card-selected {
    // border: none !important;
    color: var(--livis-color-primary-dblue) !important;
  }

  .livis-inspection-type div:nth-child(1) {
    height: 2.88rem;
    width: 2.88rem;
  }

  .livis-inpection-card {
    padding-right: 16px !important;
  }

  .livis-inspection-create-modal.livis-operator-builder-preview-modal {
    width: 80% !important;
  }

  //inspection station css ends

  //data management css starts

  .livis-manage-data-count p {
    font-size: 14px !important;
  }

  .livis-data-management-legend-wrap p {
    font-size: 13px !important;
  }

  //data management css ends

  // .livis-part-filter-wrap {
  //   margin-inline: 2rem !important;
  // }

  // .livis-part-main-side-wrap {
  //   flex-direction: column;
  // }

  // .livis-sidebar-menu-wrap {
  //   position: static;
  //   min-width: 100% !important;
  //   display: flex;
  // }

  // .livis-training-menu-wrap {
  //   display: flex;
  //   flex-wrap: wrap !important;
  //   flex-direction: row !important;
  //   min-width: 100% !important;
  // }

  // .livis-traning-menu-item,
  // .livis-training-data-status,
  // .livis-trash-menu-item {
  //   max-width: 48% !important;
  // }

  // .livis-sidebar-divider {
  //   display: none !important;
  // }

  // .livis-part-main-wrap {
  //   position: static;
  //   min-width: 100% !important;
  // }

  //part css starts

  .livis-part-data-wrap {
    margin-inline: 0.5rem !important;
  }

  .livis-part-filter-wrap {
    margin-inline: 1rem !important;
  }

  //part css ends


  //training css starts

  .livis-training-data-status-content p:nth-child(2) {
    display: none !important;
  }


  .livis-training-menu-content p {
    font-size: 8px !important;
  }

  .livis-training-menu-wrap,
  .livis-usecase-menu-wrap {
    // width: 100% !important;
  }

  //training css ends

  //trash css starts

  .livis-trash-menu-content img {
    margin-right: 0.5rem !important;
  }

  //trash css ends

  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 48rem ;
  }

}

@media(min-width: 320px) and (max-width: 480px) {
  .livis-stepper-body{
    max-height: 24rem !important;
  }

  .livis-add-recipe-modal .livis-stepper-body {
    max-height: 18rem !important;
  }
}

@media(min-width: 480px) and (min-height: 999px) {
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 55rem !important;
  }
}

@media (min-width: 0px) and (max-width: 899px){
  
.livis-training-data-progress-container-see-more {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 12px;
  margin-top: 1rem;
  height: 1348px;
  margin-bottom: 1rem;

  .highcharts-no-data{
    text{
      font-weight: normal !important;
    }
  }

  & .livis-training-data-progress-content {
    height: 223px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid var(--livis-part-drag-container);
    background-color: var(--livis-color-background-white);
    display: flex;
    align-items: end;
    justify-content: center;
  }

  & .livis-training-data-progress-data {
    padding: 0 25px;
    height: 71px;

    & .livis-training-data-progress-stats {
      margin-bottom: 1rem;

      & p:nth-child(1) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: var(--livis-color-primary-gray);
        margin: 0;
        margin-right: 1.5rem;
      }

      & p:nth-child(2) {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: var(--livis-gray-font-color);
        margin: 0;
      }
    }
  }
}
}

@media (min-width: 400px) and (max-width: 666px) {

  //inspection css starts
  .livis-inspection-create-modal {
    width: 90% !important;
  }

  //inspection css ends
}

@media (min-width: 481px) and (max-width: 768px) {

  //inspection css starts

  .livis-inspection-filter-wrap {
    // margin-top: 0px !important;
  }

  .livis-inspection-info-error-chip {
    display: none !important;
  }

  .livis-inpection-card {
    padding-right: 16px !important;

    .livis-inspection-card-grid-container {
      height: 100%;
    }
  }

  .livis-inspection-part-deployment-wrap-mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .livis-inspection-part-deployment-wrap {
    display: none !important;
  }

  .livis-inspection-type-text p {
    font-size: 13px !important;
  }

  .livis-inspection-title {
    font-size: 16px !important;
  }

  .livis-inspection-statics-wrap div p:nth-child(1) {
    font-size: 18px !important;
  }

  .livis-inspection-statics-wrap div p:nth-child(2) {
    font-size: 13px !important;
  }

  .livis-inspection-location-wrap p:nth-child(1),
  .livis-inspection-location-wrap p:nth-child(2) {
    font-size: 13px !important;
  }

  .livis-inspection-view-wrap button,
  .livis-usecase-action-wrap button,
  .inspection-station-button button {
    // min-width: 83px !important;
    min-height: 36px !important;
    font-size: 14px !important;
  }

  .livis-inspection-station-recet-user-divider-mobile,
  .livis-inspection-station-recet-user-mobile {
    display: none !important;
  }

  .livis-inspection-statics-wrap {
    justify-content: flex-start !important;
  }

  .livis-inspection-station-quality-score-divider-mobile {
    margin-inline: 4rem !important;
  }

  .livis-inspection-station-count p {
    font-size: 14px !important;
  }

  .livis-inspection-legend-wrap p {
    font-size: 13px !important;
  }

  .livis-tutorial-text-wrap {
    max-width: 75% !important;
  }

  .livis-tutorial-text {
    display: flex !important;
  }

  .livis-tutorial-text-btn {
    width: 30% !important;
    align-items: flex-end !important;
    justify-content: flex-end !important;
    display: flex !important;
  }



  .livis-inspection-create-modal {
    width: 55.5rem !important;
    min-height: 52.731rem !important;
  }

  // .livis-station-type-wrap .css-yllmzn-MuiGrid-root>.MuiGrid-item:not(.livis-station-type-wrap .css-yllmzn-MuiGrid-root>.MuiGrid-item:first-child) {
  //   padding-left: 6px !important;
  // }



  .livis-station-card-content {
    font-style: normal;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 50px;
    color: var(--livis-color-primary-gray);
  }

  .livis-station-type-wrap {
    margin-bottom: 13rem !important;
  }


  .livis-inspection-header-wrap {
    padding-inline: 1rem !important;
  }

  .livis-inspection-filter-wrap {
    // margin-inline: 2.5rem !important;
  }

  .livis-inspection-data-wrap,
  .livis-usecase-data-wrap {
    margin-inline: 2rem !important;
  }

  .livis-inspection-part-deployment-wrap-mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .livis-inspection-part-deployment-wrap {
    display: none !important;
  }

  .inspection-station-chip-mobile,
  .livis-inspection-location-wrap-mobile {
    display: none !important;
  }

  .livis-inspection-vertical-line-mobile,
  .livis-inspection-statics-mobile {
    display: none !important;
  }

  .livis-inspection-dashboard-card-wrap,
  .livis-inspection-total-statics {
    min-height: 25rem !important;
    align-items: center !important;
    display: flex !important;
  }

  .livis-inspection-dashboard-card-wrap>div {
    padding: 0px !important;
    min-height: 25rem !important;
    width: 100% !important;
  }

  .livis-defect-frequency-graph {
    width: 95%;
    height: 20rem !important;
    margin: auto;
    margin-top: 0.5rem !important;
  }

  .livis-inspection-type div:nth-child(1) {
    height: 2.9rem;
    width: 2.9rem;
  }

  .livis-inspection-filter-wrap {
    margin-inline: 1rem !important;
  }

  //inspection css ends

  //training css starts

  .livis-training-card-wrap:nth-child(2) {
    margin: 1rem !important;
  }

  .livis-training-card-wrap:nth-child(1) {
    margin: 1rem !important;
  }

  //training css ends

  //usecase starts
  .livis-usecase-header-wrap {
    margin: 2rem !important;
  }

  .livis-usecase-header-wrap {
    margin: 2rem !important;
  }

  .livis-usecase-no-data {
    margin-top: 15rem !important;
  }

  .livis-usecase-no-data p:first-child {
    font-size: 2rem !important;
  }

  .livis-usecase-no-data p:last-child {
    font-size: 1.4rem !important;
  }

  .livis-usecase-no-data p:nth-child(2) {
    font-size: 1.25rem !important;
  }

  .livis-usecase-no-data img {
    width: 8rem !important;
    height: 7.5rem !important;
  }

  button.livis-usecase-live-button img {
    width: 16px !important;
    height: 16px !important;
  }

  .livis-usecase-preview-wrap {
    width: 18rem !important;
  }

  .livis-usecase-status-wrap div.active,
  .livis-usecase-status-wrap div.inactive {
    width: 4px !important;
  }

  .livis-usecase-list-preview-mobile {
    // display: none !important;
  }

  .livis-usecase-card-grid {
    min-height: 8.75rem !important;
  }

  .livis-usecase-live-button-mobile {
    text-indent: -9999999px !important;
    max-width: 40px !important;
    min-width: 40px !important;
  }

  .livis-usecase-list-three-dots-wrap button {
    max-width: 24px !important;
    min-width: 24px !important;
    width: 24px !important;
  }

  .livis-usecase-list-three-dots-wrap button.livis-usecase-live-button {
    max-width: 40px !important;
    min-width: 40px !important;
    padding: 0px !important;
  }

  .livis-usecase-list-three-dots-wrap button.livis-usecase-live-button span {
    margin: 0rem !important;
  }

  .livis-usecase-view-live-three-dot-wrap .livis-usecase-list-three-dots-wrap {
    // margin-left: 1.5rem !important;
    margin-right: 0.5rem !important;
  }

  .livis-usecase-view-live-three-dot-wrap .livis-usecase-view-btn {
    margin-left: 1rem !important;
  }

  .livis-usecase-card {
    padding-top: 1.07143rem !important;
  }

  .livis-usecase-title p {
    margin-top: 0.875rem !important;
  }

  //usecase ends

  //tag managemenet css starts

  .livis-category-type-card,
  .livis-category-type-card-selected {
    min-height: 16.3rem !important;
  }

  .livis-category-type-card button .livis-category-type-text,
  .livis-category-type-card-selected button .livis-category-type-text {
    margin-top: 2.5rem !important;
  }

  .livis-category-type-text {
    font-size: 1.25rem !important;
  }

  .livis-category-type-scrollable-wrap {
    min-height: 56vh !important;
  }

  .livis-tag-select-step-number-text .MuiTypography-body1 {
    font-size: 15px !important;
  }

  .livis-tag-select-search-wrap .livis-search-field>div {
    min-height: 32px !important;
    min-width: 195px !important;
    max-width: 195px !important;
  }

  //tag managemenet css ends

  //custom css starts 

  // .livis-stepper-button {
  //   margin-top: 11.8rem !important;
  // }

  .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
  .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    font-size: 15px !important;
  }

  .livis-create-btn-wrap button {
    font-size: 14px !important;
  }

  .livis-modal-slide-animation {
    width: 70%;
  }

  .livis-intro-modal {
    width: 564px !important;
    height: 566px !important;
  }

  .css-46bh2p-MuiCardContent-root {
    padding: 0px !important;
  }

  .livis-intro-art-work {
    width: 487px !important;
    height: 202px;
    padding-top: 30px !important;
  }

  .livis-intro-heading {
    padding-top: 2.5rem !important;
  }

  .livis-intro-title {
    max-width: 31.625rem !important;
  }

  .livis-intro-button-wrap {
    max-width: 177px !important;
  }

  .livis-intro-button-wrap button {
    padding: 1rem 1.5rem !important;
    border-radius: 50px !important;
  }

  .livis-auth-form-wrap {
    margin-top: 1rem !important;
  }

  //custom css ends

  //data management css starts

  .livis-manage-data-count p {
    font-size: 14px !important;
  }

  .livis-data-management-legend-wrap p {
    font-size: 13px !important;
  }

  //data management css ends

}

@media (min-width: 481px) and (max-width: 599px) {

  //inspection css starts
  .livis-defect-frequency-graph {
    height: 14rem !important;
  }

  //inspection css ends

  .livis-add-part-modal .livis-stepper-body {
    max-height: 15rem !important;
   }
}

@media (min-width: 598px) and (max-width: 768px) {
  .livis-tag-select-wrapper {
    max-height: 42vh !important;
  }

  .livis-usecase-tag-selection:has(.livis-tag-select-wrapper) .livis-stepper-body {
    max-height: 44rem !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {

  //inspection css starts
  .livis-inspection-filter-wrap {
    // margin-top: 0px !important;
  }

  .livis-inspection-part-deployment-wrap-mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .livis-inspection-part-deployment-wrap {
    display: none !important;
  }

  .livis-tutorial-text-btn button {
    min-width: 6.43rem !important;
    min-height: 2.725rem !important;
    font-size: 1rem !important;
  }

  .livis-defect-frequency-text {
    margin: 0px !important;
  }

  .livis-defect-frequency-graph {
    margin-top: 0.75rem !important;
  }

  .livis-defect-frequency-graph {
    width: 95%;
    height: 16rem !important;
    margin: auto;
  }

  .livis-inspection-header-video-wrap {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .livis-inspection-part-deployment-wrap-mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .livis-inspection-part-deployment-wrap {
    display: none !important;
  }

  .inspection-station-chip-mobile,
  .livis-inspection-location-wrap-mobile {
    display: none !important;
  }

  .livis-inspection-vertical-line-mobile,
  .livis-inspection-statics-mobile {
    display: none !important;
  }

  .livis-station-type-card .MuiCardContent-root,
  .livis-station-type-card-selected .MuiCardContent-root {
    padding: 0.2rem !important;
  }

  .livis-inspection-create-modal {
    width: 59.539rem !important;
  }

  //inspection css ends

  //usecase css starts
  .livis-usecase-header-wrap {
    margin: 2rem !important;
  }

  .livis-usecase-card {
    padding-top: 0.92308rem !important;
  }

  .livis-usecase-preview-wrap {
    max-width: 14.077rem !important;
    max-height: 8.308rem !important;
  }

  .livis-usecase-create-at p {
    padding-right: 0rem !important;
  }

  .livis-usecase-divider-mobile,
  .livis-usecase-labeling-mobile {
    display: none !important;
  }

  .livis-usecase-no-data {
    margin-top: 5.5rem !important;
  }

  .livis-usecase-no-data p:first-child {
    font-size: 1.55rem !important;
  }

  .livis-usecase-no-data p:last-child {
    font-size: 1.4rem !important;
  }

  .livis-usecase-no-data p:nth-child(2) {
    font-size: 1.25rem !important;
  }

  .livis-usecase-no-data img {
    width: 8rem !important;
    height: 7.5rem !important;
  }

  //usecase css ends

  //training css starts
  .livis-training-video-wrap {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2%;
  }

  .livis-training-card-wrap:nth-child(2) {
    display: none !important;
  }

  .livis-training-card-wrap:nth-child(1) {
    margin: 2.68rem 2.5rem 2.68rem 0.375rem !important;
  }

  //training css ends

  //custom css starts
  .livis-modal-slide-animation {
    width: 80%;
  }

  .livis-modal-slide-animation {
    width: 80%;
  }

  .livis-intro-modal {
    width: 43.625rem !important;
  }

  .livis-intro-art-work {
    width: 35.8125rem !important;
    padding-top: 2.5rem !important;
  }

  .livis-intro-heading {
    padding-top: 2.5rem !important;
  }

  .livis-intro-title {
    max-width: 31.625rem !important;
  }

  .livis-intro-button-wrap {
    max-width: 11.06rem !important;
  }

  .livis-intro-button-wrap button {
    padding: 1rem 1.5rem !important;
    border-radius: 50px !important;
  }

  .livis-auth-form-wrap {
    margin-top: 1rem !important;
  }

  // .livis-stepper-button {
  //   margin-top: 8.95rem !important;
  // }

  .livis-modal-slide-small {
    width: 50% !important;
  }

  .livis-confirm-close-modal {
    width: 29.75rem !important;
    // height: 13.25rem !important;
  }

  //custom css ends

}

@media (min-width: 900px) and (max-width: 1024px) {
  //inspection css starts

  .livis-defect-frequency-graph {
    margin-top: 0px !important;
    max-height: 14rem !important;
    width: 95% !important;
  }

  //inspection css ends

  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 34rem !important;
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  //inspection css starts

  .livis-defect-shortby-wrap {
    display: none !important;
  }

  //inspection css ends
}

@media(min-height: 1001px) and (max-height: 1500px){
  .livis-tag-select-wrapper  {
    max-height: 25vh !important;
  }
}


@media (min-width: 900px) and (max-width: 1444px) {

  //inspection css starts
  .livis-major-defect-action {
    justify-content: center !important;
  }

  .livis-inspection-donut-header {
    margin-inline: 1rem !important;
  }

  .livis-inspection-donut-header * {
    text-align: center !important;
  }

  .livis-inspection-dashboard-card-wrap {
    min-height: 23.45rem !important;
    max-height: 23.45rem !important;
  }

  .livis-inspection-dashboard-card-wrap>div {
    min-height: 22.45rem !important;
    max-height: 22.45rem !important;
  }

  .livis-inspection-dashboard-count-card-wrap>div {
    min-height: 23.45rem !important;
    max-height: 23.45rem !important;
  }

  .livis-inspection-header-wrap {
    padding-inline: 2.074075rem !important;
  }

  .livis-inspection-dashboard-card-one {
    min-width: 36.5% !important;
    max-width: 36.5% !important;
  }

  .livis-inspection-dashboard-card-two {
    min-width: 37% !important;
    max-width: 37% !important;
  }

  .livis-inspection-dashboard-card-three {
    min-width: 26.5% !important;
    max-width: 26.5% !important;
  }

  .livis-inspection-card-wrap {
    margin: 0.25rem !important;
  }

  //inspection css ends
}

@media (min-width: 1025px) and (max-width: 1200px) {

  //inspection css starts
  .livis-inspection-filter-wrap {
    // margin-top: 0px !important;
  }

  .livis-inspection-part-deployment-wrap-mobile {
    display: flex !important;
    justify-content: center !important;
  }

  .livis-inspection-part-deployment-wrap {
    display: none !important;
  }

  .livis-inspection-vertical-line-mobile,
  .livis-inspection-statics-mobile {
    display: none !important;
  }

  .livis-defect-frequency-graph {
    width: 20rem;
    height: 16rem;
    margin: auto;
  }

  .livis-donut-frequency-graph {
    width: 100%;
    height: 18.75rem;
    margin: auto;
  }

  .livis-inspection-header-video-wrap {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .livis-defect-frequency-graph {
    margin-top: 0px !important;
    max-height: 14rem !important;
    width: 95% !important;
  }

  .livis-defect-frequency-text {
    margin-block: 0px !important;
  }

  .livis-defect-shortby-wrap {
    display: none !important;
  }

  .livis-defect-frequency-legend {
    justify-content: flex-start !important;
  }

  .livis-inspection-graph-header .livis-defect-frequency-text {
    font-size: 16px !important;
    margin-bottom: 0.075rem !important;
  }

  .livis-frequency-select-filter {
    margin-block: 1rem !important;
    float: right !important;
  }

  .livis-defect-frequency-legend p {
    font-size: 13px !important;
  }

  .livis-frequency-select-form {
    font-size: 14px !important;
    // max-width: 10rem !important;
    // max-height: 2.0625rem !important;
  }

  .livis-major-defect-action a {
    font-size: 15px !important;
  }

  .livis-inspection-create-modal {
    width: 57rem !important;
    padding-top: 1rem !important;
  }

  //inspection css ends

  //usecase css starts
  .livis-usecase-header-wrap {
    margin: 2rem !important;
  }

  //usecase css ends

  //custom css starts

  .livis-highchart-custom * {
    height: 15rem !important;
  }

  .livis-modal-slide-animation {
    width: 70% !important;
  }

  .livis-modal-slide-animation {
    width: 70% !important;
  }

  .livis-intro-modal {
    width: 43.625rem !important;
  }

  .livis-intro-art-work {
    width: 35.8125rem !important;
    padding-top: 2.5rem !important;
  }

  .livis-intro-heading {
    padding-top: 2.5rem !important;
  }

  .livis-intro-title {
    max-width: 31.625rem !important;
  }

  .livis-intro-button-wrap {
    max-width: 11.06rem !important;
  }

  .livis-intro-button-wrap button {
    padding: 1rem 1.5rem !important;
    border-radius: 50px !important;
  }

  .livis-modal-slide-small {
    width: 50% !important;
  }

  //custom css ends


  //training css starts

  .livis-training-card-wrap:nth-child(2) {
    display: none !important;
  }

  .livis-training-card-wrap:nth-child(1) {
    margin: 2.68rem 2.5rem 2.68rem 0.375rem !important;
  }

  //training css ends

}

@media (min-width: 1201px) and (max-width: 1500px) {

  //tag management css starts
  .livis-tag-management-tag-img-wrap {
    height: 5rem !important;
  }

  .livis-tag-management-tag-created-wrap {
    font-size: 10px !important;
  }

  //tag management css ends

  //custom css starts
  .livis-intro-modal {
    width: 43.625rem !important;
    border-radius: 1.25rem !important;
  }

  // .livis-stepper-button {
  //   margin-top: 9.2rem !important;
  // }

  .livis-intro-art-work {
    width: 35.8125rem !important;
    padding-top: 2.5rem !important;
  }

  .livis-intro-heading {
    padding-top: 2.5rem !important;
  }

  .livis-intro-title {
    max-width: 31.625rem !important;
  }

  .livis-intro-button-wrap {
    max-width: 11.06rem !important;
  }

  .livis-intro-button-wrap button {
    padding: 1rem 1.5rem !important;
    border-radius: 50px !important;
  }

  .livis-donut-frequency-graph {
    width: 100%;
    height: 18.75rem;
    margin: auto;
  }

  .livis-modal-slide-small {
    width: 40% !important;
  }

  .livis-confirm-close-modal {
    width: 29.75rem !important;
    // height: 13.25rem !important;
  }

  //custom css ends

  //inspection css starts
  .livis-inspection-create-modal {
    width: 57.19rem !important;
  }

  .livis-station-card-content {
    margin: 0px !important;
    padding: 0px !important;
  }

  .livis-inspection-header-video-wrap {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .livis-defect-frequency-graph {
    width: 95%;
    height: 14rem;
    margin: auto;
  }

  //inspection css ends

  // .livis-usecase-live-button span {
  //   text-indent: -9999px;
  //   margin: 0px !important;
  // }

  //training css starts

  .livis-training-card-wrap:nth-child(2) {
    display: none !important;
  }

  .livis-training-card-wrap:nth-child(1) {
    margin: 2.68rem 2.5rem 2.68rem 0.375rem !important;
  }

  //training css ends

  //usecase css starts

  .livis-usecase-list-wrap .livis-usecase-statics div {
    margin-right: 1rem;
  }

  button.livis-usecase-live-button {
    width: 0.5rem !important;
  }

  //usecase css ends

}

// @media (min-width: 1200px) and (max-width: 1365px) {
//   //data management css
//   .livis-manage-data-header>div {
//     display: block !important;
//   }
// }

// @media (min-width: 1513px) and (max-width: 1593px) {
//   //data management css
//   .livis-manage-data-header>div {
//     display: block !important;
//   }
// }

@media (max-height: 700px){
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 18rem; 
  }
}

@media (max-width: 1300px) and (min-width: 900px) {

  //use case css starts
  .livis-usecase-industry-info-wrap {
    display: none !important;
  }

  .livis-usecase-view-live-three-dot-wrap {
    min-width: calc(14.416667% + 16.666667%);
  }

  //use case css ends

  //inspection station css starts
  .livis-inspection-create-modal.livis-operator-builder-preview-modal {
    width: 95% !important;
  }

  //inspection station css ends
}

@media (max-width: 2300px) and (min-width: 1512px) {
  .livis-stepper-body{
    max-height: 27rem;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .livis-usecase-tag-selection:has(.livis-tag-select-wrapper) .livis-stepper-body{
    max-height: 46rem !important;
  }
  .livis-tag-select-wrapper {
    max-height: 28vh !important;
  }
}

@media (min-width: 300px) and (max-width: 600px) {
  .livis-recipe-legend-wrap{
    display: flex !important;
    justify-content: flex-end !important;

    .livis-legend-circle-active , .livis-legend-circle-complete {
      margin: 0px !important;
    }
  }
}


@media (min-width: 320px) and (min-height: 1000px) {
  .livis-tag-select-wrapper {
    max-height: 24vh !important;
  }
  .livis-usecase-tag-selection:has(.livis-tag-select-wrapper) .livis-stepper-body{
    max-height: 46rem !important;
  }
}

@media (min-width: 480px) and (min-height: 999px) {
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 55rem !important;
  }
  .livis-tag-select-wrapper {
    max-height: 24vh !important;
  }
}

@media (min-width: 769px) and (max-width: 899px) and (max-height: 1000px) {
  .livis-usecase-tag-selection:has(.livis-tag-select-wrapper) .livis-stepper-body{
    max-height: 46rem !important;
  }
  .livis-tag-select-wrapper {
    max-height: 38vh !important;
  }
}

@media (min-width: 768px) and (min-height: 1024px) {
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 60rem !important;
  }
}

@media (min-width:900px) and (max-width: 1024px) and (min-height: 905px) and (max-height: 998px) {
  .livis-tag-select-wrapper {
    max-height: 28vh !important;
  }
}

@media (min-width:600px) and (max-width: 770px) and (min-height: 500px) and (max-height: 999px){
  .livis-tag-select-wrapper {
    max-height: 46vh !important;
  }
  .livis-usecase-tag-selection:has(.livis-tag-select-wrapper) .livis-stepper-body{
    max-height: 60rem !important;
  }
}

@media (min-width: 1024px) and (min-height: 768px) {
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 70rem !important;
  }
}


@media (min-width: 1200px) and (min-height: 900px) {
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 80rem !important;
  }
}

@media (min-width: 1600px) and (min-height: 1000px) {
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 90rem !important;
  }
  
}

@media (min-width: 1920px) and (min-height: 1080px) {
  .livis-usecase-tag-selection .livis-stepper-body {
    max-height: 100rem !important;
  }
  
  .livis-tag-select-wrapper {
    max-height: 38vh !important;
  }
}


//Queries with inervals ends