/*

1. font family
2. variables
3. font rem standards
4. btns class
5. input class

------------------

1. Module Wise Css

// inspection station css starts
......
// inspection station css ends


For all other modules


2. Custom mui css


*/



/*
  Important Note
  1. Use only rem for the font size with the proper calculations
      eg :- required font-size - 16px , then 16/16 = 1 rem (where denominator 16 is constant ) , similarly for 32px - 2rem
  2. Use vh and vw or rem for the width and height (which suits best for the responsiveness) 
  3. Make sure to apply font family which is declared below
  4. do not apply any css which would disturb other grid / sections.
  5. make sure to verify all the pages  once you are writing
*/



// importing custom font for usage in the entire product
@font-face {
  font-family: "Noto Sans Regular";
  src: url("fonts/Noto_Sans/NotoSans-Regular.ttf");
}

@font-face {
  font-family: "Noto Sans Bold";
  src: url("fonts/Noto_Sans/NotoSans-ExtraBold.ttf");
}

@font-face {
  font-family: "Noto Sans SemiBold";
  src: url("fonts/Noto_Sans/NotoSans-Medium.ttf");
}

// defined general light theme colors which will be applied to body
//usage - eg:- color : var(--livis-color-primary-blue)
.livis-light-theme {
  --livis-color-primary-blue: #0857de;
  --livis-color-primary-dblue: #1a26c1;
  --livis-color-primary-lblue: #8c92e0;
  --livis-color-primary-gray: #292931;
  --livis-color-secondary-red: #b50000;
  --livis-color-secondary-yellow: #cdc106;
  --livis-color-secondary-blue: #4f0eca;
  --livis-color-secondary-skyblu: #117cb6;
  --livis-color-secondary-tail: #13ab93;
  --livis-color-secondary-pink: #b00058;
  --livis-color-secondary-golden: #d0b109;
  --livis-color-secondary-orange: #e14f00;
  --livis-color-background: #f5f5fa;
  --livis-color-background-white: #fefeff;
  --livis-color-disabled: #e9e9ea;
  --livis-white-color: #ffffff;
  --livis-gray-font-color: #69696e;
  --livis-gray-dark-font-color: #54545a;
  --livis-gray100-font-color: #3e3e45;
  --livis-black-font-color: #000000;
  --livis-red-font-color: #bc0000;
  --livis-pink-font-color: #e7b2cd;
  --livis-input-placeholder-color: #7f7f83;
  --livis-search-background-color: #e8e9f9;
  --livis-active-color: #59c4b3;
  --livis-inactive-color: #ed9566;
  --livis-complete-color: #d0b109;
  --livis-connection-color: #165374;
  --livis-form-select-button-background-color: #e7f2f8;
  --livis-form-select-border-background-color: rgba(22, 83, 116, 0.5);
  --livis-form-table-background-color: #e8e9f9;
  --livis-error-color: #971717;
  --livis-warning-color: #5c550a;
  --livis-success-color: #0e5d27;
  --livis-info-color: #165374;
  --livis-tag-select-background: rgb(232, 233, 249, 0.15);
  --livis-tag-unselected: #afb3e9;
  --livis-autocomplete-chip-button: rgb(22, 83, 116, 0.5);
  --livis-autocomplete-chip-button-selected: #165374;
  --livis-hover-color: #e7f2f8;
  --livis-menu-selected: #f2f6fd;
  --livis-border-color: #d3e0ff;
  --livis-part-controller-button-background: rgb(232, 233, 249, 0.6);
  --livis-preview-button-color: #9ecfae;
  --livivs-preview-live-color: #ff0000;
  --livis-part-box-background: #f8faff;
  --livis-part-box-border: #d3e0ff;
  --livis-instruction-secondary-color: #747474;
  --livis-chip-data-background-color: #f5f5f5;
  --livis-chip-data-font-color: #5f5f5f;
  --livis-labelled-color: #3da05d;
  --livis-unlabelled-color: #c43333;
  --livis-part-data-flag-chip-color: #faf9e6;
  --livis-part-drag-container: #a5bfff;
  --livis-data-form-header-color: #2a21ab;
  --livis-dropdown-list-background-color: #f8f7ff;
  --livis-flagged-lengend-circle-color: #8c8218;
  --livis-undefined-lengend-circle-color: #297aa6;
  --livis-asseembly-chip-background: #fcede5;
  --livis-asseembly-chip-text-color: #823712;
  --livis-usecase-feature-background: #e6f3ea;
  --livis-usecase-defect-background: #f7e5e5;
  --livis-operator-accepted-color: #108234;
  --livis-card-disabled-indicator: rgb(138, 138, 138);
  --livis-sso-btn-border-color: #d1d4f3;
  --livis-reports-download-btn-color: #fafafa;
  --livis-operator-panel-accordian-border-color: #c7d7ff;
  --livis-add-part-border-color: #dddddd;
  --livis-add-part-camera-title-success: #0a9441;
  --livis-add-part-camera-title-error: #d10404;
  --livis-add-part-camera-title-disabled: #696969;
  --livis-cobot-controller-instruction-wrapper-bg: #fcfef600;
  --livis-report-drill-down-color: #09732b;
  --livis-contact-us-border-color: #d4d4d6;
  --livis-part-rejected-color: #d40000;
  --livis-disabled-card-text-color: #f8f8f8;
  --livis-inspection-type-text-color: #0c18c0;
  --livis-inspection-static-color: #4851cd;
  --livis-usecase-industry-info-color: #4a13ae;
  --livis-usecase-horizontal-line-color: #f4f4f4;
  --livis-usecase-industry-info-bg: #ede6fa;
  --livis-auto-complete-chip-btn: #0d496a;
  --livis-operator-builder-modal-bg: #fffefe;
  --livis-operator-panel-add-feature-modal: #424242;
  --livis-operator-builder-modal-select-bg: #f7f9ff;
  --livis-operator-builder-configuration-upload: #9cbcf2;
  --livis-operator-builder-deactivate-modal-btn: #1976d2;
  --livis-operator-builder-config-panel-bg: #fefefe;
  --livis-operator-builder-feed-overlay-color: #ced1d8;
  --livis-operator-builder-tooltip-color: #5e67d3;
  --livis-operator-panel-cycle-time-border: #e1c6b7;
  --livis-operator-panel-accordian-inspection-heading: #ebeef8;
  --livis-operator-panel-inspection-accordian-text: #222329;
  --livis-reset-btn-background: #edcb0c;
  --livis-reset-btn-color: #3b3307;
  --livis-operator-panel-mini-feed-border: #b5cdf5;
  --livis-operator-panel-user-details-table: #f6f7fd;
  --livis-parameter-frequency-container-border: #99a6c8;
  --livis-legend-circle-bg: #0eb744;
  --livis-legend-without-frequency-circle: #f75809;
  --livis-modal-freqency-indicator-low: #ff5722;
  --livis-cobot-controller-preview-bg: #f6f6fd;
  --livis-mui-overwrite-bg: #e3e4e6;
  --livis-data-manager-img-preview-bg: #f4f8fb;
  --livis-manage-data-wrap-border: #d9d9d9;
  --livis-part-menu-mui-custom-color: #757575;
  --livis-training-feasability-wrap: #da2e2e;
  --livis-credit-wrapper-bg: #f5f8ff;
  --livis-training-status-success-bg: #249449;
  --livis-train-agumentation-border: #e3e3e3;
  --livis-flag-submit-btn-bg: #fefdf3;
  --livis-testing-card-credit-wrap: #eef6fa;
  --livis-recipe-part-container-border: #b2c1d8;
  --livis-recipe-configuration-input-border: #b5c5eb;
  --livis-recipe-details-part-footer-color: #1e1b39;
  --livis-report-part-search-input-bg: #f1f2fb;
  --livis-report-analytics-text-one-color: #c03379;
  --livis-dashboard-header-right-box-border: #e5e5ef;
  --livis-statistics-count-wrap-border: #c1d3fe;
  --livis-minor-pill-color: #4c39f7;
  --livis-preview-rejected-img-bg: #f3e6e6;
  --livis-drilldown-graph-color: #545454;
  --livis-drill-down-defect-graph-border: #d1d3f3;
  --livis-analytics-custom-tooltip-border: #ccc;
  --livis-analytics-custom-tooltip-desc-color: #8884d8;
  --livis-analytics-custom-tooltip-accepted-color: #82ca9d;
  --livis-analytics-custom-tooltip-rejected-color: #ff476f;
  --livis-create-role-permission-block-bg: #fbfbfe;
  --livis-landing-header-hover-tooltip: #4196c5;
  --livis-landing-header-right-routes-hover: #f6f6f6;
  --livis-tag-manage-table-border: #d8d8d9;
  --livis-tag-management-hamburger-border: #f4f5fc;
  --livis-tag-management-hamburger-type-color: #5a5a5a;
  --livis-tag-management-csv-btn-color: #ededfa;
  --livis-tag-management-add-tags-border: #a3a8e6;
  --livis-custom-css-mui-pop-over-bg: #e6eefc;
  --livis-custom-css-mui-bar-primary-color: #2067e1;
  --livis-custom-css-mui-menu-item-color: #1c1b1f;
  --livis-cobot-controller-instruction-wrapper-border: #5c550a40;
  --livis-page-title-selected-border: #1a26c160;
  --livis-inspection-create-wrap-bg: #f1f2fbcc;
  --livis-add-paramter-chip-freq-bg: #0e5d271a;
  --livis-operator-builder-selection-box-bg: #10823430;
  --livis-status-accepted-bg: #108234bf;
  --livis-status-rejected-bg: #b50000bf;
  --livis-operator-panel-fullscreen-info-wrap-bg: #282d3230;
  --livis-parts-frequency-value-wrapper-bg: #1a26c110;
  --livis-helper-text-wrap-bg: #0c883510;
  --livis-helper-text-wrap-border: #0c883595;
  --livis-add-part-parameter-position-apply-to-all-bg: #e8e9f9cc;
  --livis-bl-positionwise-camera-switch: #1f1f1fcc;
  --livis-add-part-parameter-table-wrap-bg: #f6f6f6cc;
  --livis-add-part-parameter-table-wrap-border-bottom: #ddddddcc;
  --livis-manage-data-label-button-bg: #1f1f1f80;
  --livis-manage-data-description-wrap-bg: #1f1f1ff2;
  --livis-operator-accepted-box-shadow: #10823400;
  --livis-augmentation-settings-modal-values-container-bg: #F2F7FF;
  --livis-flag-update-input-bg: #00000060;
  --livis-inference-btn-passed-bg: #0c8835;
  --livis-recipie-usecase-auto-update-accordian-btn-hover-outline: #1A26C140;
  --livis-report-preview-rejected-images-wrap-border: #b500004d;
  --livis-report-preview-accepted-images-wrap-bg: #e6f3ea80;
  --livis-report-preview-accepted-images-wrap-border: #0e5d274d;
  --livis-report-chart-container-color: #9D2929;
  --livis-audit-log-wrap-border: #8c92e050;
  --livis-user-information-text-color: #00000080;
  --livis-user-card-not-provided-color: #29293150;
  --livis-landing-header-routes-wrap-hover-bg: #4747471a;
  --livis-dashboard-header-card-box-shadow: #a8a8a859;
  --livis-dashboard-card-box-shadow: #a8a8a826;
  --livis-dasboard-card-sub-details-divider-bg: #69696ebf;
  --livis-dashboard-avghours-completion-bg: #2e204098;
  --livis-tag-management-header-search-items-wrap-border: #8c92e059;
  --livis-tag-management-header-search-items-hover-bg: #e8e9f980;
  --livis-tag-management-hamburger-name-bg: #ede6fabf;
  --livis-tag-management-tag-wrap-border: #afb3e980;
}

// defined general dark theme colors which will be applied to body
//usage - eg:- color : var(--livis-color-primary-blue)
.livis-dark-theme {
  --livis-color-primary-blue: #117cb6;
  --livis-color-primary-dblue: #117cb6;
  --livis-color-primary-gray: var(--livis-color-primary-gray);
  --livis-color-secondary-red: #b50000;
  --livis-color-secondary-yellow: #cdc106;
  --livis-color-secondary-blue: #4f0eca;
  --livis-color-secondary-skyblu: #117cb6;
  --livis-color-secondary-tail: #13ab93;
  --livis-color-secondary-pink: #b00058;
  --livis-color-secondary-golden: #d0b109;
  --livis-color-secondary-orange: #e14f00;
  --livis-color-background: #000000;
  --livis-color-background-white: #000000;
  --livis-white-color: #ffffff;
  --livis-gray-font-color: #ffffff;
  --livis-black-font-color: #ffffff;
  --livis-red-font-color: #bc0000;
  --livis-input-placeholder-color: #ffffff;
  --livis-custom-table-bg-color: #f5f7fb;
}

// font size customizor (standard font size given for particular breakpoints)
html {
  font-size: 0.75rem;
  overflow: hidden;

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 0.7rem;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    font-size: 0.813rem;
  }

  @media (min-width: 1025px) and (max-width: 1200px) {
    font-size: 0.844rem;
  }

  @media (min-width: 1201px) and (max-width: 1512px) {
    font-size: 0.844rem;
  }

  @media (min-width: 1513px) {
    font-size: 1rem;
  }
}


//css which will be applied throught the body
body {
  width: 100%;
  height: 100%;
  margin: 0rem;
  padding: 0rem !important;
  background: var(--livis-color-background) !important;
  font-family: "Noto Sans Regular";
  color: var(--livis-color-primary-gray) !important;
  overflow: auto !important;
}

// input default css
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
  font-size: 1.25rem !important;
}


path:focus,
g:focus {
  outline: none !important;
}

// hides scrollbar through out the project

// * {
//   -ms-overflow-style: none;
//   scrollbar-width: none;
// }

// *::-webkit-scrollbar {
//   display: none;
// }

// css which will be applied to root id of the html

#root {
  display: flex;
}

//custom css starts

.App {
  width: 100%;
}


.livis-livis-alert {
  border-radius: 8px;
}

.visibility-hidden {
  // visibility: hidden !important;
  color: transparent !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.livis-button-icon {
  margin-right: 0.5rem !important;
}

//livis buttons
.livis-request-demo-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  border: solid 0.0625rem var(--livis-color-primary-dblue);
  color: var(--livis-color-primary-dblue);
  text-decoration: none;
  width: 11.5rem;
  height: 3.625rem;
  padding: 0rem !important;
  font-size: 1rem !important;
}

.livis-request-demo-btn:hover {
  background-color: var(--livis-color-primary-dblue);
  color: var(--livis-white-color);
}

// .livis-primary-btn {
//   margin-top: 1.4rem !important;
//   line-height: 2.56 !important;
//   margin-bottom: 1.2rem !important;
// }

.livis-primary-btn:hover {
  background-color: var(--livis-color-primary-dblue) !important;
}

.livis-disabled-btn {
  width: 100% !important;
  background-color: var(--livis-color-primary-blue) !important;
  opacity: 70% !important;
  border-radius: 1.5625rem !important;
  margin-top: 1.4rem !important;
  margin-bottom: 1.2rem !important;
  padding: 0rem !important;
  font-family: "Noto Sans SemiBold" !important;
  line-height: 2.56 !important;
  letter-spacing: 0.01125rem !important;
  font-size: 1.125rem !important;
  text-transform: none !important;
  color: var(--livis-white-color) !important;
  // padding-block: 0.75rem !important;
}

.livis-secondary-btn {
  width: 100% !important;
  background-color: var(--livis-search-background-color) !important;
  border-radius: 1.5625rem !important;
  margin-top: 3rem !important;
  margin-bottom: 2rem !important;
  padding: 0rem !important;
  font-family: "Noto Sans SemiBold" !important;
  line-height: 1.56 !important;
  letter-spacing: 0.01125rem !important;
  color: var(--livis-color-primary-dblue) !important;
  font-size: 1.125rem !important;
  text-transform: none !important;
}

.livis-connect-btn {
  background-color: var(--livis-search-background-color) !important;
  border-radius: 1.5625rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: unset !important;
}

.livis-sso-btn {
  width: 100%;
  padding: 0.625rem 0rem !important;
  border-radius: 3.125rem !important;
  border: solid 0.0625rem var(--livis-sso-btn-border-color) !important;
  background-color: var(--livis-color-background-white) !important;
  justify-content: left !important;
  font-size: 1rem !important;
  text-transform: none !important;
  letter-spacing: 0.01em;
  line-height: 1.75rem;
  color: var(--livis-gray100-font-color) !important;
}

.livis-sso-btn:hover {
  background-color: var(--livis-color-primary-dblue) !important;
  color: var(--livis-white-color) !important;
}

.livis-create-btn {
  border: 0.0625rem solid var(--livis-color-primary-lblue) !important;
  border-radius: 6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-view-btn {
  border-radius: 3.125rem !important;
  padding: 0.5rem 1rem !important;
  background: var(--livis-color-primary-blue) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-chip-data-background-color) !important;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-remove-btn {
  border-radius: 3.125rem !important;
  // padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  text-transform: unset !important;
  background-color: var(--livis-color-secondary-red) !important;
  box-shadow: none !important;
  color: var(--livis-white-color) !important;
}

.livis-back-btn {
  border-radius: 50px !important;
  border: solid 1px var(--livis-color-primary-lblue) !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}

.livis-modal-cancel-btn {
  width: 1rem;
  height: 1rem;
  margin-inline: 0.625rem;
  cursor: pointer;
  box-shadow: none !important;
}

.livis-modal-cancel-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: none !important;
}

.livis-modal-btn-round {
  border-radius: 3.125rem !important;
}

.livis-modal-btn-round[disabled] {
  opacity: 0.5;
}

.livis-modal-btn-round.Mui-disabled {
  color: var(--livis-white-color) !important;
}

.livis-modal-cancel-close-btn {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  // color: var(--livis-color-primary-dblue) !important;
  font-weight: 500;
  font-size: 1rem;
  // border: 1px solid var(--livis-color-primary-lblue) !important;
  text-transform: unset !important;
  box-shadow: none !important;
}

// Square button
.livis-modal-cancel-danger-btn {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  color: var(--livis-reports-download-btn-color) !important;
  font-weight: 500;
  background-color: var(--livis-color-secondary-red) !important;
  font-size: 1rem;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-modal-cancel-contained-btn {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  font-weight: 500;
  font-size: 1rem;
  background-color: var(--livis-color-primary-blue) !important;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-train-btn {
  margin-left: 1.25rem !important;
  min-width: 6.5625rem !important;
  height: 3rem;
  font-weight: 500 !important;
  font-size: 1rem !important;
  background-color: var(--livis-color-primary-blue) !important;
  line-height: 20px !important;
  border: 1px solid var(--livis-color-primary-blue) !important;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-training-secondary-btn {
  margin-left: 1.25rem !important;
  // width: 6.5625rem;
  height: 3rem;
  font-weight: 500 !important;
  font-size: 1rem !important;
  color: var(--livis-color-primary-blue) !important;
  line-height: 20px !important;
  border: 1px solid var(--livis-color-primary-blue) !important;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-modal-flag-btn {
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  color: var(--livis-color-primary-dblue);
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid var(--livis-color-primary-lblue);
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-enable-btn {
  border-radius: 3.125rem !important;
  padding: 0.5rem 1rem !important;
  border: 1px solid var(--livis-color-background-white) !important;
  background: var(--livis-color-primary-blue) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-chip-data-background-color) !important;
  text-transform: unset !important;
  z-index: 3;
  box-shadow: none !important;
}

// Breadcrumb css
.livis-breadcrumb-saperator {
  color: var(--livis-color-primary-blue);
}

.livis-breadcrumb-step-active {
  color: var(--livis-color-primary-blue) !important;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.livis-breadcrumb-step-visited {
  color: var(--livis-color-primary-blue) !important;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.livis-breadcrumb-step-unvisited {
  color: var(--livis-instruction-secondary-color) !important;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.livis-main-body-wrap {
  height: 92vh !important;
  overflow: auto !important;
}




// global css starts 


.livis-operator-builder-view .livis-operator-panel-img-feed {
  margin-top: 0.5rem !important;
  height: 53vh !important;
}

.invisible {
  display: none !important;
  visibility: hidden !important;
}

.livis-operator-panel-accordian-wrap {
  // margin-top: 1rem !important;
  max-height: 63vh !important;
  overflow-y: auto;
  scrollbar-gutter: auto;
  border-radius: 0.75rem !important;
  padding-bottom: 3px !important;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.livis-input-field {
  font-family: "Noto Sans Regular" !important;
  margin-top: 1rem !important;
}

.livis-modal-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-modal-zoom-animation {
  transition-delay: 100ms !important;
}

.livis-reset-password-instruction {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}


.livis-stepper-form {
  overflow-x: hidden !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: column !important;
  padding-right: 0.5rem !important;
  // max-height: 80vh !important;
  overflow-y: auto !important;
}

.livis-stepper-button {
  display: flex !important;
  justify-content: end !important;
  margin-top: 1rem !important;
}

.livis-usecase-action-wrap {
  // min-width: 7.5rem !important;
  padding-right: 0.75rem !important;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  align-items: flex-end !important;
}

.livis-add-part-parameter-position-container,
.livis-add-part-parameter-camera-container {
  border: 1px solid var(--livis-operator-panel-accordian-border-color);
  border-radius: 12px;
  padding: 1rem !important;
  height: calc(100% - 3rem) !important;
}

.livis-add-part-parameter-position-title-container {
  // background-color: #dddddd;
  border-radius: 12px;
  border: 0.5px solid var(--livis-add-part-border-color);
  padding: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem !important;
  font-family: "Noto Sans SemiBold";
  color: var(--livis-color-primary-gray);
  cursor: pointer;
  margin-block: 0.5rem !important;
  position: relative;

  img {
    padding-right: 0.25rem !important;
  }
}

.livis-add-part-parameter-position-list-container {
  padding-top: 1rem !important;
}

.livis-add-part-parameter-divider {
  width: 100%;
  height: 1px !important;
  border-bottom: 1px solid var(--livis-add-part-border-color);
  margin-block: 1rem !important;
}

.livis-add-part-parameter-camera-title-container-success {
  border: 2px solid var(--livis-add-part-camera-title-success) !important;
}

.livis-add-part-parameter-camera-title-container-error {
  border: 2px solid var(--livis-add-part-camera-title-error) !important;
}


.livis-add-part-parameter-camera-title-container-disable {
  border: 1px solid var(--livis-add-part-border-color) !important;
  color: var(--livis-add-part-camera-title-disabled) !important;
}

.livis-add-part-parameter-camera-title-container-active {
  background-color: var(--livis-color-primary-dblue) !important;
  color: var(--livis-chip-data-background-color) !important;
}

.livis-add-part-parameter-position-title-container-img {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: var(--livis-white-color);
  border-radius: 50%;
  z-index: 1000 !important;
}

.livis-cobot-controller-instruction-wrapper {
  background-color: var(--livis-cobot-controller-instruction-wrapper-bg);
  border: 1px solid var(--livis-cobot-controller-instruction-wrapper-border);
  padding: 0.75rem 1rem !important;
  border-radius: 12px !important;
  margin-top: 0.5rem !important;
  height: calc(100% - 3rem) !important;
}

.livis-report-defects-drilldown-container {
  flex: 1;
  text-align: center;
  position: relative;
  color: var(--livis-report-drill-down-color);
}

.livis-primary-btn {
  width: 100% !important;
  border-radius: 1.5625rem !important;
  margin-top: 3rem !important;
  margin-bottom: 2rem !important;
  padding: 0rem !important;
  font-family: "Noto Sans SemiBold" !important;
  line-height: 1.56 !important;
  letter-spacing: 0.01125rem !important;
  color: var(--livis-color-background) !important;
  font-size: 1.125rem !important;
  text-transform: none !important;
  background-color: var(--livis-color-primary-blue) !important;
}

.no-scroll {
  overflow: hidden !important;
}

//global css ends




//login page new css start

.livis-auth-header-wrap {
  background-color: var(--livis-color-background-white) !important;
  // padding-bottom: 1.5rem !important;
}

// .livis-auth-header-wrap::before {
//   content: "";
//   position: absolute;
//   background-color: transparent;
//   top: 95px;
//   height: 50px;
//   width: 25px;
//   border-top-left-radius: 25px;
//   box-shadow: 0 -25px 0 0 var(--livis-white-color);
// }
// .livis-auth-header-wrap::after {
//   content: "";
//   position: absolute;
//   background-color: transparent;
//   top: 95px;
//   right: 0;
//   height: 50px;
//   width: 25px;
//   border-top-right-radius: 25px;
//   box-shadow: 0 -25px 0 0 var(--livis-white-color);
// }

.livis-auth-main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.livis-auth-header {
  min-height: 10vh !important;
  padding-top: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.livis-landing-header {
  padding-top: 0.5rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 0.5rem !important;
}

.livis-auth-header-demo-wrap {
  display: flex;
  justify-content: flex-end;
}

.livis-demo-text-show {
  display: none;
}

.livis-contact-us-button {
  text-decoration: none !important;
  position: fixed;
  bottom: 2.25rem;
  right: 2.625rem;
  visibility: hidden;
}

.livis-contact-us-wrap {
  border: 0.0625rem solid var(--livis-contact-us-border-color) !important;
  border-radius: 0.9375rem !important;
}

.livis-contact-us-wrap * {
  box-shadow: none !important;
  border: none !important;
  border-radius: 0.9375rem !important;
}

.livis-contact-us-content>div:first-child {
  display: flex !important;
}

.livis-contact-us-content {
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 0.5rem 1rem !important;
}

.livis-contact-us-content>div {
  padding-right: 1rem !important;
}

.livis-contact-us-content img {
  width: 100%;
}

.livis-contact-us-text p:nth-child(1) {
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  color: var(--livis-gray-font-color);
  margin: 0rem;
}

.livis-contact-us-text p:nth-child(2) {
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  margin: 0rem;
  color: var(--livis-color-primary-gray);
}

.livis-auth-footer {
  height: 5vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-main-footer-wrap {
  height: 3vh;
  max-height: 3vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-landing-footer-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
  text-decoration: none !important;
  color: inherit !important;
}

.livis-footer-copyright-text {
  font-size: 1rem;
  line-height: 1.25rem;
}

.livis-footer-copyright-text span:nth-child(1) {
  color: var(--livis-black-font-color);
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1rem;
  line-height: 1.25rem;
}

.livis-footer-copyright-text span:nth-child(2) {
  color: var(--livis-red-font-color);
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1rem;
  line-height: 1.25rem;
}

.livis-auth-form-wrap {
  // height: 82vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-auth-footer p {
  margin: 0.5rem !important;
}

.livis-card {
  background-color: var(--livis-color-background-white) !important;
  box-shadow: 0px -2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 8px 16px -8px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2) !important;
  border-radius: 1.5625rem !important;
  display: flex !important;
}

.livis-auth-form-header p {
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: var(--livis-color-primary-gray) !important;
  margin-bottom: 3.75rem !important;
  margin-top: 3.125rem !important;
}

.livis-sso-key {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  background-color: var(--livis-color-primary-blue);
  margin-right: 0.625rem;
  margin-left: 0.9375rem;
}

.livis-signin-sso-wrap {
  margin-bottom: 5rem;
}

.livis-sso-icon {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 1.25rem;
  background: var(--livis-search-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.75rem !important;
  margin-left: 0.8125rem !important;
  margin-right: 1rem !important;
}

.livis-alert-icon {
  width: 1.5rem;
}

.livis-sso-icon img {
  width: 1.5625rem;
}

.livis-text-wrap {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 500 !important;
}

.livis-text-wrap p {
  font-size: 0.875rem;
  letter-spacing: 0.14px !important;
  color: var(--livis-gray-font-color) !important;
}

.livis-text-wrap>img {
  margin-top: 3.5rem;
}

.livis-auth-fields-wrap .livis-primary-btn {
  margin-top: 3rem !important;
}

.livis-login-forgot-password-wrap a {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  color: var(--livis-color-primary-blue) !important;
  text-decoration: none !important;
}

.livis-login-forgot-password-wrap {
  margin-top: 0rem !important;
  margin-bottom: 3rem !important;
}

.livis-auth-form-content {
  padding: 0rem !important;
  width: 75% !important;
  margin: auto;
}

#livis-login-btn {
  min-height: 3.75rem !important;
  border-radius: 3.125rem !important;
}

.livis-auth-art-work {
  background-image: url("LoginArt.svg");
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;
  justify-content: space-between;
}

.livis-reset-password-instruction ul li {
  max-height: 1.375rem !important;
}

.livis-password-instruction-list-icon {
  min-width: 1.5rem;
  text-align: center;
}

.livis-input-field+small,
.livis-text-danger {
  font-family: "Noto Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375rem !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
}

.livis-empty-string {
  color: transparent !important;
  background: transparent !important;
  // min-height: 1.5rem !important;
  width: 100% !important;
  display: block !important;
  font-family: "Noto Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9375rem !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
}

.livis-connection-status-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.livis-switch-label {
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  color: var(--livis-color-primary-gray) !important;
  margin-right: 22px !important;
}

// login page new css end



//components css starts


//Header css starts

.livis-password-instruction-text {
  margin-top: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  color: var(--livis-gray-font-color);
}

.livis-password-instruction-list-icon {
  margin-top: 0.25rem !important;
  margin-right: 0.5rem !important;
  color: var(--livis-gray-font-color) !important;
}

.livis-reset-password-instruction p {
  font-size: 0.9375rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  margin-bottom: 0.5em;
}

.livis-reset-password-instruction ul {
  list-style-type: none;
  margin: 0rem;
  padding: 0rem;
  color: var(--livis-gray-font-color);
  font-size: 0.9375rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
}

.livis-header-routes-wrap {
  padding: 12px 18px !important;
  border-radius: 12px;
  text-align: center;
  margin-right: 0.25rem !important;
  color: var(--livis-gray-dark-font-color);
  cursor: pointer;
  display: flex;
}

.livis-header-routes-wrap-selected {
  padding: 12px 18px !important;
  border-radius: 12px;
  background: var(--livis-color-primary-blue);
  text-align: center;
  margin-right: 0.5rem !important;
  color: var(--livis-color-background-white);
  cursor: pointer;
  display: flex;
}

.livis-header-routes-wrap>div,
.livis-header-routes-wrap-selected>div {
  text-align: left;
  font-size: 0.875rem;
  line-height: 18px;
  font-family: "Noto Sans Regular";
  padding-left: 0.45rem !important;
}

.livis-header-routes-container {
  border-radius: 12px;
  // border: 1px solid rgba(165, 191, 255, 0.25);
  // background: var(--livis-white-color);
  // box-shadow: 0px 2px 4px -2px rgba(168, 168, 168, 0.08), 0px 8px 16px -8px rgba(168, 168, 168, 0.12), 0px -2px 4px -2px rgba(168, 168, 168, 0.08), 0px -8px 18px -8px rgba(168, 168, 168, 0.12), 2px 0px 4px -2px rgba(168, 168, 168, 0.08), -2px 0px 4px -2px rgba(168, 168, 168, 0.08);
  display: flex;
  padding: 4px 0px;
  z-index: 100 !important;
}

.livis-header-menu-wrap {
  min-width: fit-content !important;
}

.livis-page-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // min-width: 47% !important;

  & p {
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 1.25rem;
    letter-spacing: 0.15px;
    font-family: "Noto Sans Regular";
    color: var(--livis-gray-dark-font-color);
  }
}

.livis-page-title-selected {
  font-size: 1.25rem !important;
  font-family: "Noto Sans SemiBold" !important;
  letter-spacing: 0.15px !important;
  color: var(--livis-color-primary-gray) !important;
  margin: 0rem !important;
  margin-right: 1.5rem !important;
  // text-decoration: underline !important;
  background: var(--livis-part-box-background);
  border: 0.2px solid var(--livis-page-title-selected-border);
  border-radius: 16px;
  padding: 0.5rem 1rem !important;
  // padding-inline: 1rem !important;
  // padding-block: .25rem !important;
  color: var(--livis-color-primary-dblue) !important;
}

.livis-page-title p:hover {
  color: var(--livis-color-primary-dblue) !important;
}

.livis-landing-logo-wrap {
  display: flex;
  align-items: center;
  // justify-content: center;
  cursor: pointer !important;
  // max-width: fit-content !important;

  & .img {
    cursor: pointer;
  }
}

// .livis-landing-logo-wrap img {
//   width: 7.5rem;
// }

.livis-landing-search-wrap {
  display: flex;
  justify-content: end;
  align-items: center;
}

// .livis-search-field {
//   width: 34.25rem !important;

// }

.livis-search-field input {
  height: 3rem !important;
  padding-block: 0rem !important;
  padding-inline: 1.25rem !important;
}

.livis-landing-search-wrap div {
  border-radius: 50px !important;
}

.livis-landing-header-btns {
  display: flex;
  justify-content: end;
  align-items: center;
}

.livis-landing-header-btns a {
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
  background: var(--livis-search-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  cursor: pointer;
  // padding: 0.3125rem !important;
}

.livis-landing-header-btns-mobile {
  display: flex;
  justify-content: end;
  align-items: center;
}

.livis-header-account-image {
  width: 100% !important;
}

.livis-landing-header-btns-mobile a {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 1.5rem;
  background: var(--livis-search-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}

.livis-word-wrap {
  word-wrap: break-word !important;
}

.livis-landing-hamburger-wrap {
  display: none !important;
}

.livis-landing-header-btns-mobile {
  display: none !important;
}

.livis-landing-header-btns-mobile img {
  width: 1.5rem;
}

.livis-landing-header-btns img {
  width: 1.5rem;
}

.livis-header-menu-language-flag {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.livis-auth-header-grid {
  justify-content: space-between !important;
  align-items: center !important;
}

.livis-landing-heading-logout-modal-grid {
  display: flex !important;
  justify-content: end !important;
  margin-top: 10% !important;
}

.livis-header-lang-picker-align-center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.livis-header-lang-img {
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px !important;
}

.livis-header-select-language-container {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.livis-header-select-language {
  justify-content: flex-start !important;
}


//header css ends


//sweet alert css

.livis-sweet-alert-wrap {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
}

.livis-sweet-alert {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 30% !important;
  min-width: 400px;
  // min-height: 500px;
  background: var(--livis-color-background-white);
  box-shadow: 0px -2px0.25rem -2px rgba(168, 168, 168, 0.25),
    0px 2px0.25rem -2px rgba(168, 168, 168, 0.15),
    0px 0.5rem 1rem -8px rgba(168, 168, 168, 0.25),
    0px0.25rem 0.5rem -4px rgba(168, 168, 168, 0.2);
  border-radius: 30px;
  padding-block: 3rem !important;
  padding-inline: 5rem !important;
  z-index: 100000;
}

.livis-sweet-alert-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 60px;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
  margin-bottom: 2rem !important;
  max-width: 100%;
  text-align: center;
}

.livis-sweet-alert-title {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  color: var(--livis-gray-font-color);
}

.livis-sweet-alert-img-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5rem !important;
  margin-bottom: 5rem !important;
}

.livis-sweet-alert-btn-wrap {
  display: flex;
  justify-content: space-between !important;
}

.livis-sweet-alert-btn-wrap button,
.livis-sweet-alert-btn-wrap-2 button {
  height: 60px !important;
  min-width: 40% !important;
}

.livis-sweet-alert-btn-wrap-2 {
  display: flex;
  justify-content: center;
}

//footer starts

.livis-operator-panel-footer-wrap,
.livis-operator-panel-footer-wrap * {
  max-height: 4vh !important;
}

.livis-operator-panel-footer-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-operator-panel-footer-wrap .livis-landing-footer-copyright {
  margin: 0px !important;
}

.livis-landing-footer-info-right-align {
  display: flex;
  justify-content: flex-end;
}


//footer ends

// No data template starts
.livis-no-data-template {
  // height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & img {
    margin-top: 50%;
    width: 15rem;
  }

  span {
    margin-top: 2rem !important;
  }
}

.livis-no-data-text {
  text-align: center;
  font-family: "Noto Sans SemiBold";
  font-size: 1.25rem !important;
  color: var(--livis-color-primary-blue);
}


.livis-no-data-template-type-inspection {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

// No data template ends

//loader css starts

.livis-spinner-loader {
  color: var(--livis-color-primary-blue);
  z-index: 1000000000 !important;
  background-color: var(--livis-color-background-white) !important;
}

.livis-spinner-loader-translucent {
  color: var(--livis-color-primary-blue);
  z-index: 1000000000 !important;
  background-color: rgba(245, 245, 250, 0.4) !important;
}

//loader css ends

//stepper css starts


.livis-stepper-title {
  padding-left: 1rem;
  position: sticky;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
  margin-top: 0.5rem !important;
  margin-bottom: 1rem !important;
}

.livis-plc-camera-title {
  margin-top: 2.5rem !important;
  padding-left: 0px !important;
}

// .livis-stepper-form>div>div>div {
//   padding-top: 0px !important;
// }
.livis-stepper-controller-radio-wrap {
  margin-block: 1rem !important;
}

.livis-stepper-controller-step>div {
  margin: 0px !important;
}

.livis-stepper-controller-step h4 {
  padding-left: 0rem !important;
}

.livis-stepper-steps {
  padding: 0 !important;
  margin-bottom: 2rem !important;
  justify-content: center;
}

//.livis-stepper-form

// .livis-modal-main-content {
//   max-height: 80% !important;
// }

.livis-form-final-step {
  margin-top: 1.625rem !important;
}

.livis-stepper-icon {
  padding-top: 0.3125rem;
}

.livis-stepper-form .livis-disabled-btn,
.livis-stepper-form .livis-primary-btn,
.livis-stepper-form .livis-secondary-btn {
  // padding: 0.5rem !important;
}

.livis-connection-status {
  color: var(--livis-connection-color) !important;
  font-family: "Noto Sans Regular" !important;
  // padding-top: 1rem;
  align-items: center !important;
  display: flex !important;
}

.livis-plc-display-none {
  display: none !important;
}

.livis-stepper-plc-camera-input {
  border: 1px solid var(--livis-form-table-background-color);
  border-radius: 12px;
  margin-top: 1.25rem !important;
  padding-inline: 1.25rem !important;
  padding-bottom: 1.25rem !important;

  .livis-inspection-camera-input-stepper {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    .css-vj1n65-MuiGrid-root {
      margin-top: 1rem;
    }
  }

  .livis-inspection-camera-input-stepper-remove-btn {
    display: flex;
    justify-content: end;
  }

  .livis-camera-input-stepper-camera-details {
    display: flex;
    justify-content: flex-start;
  }

  .livis-inspection-camera-input-connect-btn {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

//.livis-input-field

.livis-controller-tab-icon {
  width: 1rem !important;
  height: 1rem !important;
}

.livis-stepper-plc-camera-input>div {
  margin-top: 1.25rem !important;
}

.livis-stepper-plc-camera-input-optional-text {
  margin-left: 0.5rem !important;
  font-size: 1rem !important;
  font-family: "Noto Sans SemiBold" !important;
}

.livis-stepper-button button {
  font-size: 1rem !important;
}

.livis-stepper-button-grid-margin {
  margin-right: 20px !important;
}


.livis-stepper-steps-container {
  position: sticky;
  top: 0;
  padding: 0.625rem;
}

.livis-stepper-body {
  max-height: 32rem !important;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
}

.livis-stepper-footer {
  position: sticky;
  bottom: 0;
  padding: 0rem 1rem;
}

.livis-modal-main-content {
  .livis-stepper-button {
    position: sticky;
    bottom: -1px;
    background-color: var(--livis-white-color);
    padding: 1rem 1rem;
  }
}

.livis-add-part-modal .livis-stepper-body {
  max-height: 30rem !important;
}

//stepper css ends


// modal  css starts


.livis-modal-header-text {
  font-size: 1.375rem !important;
  font-weight: 600 !important;
  font-family: "Noto Sans Regular" !important;
  display: flex;
  align-items: center;
}

.livis-modal-sub-header {
  font-weight: 600 !important;
  font-size: 1.25rem;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
}

// .livis-modal-main-content {
//   padding-block: 1rem !important;
//   margin-bottom: 1rem !important;
// }

.livis-modal-slide-animation {
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  margin: auto !important;
  outline: none !important;
  position: absolute;
  transform: translate(-50%, -50%);
  min-height: fit-content !important;
  height: fit-content !important;
  max-height: 95% !important;
  background-color: var(--livis-color-background-white);
  box-shadow: 0px -2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.35),
    0px 8px 16px -8px rgba(168, 168, 168, 0.2),
    0px 4px 8px -4px rgba(168, 168, 168, 0.25);
  padding: 1.5rem 2rem;
  border-radius: 0.9375rem;
  overflow: auto !important;
}

//hide scrollbar of parent modal body

.livis-modal-slide-animation {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.livis-modal-slide-animation::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}


.livis-text-danger {
  color: var(--livis-color-secondary-red);
}

.livis-modal-config-slider {
  max-width: 95% !important;
}

// modal  css ends

//components css ends


//inspection station css

.livis-inspection-header-wrap {
  display: flex;
  justify-content: center;
  border-radius: 0rem 0rem 1.875rem 1.875rem;
  background-color: var(--livis-color-background-white) !important;
  padding: 0rem 2.5rem 1.25rem 2.5rem;
}

.livis-inspection-header {
  width: 100%;
  display: flex;
  border-radius: 0.9375rem;
  justify-content: center;
  box-shadow: 0 4px 8px -4px rgb(168 168 168 / 20%),
    0 8px 16px -8px rgb(168 168 168 / 25%),
    0 2px 4px -2px rgb(168 168 168 / 15%),
    0 -2px 4px -2px rgb(168 168 168 / 35%),
    -1px 0 2px -1px rgb(168 168 168 / 25%),
    1px 0 2px -1px rgb(168 168 168 / 25%);
  background-image: url("BackgroundPattern.svg"),
    linear-gradient(79deg, #0f1c90 -18%, #41129b 31%, #660b43 121%) !important;
  background-repeat: no-repeat;
}

.livis-inspection-header.livis-inspection-graph {
  background: transparent !important;
  box-shadow: none !important;
}

.livis-inspection-header-video-wrap {
  display: flex !important;
  justify-content: flex-end !important;
}

.livis-inspection-station-dasboard-accordian-details {
  padding-inline: 0px !important;
}

.livis-dashboard-accoridan {
  width: 100% !important;
}

.livis-inspection-station-dasboard-accordian-details .livis-operator-builder-position-relative {
  max-height: 2rem !important;
  min-height: 2rem !important;
  height: 2rem !important;
  box-shadow: none !important;
}

.livis-inspection-header-wrap .livis-operator-builder-position-relative {
  box-shadow: none !important;
}

.livis-stepper-table-wrap {
  display: block !important;
  display: none !important;
}

.livis-stepper-table-wrap-mobile {
  // display: none !important;
}

//data table css
.livis-muidatatable-wrap {
  height: 100px;
  width: 90%;
  margin: auto;
}

table thead *,
table tbody * {
  text-align: center;
  justify-content: center !important;
}

.livis-data-table-operation-wrapper {
  display: flex !important;
  min-width: 100% !important;
}

.livis-data-table-operation-button-wrapper {
  display: flex !important;
}

.livis-data-table-operation-button-wrapper button {
  padding-inline: 0.9375rem !important;
  margin-inline: 0.625rem !important;
}

.livis-tutorial-text {
  margin: 2rem;
}

.livis-tutorial-text p {
  margin: 0rem;
}

.livis-tutorial-text p:nth-child(1) {
  color: var(--livis-pink-font-color);
  font-size: 1.25rem;
  font-family: "Noto Sans Regular";
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.18rem;
  letter-spacing: 1px;
}

.livis-tutorial-text p:nth-child(2) {
  font-size: 2.375rem;
  color: var(--livis-color-background-white);
  font-family: "Noto Sans Regular";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.8rem;
  letter-spacing: 0.7px;
}

.livis-tutorial-text p:nth-child(3) {
  font-size: 1.125rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6rem;
  letter-spacing: 0.7px;
  text-align: left;
  color: var(--livis-color-background-white);
  margin-bottom: 1rem;
  margin-top: 1.81rem;
  max-width: 36rem;
}

.livis-tutorial-text button {
  background-color: var(--livis-search-background-color);
  padding: 0.5rem 1rem;
  border-radius: 3.125rem;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--livis-color-primary-dblue);
  text-transform: unset;
}

.livis-training-video-wrap {
  display: flex;
}

.livis-tutorial-wrapper {
  display: flex;
}

.livis-tutorial-text-btn {
  margin-top: 2.5rem !important;
}

.livis-training-video-wrap .livis-training-card-wrap:nth-child(1) p {
  margin-top: 0px !important;
}

.livis-training-video-wrap .livis-training-card-wrap:nth-child(1) p:nth-child(1) {
  margin-bottom: -25px !important;
  margin-top: 1rem !important;
}

.livis-tutorial-text-btn button {
  min-width: 9.125rem !important;
  min-height: 3.75rem !important;
  font-size: 1rem !important;
}

.livis-tutorial-modal-menu-header {
  margin-top: 0%;
  color: var(--livis-color-primary-dblue);
  font-weight: 800 !important;
}

.livis-tutorial-modal-menu {
  &:hover {
    background: none !important;
  }
}

.livis-tutorial-modal-menu-selected {
  background-color: var(--livis-menu-selected) !important;
  border-radius: 10px !important;
}

.livis-tutorial-modal-menu-container {
  margin-left: 0.3rem;
}

.livis-tutorial-modal-video-container {
  margin-left: 1rem;
  background-color: var(--livis-menu-selected);
  border: 1px solid var(--livis-border-color);
  border-radius: 15px;
  padding: 1rem;
  width: 100%;
}

.livis-tutorial-video-thumbnail {
  position: relative;
  border-radius: 12px !important;
  cursor: pointer;
}

.livis-tutorial-video-thumbnail>img {
  width: 100%;
  height: 100%;
}

.livis-tutorial-video-thumbnail-play-button {
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.livis-modal-button-wrapper {
  margin-inline: 0.5625rem;
  width: 100%;
  height: 100%;
}

.livis-training-card-wrap:nth-child(1) {
  position: relative;
  margin: 2.68rem 0.375rem 2.68rem 0.375rem;
  box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0.25);
  border-radius: 2.1rem;
}

.livis-training-card-wrap:nth-child(2) {
  position: relative;
  margin: 2.68rem 2.5rem 2.68rem 0.375rem;
  box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0.25);
  border-radius: 2.1rem;
}

.livis-training-card-wrap img {
  width: 100%;
  height: 100%;
}

.livis-defect-shortby-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.livis-training-card-wrap div {
  position: absolute;
  bottom: 0rem;
  width: 100%;
  text-align: center;
  background: rgba(48, 59, 199, 0.85);
  color: var(--livis-color-background-white);
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.livis-training-card-wrap p {
  font-weight: 500;
  font-size: 1.25rem;
  padding: 0.3rem;
  letter-spacing: 0.7px;
}

.livis-training-card-wrap a {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.livis-training-card-wrap a img {
  width: 3.125rem;
}

.livis-inspection-filter-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 1.75rem;
  margin-bottom: 1rem;
}

.livis-inspection-filer-div>div {
  // max-width: 150px !important;
}

.livis-inspection-filer-div {
  width: 20% !important;
}

.livis-inspection-select-filter {
  width: 100%;
}

.livis-inspection-station-count {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1rem !important;
  min-width: 1.5rem !important;
  max-width: 1.5rem !important;
  height: 1.5rem;
  background: var(--livis-form-table-background-color) !important;
  text-align: center;
  border-radius: 0.3125rem;
  padding: 0.25rem;
}

.livis-inspection-station-count p {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-dblue);
  width: 100% !important;
}

.livis-inspection-station-search-input {
  background-color: transparent !important;
  max-width: 80% !important;
  margin: auto !important;
  font-size: 1rem !important;
}

.livis-inspection-station-search-input>div {
  background-color: transparent !important;
}

#livis-inspection-station-search-input {
  padding-top: 0.5rem !important;
}

.livis-inspection-legend-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-inspection-legend-wrap p {
  margin-right: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  color: var(--livis-gray100-font-color);
  letter-spacing: 0.1px;
}

.livis-legend-circle-active {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-active-color);
  margin-right: 0.75rem;
}

.livis-legend-circle-workstation-deactive {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-inactive-color);
  margin-right: 0.75rem;
}

.livis-legend-circle-deactive {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-color-secondary-golden);
  margin-right: 0.75rem;
}

.livis-legend-circle-complete {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-inactive-color);
  margin-right: 0.75rem;
}

.livis-inspection-create-wrap {
  display: flex;
  justify-content: end;
  align-items: center;
}

.livis-inspection-create-wrap>div:nth-child(1) {
  margin-right: 2.125rem;
}

.livis-inspection-diplay-wrap {
  display: flex;
  justify-content: end;
  align-items: center;
}

.livis-inspection-pagination-button-wrap {
  display: flex;
  justify-content: end;
  align-items: center;

  & p {
    margin-right: 1.5rem;
    min-width: fit-content !important;
  }

  & a {
    padding: 0.3125rem;
    border-radius: 0.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.3125rem;
    cursor: pointer;
  }

  & a:hover {
    background-color: var(--livis-color-background-white);
  }

  & a.active {
    background-color: var(--livis-color-background-white);
  }
}

.livis-inspection-diplay-wrap p {
  margin-right: 1.5rem;
}

.livis-inspection-diplay-wrap a {
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.3125rem;
  cursor: pointer;
}

.livis-inspection-diplay-wrap a:hover {
  background-color: var(--livis-color-background-white);
}

.livis-inspection-diplay-wrap a.active {
  background-color: var(--livis-color-background-white);
}

.livis-inspection-data-wrap,
.livis-usecase-data-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 2.625rem;
}

// .livis-usecase-data-wrap{
//   min-height: 70vh;
//   overflow: auto;
// }


.livis-filter-header-sticky {
  position: fixed;
  top: 3.9rem !important;
  width: calc(100% - 5.43rem - 5.43rem) !important;
  margin-inline: 0rem !important;
  padding-inline: 5.43rem !important;
  background-color: var(--livis-color-background-white) !important;
  z-index: 1 !important;
  padding-block: 1rem !important;
}

.livis-inspection-part-deployment-wrap-mobile {
  display: none !important;
  padding-top: 0.875rem !important;
}

.livis-inspection-part-deployment-wrap button {
  padding: 0.565rem 1.25rem !important;
}

// .livis-usecase-no-data p:last-child{
//   margin-bottom: 12rem !important;
// }
.livis-help-us-wrap {
  margin-bottom: 0.9375rem;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1.875rem;
  background: var(--livis-color-secondary-pink);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 6px 12px -6px rgba(168, 168, 168, 0.15),
    0px 2px 4px -2px rgba(168, 168, 168, 0.4),
    0px 4px 8px -4px rgba(168, 168, 168, 0.25);
  color: var(--livis-pink-font-color);
}

//.livis-modal-header

.livis-modal-header-text {
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 40px;
  color: var(--livis-color-primary-gray);
  padding-bottom: 0.3125rem;
}

// .livis-modal-main-content {
//   padding-block: 1.5rem;
//   margin-bottom: 1.5rem !important;
// }

.livis-modal-footer {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.livis-modal-slide-small {
  top: 35%;
  left: 35%;
  width: 30%;
}

.livis-modal-slide-medium {
  top: 50%;
  left: 50%;
  width: 45%;
}

.livis-modal-slide-large {
  width: 55%;
}

.livis-modal-slide-xlarge {
  width: 83%;
}

// .livis-inspection-create-modal {
//   width: 826px !important;
//   height: 609px !important;
//   background: var(--livis-color-background-white);
//   box-shadow: 0px -2px 4px -2px rgba(168, 168, 168, 0.25), 0px 2px 4px -2px rgba(168, 168, 168, 0.35), 0px 8px 16px -8px rgba(168, 168, 168, 0.2), 0px 4px 8px -4px rgba(168, 168, 168, 0.25);
//   border-radius: 15px;
// }

// .livis-modal-dialog-slide-animation {
//   position: absolute;
//   top: 35%;
//   left: 35%;
//   transform: translate(-50%, -50%);
//   width: 30%;
//   height: fit-content !important;
//   background-color: var(--livis-color-background-white);
//   box-shadow: 24;
//   padding: 1.5rem 2rem;
//   border-radius: 0.9375rem;
// }


.livis-inspection-data,
.livis-usecase-data,
.livis-part-data {
  width: 100%;
}

.livis-inspection-card-wrap {
  margin: 0.5rem;
  height: calc(100% - 1rem) !important;
}

.livis-workstation-card-wrap {
  margin-inline: 0.375rem;
}

.livis-disabled-card {
  background-color: rgba(31, 31, 31, 0.3) !important;
  // z-index: 2;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.livis-disabled-card-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-disabled-card-text {
  padding: 0.75rem 0.5rem !important;
  background-color: var(--livis-disabled-card-text-color);
  border-radius: 1rem !important;
}

.livis-inspection-type {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.livis-inspection-type div:nth-child(1) {
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 1.25rem;
  background: var(--livis-search-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.livis-list-wrap .livis-inspection-type div:nth-child(1) {
  height: 3rem !important;
  width: 3rem !important;
  margin-left: 24px !important;
  border-radius: 1.4rem;
}

.livis-inspection-card-body1 {
  padding-left: 1rem !important;
}

.livis-list-wrap p.livis-inspection-type-text {
  padding-top: 0.5rem !important;
  margin-bottom: 0rem !important;
}

.livis-inspection-type-list {
  justify-content: center;

  & div:nth-child(1) {
    margin-top: 0 !important;
  }
}

.livis-inspection-type-active {
  height: 9.375rem;
  width: 0.375rem;
  background-color: var(--livis-active-color);
  top: -1rem !important;
}

.livis-inspection-type-inactive {
  height: 9.375rem;
  width: 0.375rem;
  // background-color: var(--livis-card-disabled-indicator);
  background-color: var(--livis-inactive-color);
  top: -1rem !important;
}

.livis-inspection-type-active-list-view {
  width: 0.375rem;
  background-color: var(--livis-active-color);
}

.livis-inspection-type-inactive-list-view {
  width: 0.375rem !important;
  background-color: var(--livis-inactive-color) !important;
}

.livis-inspection-list-view {
  display: flex !important;
  margin: 0.625em 2rem !important;
}

.livis-inspection-list-view .livis-inpection-card {
  width: 100% !important;
  border-radius: 0rem 0.75rem 0.75rem 0rem !important;
}

.livis-inspection-card-body {
  position: relative;
}

.livis-inspection-card-body p {
  margin: 0rem;
  word-break: break-all;
}

.livis-inspection-type-text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.livis-inspection-type-text p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: var(--livis-inspection-type-text-color);
  margin-top: 0.375rem !important;
  text-transform: capitalize;
}

.livis-inspection-title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;
  color: var(--livis-color-primary-gray);
  margin-top: 0.5rem !important;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.livis-inspection-statics-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem !important;
  margin-bottom: 0.5rem !important;

  & .livis-inspection-station-recent-user {
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.livis-inspection-statics-wrap .livis-inspection-statics {
  margin-top: 0rem;
}

.livis-inspection-statics-wrap div p:nth-child(1) {
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
}

.livis-inspection-statics-wrap div p:nth-child(2) {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--livis-gray-font-color);
  margin-top: 0.5em !important;
}

.livis-inspection-view-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-inspection-view-wrap button {
  margin-right: -25px !important;
}

.livis-inspection-view-wrap button {
  margin-left: 1rem !important;
  text-align: center !important;
}

.livis-inspection-location-wrap {
  margin-bottom: 1.25rem;
  margin-top: 2rem;
  max-width: 90% !important;
}

.livis-inspection-location-wrap p:nth-child(1) {
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.livis-inspection-location-wrap p:nth-child(2) {
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.15px;
  color: var(--livis-gray-font-color);
  margin-top: 0.25em !important;
}

.livis-inspection-location-wrap-grid {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.livis-list-wrap .livis-inspection-statics-wrap {
  margin: 0rem !important;
  justify-content: center;
}

.livis-list-wrap .livis-inspection-statics-wrap div p:nth-child(1) {
  margin-bottom: 0.5rem !important;
}

.livis-list-wrap .livis-inspection-statics-wrap div p:nth-child(2) {
  line-height: 24px !important;
}

.livis-list-wrap .livis-inspection-location-wrap {
  margin: 0rem !important;
  text-align: center;
}

.livis-list-wrap .livis-inspection-type div:nth-child(2) {
  position: absolute;
}

.livis-list-wrap .livis-inpection-card {
  padding: 0.875rem !important;
  text-align: left;
  padding-left: 0rem !important;
}

.livis-list-wrap .inspection-station-chip {
  margin: auto;
}

.livis-list-wrap .inspection-station-button {
  margin: auto;
}

.livis-list-wrap .inspection-station-more {
  margin: auto;
}

.livis-list-wrap p.livis-inspection-type-text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--livis-inspection-type-text-color);
}

.livis-list-wrap .livis-inspection-type div:nth-child(1) {
  margin-top: 0.7em;
}

.livis-station-type-card {
  border-radius: 1.25rem !important;
  border: 0.0625rem solid var(--livis-color-primary-lblue);
  box-shadow: none !important;
  position: relative;
}

.livis-station-type-card-selected {
  border-radius: 1.25rem !important;
  border: 0.15rem solid var(--livis-color-primary-dblue);
  box-shadow: none !important;
  position: relative;
}

.livis-station-card-content {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  // padding-bottom: 1rem !important;
  font-family: "Noto Sans SemiBold" !important;
}

.livis-inspection-station-station-type-image {
  object-fit: scale-down !important;
  height: 200px;
  width: 75% !important;
  margin: auto;
}

.livis-station-type-card .livis-station-card-content-wrap,
.livis-station-type-card-selected .livis-station-card-content-wrap {
  padding: 11px !important;
}

.livis-option-btn {
  // margin: 0.9375rem 0.3125rem 0 0 !important;
  margin: 0.5rem 0.3125rem 0 0 !important;
  border-radius: 3.125rem !important;
  padding: 0.5rem 1rem !important;
  height: 2.2rem;
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: unset !important;
  background-color: var(--livis-form-select-button-background-color) !important;
}

.livis-option-selcted {
  border: solid 0.0625rem var(--livis-form-select-button-border-color);
}

.livis-form-data-table-wrap {
  background-color: rgb(232, 233, 249, 0.35);
  width: 99%;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem !important;
}

.livis-station-camera-table-wrap {
  border: 1px solid var(--livis-color-primary-lblue);
  width: 99% !important;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem !important;
}

.livis-form-table {
  padding: 1.25rem !important;
  width: 100%;
}

.livis-form-table th {
  padding-bottom: 9px;
  border-bottom: 1px solid var(--livis-color-primary-lblue);
}

.livis-form-table-data-header {
  color: var(--livis-gray-dark-font-color);
  text-align: left !important;
  font-size: 0.8125rem !important;
}

.livis-form-table-data {
  text-align: left !important;
  font-size: 0.875rem !important;
  word-break: break-all !important;
  margin: 0px !important;
  max-width: 100% !important;
}

.livis-form-data-status-active {
  color: green;
}

.livis-form-data-status-inactive {
  color: red;
}

.livis-form-dropdown-select {
  margin-top: 1rem !important;
}

.livis-dropdown-label {
  font-family: "Noto Sans Regular" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  color: var(--livis-input-placeholder-color) !important;
}

.livis-inspection-graph-header {
  margin: 0rem 2rem;
}

.livis-inspection-donut-header {
  margin-inline: 1rem !important;
}

.livis-defect-frequency-legend {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0rem;
}

.livis-defect-frequency-legend div {
  width: 1rem;
  height: 1rem;
  border-radius: 0.625rem;
  background-color: var(--livis-active-color);
  margin-right: 0.625rem;
}

.livis-defect-frequency-legend div.major {
  background-color: var(--livis-color-secondary-orange) !important;
}

.livis-defect-frequency-legend div.minor {
  background-color: var(--livis-color-secondary-blue) !important;
}

.livis-defect-frequency-legend p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--livis-gray100-font-color);
  margin-right: 0.9375rem;
}

.livis-inspection-graph-header .livis-defect-frequency-text {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
}

.livis-inspection-graph-header .livis-defect-shortby-text {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--livis-input-placeholder-color);
}

.livis-frequency-select-filter {
  // margin-top: 10%;

  & .MuiSelect-select {
    color: var(--livis-color-primary-dblue);
    font-weight: 500;
  }
}

.livis-frequency-select-filter-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-highchart-custom {
  transform: scale(0.9);
}

// .livis-inspection-total-statics>div div {
//   display: flex !important;
//   align-items: center !important;
//   justify-content: center !important;
// }

.livis-inspection-static-data-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-inspection-static-data {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // margin-left: 3.5rem;

  & img {
    margin-left: 0.6875rem;
    padding-top: 0.375rem;
  }
}

.livis-inspection-static-data p {
  margin-top: 0rem !important;
  margin-bottom: 0.3125rem !important;
  // text-align: center !important;
}

.livis-inspection-static-data p:nth-child(1) {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 24px;
  color: var(--livis-gray-font-color);
  margin-bottom: 1rem !important;
  text-align: center;
}

.livis-inspection-static-data p:nth-child(2) {
  font-weight: 500;
  font-size: 2rem;
  line-height: 40px;
  letter-spacing: 0.0015em;
  color: var(--livis-color-primary-gray);
  margin-bottom: 0.5rem !important;
  text-align: center;
}

.livis-inspection-static-data p:nth-child(3) {
  font-weight: 600;
  font-size: 0.9375rem;
  line-height: 24px;
  color: var(--livis-inspection-static-color);
  // margin-bottom: 3.3125rem !important;
  text-align: center;
}

.livis-major-defect-action a {
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-dblue);
  text-decoration: none;
  min-width: max-content !important;
}

.livis-major-defect-action {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.livis-form-alert-error {
  margin-top: 1.25rem;
  background-color: var(--livis-white-color);
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 0.25rem;
  box-shadow: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: rgb(253, 237, 237);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.375rem 1rem;
  color: rgb(95, 33, 32);
  border-radius: 0.5rem;
  align-items: center;
}


// intro modal css start
.livis-intro-modal {
  background: var(--livis-color-background-white) !important;
  box-shadow: 0px -2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 8px 16px -8px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2) !important;
  border-radius: 30px !important;
  width: 47.875rem !important;
  padding-block: 2rem !important;
}

.livis-intro-image-wrap {
  display: flex !important;
  justify-content: center !important;
  padding-block: 0.9375rem !important;
}

.livis-intro-art-work {
  width: 35.8125rem;
}

.livis-intro-heading {
  font-size: 2.5rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: var(--livis-color-primary-gray);
  padding-block: 0.9375rem !important;
}

.livis-intro-title {
  font-size: 1.25rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--livis-gray-font-color);
  max-width: 60% !important;
  margin: auto !important;
  padding-block: 0.9375rem !important;
}

.livis-intro-button-wrap {
  max-width: 9.375rem !important;
  margin: auto !important;
  min-height: 3.125rem !important;
}

.livis-intro-button-wrap button {
  min-height: 3.75rem !important;
  border-radius: 3.125rem !important;
}

// livis summary modal css
.livis-summary-table {
  max-width: 95% !important;
  width: 95% !important;
  margin: auto !important;
  border-collapse: collapse;
}

.livis-summary-table-heading {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--livis-gray-dark-font-color);
}

.livis-summary-table-row {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  // text-align: left;
  color: var(--livis-color-primary-gray);
}

.livis-summary-table-row-1 {
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  // text-align: left;
  color: var(--livis-color-primary-dblue);
}

.livis-summary-table-row-2 {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: left;
  color: var(--livis-color-secondary-red);
}

.livis-summary-table-heading td {
  border-bottom: 0.0625rem solid var(--livis-color-primary-dblue) !important;
  padding-block: 0.75rem !important;
  cursor: pointer;
}

.livis-summary-table-rows td {
  border-bottom: 0.0625rem solid rgba(140, 146, 224, 0.5) !important;
  padding-block: 0.75rem !important;
}

.livis-summary-table-cancel-btn-wrap {
  width: 6.25rem !important;
  float: right;
  margin-block: 1.5625rem;
  margin-right: 2.5%;
}

// create modal css
.livis-inspection-create-modal {
  width: 62.625rem !important;
  // height: 36.0625rem !important;
  padding-top: 1rem !important;
}


.livis-modal-main-content {
  margin-top: 1rem !important;
  margin-inline: 1rem !important;
  min-height: calc(100% - 5rem) !important;
  max-height: 89vh !important;
  overflow-y: auto;
  padding-right: 0.25rem !important;
  overflow-x: hidden;
}

.livis-augmentation-modal-main {
  margin-top: 0 !important;
  margin-inline: 0 !important;
  padding-right: 0.625rem !important;
  margin-left: -1.5rem;

}

// .livis-stepper-button

.livis-stepper-button button {
  width: 6rem !important;
  height: 3rem !important;
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
  float: right !important;
}

.livis-stepper-button-grid {
  max-width: 6rem !important;
  max-height: 3rem !important;
}

.livis-usecase-stepper-button-grid {
  max-width: 6rem !important;
  max-height: 3rem !important;
  margin-top: 3rem !important;
  margin-right: 20px;
}

.livis-usecase-stepper-button-grid button {
  min-width: 6rem !important;
  min-height: 3rem !important;
  margin-block: 0rem !important;
  font-size: 1rem !important;
}

//.livis-stepper-form

// notification menu css
.livis-notification,
.livis-notification-none {
  width: 20rem !important;
  height: 25rem !important;
}

.livis-notification-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--livis-color-primary-dblue) !important;
}

.livis-auth-header-logo-wrap img {
  margin-inline: 1.5rem !important;
  margin-top: 0.5rem !important;
  // width: 8rem !important;
  // height: 3rem !important;
  // width: 5.625rem !important;
  // height: 1.7355rem !important;
}

.livis-search-field {
  margin: 0rem !important;

  & img {
    cursor: pointer;
  }
}

.livis-inspection-dashboard-card-wrap {
  min-height: 22.563rem !important;
  max-height: 22.563rem !important;
  // box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.3), 0 2px 4px -2px rgba(168, 168, 168, 0.45), 0 10px 20px -10px rgba(168, 168, 168, 0.25), 0 -2px 4px -2px rgba(168, 168, 168, 0.45), 1px 0 2px -1px rgba(168, 168, 168, 0.45), -1px 0 2px -1px rgba(168, 168, 168, 0.45) !important;
  // border-radius: 0.9375rem !important;
}

.livis-inspection-dashboard-card-wrap>div {
  min-height: 21.563rem !important;
  max-height: 21.563rem !important;
  box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.3),
    0 2px 4px -2px rgba(168, 168, 168, 0.45),
    0 10px 20px -10px rgba(168, 168, 168, 0.25),
    0 -2px 4px -2px rgba(168, 168, 168, 0.45),
    1px 0 2px -1px rgba(168, 168, 168, 0.45),
    -1px 0 2px -1px rgba(168, 168, 168, 0.45) !important;
  border-radius: 0.9375rem !important;
}

.livis-inspection-dashboard-count-card-wrap>div {
  padding: 0px !important;
  min-height: 22.563rem !important;
}

::-webkit-scrollbar {
  // width: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px rgb(183, 180, 180);
  border: solid 3px transparent;
}

// .livis-donut-wrap {
//   margin-top: 2.8% !important;
// }

.livis-donut-wrap>div {
  min-height: 21.563rem !important;
  max-height: 21.563rem !important;
}

.livis-inspection-total-statics {
  min-height: 22.563rem !important;
  max-height: 22.563rem !important;
  display: flex;
  align-items: center;
}

.livis-inspection-total-statics>div>div {
  min-height: 10rem !important;
}

.livis-highchart-custom *,
.livis-inspection-donut * {
  height: 22rem !important;
}

.livis-inspection-donut * {
  max-width: 22rem !important;
  width: 22rem !important;
}

.livis-inspection-station-count-wrap {
  display: flex !important;
  align-items: center !important;
}

.livis-inspection-vertical-line {
  width: 0.01rem;
  height: 3.25rem;
  flex-grow: 0;
  margin: 0 1.125rem;
  background-color: var(--livis-color-disabled);
  margin-top: 1rem !important;
}

.livis-list-wrap .livis-inspection-vertical-line {
  margin: 0 1.5rem;
  height: 3.5rem !important;
}

.livis-list-wrap .livis-inspection-location-wrap p:nth-child(1) {
  line-height: 24px !important;
  text-align: left !important;
  margin-bottom: 0.5rem !important;
}

.livis-list-wrap .livis-inspection-location-wrap p:nth-child(2) {
  line-height: 24px !important;
  min-width: max-content !important;
  text-align: left !important;
}

.livis-list-wrap .inspection-station-chip span {
  padding-block: 0.5rem !important;
  font-size: 0.875rem !important;
  padding-inline: 0.875rem !important;
  line-height: 18px !important;
  font-family: "Noto Sans Regular";
  font-weight: 500 !important;
}

.livis-empty-grid {
  height: 5.976875rem;
}

.livis-defect-frequency-graph {
  display: flex !important;
  justify-content: center !important;
  width: 95%;
  height: 16rem;
  margin: auto;
  margin-left: 1.5rem !important;
  margin-top: 1.8rem !important;
}

.livis-graph-no-data-avvailable {
  align-items: center;
  display: flex;
  font-family: "Noto Sans SemiBold";
  margin-top: -3rem !important;
}

.livis-donut-frequency-graph {
  width: 100%;
  height: 250px;
  margin: 0px !important;
}

.livis-donut-frequency-graph .highcharts-container {
  margin: auto !important;
}

.livis-create-btn-wrap_mobile {
  display: none !important;
}

.livis-create-btn-wrap {
  min-width: max-content !important;
}

.livis-station-type-wrap {
  margin: 3% 0 9.9% 0;
}

.livis-form-alert-error p {
  margin-left: 2%;
}

.livis-reset-password-instruction-ul li {
  display: flex;
}

.livis-inspection-create-wrap .MuiInputBase-root::before,
.livis-inspection-create-wrap .MuiInputBase-root::after {
  display: none;
}

.livis-inspection-create-wrap .MuiInputBase-root {
  border-radius: 50px;
  border: 0.5px solid var(--livis-color-primary-lblue);
  background: var(--livis-inspection-create-wrap-bg);
}

.livis-drawer-menu-open-btn {
  margin-right: 10px;
}

.livis-menu-content-body {
  flex-grow: 1;
  padding: 30px;
}

.livis-form-data-table-container {
  width: 100%;
  margin-bottom: 50px;
  border-radius: 8px;
}

.livis-custom-table {
  width: 100%;
}

.livis-custom-table-row {
  background-color: var(--livis-custom-table-bg-color);
}

.livis-inspector-close-caution-grid {
  display: flex;
  justify-content: end;
  margin-top: 10%;
}

.livis-form-data-table-wrap-review {
  background-color: rgb(232, 233, 249, 0.35);
  width: 99%;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem !important;
  margin-top: 40px;

  .livis-form-data-table-grid-container {
    display: flex;
    justify-content: flex-start;
    padding: 2%;
    margin-top: 0px;
  }

  .livis-inspector-tags {
    padding-top: 1rem;
  }
}

.livis-form-data-table-wrap-box {
  background-color: rgb(232, 233, 249, 0.35);
  width: 99%;
  margin-bottom: 2rem !important;
  border-radius: 0.5rem !important;
  margin-top: 40px;

  .livis-form-data-table-box-container {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 3% 3%;
  }

  .livis-form-data-table-box-container-padding {
    display: flex;
    justify-content: flex-start;
    padding: 2%;
  }
}

.livis-inspection-form-modal-container {
  display: flex;
  justify-content: end;
  margin-top: 10%;
}

.inspection-station-button {
  display: flex;
  justify-content: flex-end;
}

.livis-inspection-stepper-switch {
  margin-top: 5% !important;
}

.livis-inspection-controller-data-stepper {
  display: flex;
  justify-content: space-between;
}

.livis-stepper-controller-data-box {
  margin: 20px;
}

.livis-controller-data-form-control {
  margin-top: 20px;
}

.livis-inspection-cobot-container {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.livis-inspection-cobot-container-start {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.livis-inspection-connect-btn {
  display: flex;
  justify-content: end;
}

.livis-inspection-peripheral-device-container {
  display: flex;
  justify-content: flex-start;
}

.livis-inspection-pheripheral-device-container-grid {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.livis-info-station-name-tooltip-wrap,
.livis-info-station-name-tooltip-wrap p {
  margin-top: 0px !important;
  max-width: 100% !important;
}

.livis-station-info-id-copy {
  padding-left: 1rem !important;
  color: var(--livis-color-primary-blue) !important;
  font-weight: 900 !important;
  cursor: pointer;
  font-size: 0.75rem !important;
  text-decoration: underline;
}

.livis-inspection-station-tutorial-btn-avatar {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.livis-inspection-station-add-station-avatar {
  width: 32px !important;
  height: 32px !important;
}

//inspection station css ends


//accordian css starts
.livis-accordian {
  background: rgba(246, 247, 253, 0.7) !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  border-radius: 0.5rem;
}

.livis-accordian.Mui-expanded {
  border: 1px solid var(--livis-color-primary-dblue) !important;
  padding: 1px;
}

.livis-accordian div {
  background-color: var(--livis-white-color) !important;
  border-radius: 0.5rem;
}

.livis-accordian-heading {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 0.75rem;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--livis-gray-dark-font-color);
  overflow-wrap: anywhere !important;
}

.livis-accordian-subheading {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.9375rem;
  line-height: 20px;
  color: var(--livis-color-primary-gray);
  overflow-wrap: anywhere !important;
}

.livis-accordian-margin {
  margin-bottom: 1rem !important;
}

.livis-accordian-heading-text-wrap,
.livis-accordian-heading-image-wrap {
  display: block !important;
  overflow-wrap: anywhere !important;
  padding-inline: 0.5rem !important;

  .livis-accordian-summary {
    font-size: "0.9rem";
  }
}



.livis-inspection-list-active-card {
  border-left: 5px solid var(--livis-active-color);
}

.livis-inspection-list-inactive-card {
  border-left: 5px solid var(--livis-inactive-color);
}

.livis-inspection-info-error-chip {
  min-height: 28px !important;
  margin-right: 0.5rem !important;
  font-family: "Noto Sans Regular" !important;
}

.livis-add-station-wrap {
  margin-bottom: 5.85rem;

  .livis-add-station-stepper-box {
    margin: 20px;
  }
}

.livis-inspection-station-add-form {
  & .MuiGrid-item {
    padding-top: 0 !important;
  }

  display: flex;
  justify-content: space-between;
}

.livis-operator-panel-accordian-defects-cycle-time {
  text-align: center;
  font-size: 1rem !important;
  font-weight: 600;
  margin: 0px !important;
}

.livis-operator-panel-accordian-defects-details {
  font-size: 1rem !important;
  margin: 0px !important;
  color: var(--livis-part-rejected-color);
  text-transform: capitalize;
}

//accordian css ends

//inspection station and usecase combined css starts

.livis-inspection-no-data,
.livis-usecase-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8.37rem !important;
  // height: 32vh;
}

.livis-inspection-no-data p:nth-child(2),
.livis-usecase-no-data p:nth-child(2) {
  font-weight: 400;
  font-size: 1.125rem;
  letter-spacing: 0.7px;
  color: var(--livis-gray-font-color);
}

.livis-inspection-no-data p:nth-child(3),
.livis-usecase-no-data p:nth-child(3) {
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.7px;
  margin: 0rem;
  color: var(--livis-color-primary-dblue);
}

.livis-inpection-card,
.livis-usecase-card {
  position: relative;
  padding: 1.125rem !important;
  padding-left: 0rem !important;
  padding-right: 0.75rem !important;
  padding-bottom: 0rem !important;
  border-radius: 0.75rem 0.75rem 0.75rem 0.75rem !important;
  box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.2),
    0 2px 4px -2px rgba(168, 168, 168, 0.15),
    0 -2px 4px -2px rgba(168, 168, 168, 0.25),
    0 8px 16px -8px rgba(168, 168, 168, 0.25) !important;
  height: calc(100% - 1.125rem) !important;
}

.livis-inspection-view-wrap button,
.livis-usecase-action-wrap button,
.inspection-station-button button {
  // max-width: 5.6875rem !important;
  min-height: 3rem !important;
  // margin-right: -20px !important;
  box-shadow: none !important;
  font-family: "Noto Sans Regular" !important;
  min-width: fit-content !important;
}

.livis-inspection-card-body .livis-layout-btn {
  max-width: fit-content !important;
}

.livis-layout-no-result-wrap {
  justify-content: center;
  display: flex;
  height: 60vh;
}


//inspection station and usecase combined css ends

//Usecase css starts

.livis-usecase-no-data p:first-child {
  margin-bottom: 0rem !important;
}

.livis-usecase-no-data a {
  margin-block: 3rem !important;
}

.livis-usecase-card {
  padding-top: 1.5rem !important;
  height: calc(100% - 1.5rem) !important;
}


.livis-usecase-three-dots-btn-wrap button:hover {
  background-color: transparent !important;
}

.livis-usecase-action-wrap button {
  min-width: 5.6875rem !important;
}

.livis-usecase-three-dots-btn-wrap button {
  display: flex !important;
  justify-content: flex-end !important;
  // padding-right: 0px !important;
  // padding-top: 5rem !important;
  min-width: fit-content !important;
  max-width: fit-content !important;
  width: fit-content !important;
}

.livis-create-usecase-wrap {
  margin: 1rem;
  margin-bottom: 10.85rem;
}

.livis-create-usecase-grid {
  display: flex;
  justify-content: space-between;
}

.livis-usecase-card-wrap {
  margin: 0.5rem;
  margin-inline: 0.375rem;
  height: calc(100% - 1rem) !important;
}

.livis-usecase-list-wrap .livis-usecase-card {
  padding: 0px !important;
  border-left: 5px solid var(--livis-active-color) !important;
  height: 100% !important;
}

.livis-usecase-description-card {
  padding: 0.625rem 0.625rem 1.25rem 0.625rem;
  border-radius: 0.75rem !important;
  box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.2),
    0 2px 4px -2px rgba(168, 168, 168, 0.15),
    0 -2px 4px -2px rgba(168, 168, 168, 0.25),
    0 8px 16px -8px rgba(168, 168, 168, 0.25) !important;
  height: calc(100% - 2rem);
}

.livis-usecase-description-wrap {
  width: 100% !important;
  height: 23rem !important;
  border-radius: 1.56rem;
  border: solid 1px var(--livis-border-color);
  background-color: var(--livis-part-box-background);
  // height: 100% !important;
  min-height: 100% !important;
}

.livis-usecase-description-header {
  margin: 0rem 1rem;

  & p {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: 0.7px;
  }
}

.livis-usecase-description-header button {
  background-color: transparent !important;
}

.livis-usecase-description-body {
  margin: 0rem 1rem;
  color: var(--livis-gray100-font-color);
  max-width: 90%;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  height: 80% !important;
  overflow: auto;
  padding-right: 2rem;
}

.livis-usecase-list-desc-wrap {
  height: 8rem;
  border-radius: 0.75rem !important;
  background-color: var(--livis-part-box-background) !important;
  border: 1px solid var(--livis-border-color);
  box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.2),
    0 2px 4px -2px rgba(168, 168, 168, 0.15),
    0 -2px 4px -2px rgba(168, 168, 168, 0.25),
    0 8px 16px -8px rgba(168, 168, 168, 0.25) !important;
}

.livis-manage-data-image-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.livis-usecase-desc-content {
  height: 100%;
}

.livis-usecase-list-desc-title {
  padding-left: 2.625rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--livis-color-primary-gray);
}

.livis-usecase-list-description-body {
  height: 100%;
  overflow: auto;
  padding-right: 2rem;
}

.livis-usecase-list-description-close-button-wrap {
  padding: 1rem;
}

.livis-usecase-active {
  padding: 0px !important;
  border-left: 5px solid var(--livis-active-color) !important;
}

.livis-usecase-inactive {
  padding: 0px !important;
  border-left: 5px solid var(--livis-inactive-color) !important;
}

.livis-usecase-status-wrap {
  display: flex;
  align-items: flex-end;
}

.livis-usecase-list-wrap .livis-usecase-status-wrap {
  display: inline;
}

.livis-usecase-status-wrap div.active {
  width: 6px;
  height: 7.75rem;
  background-color: var(--livis-active-color);
}

.livis-usecase-status-inactive {
  width: 6px;
  height: 7.75rem;
  background-color: var(--livis-inactive-color);
}

.livis-usecase-list-view-data .livis-usecase-title-wrap {
  padding-left: 32px !important;
}

.livis-usecase-preview-wrap {
  display: flex !important;
  // width: 16.1875rem !important;
  height: 9.5rem !important;
  flex-grow: 0 !important;
  border-radius: 25px !important;
  border: solid 1px var(--livis-border-color) !important;
  background-color: var(--livis-part-box-background);
  position: relative !important;
  object-fit: cover !important;
  box-shadow: none !important;
}

.livis-usecase-list-preview-wrap {
  display: flex !important;
  // width: 12rem;
  height: 9.5rem;
  flex-grow: 0;
  border-radius: 25px;
  border: solid 1px var(--livis-border-color);
  background-color: var(--livis-part-box-background);
  position: relative;
  object-fit: cover;
}

.livis-usecase-horizontal-line {
  margin-inline: 1.25rem !important;
  margin-top: 1rem !important;
  margin-bottom: 0rem !important;
}

.livis-usecase-list-wrap .livis-usecase-list-preview-wrap {
  border: none;
  border-radius: 0px;
}

.livis-usecase-title p {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-gray);
  letter-spacing: 0.15px;
  margin: 1.25rem 0rem 0rem 0rem;
}

.livis-usecase-list-wrap .livis-usecase-stats-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.livis-usecase-create-at {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.livis-usecase-client-details-text {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem !important;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
}

.livis-usecase-list-wrap .livis-usecase-create-at {
  justify-content: left;
}

.livis-usecase-create-at button {
  padding: 0.375rem !important;
  margin-bottom: 2px !important;
}

.livis-usecase-create-at p {
  font-weight: 500;
  font-size: 0.813rem !important;
  line-height: 1.25rem;
  letter-spacing: 0.15px;
  color: var(--livis-instruction-secondary-color);
  margin: 0rem 0rem 0rem 0rem;
  padding-right: 1rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.livis-usecase-list-wrap .livis-usecase-stats-wrap p {
  margin: 0px !important;
}

.livis-usecase-statics {
  margin-top: 1.125rem !important;
  display: flex !important;
  justify-content: space-between;
  padding-right: 2.625rem !important;
  align-items: center;
  // margin: 1rem 0rem 0rem 0rem;
}

.livis-usecase-list-wrap .livis-usecase-statics {
  justify-content: left;
  align-items: center;
  height: 100%;
}

.livis-usecase-list-wrap .livis-usecase-statics div {
  margin-right: 2rem;
}

.livis-usecase-list-wrap .livis-usecase-statics div p {}

.livis-usecase-statics div p:nth-child(1) {
  font-weight: 600;
  font-size: 1.12rem;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
  margin: 0rem 0rem 0.5rem 0rem;
}

.livis-usecase-statics div p:nth-child(2) {
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.15px;
  color: var(--livis-gray-font-color);
  margin: 0rem 0rem 0rem 0rem;
}

//.livis-usecase-action-wrap

.livis-usecase-list-wrap .livis-usecase-action-wrap {
  display: flex;
  flex-direction: row !important;
  justify-content: space-around;
  align-items: center;
}

button.livis-usecase-deployment-button {
  background-color: var(--livis-color-primary-blue);
  // padding: 0.5rem 1rem;
  // width: 12rem;
  border-radius: 6.5rem;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--livis-white-color);
  text-transform: unset;
  // border: 1px solid var(--livis-color-primary-lblue);
}

button.livis-usecase-live-button {
  background-color: var(--livis-white-color);
  // padding: 0.5rem 1rem;
  width: 5.8rem;
  border-radius: 6.5rem;
  font-size: 1rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--livis-color-primary-dblue);
  text-transform: unset;
  border: 1px solid var(--livis-color-primary-lblue);
}

button.livis-usecase-live-button:hover {
  background-color: var(--livis-color-primary-blue);
  color: white;
}

.livis-usecase-industry-info {
  margin: 0.75rem 0rem 1.25rem 0.75rem;
  display: flex;
  // justify-content: center;
  align-items: center;
  // overflow-y: auto;
  flex-wrap: wrap !important;
}

.livis-usecase-industry-info .usecase-material {
  color: var(--livis-error-color) !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  font-size: 0.75rem !important;
  font-family: "Noto Sans Regular" !important;
}

.usecase-material span {
  padding: 2px 10px !important;
}

.livis-usecase-industry-info .usecase-assembly {
  color: var(--livis-connection-color) !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  font-size: 0.75rem !important;
  font-family: "Noto Sans Regular" !important;
}

.usecase-assembly span {
  padding: 2px 10px !important;
}

.livis-usecase-industry-info .usecase-sub-assembly {
  color: var(--livis-success-color) !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  font-size: 0.75rem !important;
  font-family: "Noto Sans Regular" !important;
}

.usecase-sub-assembly span {
  padding: 2px 10px !important;
}

.livis-usecase-industry-info .usecase-process {
  color: var(--livis-usecase-industry-info-color) !important;
  font-weight: 600 !important;
  line-height: 18px !important;
  font-size: 0.75rem !important;
  font-family: "Noto Sans Regular" !important;
}

.usecase-process span {
  padding: 2px 10px !important;
}

.livis-usecase-list-view-data .livis-usecase-industry-info {
  padding-right: 3.125rem !important;
}

.livis-usecase-list-three-dots-wrap button {
  max-width: 24px !important;
  min-width: 24px !important;
  width: 24px !important;
}

.livis-usecase-view-live-three-dot-wrap {
  justify-content: flex-end !important;
}

.livis-usecase-view-live-three-dot-wrap .livis-usecase-list-three-dots-wrap {
  margin-left: 1.5rem !important;
  margin-right: 0.75rem !important;
}

.livis-usecase-view-live-three-dot-wrap .livis-usecase-view-btn {
  margin-left: 1.5rem !important;
}

// .livis-usecase-list-view-data .livis-usecase-industry-info .usecase-assembly{
//   color: var(--livis-error-color) !important;
//   font-weight: 600 !important;
//   line-height: 18px !important;
//   font-size: 12px !important;
// }
// .livis-usecase-list-view-data .usecase-assembly span{
//   padding: 2px 10px !important;
// }
// .livis-usecase-list-view-data .livis-usecase-industry-info .usecase-sub-assembly{
//   color: var(--livis-connection-color) !important;
//   font-weight: 600 !important;
//   line-height: 18px !important;
//   font-size: 12px !important;
// }
// .livis-usecase-list-view-data .usecase-sub-assembly span{
//   padding: 2px 10px !important;
// }

// .livis-usecase-list-view-data .livis-usecase-industry-info .usecase-process{
//   color: var(--livis-success-color) !important;
//   font-weight: 600 !important;
//   line-height: 18px !important;
//   font-size: 12px !important;
// }
// .livis-usecase-list-view-data .usecase-process span{
//   padding: 2px 10px !important;
// }

.livis-selected-tag-chip-field .MuiChip-root {
  max-height: 28px !important;
  margin-top: 0.625rem !important;
  // font-size: 12px !important;
  font-size: 0.875rem !important;
}

.livis-selected-tag-chip-field>div {
  flex-wrap: wrap !important;
}

.livis-usecase-horizontal-line {
  border: 1px solid var(--livis-usecase-horizontal-line-color);
}

.livis-usecase-info-icon {
  color: var(--livis-color-primary-dblue) !important;
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.livis-usecase-statics-grid {
  margin: 0rem !important;
}

.livis-usecase-list-wrap .livis-usecase-industry-info {
  margin: 0rem;
  height: 100%;
}

.livis-usecase-industry-info div {
  margin: 0rem 0.5rem 0rem 0rem;
}

.livis-usecase-list-wrap .livis-usecase-industry-info div {
  margin: 0.1rem 0.1rem;
}

.livis-usecase-industry-info .usecase-material {
  background-color: var(--livis-usecase-defect-background);
  margin-block: 0.25rem !important;
}

.livis-usecase-industry-info .usecase-assembly {
  background-color: var(--livis-hover-color);
  margin-block: 0.25rem !important;
}

.livis-usecase-industry-info .usecase-sub-assembly {
  background-color: var(--livis-usecase-feature-background);
  margin-block: 0.25rem !important;
}

.livis-usecase-industry-info .usecase-process {
  background-color: var(--livis-usecase-industry-info-bg);
  margin-block: 0.25rem !important;
}

.livis-tag-select-wrapper {
  border-radius: 1rem;
  background-color: var(--livis-tag-select-background);
  padding: 0 1rem 1rem 1rem;
  overflow: auto;
  max-height: 31vh;

  .livis-tag-select-wrapper-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
  }

  .livis-usecase-tag-category {
    width: 100%;
    height: 100%;
    padding-top: 20px;
  }
}

.livis-usecase-stepper-button-container {
  display: flex;
  justify-content: end;
}

.livis-tag-select-wrapper * {
  font-family: "Noto Sans Regular" !important;
}

.livis-usecase-form-search-wrap {
  height: 1.5rem !important;
}

.livis-usecase-list-view-data .livis-usecase-action-wrap {
  align-items: center !important;
}

.livis-usecase-list-view-data .livis-usecase-list-preview-wrap {
  height: 8rem !important;
}

.livis-category-type-card {
  border-radius: 8px !important;
  border: 0.0625rem solid var(--livis-tag-unselected);
  box-shadow: none !important;
  // width: 11.063rem !important;
  height: 11.375rem !important;
  cursor: pointer;
  display: flex !important;
  justify-content: space-between !important;
  margin: auto !important;
}

.livis-category-type-card-selected {
  border-radius: 8px !important;
  border: 1.5px solid var(--livis-color-primary-dblue);
  box-shadow: none !important;
  // width: 11.063rem !important;
  height: 11.375rem !important;
  cursor: pointer;
  display: flex !important;
  justify-content: space-between !important;
  margin: auto !important;
}

.livis-category-type-card img,
.livis-category-type-card-selected img {
  // width: 100% !important;
}

.livis-category-type-card button,
.livis-category-type-card-selected button {
  display: flex !important;
  flex-direction: column !important;
  // justify-content: space-around !important;
  justify-content: flex-start !important;
}

.livis-category-type-card button .livis-category-type-text,
.livis-category-type-card-selected button .livis-category-type-text {
  // min-height: 48px !important;
  // line-height: 48px !important;
  // margin-top: 1.5rem !important;
  margin-block: 1rem !important;
}

.livis-category-type-scrollable-wrap {
  // max-height: 53vh !important;
  // overflow: auto !important;
}

.livis-usecase-tag-icon-wrap {
  // background-color: var(--livis-black-font-color);
  width: 86%;
  height: 90px;
  border-radius: 16px;
  margin: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.livis-usecase-tag-icon {
  max-height: 80% !important;
  max-width: 80% !important;
  padding-top: 1rem;
}

.livis-usecase-other-icon {
  height: 50px !important;
}

.livis-category-type-card .livis-category-type-card-others-button {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
}

.livis-category-type-text {
  display: flex;
  justify-content: center;
  align-self: center !important;
  // line-height: 35px !important;
  text-align: center;
  padding-inline: 0.5rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
}

.livis-selected-tag-chip-field {
  margin-top: 2rem;
  padding: 0 1rem;
}

.livis-usecase-tags-chip-0 {
  background-color: var(--livis-asseembly-chip-background) !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-asseembly-chip-text-color) !important;
  }

  & .MuiChip-deleteIcon {
    font-size: 1rem !important;
  }
}

.livis-usecase-tags-chip-1 {
  background-color: var(--livis-form-select-button-background-color) !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-connection-color) !important;
  }

  & .MuiChip-deleteIcon {
    font-size: 1rem !important;
  }
}

.livis-usecase-tags-chip-2 {
  background-color: var(--livis-part-data-flag-chip-color) !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-warning-color) !important;
  }

  & .MuiChip-deleteIcon {
    font-size: 1.125rem !important;
  }
}

.livis-usecase-tags-chip-3 {
  background-color: var(--livis-usecase-industry-info-bg) !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-usecase-industry-info-color) !important;
  }

  & .MuiChip-deleteIcon {
    font-size: 1rem !important;
  }
}

.livis-divider {
  background-color: var(--livis-color-primary-dblue) !important;
  margin-top: 0.625rem !important;
}

.livis-autocomplete-chip-btn {
  margin: 0.9375rem 0.3125rem 0 0 !important;
  border-radius: 1.5rem !important;
  border: solid 0.0625rem rgba(13, 73, 106, 0.2) !important;
  text-transform: none !important;
  font-size: 0.875rem !important;
  padding: 5px 5px 5px 12px !important;
  justify-content: center;
  align-items: center;
  color: var(--livis-auto-complete-chip-btn) !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.07px !important;

  // &:hover {
  //   border: solid 0.0625rem var(--livis-autocomplete-chip-button-selected) !important;
  //   background-color: var(--livis-hover-color) !important;
  // }
}

.livis-autocomplete-chip-btn-grey {
  border: solid 0.0625rem var(--livis-auto-complete-chip-btn) !important;
  background-color: rgba(13, 73, 106, 0.1) !important;
}

.livis-autocomplete-chip-btn-light-green {
  border: solid 0.0625rem rgba(14, 93, 39, 0.3) !important;
  background-color: var(--livis-usecase-feature-background) !important;
}

.livis-autocomplete-chip-btn-light-red {
  border: solid 0.0625rem rgba(130, 55, 18, 0.3) !important;
  background-color: var(--livis-asseembly-chip-background) !important;
}

.livis-autocomplete-chip-btn-selected {
  border: solid 0.0625rem var(--livis-success-color) !important;
  background-color: var(--livis-usecase-feature-background) !important;
}

.livis-autocomplete-chip-btn-selected-defect {
  border: solid 0.0625rem var(--livis-asseembly-chip-text-color) !important;
  background-color: var(--livis-asseembly-chip-background) !important;
}

.livis-usecase-descrition-wrap {
  height: 100% !important;
}

.livis-tag-select-step-number-text {
  color: var(--livis-data-form-header-color) !important;
}

.livis-tag-select-step-of-text {
  font-weight: 700 !important;
}

.livis-tag-select-search-wrap .livis-search-field {
  display: flex;
  align-items: flex-end !important;
}

.livis-tag-select-search-wrap .livis-search-field>div {
  border-radius: 3.125rem !important;
  max-height: 2rem;
  font-size: 0.875rem !important;
  max-width: 15.625rem;
  border: none !important;
}

.livis-tag-select-search-wrap .livis-search-field>div::before,
.livis-tag-select-search-wrap .livis-search-field>div::after {
  border: none !important;
}

.livis-tag-select-search-wrap .livis-search-field>div img {
  width: 1rem !important;
}

.livis-tag-select-search-wrap .livis-search-field>div input,
.livis-tag-select-search-wrap .livis-search-field>div img {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 24px;
  color: rgba(41, 33, 145, 0.6);
}

.livis-tag-select-wrapper>div:first-child {
  padding-bottom: 1.25rem !important;
  padding-top: 16px;

}

.livis-operator-builder-position-relative.livis-add-parameter-accoridan {
  box-shadow: none !important;
  border-radius: 1rem !important;
}

.livis-add-paramter-chip-freq {
  background-color: var(--livis-add-paramter-chip-freq-bg);
  border-radius: 1rem;
  padding-inline: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.livis-add-paramters-autocomplete-input {
  padding-left: 1rem !important;
  margin-bottom: 9rem !important;
}

.livis-add-paramters-autocomplete-input .MuiChip-root {
  margin: 0.5rem !important;
  // font-size: 12px !important;
  font-size: 0.875rem !important;
}

.livis-autocomplete-parameter-features {
  background-color: var(--livis-usecase-feature-background) !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-success-color) !important;
  }
}

.livis-autocomplete-parameter-defects {
  background-color: var(--livis-usecase-defect-background) !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-error-color) !important;
  }
}

.livis-usecase-create-modal .livis-stepper-form {
  max-height: fit-content !important;
}

.livis-create-use-case-container {
  display: flex;
  justify-content: space-between;
}


.livis-usecase-stepper-button-grid-margin {
  margin-right: 20px !important;
}

.livis-usecase-filter-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}


.livis-usecase-take-tour-text {
  margin-top: 2rem !important;
  color: var(--livis-color-primary-blue);
}


.livis-usecase-sort-wrap {
  display: flex;
  justify-content: flex-end;
  max-width: 12rem !important;
  margin-inline: auto 3rem !important;
  margin-bottom: 3rem !important;
}

.livis-usecase-search-sort-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem !important;

  .livis-station-header-search-wrap-small {
    margin-top: -0.35rem !important;
    margin-bottom: 3rem !important;
    margin-right: 2rem !important;
  }
}


//Usecase css ends


//operator builder css starts

.livis-operator-builder-position-relative.livis-manage-data-tab>div {
  margin-block: 0px !important;
}

.livis-operator-panel-layout-publish-modal {
  display: flex;
  justify-content: end;
  margin-top: 10%;
}

.livis-operator-panel-search-workstation {
  width: 300px;
}

.livis-configure-panel-img-input {
  display: none;
}


.livis-operator-builder-feed-overlay-icon-width {
  width: 100%;
}


.livis-image-verification-grid {
  margin-top: 50px;
  margin-left: 80px;
  margin-bottom: 40px;
  font-size: 1.5rem;
  font-weight: 700;
}

.livis-verification-image-grid {
  margin-top: 0px;
  margin-left: 80px;
  margin-bottom: 40px;
  font-size: 1.15rem;
  font-weight: 700;
}

.livis-operator-builder-main {
  max-height: 75vh !important;
}

.livis-operator-builder-card-wrap {
  margin: 0.375rem !important;
}

.livis-operator-builder-data {
  width: calc(100% - 2rem) !important;
  margin-inline: 1rem !important;
}

.livis-operator-laout-cards-wrap {
  width: 100% !important;
}

.livis-operator-builder-filter-wrap .livis-inspection-create-wrap>div:nth-child(1) {
  margin-right: 0rem !important;
}

.livis-operator-builder-statics-wrap {
  justify-content: flex-start !important;
  margin-left: -2.5rem !important;
}

.livis-operator-builder-data .livis-inspection-location-wrap {
  margin-left: -2.5rem !important;
}

.livis-operator-builder-statics-wrap .livis-inspection-station-quality-score-divider-mobile {
  margin-inline: 1.5rem !important;
}

.livis-operator-builder-create-modal-body h4 {
  padding-left: 0rem !important;
}

.livis-operator-builder-create-modal-input {
  margin-block: 1rem !important;
}

.livis-operator-builder-create-modal-worstation-details-card {
  background: rgba(232, 233, 249, 0.15);
  border-radius: 16px;
  padding-inline: 2.5rem !important;
  padding-block: 1.5rem !important;
}

.livis-operator-builder-create-modal-worstation-details-wrap {
  justify-content: center !important;
  align-items: center;
  margin-top: 1.5rem !important;
  margin-bottom: 3rem !important;
}

.livis-operator-builder-create-modal-worstation-card {
  background: var(--livis-operator-builder-modal-bg);
  border: 1px solid var(--livis-tag-unselected);
  border-radius: 12px;
  padding-inline: 0.5rem !important;
  padding-block: 1rem !important;
  display: flex !important;
  align-items: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem !important;
  line-height: 24px !important;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray) !important;
  margin-inline: 0.5rem !important;
  cursor: pointer;
  margin-block: 0.25rem !important;
}

.livis-operator-builder-create-modal-worstation-card img,
.livis-operator-builder-create-modal-worstation-card-active img {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
  padding-inline: 0.75rem !important;
}

.livis-operator-builder-create-modal-worstation-card-active {
  background: var(--livis-operator-builder-modal-bg);
  border-radius: 12px;
  padding-inline: 0.5rem !important;
  padding-block: 1rem !important;
  display: flex !important;
  align-items: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem !important;
  line-height: 24px !important;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray) !important;
  margin-inline: 0.5rem !important;
  border: 1px solid var(--livis-color-primary-dblue);
  cursor: pointer;
  margin-block: 0.25rem !important;
}


.livis-operator-panel-menu-items {
  width: 100% !important;
}

.livis-operator-panel-single-menu-item {
  margin-inline: 1rem !important;
  display: flex;
  align-items: center !important;
  padding-block: 0.4rem !important;
  margin-block: 0.5rem !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem !important;
  line-height: 20px;
  cursor: pointer;
  color: var(--livis-color-primary-gray);
  text-shadow: 0px 0px 16px var(--livis-white-color);
  padding-right: 1rem !important;
  word-break: break-word;
  max-width: calc(100% - 2rem);
  //   background: var(--livis-part-box-background);
  //   border: 1px solid var(--livis-color-primary-dblue);
  //   border-radius: 16px;
}

.livis-operator-panel-single-menu-item-active {
  margin-inline: 1rem !important;
  display: flex !important;
  align-items: center !important;
  padding-block: 0.4rem !important;
  margin-block: 0.5rem !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem !important;
  line-height: 20px;
  cursor: pointer;
  color: var(--livis-color-primary-gray);
  text-shadow: 0px 0px 16px var(--livis-white-color);
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-color-primary-dblue);
  border-radius: 16px;
  padding-right: 1rem !important;
  word-break: break-word;
  max-width: calc(100% - 2rem);
}

.livis-operator-panel-single-menu-item img,
.livis-operator-panel-single-menu-item-active img {
  padding: 0.5rem !important;
}

.livis-operator-panel-accordian-sub-menu {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 20px;
  color: var(--livis-input-placeholder-color);
  margin-left: 1.75rem !important;
  margin-block: 0.5rem !important;
  padding-block: 0.625rem !important;
  padding-left: 0.875rem !important;
  cursor: pointer;
  word-break: break-word;
  max-width: 65%;
  /* word-wrap: break-word; */
  // background: var(--livis-part-box-background);
  // border: 1px solid var(--livis-border-color);
  // border-radius: 12px;
}

.livis-operator-panel-accordian-sub-menu-active {
  border-radius: 12px;
  border: 1px solid var(--livis-border-color);
  background: var(--livis-part-box-background);
  color: var(--livis-color-primary-dblue);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: 1.75rem !important;
  margin-right: 1rem !important;
  margin-block: 0.5rem !important;
  padding-block: 0.625rem !important;
  padding-inline: 0.875rem !important;
  cursor: pointer;
}

// operator builder css
.livis-operator-builder-container {
  padding-inline: 1.75rem !important;
  padding-top: 0.75rem !important;
}

.livis-hide-operator-builder-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh !important;
  flex-direction: column;
}

.livis-hide-operator-builder-wrap div:nth-child(1) {
  font-size: 1.5rem !important;
  line-height: 40px !important;
  color: var(--livis-color-primary-gray) !important;
  font-family: "Noto Sans Bold";
  text-align: center;
}

.livis-hide-operator-builder-wrap div:nth-child(2) {
  font-size: 0.875rem !important;
  line-height: 24px !important;
  color: var(--livis-color-primary-gray) !important;
  font-family: "Noto Sans Regular";
  margin-top: 1rem !important;
  text-align: center;
}

.livis-operator-builder-goback-btn-wrap button {
  padding-inline: 1rem !important;
}

.livis-operator-builder-topbar {
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.6);
  box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0.1),
    0px -2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 2px 4px -2px rgba(168, 168, 168, 0.15);
  border-radius: 16px;
  height: 72px;
}

.livis-operator-builder-main-container {
  height: 90% !important;
}

.livis-operator-builder-selection-bar-wrap {
  padding-right: 0.75rem !important;
  position: relative !important;
}

.livis-operator-builder-selection-bar {
  background: rgba(254, 254, 255, 0.8);
  border: 1px solid rgba(199, 215, 255, 0.6);
  box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0.1),
    0px -2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 2px 4px -2px rgba(168, 168, 168, 0.15);
  border-radius: 16px;
  height: 100% !important;
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  align-items: center !important;
  //   padding-block: 1.875rem !important;
}

.livis-operator-builder-selection-bar-top-icons-wrap {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between;
}

.livis-operator-builder-selection-bar-top-icons-wrap img {
  margin-block: 1rem !important;
  margin-top: 1.875rem !important;
  cursor: pointer;
}

.livis-operator-builder-selection-bar-bottom-icons {
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between;
}

.livis-operator-builder-selection-bar-bottom-icons img {
  margin-bottom: 1.875rem !important;
  cursor: pointer;
}

.livis-video-tutorial-icon {
  width: 36px !important;
  height: 36px !important;
}

.livis-operator-builder-configuration-wrap {
  padding-right: 0rem !important;
  padding-left: 0.75rem !important;
  position: static !important;
}

.livis-operator-builder-main-wrap {
  background: var(--livis-color-background);
  border: 2px solid rgba(8, 87, 222, 0.25);
  box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0.1),
    0px -2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 2px 4px -1px rgba(168, 168, 168, 0.15);
  border-radius: 15px;
}

.livis-operator-builder-main-header {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-dblue);
  padding-inline: 1.5rem !important;
  padding-block: 0.75rem !important;
  background: rgba(233, 233, 234, 0.1) !important;
  border-radius: 14px 14px 0px 0px;
}

.livis-operator-builder-preview-modal {
  width: 93.375rem !important;
  padding-inline: 2rem !important;
  // height: 58rem !important;
}

.livis-operator-builder-preview-modal-body {
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.livis-operator-builder-preview-modal-body .livis-auth-main {
  max-height: 75vh !important;
}

.livis-operator-builder-preview-modal-body .livis-landing-logo-wrap img {
  transform: scale(0.73);
}

.livis-operator-builder-preview-modal-body .livis-operator-panel-img-feed {
  height: 55vh !important;
}

.livis-operator-builder-img-feed {
  width: 100% !important;
  height: 53vh !important;
  margin-top: 1rem !important;
  // background-color: var(--livis-black-font-color);
  border-radius: 1.25rem !important;
  display: flex;
  // justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}

.livis-operator-builder-list-img-feed {
  overflow: auto !important;
  align-items: flex-start;
  background-color: var(--livis-black-font-color);
}

.livis-operator-builder-intro-modal-body {
  // margin-inline: 1rem !important;
  margin-top: 3.5rem !important;
}

.livis-operator-builder-intro-modal {
  width: 61% !important;
}

.livis-operator-builder-intro-modal .livis-modal-main-content {
  margin-inline: 2rem !important;
}

.livis-operator-builder-intro-modal-body-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.0015em;
  color: var(--livis-color-primary-gray);
  margin-block: 0.75rem !important;
}

.livis-operator-builder-intro-modal-body-title {
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;
  line-height: 24px;
  text-align: right;
  color: var(--livis-gray-font-color);
  margin-block: 2rem !important;
  margin-left: 2rem !important;
}

.livis-operator-builder-intro-modal-carousal-indicators {
  display: flex !important;
  justify-content: flex-end !important;
  margin-top: 4.5rem !important;
}

.livis-operator-builder-intro-modal-carousal-indicator {
  width: 0.75rem !important;
  height: 0.75rem !important;
  border-radius: 50% !important;
  background: var(--livis-form-table-background-color);
  margin-left: 0.5rem !important;
}

.livis-operator-builder-intro-modal-carousal-indicator-active {
  width: 0.75rem !important;
  height: 0.75rem !important;
  border-radius: 50% !important;
  margin-left: 0.5rem !important;
  background: var(--livis-color-primary-blue);
}

.livis-operator-builder-intro-modal-body .livis-stepper-button {
  margin-top: 12rem !important;
}

.livis-operator-builder-configuration-bar-wrap {
  width: 100% !important;
  position: relative !important;
  height: 100% !important;
}

.livis-operator-builder-configuration-bar-grid {
  padding: 1rem !important;
  max-height: 70vh !important;
  overflow-y: auto !important;
}

.livis-operator-builder-configuration-bar-grid .MuiOutlinedInput-root {
  width: 100% !important;
}

.livis-operator-builder-configuration-bar-info-block {
  background: rgba(245, 248, 255, 0.75);
  border: 1px solid rgba(199, 215, 255, 0.4);
  border-radius: 12px;
  padding-inline: 1rem !important;
  padding-block: 0.5rem !important;
  margin-block: 1rem !important;
}

.livis-operator-builder-configuration-bar-divider {
  border: 1px solid rgba(209, 212, 243, 0.5);
  margin-block: 1rem !important;
  max-height: 1px !important;
}

.livis-operator-builder-configuration-bar-accordian-wrap .MuiAccordion-root {
  box-shadow: none !important;
}

.livis-operator-builder-configuration-bar-accordian-wrap .MuiAccordion-root.Mui-disabled {
  background-color: transparent !important;
}

.livis-operator-builder-configuration-bar-accordian-wrap .MuiAccordionSummary-root {
  min-height: fit-content !important;
  padding-inline: 0rem !important;
}

.livis-operator-builder-configuration-bar-accordian-wrap .MuiCollapse-wrapper,
.livis-operator-builder-configuration-bar-accordian-wrap .MuiAccordionDetails-root {
  margin: 0rem !important;
  padding: 0rem !important;
  width: 100% !important;
}

.livis-operator-builder-configuration-bar-accordian-wrap .livis-operator-builder-configuration-bar-info-block {
  margin-top: 0rem !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  // line-height: 24px;
  letter-spacing: -0.0015em;
  color: rgba(41, 41, 49, 0.9);
}

.livis-operator-builder-configuration-bar-accordian-wrap .MuiAccordionSummary-content {
  margin-block: 0.5rem !important;
  margin-inline: 0rem !important;
}

.livis-operator-builder-header-divider {
  border-right: 1px solid var(--livis-operator-panel-accordian-border-color);
  max-width: 0.25rem !important;
}

.livis-operator-builder-client-logo-wrap {
  // background: var(--livis-color-background);
  // border: 1px dashed rgba(0, 0, 0, 0.1);
  // box-shadow: 0px -1px 2px -1px rgba(168, 168, 168, 0.25),
  //   0px 4px 8px -4px rgba(168, 168, 168, 0.15),
  //   0px 1px 2px -1px rgba(168, 168, 168, 0.25),
  //   0px 2px 4px -2px rgba(168, 168, 168, 0.2);
  border-radius: 8px;
  margin-left: 1rem !important;
}

.livis-operator-builder-header .livis-landing-search-wrap>div {
  min-width: 100% !important;
}

.livis-operator-builder-header {
  padding-top: 0.75rem !important;
  padding-left: 1.875rem !important;
}

.livis-operator-builder-auth-header-wrap {
  padding-bottom: 1rem !important;
}

.livis-operator-builder-view .livis-operator-panel-part-name {
  font-style: normal;
  font-weight: 600;
  font-size: 0.938rem;
  line-height: 20px;
  display: flex;
  // justify-content: center !important;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
  padding: 0rem !important;
}

.livis-operator-builder-view .livis-operator-panel-part-number {
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-dblue);
}

.livis-operator-builder-view .livis-operator-panel-batch-text {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 24px;
  color: var(--livis-instruction-secondary-color);
}

.livis-operator-builder-view .livis-operator-panel-batch-name {
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  color: var(--livis-color-primary-gray);
}

.livis-operator-builder-view .livis-operator-panel-current-batch {
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  color: var(--livis-color-primary-dblue);
}

.livis-operator-builder-view .livis-operator-panel-batch-size {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 24px;
  color: var(--livis-gray-dark-font-color);
}

.livis-operator-builder-view .livis-operator-panel-cycle-time {
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 24px;
  color: var(--livis-asseembly-chip-text-color);
  padding-block: 0.25rem !important;
  display: flex;
  align-items: center;
}

.livis-operator-panel-cycle-time-count {
  background: var(--livis-asseembly-chip-background);
  border-radius: 8px;
  padding-inline: 0.75rem !important;
  padding-block: 0.15rem !important;
  margin-left: 1rem !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: var(--livis-asseembly-chip-text-color);
}

.livis-operator-builder-view .livis-operator-panel-class-threshold {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 24px;
  color: var(--livis-color-primary-dblue);
  padding-block: 0.25rem !important;
}

.livis-operator-builder-part-details-wrap {
  padding-block: 0.5rem !important;
}

.livis-operator-builder-view .livis-operator-panel-info-wrap {
  padding-top: 0.5rem !important;
}

.livis-operator-builder-view .livis-operator-panel-button-wrap .livis-back-btn {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  line-height: 24px !important;
}

.livis-operator-builder-view .livis-operator-panel-button-wrap .livis-primary-btn {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  line-height: 24px !important;
}

.livis-operator-builder-view .livis-operator-panel-status-box {
  height: 4.25rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-wrap .Mui-expanded .livis-operator-panel-accordian-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.75rem !important;
  line-height: 24px !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-defects-heading,
.livis-operator-builder-view .livis-operator-panel-accordian-recent-inspection-heading {
  font-weight: 500 !important;
  font-size: 0.688rem !important;
  line-height: 24px !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-defects {
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 20px !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-defects-count,
.livis-operator-panel-accordian-feature-count {
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  line-height: 30px !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-heading {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  line-height: 24px !important;
}

.livis-operator-builder-view .livis-operator-panel-accepted-text {
  font-size: 0.75rem !important;
}

.livis-operator-builder-view .livis-operator-panel-total-inspection-text {
  font-size: 1rem !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-recent-inspection-column-heading-text {
  font-size: 0.75rem !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-recent-inspection-row-text {
  font-size: 0.625rem !important;
}

.livis-operator-builder-view .livis-operator-panel-accordian-wrap {
  max-height: 52vh !important;
  // overflow-y: visible;
}

//.livis-operator-builder-view .livis-operator-panel-img-feed 

.livis-operator-builder-header .livis-operator-page-title p {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.938rem !important;
  line-height: 48px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--livis-gray-dark-font-color);
}

.livis-operator-builder-header .livis-search-field input {
  height: 2.06rem !important;
}

.livis-operator-builder-header .livis-landing-header-btns img {
  width: 1.125rem !important;
}

.livis-operator-builder-header .livis-landing-header-btns a {
  width: 2rem !important;
  height: 2rem !important;
}

.livis-operator-builder-livis-logo {
  width: 4rem !important;
}

.livis-operator-builder-client-logo {
  max-width: 100%;
  max-height: 40px;
}

.livis-operator-builder-progress-bar-wrap {
  width: 30%;
  margin-right: 4rem !important;
}

.livis-operator-builder-progress-bar-text-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem !important;
  margin-top: 0.25rem !important;
}

.livis-operator-builder-progress-bar-text {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 24px;
  text-transform: capitalize;
  color: var(--livis-color-primary-gray);
}

.livis-operator-builder-progress-bar-text-percentage {
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 24px;
  text-transform: capitalize;
  color: var(--livis-color-primary-blue);
}

.livis-operator-builder-view .livis-operator-panel-fullscreen-icon {
  width: 1.875rem !important;
  height: 1.875rem !important;
}

// add feature modal starts
.livis-operator-builder-add-feature-modal-wrap {
  position: absolute !important;
  top: 0px !important;
  left: 6.25rem !important;
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.8);
  box-shadow: 0px -2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.35),
    0px 8px 16px -8px rgba(168, 168, 168, 0.2),
    0px 4px 8px -4px rgba(168, 168, 168, 0.25);
  border-radius: 16px;
  padding: 1.5rem !important;
  padding-top: 1rem !important;
  width: 68rem !important;
  height: 47rem !important;
  z-index: 1000 !important;
}

.livis-operator-builder-add-feature-modal-body-grid {
  height: calc(47rem - 5rem) !important;
}

.livis-operator-builder-add-feature-modal-body-components-section {
  background: rgba(232, 233, 249, 0.15);
  border: 1px solid rgba(199, 215, 255, 0.25);
  border-radius: 8px;
  padding-inline: 1.5rem !important;
  overflow: auto;
  height: calc(47rem - 5rem) !important;
}

.livis-operator-builder-add-feature-modal-body-components-wrap {
  padding-block: 1rem !important;
}

.livis-operator-builder-add-feature-modal-body-components-description-section {
  padding: 0rem !important;
  padding-inline: 1rem !important;
}

.livis-operator-builder-add-feature-modal-body-components-description-wrap {
  background: rgba(232, 233, 249, 0.15);
  border: 1px solid rgba(199, 215, 255, 0.25);
  border-radius: 8px;
  overflow: auto;
  // height: calc(47rem - 5rem) !important;
}

.livis-operator-builder-add-feature-modal-body-components-description-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 1.125rem !important;
  line-height: 20px !important;
  color: var(--livis-color-primary-gray);
  padding: 1.5rem !important;
}

.livis-operator-builder-add-feature-modal-body-components-description-img-wrap img {
  width: calc(100% - 3rem) !important;
  padding-inline: 1.5rem !important;
  height: 18rem !important;
  border-radius: 24px !important;
}

.livis-operator-builder-add-feature-modal-body-components-description-sub-heading {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  color: var(--livis-color-primary-gray);
  padding-inline: 1.5rem !important;
  padding-top: 1.5rem !important;
  padding-bottom: 0.5rem !important;
}

.livis-operator-builder-add-feature-modal-body-components-description-text {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.938rem !important;
  line-height: 24px !important;
  letter-spacing: 0.15px !important;
  color: var(--livis-color-primary-gray) !important;
  margin-inline: 1.5rem !important;
}

.livis-operator-builder-add-feature-modal-body-components-description-list {
  padding-left: 1rem !important;
}

.livis-operator-builder-add-feature-modal-body-components {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--livis-color-primary-gray);
  padding-block: 0.5rem !important;
  padding-left: 1rem !important;
  margin-block: 0.75rem !important;
  cursor: pointer;
  // color: var(--livis-color-primary-dblue);
  // background: var(--livis-part-box-background);
  // border: 1px solid var(--livis-border-color);
  // border-radius: 12px;
}

.livis-operator-builder-add-feature-modal-body-components:hover {
  border-radius: 12px;
  border: 1px solid var(--livis-border-color);
  background: var(--livis-part-box-background);
  color: var(--livis-color-primary-dblue);
  font-size: 1rem;
  font-style: normal;
  font-family: "Noto Sans SemiBold";
}

.livis-operator-builder-add-feature-modal-body-components.active {
  border-radius: 12px;
  border: 1px solid var(--livis-border-color);
  background: var(--livis-part-box-background);
  color: var(--livis-color-primary-dblue);
  font-size: 1rem;
  font-style: normal;
  font-family: "Noto Sans SemiBold";
}


// add feature modal ends

// badge and boxes css
.livis-operator-builder-badge {
  background: rgba(48, 48, 48, 0.75);
  box-shadow: 0px -1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.15),
    0px 1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.2);
  border-radius: 25px;
  width: 1.5rem !important;
  height: 1.5rem !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--livis-reports-download-btn-color);
  position: absolute;
  top: -20px;
  left: 10px;
  z-index: 100;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
}

.livis-operator-builder-position-relative {
  position: relative;
  cursor: pointer !important;
}

.livis-operator-builder-feed-badge {
  top: 0px !important;
  left: 22px !important;
}

.livis-operator-builder-feed-only-badge {
  top: 3.5rem !important;
}

.livis-operator-builder-ready-to-inspect-badge {
  top: -15px !important;
  left: 10px !important;
}

.livis-operator-builder-inspect-badge {
  right: 100px !important;
  top: 0px !important;
  left: auto !important;
}

.livis-operator-builder-selection-box-top-left {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  background: var(--livis-white-color);
  border: 2px solid var(--livis-operator-accepted-color);
  border-radius: 2px;
  top: -5px !important;
  left: -5px !important;
}

.livis-operator-builder-selection-box-top-right {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  background: var(--livis-white-color);
  border: 2px solid var(--livis-operator-accepted-color);
  border-radius: 2px;
  top: -5px !important;
  right: -5px !important;
}

.livis-operator-builder-selection-box-bottom-left {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  background: var(--livis-white-color);
  border: 2px solid var(--livis-operator-accepted-color);
  border-radius: 2px;
  bottom: -5px !important;
  left: -5px !important;
}

.livis-operator-builder-selection-box-bottom-right {
  position: absolute;
  width: 10px !important;
  height: 10px !important;
  background: var(--livis-white-color);
  border: 2px solid var(--livis-operator-accepted-color);
  border-radius: 2px;
  bottom: -5px !important;
  right: -5px !important;
}

.livis-operator-builder-selection-box {
  position: absolute;
  top: 0px;
  // left: -4px;
  // padding-inline: 4px;
  width: 100% !important;
  height: 100% !important;
  background: var(--livis-operator-builder-selection-box-bg);
  // border: 2px dashed rgba(8, 87, 222, 0.5);
  background-image: linear-gradient(90deg, #10823450 50%, transparent 50%),
    linear-gradient(90deg, #10823450 50%, transparent 50%),
    linear-gradient(0deg, #10823450 50%, transparent 50%),
    linear-gradient(0deg, #10823450 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 15px 4px, 15px 4px, 4px 15px, 4px 15px;
  background-position: left top, right bottom, left bottom, right top;
  animation: border-dance 0.5s infinite linear;
}

.livis-operator-builder-production-plan-selection-box {
  padding-block: 0.5rem !important;
  margin-top: -0.5rem !important;
}

@keyframes border-dance {
  0% {
    background-position: left top, right bottom, left bottom, right top;
  }

  100% {
    background-position: left 15px top, right 15px bottom, left bottom 15px,
      right top 15px;
  }
}

//selection box css
.livis-operator-builder-selection-box-part-information {
  top: -0.8rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.6rem !important;
  left: -0.8rem !important;
  max-width: 10rem !important;
}

.livis-operato-builder-badge-part-information {
  top: -1.5rem !important;
  left: 0.1rem !important;
}

.livis-operator-builder-selection-box-threshold {
  top: -0.8rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.6rem !important;
  left: -0.8rem !important;
  min-width: 10rem !important;
}

.livis-operator-builder-selection-box-start-process {
  top: -0.8rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.6rem !important;
  left: -0.8rem !important;
  padding-right: 1.6rem !important;
  // min-width: 10rem !important;
}

.livis-operator-builder-selection-box-inspect {
  top: -0.8rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.6rem !important;
  left: -0.8rem !important;
  padding-right: 1.6rem !important;
}

.livis-operator-builder-selection-box-camera-feed {
  top: 3.7rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.2rem !important;
  left: -0.8rem !important;
  padding-right: 1.6rem !important;
  height: 90% !important;
}

.livis-operator-builder-selection-box-logo {
  top: -0.8rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
  left: -0.8rem !important;
  padding-right: 1.6rem !important;
}

//selction box css end

.livis-operator-builder-config-panel-inspection-result-display-text-wrap {
  display: flex;
  justify-content: space-between;
}

.livis-operator-builder-start-process-btn-wrap {
  width: 45% !important;
}

.livis-operator-builder-view .livis-operator-panel-button-wrap .livis-back-btn,
.livis-operator-builder-view .livis-operator-panel-button-wrap .livis-primary-btn {
  min-width: 100% !important;
}

.livis-operator-builder-badge-complete {
  background: var(--livis-usecase-feature-background) !important;
  box-shadow: 0px -1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.15),
    0px 1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.2) !important;
  border-radius: 25px !important;
  color: var(--livis-success-color) !important;
}

.livis-operator-builder-sub-badge {
  position: absolute !important;
  top: -8px !important;
  right: -5px !important;
}

.livis-operator-builder-config-panel-properties-wrap {
  margin-top: 1rem !important;
}

.livis-operator-builder-config-panel-properties-wrap-heading {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  color: var(--livis-color-primary-gray) !important;
  margin-bottom: 1rem !important;
}

.livis-operator-builder-config-panel-properties-wrap-checkbox-heading {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 24px;
  color: var(--livis-color-primary-gray);
  margin-top: 1.5rem !important;
}

.livis-operator-builder-config-panel-properties-wrap .livis-operator-builder-add-feature-modal-body-components {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  color: var(--livis-operator-panel-add-feature-modal) !important;
  padding-block: 0rem !important;
  margin-block: 0rem !important;
}

.livis-operator-builder-configuration-bar-wrap .livis-operator-panel-button-wrap {
  padding-inline: 1rem !important;
  position: absolute !important;
  bottom: 1rem !important;
  width: -webkit-fill-available !important;
}

.livis-operator-builder-configuration-bar-wrap .livis-operator-panel-button-wrap button {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
}

.livis-operator-builder-configuration-bar-wrap .livis-operator-panel-button-wrap .livis-operator-panel-button-wrap .livis-back-btn {
  width: 50% !important;
}

.livis-operator-builder-add-feature-modal-body-selection-dropdown.MuiOutlinedInput-root {
  border-radius: 0.5rem !important;
  background: var(--livis-operator-builder-modal-select-bg) !important;
  border: none !important;
  outline: none !important;
  margin-bottom: 1.5rem !important;
}

.livis-operator-builder-add-feature-modal-body-selection-dropdown .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.livis-operator-builder-add-feature-modal-body-text-plus-input-wrap .livis-operator-builder-add-feature-modal-body-input .MuiTextField-root {
  margin: 0rem !important;
}

.livis-operator-builder-add-feature-modal-body-text-plus-input-wrap .livis-operator-builder-add-feature-modal-body-input .MuiOutlinedInput-root {
  border-radius: 0.25rem !important;
  background: var(--livis-operator-builder-modal-select-bg) !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 28px !important;
  text-transform: uppercase;
  color: var(--livis-color-primary-gray);
}

.livis-operator-builder-add-feature-modal-body-text-plus-input-wrap .livis-operator-builder-add-feature-modal-body-input .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(140, 146, 224, 0.6) !important;
}

.livis-operator-builder-add-feature-modal-body-text-plus-input-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem !important;
}

.livis-operator-builder-add-feature-modal-body-text-plus-input-wrap .livis-operator-builder-add-feature-modal-body-text {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 24px !important;
  color: var(--livis-color-primary-gray) !important;
}

.livis-operator-builder-config-panel-properties-wrap-checkbox-wrap {
  border: 1px solid rgba(209, 212, 243, 0.5);
  border-radius: 8px;
  padding: 0rem !important;
  margin-top: 0.75rem !important;
  margin-bottom: 1.25rem !important;
  max-height: 8rem !important;
  overflow: auto;
}

.livis-operator-builder-config-panel-properties-wrap-checkbox-section {
  justify-content: center !important;
}

.livis-operator-builder-configuration-bar-info-block-show-more {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.813rem !important;
  line-height: 16px !important;
  letter-spacing: 0.1px;
  color: var(--livis-data-form-header-color);
  margin-top: 0.5rem !important;
}

.livis-operator-builder-configuration-bar-upload-wrap {
  height: 160px;
  background: var(--livis-color-background-white);
  border: 1px dashed var(--livis-operator-builder-configuration-upload);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  cursor: pointer;
}

.livis-operator-builder-configuration-bar-upload-heading {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
  margin-block: 1rem !important;
}

.livis-operator-builder-configuration-bar-upload-heading span {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-decoration-line: underline !important;
  color: var(--livis-data-form-header-color);
}

.livis-operator-builder-configuration-bar-upload-title {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem !important;
  line-height: 16px !important;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--livis-instruction-secondary-color);
}

.livis-operator-panel-defects-grid {
  position: relative;
}

.livis-operator-builder-recent-inspection-badge {
  top: -15px !important;
  left: 10px !important;
}

.livis-operator-builder-change-image-grid {
  margin-top: 2.5rem !important;
}

.livis-operator-builder-change-image-grid button {
  float: right;
}

.livis-operator-builder-create-modal-body .MuiAutocomplete-root {
  min-width: 100% !important;
}

.livis-operator-builder-activate-deactivate-modal .livis-yes-activate-deactivate-btn {
  background-color: var(--livis-operator-builder-deactivate-modal-btn) !important;
}

.livis-operator-builder-health-check-empty {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--livis-search-background-color);
}

.livis-operator-panel-batch-info.livis-operator-builder-position-relative-dummy {
  background-color: var(--livis-color-background);
  width: 100% !important;
}

.livis-operator-panel-class-threshold-dummy {
  background-color: var(--livis-color-background);
  border: none !important;
}

.livis-operator-builder-only-part-number-css {
  display: flex !important;
}

.livis-operator-builder-config-panel-properties-wrap .MuiOutlinedInput-input {
  text-align: center !important;
  background-color: var(--livis-operator-builder-config-panel-bg) !important;
  border: 1px solid rgba(209, 212, 243, 0.5);
  border-radius: 25px !important;
}

.livis-operator-builder-toggle-btn {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}

.livis-operator-builder-end-process-badge {
  left: 4rem !important;
}

.livis-operator-start-process-badge {
  top: -0.6rem !important;
}

.livis-operator-builder-config-panel-properties-wrap .MuiSelect-select {
  background-color: var(--livis-operator-builder-modal-select-bg) !important;
  border-radius: 0.5rem !important;
}

.livis-operator-builder-configuration-bar-wrap .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.livis-operator-builder-config-wrap {
  position: static !important;
}

.livis-operator-builder-feed-overlay {
  position: relative;
  width: 100% !important;
  height: 100% !important;
}

.livis-operator-builder-feed-overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.livis-operator-builder-feed-overlay-heading {
  color: var(--livis-operator-builder-feed-overlay-color);
  text-align: center;
  font-size: 1.5rem;
  font-family: "Noto Sans SemiBold";
  margin-block: 1.5rem 0.5rem;
}

.livis-operator-builder-feed-overlay-heading.heading-3 {
  font-size: 1rem;
}

.livis-operator-builder-feed-overlay-heading.heading-4 {
  font-size: 0.75rem;
}

.livis-operator-builder-feed-overlay-body {
  color: var(--livis-operator-builder-feed-overlay-color);
  text-align: center;
  font-size: 1rem;
  font-family: "Noto Sans Regular";
  max-width: 90%;
}

.livis-operator-builder-feed-overlay-body.title-4 {
  font-size: 0.5rem;
}

.livis-operator-builder-feed-overlay-body.title-3 {
  font-size: 0.75rem;
}

.livis-operator-builder-feed-overlay img {
  object-fit: cover !important;
}

.livis-operator-builder-feed-overlay img.img-3 {
  width: 5rem !important;
}

.livis-operator-builder-feed-overlay img.img-4 {
  width: 3rem !important;
}

.livis-operator-builder-feed {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--livis-black-font-color);
}

.livis-operator-builder-feed-0 {
  height: 100%;
}

.livis-operator-builder-feed-1 {
  height: 100%;
  width: 100%;
}

.livis-operator-builder-feed-2 {
  height: 100%;
  width: 50%;
}

.livis-operator-builder-feed-3 {
  width: 33.33%;
  height: 100%;
}

.livis-operator-builder-feed-4 {
  width: 50%;
  height: 50%;
}

.livis-operator-builder-feed-three {
  width: 33.33%;
  height: 50%;
}

.livis-operator-builder-feed-four {
  width: 25%;
  height: 50%;
}

//lock icon css
.livis-operator-builder-lock {
  background: var(--livis-operator-builder-modal-bg);
  box-shadow: 0px -1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.15),
    0px 1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.2);
  border-radius: 25px;
  width: 1.5rem !important;
  height: 1.5rem !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.15px;
  color: var(--livis-reports-download-btn-color);
  position: absolute;
  top: -20px;
  right: 10px;
  z-index: 100;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;
}

.livis-operator-builder-lock img {
  width: 1rem;
  height: 1rem;
}

.livis-operator-builder-lock-start-end {
  left: 7rem !important;
  right: auto !important;
  top: -0.8rem !important;
}

.livis-operator-builder-popup-icon {
  margin-right: 2rem !important;
  margin-left: auto !important;
  cursor: pointer;
}

.MuiTooltip-tooltip {
  // font-size: 0.6rem !important;
  // background-color: #6026cf !important;
  padding: 0.875rem !important;
  // border-radius: 0.875rem !important;
  border-radius: 16px !important;
  background: var(--livis-operator-builder-tooltip-color) !important;
  box-shadow: 0px 6px 12px 0px rgba(168, 168, 168, 0.25),
    0px 2px 4px 0px rgba(168, 168, 168, 0.35),
    0px -1px 2px 0px rgba(168, 168, 168, 0.15) !important;
}

.MuiTooltip-arrow {
  left: -0.5rem !important;
  color: var(--livis-operator-builder-tooltip-color) !important;
}

.livis-preview-btn {
  cursor: pointer !important;
}

.text-center {
  justify-content: center !important;
  text-align: center !important;
}

.livis-operator-builder-pagination {
  position: absolute;
  bottom: 2rem !important;
  right: 2.5rem !important;
  background-color: var(--livis-operator-builder-modal-bg);
  padding: 0.25rem 1rem;
  border-radius: 50px;
}

.focus-animation {
  border-radius: 0.875rem;
  border: none !important;
  background-image: none !important;
  transform: scale(0);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--livis-operator-accepted-color);
  }

  50% {
    transform: scale(1);
    box-shadow: 0 0 0 10px var(--livis-operator-accepted-box-shadow);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--livis-operator-accepted-color);
  }
}

.tutorial-video {
  border-radius: 1rem !important;
}

.strike-through {
  // text-decoration: line-through !important;
  opacity: 0.6;
}

.livis-upload-grid {
  min-height: 100% !important;
}

.livis-operator-builder-grid-list-icon {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem !important;
}

.livis-operator-builder-grid-list-icon div {
  margin-inline: 0.5rem !important;
  padding: 0.5rem 1rem !important;
  background-color: var(--livis-white-color) !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
}

.livis-operator-builder-grid-list-icon div.active {
  background-color: var(--livis-color-primary-blue) !important;
  color: white !important;
}

.livis-serial-list-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: var(--livis-color-background-white);
}

.livis-serial-list-wrap>div {
  margin-left: 2rem !important;
}

.process-batch-check {
  margin-top: 2rem !important;
  margin-bottom: 0rem !important;
}

.livis-operator-builder-feed-no-reports-icon-box {
  width: 100% !important;
}

//operator builder css ends

// operator panel css starts

.livis-operator-panel {
  padding-top: 1rem !important;
  padding-inline: 2rem !important;
  min-height: calc(100% - 1rem) !important;
}

.livis-operator-panel-part-details-wrap {
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.6);
  box-shadow: 0px0.25rem 0.5rem -4px rgba(168, 168, 168, 0.1),
    0px -2px0.25rem -2px rgba(168, 168, 168, 0.15),
    0px 2px0.25rem -2px rgba(168, 168, 168, 0.15);
  border-radius: 1rem;
  padding-block: 11px !important;
  padding-inline: 2rem !important;
}

.livis-operator-panel-button-wrap {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-left: 1.5rem !important;
}

.livis-operator-panel-button-wrap .livis-back-btn {
  border: 1px solid var(--livis-color-primary-lblue) !important;
  border-radius: 50px !important;
  width: 45% !important;
  height: 3rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: var(--livis-color-primary-dblue) !important;
}

.livis-operator-panel-button-wrap .livis-primary-btn {
  height: 3rem !important;
  width: 45% !important;
  margin: 0rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-button-wrap .livis-disabled-btn {
  height: 3rem !important;
  width: 45% !important;
  margin: 0rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-button-wrap .livis-stop-btn {
  border: 1px solid rgba(181, 0, 0, 0.7) !important;
  border-radius: 50px !important;
  width: 45% !important;
  height: 3rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: var(--livis-color-secondary-red) !important;
  text-transform: capitalize !important;
}

.livis-operator-panel-part-name {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
  padding-bottom: 0.375rem !important;
}

.livis-operator-panel-part-number {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-dblue);
}

.livis-operator-panel-cycle-time {
  border: 1px solid var(--livis-operator-panel-cycle-time-border) !important;
  border-radius: 0.75rem !important;
  padding-block: 0.75rem !important;
  padding-right: 0.25rem !important;
  padding-left: 0.75rem !important;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  color: var(--livis-asseembly-chip-text-color);
  margin-inline: 0.5rem !important;
}

.livis-operator-panel-cycle-time span {
  background: var(--livis-asseembly-chip-background);
  border-radius: 0.75rem !important;
  padding-block: 0.5625rem !important;
  padding-inline: 0.84375rem !important;
  margin-left: 0.75rem !important;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-asseembly-chip-text-color);
}

.livis-operator-panel-class-threshold {
  border: 1px solid var(--livis-part-drag-container) !important;
  border-radius: 0.75rem !important;
  padding-block: 0.75rem !important;
  padding-inline: 1rem !important;
  margin-inline: 0.5rem !important;
  color: var(--livis-color-primary-dblue);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
}

.livis-operator-panel-cycle-time-threshold-wrap {
  display: flex !important;
  justify-content: end !important;
}

.livis-operator-panel-batch-info-wrap {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
}

.livis-operator-panel-batch-info {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  padding: 0.5625rem 0.75rem !important;
}

.livis-operator-panel-batch-info-highlight {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  background: rgba(232, 233, 249, 0.5);
  border-radius: 0.75rem;
  padding: 0.5625rem 0.75rem !important;
}

.livis-operator-panel-fullscreen-batch-info-wrap {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
}

.livis-operator-panel-batch-text {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-instruction-secondary-color);
  margin-right: 0.25rem !important;
}

.livis-operator-panel-batch-name {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-gray);
  margin-right: 0.375rem !important;
}

.livis-operator-panel-current-batch {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-dblue);
  margin-left: 0.375rem !important;
  margin-right: 0.25rem !important;
}

.livis-operator-panel-batch-size {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-gray-dark-font-color);
}

.livis-operator-panel-status-box {
  background: var(--livis-color-background-white);
  border: 1.5px solid var(--livis-complete-color);
  border-radius: 1rem;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 5.625rem !important;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  color: var(--livis-complete-color);
  text-transform: uppercase !important;
}

.livis-status-accepted {
  background-color: var(--livis-status-accepted-bg) !important;
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-status-rejected {
  background-color: var(--livis-status-rejected-bg) !important;
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-img-feed {
  width: 100% !important;
  height: 74.5vh !important;
  margin-top: 1rem !important;
  background-color: var(--livis-black-font-color);
  border-radius: 1.25rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.livis-operator-panel-img-feed img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.livis-operator-panel-info-wrap {
  padding-left: 1.5rem;
  padding-top: 1rem !important;
}

.livis-operator-panel-fullscreen-info-wrap {
  padding-inline: 1.5rem !important;
  padding-top: 1rem !important;
  width: 350px !important;
  float: right !important;
}

.livis-operator-panel-feed-wrap {
  position: relative !important;
}

.livis-operator-panel-fullscreen-icon {
  position: absolute !important;
  top: 6rem !important;
  right: 2rem !important;
  cursor: pointer;
}

.livis-operator-panel-position-indicator-wrap {
  position: absolute !important;
  top: 2rem !important;
  left: 2rem !important;
  background: rgba(31, 31, 31, 0.5);
  border-radius: 1rem;
  padding-block: 0.75rem !important;
  padding-inline: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--livis-white-color);
}

.livis-operator-panel-fullscreen-position-indicator-wrap {
  width: fit-content !important;
  background: rgba(31, 31, 31, 0.5);
  border-radius: 1rem;
  padding-block: 0.75rem !important;
  padding-inline: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--livis-white-color);
  margin-block: 2.5rem !important;
  margin-inline: 2rem !important;
}

.livis-operator-panel-position-indicator {
  width: 0.75rem !important;
  height: 0.75rem !important;
  border-radius: 50% !important;
  background-color: var(--livis-operator-accepted-color) !important;
  margin-left: 0.5rem !important;
}

.livis-operator-panel-accordian-wrap .MuiAccordionDetails-root {
  padding-top: 0px !important;
}



//.livis-operator-panel-accordian-wrap 

.livis-operator-panel-accordian-wrap::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.livis-operator-panel-accordian-wrap>div {
  background: var(--livis-color-background-white) !important;
  border: 1px solid rgba(199, 215, 255, 0.6) !important;
  border-radius: 0.75rem !important;
  margin-top: 1rem !important;
  box-shadow: none !important;
  border: none !important;
}

.livis-operator-panel-accordian-wrap>div::before {
  display: none !important;
}

.livis-operator-panel-accordian-wrap .MuiAccordionSummary-content {
  max-width: fit-content !important;
}

.livis-operator-panel-accordian-wrap.livis-define-bl-accordian-wrap .MuiAccordionSummary-content {
  max-width: 100% !important;
}

.livis-operator-panel-accordian-wrap .MuiSvgIcon-root {
  margin-left: 0.5rem !important;
}

.livis-operator-panel-accordian-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-data-form-header-color);
}

.livis-operator-panel-accordian-wrap .MuiAccordionSummary-expandIconWrapper {
  color: var(--livis-data-form-header-color) !important;
}

.livis-operator-panel-accordian-wrap .Mui-expanded .livis-operator-panel-accordian-heading {
  font-weight: 700 !important;
}

.livis-operator-panel-accordian-wrap .MuiCollapse-wrapper {
  width: 100% !important;
}

.livis-operator-panel-defects-grid {
  background: var(--livis-part-box-background);
  border-radius: 1.5rem;
  margin-inline: 0.25rem !important;
  padding-bottom: 1.25rem !important;
  margin-top: 1rem !important;
  // min-height: 500px !important;
}

.livis-operator-panel-accordian-defects-heading {
  background: var(--livis-operator-panel-accordian-inspection-heading);
  border-radius: 1.5rem;
  text-align: center;
  color: var(--livis-color-primary-gray);
  padding-block: 0.75rem !important;
  margin-bottom: 0.5rem !important;
}

.livis-operator-panel-accordian-defects-wrap {
  padding-inline: 1.25rem !important;
  margin-block: 1.25rem !important;
}

.livis-operator-panel-accordian-defects {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: right;
  color: var(--livis-operator-panel-inspection-accordian-text);
  word-wrap: break-word !important;
}

.livis-operator-panel-accordian-defects-count {
  margin-top: 0.5rem !important;
}

.livis-operator-panel-accordian-defects-count,
.livis-operator-panel-accordian-defects-count.livis-operator-panel-accordian-feature-count {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem !important;
  line-height: 30px;
  text-align: right;
  color: var(--livis-color-secondary-red);
}

.livis-operator-panel-accordian-feature-count {
  color: var(--livis-operator-accepted-color) !important;
}

.livis-operator-panel-accepted-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: right;
  color: var(--livis-operator-panel-inspection-accordian-text);
  margin-bottom: 0.75rem !important;
}

.livis-operator-panel-total-inspection-text {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  text-align: right;
  color: var(--livis-operator-panel-inspection-accordian-text);
  margin-bottom: 0.5rem !important;
}

.livis-operator-panel-total-inspection-current-count {
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
  text-align: right;
  color: var(--livis-color-primary-gray);
}

.livis-operator-panel-total-inspection-total-count {
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 40px;
  text-align: right;
  color: var(--livis-color-primary-gray);
}

.livis-operator-panel-accordian-total-inspection {
  margin-inline: 1.25rem !important;
  padding-inline: 1rem !important;
  padding-block: 1.25rem !important;
  background: var(--livis-color-background-white);
  border: 1px solid var(--livis-operator-panel-accordian-border-color);
  border-radius: 1.5rem;
}

.livis-operator-panel-accepted-rejected-wrap {
  padding-inline: 1rem !important;
}

.livis-operator-panel-accordian-recent-inspection-heading {
  background: var(--livis-operator-panel-accordian-inspection-heading);
  border-radius: 1.5rem 1.5rem 0px 0px;
  text-align: center;
  color: var(--livis-color-primary-gray);
  padding-block: 0.75rem !important;
}

.livis-operator-panel-accordian-recent-inspection-column-heading {
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.5);
  border-radius: 0px 0px 1.5rem 1.5rem;
  text-align: center;
  padding-block: 0.75rem !important;
}

.livis-operator-panel-accordian-recent-inspection-column-heading-text {
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-gray);
}

.livis-operator-panel-accordian-recent-inspection-column-heading-subtext {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-gray);
}

.livis-operator-panel-accordian-recent-inspection-row {
  text-align: center;
  padding-block: 0.75rem !important;
}

.livis-operator-panel-accordian-recent-inspection-divider {
  border-bottom: 1px solid rgba(199, 215, 255, 0.5);
  height: 1px !important;
  width: 90% !important;
  margin: auto;
}

.livis-operator-panel-accordian-recent-inspection-row-text {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--livis-black-font-color);
}

.livis-operator-panel-accordian-recent-inspection-status-accepted {
  color: var(--livis-operator-accepted-color) !important;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: center;
}

.livis-operator-panel-accordian-recent-inspection-status-rejected {
  color: var(--livis-color-secondary-red) !important;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-align: center;
}

.livis-operator-panel-batch-details-modal {
  position: absolute;
  top: 60px;
  left: 0px !important;
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.6);
  box-shadow: 0px -2px0.25rem -2px rgba(168, 168, 168, 0.25),
    0px 2px0.25rem -2px rgba(168, 168, 168, 0.35),
    0px 0.5rem 1rem -8px rgba(168, 168, 168, 0.2),
    0px0.25rem 0.5rem -4px rgba(168, 168, 168, 0.25);
  border-radius: 25px;
  width: 427px;
  // height: 200px;
  padding: 25px !important;
  z-index: 10000 !important;
}

.livis-operator-panel-batch-details-modal-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-gray);
  margin-bottom: 1rem !important;
}

.livis-operator-panel-batch-details-modal-body {
  // padding-inline: 2.5rem !important;
  padding-block: 1rem !important;
}

.livis-operator-panel-batch-details-modal-button-wrap {
  display: flex !important;
  justify-content: end !important;
  margin-top: 3rem !important;
}

.livis-operator-panel-batch-details-modal-button-wrap button {
  margin-block: 0.5rem !important;
  padding-block: 0.5rem !important;
  padding-inline: 1.5rem !important;
  margin-left: 1rem !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5rem;
  max-width: 96px;
  height: 48px !important;
}

.livis-operator-panel-class-threshold-modal {
  position: absolute;
  top: 60px;
  right: 0px !important;
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.6);
  box-shadow: 0px -2px0.25rem -2px rgba(168, 168, 168, 0.25),
    0px 2px0.25rem -2px rgba(168, 168, 168, 0.35),
    0px 0.5rem 1rem -8px rgba(168, 168, 168, 0.2),
    0px0.25rem 0.5rem -4px rgba(168, 168, 168, 0.25);
  border-radius: 25px;
  width: 300px;
  padding: 25px !important;
  padding-inline: 40px !important;
  z-index: 100 !important;
}

.livis-reset-btn {
  background: var(--livis-reset-btn-background) !important;
  border-radius: 50px !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  color: var(--livis-reset-btn-color) !important;
  text-transform: capitalize !important;
}

.livis-operator-panel-threshold-slider-wrap {
  margin-top: 0.5rem !important;
}

.livis-operator-panel-threshold-slider-name {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: var(--livis-gray100-font-color);
}

.livis-operator-panel-threshold-slider-value {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-dblue);
}

.livis-operator-panel-threshold-wrap {
  margin-top: 1rem !important;
}

.livis-operator-panel-expand-more-icon {
  cursor: pointer;
  color: var(--livis-color-primary-dblue) !important;
}

.livis-operator-panel-expand-less-icon {
  cursor: pointer;
  color: var(--livis-color-primary-dblue) !important;
}

.livis-operator-panel-mini-feed {
  background: var(--livis-color-background-white);
  border: 1px solid var(--livis-operator-panel-mini-feed-border);
  box-shadow: 0px 0.5rem 1rem -8px rgba(168, 168, 168, 0.25),
    0px -2px0.25rem -2px rgba(168, 168, 168, 0.25),
    0px 2px0.25rem -2px rgba(168, 168, 168, 0.15),
    0px0.25rem 0.5rem -4px rgba(168, 168, 168, 0.2);
  border-radius: 1.25rem;
  padding: 0.5rem !important;
}

.livis-operator-panel-mini-feed-wrap {
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.6);
  box-shadow: 0px 0.5rem 1rem rgba(168, 168, 168, 0.25),
    0px -2px0.25rem rgba(168, 168, 168, 0.25),
    0px 2px0.25rem rgba(168, 168, 168, 0.15),
    0px0.25rem 0.5rem rgba(168, 168, 168, 0.2);
  border-radius: 1.25rem;
  margin-top: 1rem !important;
}

.livis-testing-inference-body .livis-operator-panel-mini-feed-grid {
  padding: 25px !important;
  max-height: 64vh !important;
  min-height: 42vh !important;
  overflow-y: scroll !important;
}

.livis-operator-panel-img-mini-feed {
  width: 100%;
  height: 250px !important;
  border-radius: 1.25rem !important;
}

.livis-operator-panel-mini-feed-result-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
}

.livis-operator-panel-mini-feed-result {
  padding-inline: 1rem !important;
  padding-block: 1rem !important;
  padding-top: 0.5rem !important;
}

.livis-operator-panel-mini-feed-result-chips {
  display: flex;
  align-items: center !important;
  margin-top: 0.5rem !important;
}

.livis-operator-panel-mini-feed-result-chips>span {
  margin-right: 1.25rem !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: var(--livis-gray100-font-color);
  min-width: fit-content !important;
}

.livis-operator-panel-mini-feed-result-chips .livis-inspection-info-feature-chip {
  background: var(--livis-usecase-feature-background) !important;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--livis-success-color) !important;
}

.livis-operator-panel-mini-feed-result-chips .livis-inspection-info-defect-chip {
  background: var(--livis-usecase-defect-background) !important;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--livis-error-color) !important;
}

.livis-operator-panel-mini-feed-section-wrap {
  padding: 0.25rem !important;
  cursor: pointer;
  position: relative;
}

.livis-operator-panel-fullscreen-div {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.livis-operator-panel-fullscreen-details-wrap {
  background: rgba(31, 31, 31, 0.75);
  border-radius: 0px 0px 1rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 1.5rem !important;
  padding-inline: 50px !important;
}

.livis-operator-panel-fullscreen-part-name {
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: var(--livis-reports-download-btn-color);
  margin-bottom: 0.5rem !important;
}

.livis-operator-panel-fullscreen-part-number {
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.25rem;
  letter-spacing: 0.15px;
  color: var(--livis-reports-download-btn-color);
}

.livis-operator-panel-fullscreen-class-threshold {
  border: 1px solid var(--livis-form-table-background-color) !important;
  border-radius: 0.75rem !important;
  padding-block: 0.75rem !important;
  padding-inline: 1rem !important;
  margin-inline: 1rem !important;
  color: var(--livis-reports-download-btn-color);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
}

.livis-operator-panel-fullscreen-class-threshold>span {
  width: max-content !important;
}

.livis-operator-panel-fullscreen-class-threshold .livis-operator-panel-expand-more-icon,
.livis-operator-panel-fullscreen-class-threshold .livis-operator-panel-expand-less-icon {
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-fullscreen-details-btn-wrap {
  display: flex !important;
}

.livis-operator-panel-fullscreen-details-btn-wrap button {
  margin: 0px !important;
  padding: 0.5rem 2rem !important;
}

.livis-operator-panel-exit-fullscreen-icon {
  margin-left: 1rem !important;
  cursor: pointer;
}

.livis-operator-panel-fullscreen-batch-info-wrap>.livis-operator-panel-batch-text,
.livis-operator-panel-fullscreen-batch-info-wrap>.livis-operator-panel-batch-size {
  color: rgba(255, 254, 254, 0.8) !important;
}

.livis-operator-panel-fullscreen-batch-info-wrap .livis-operator-panel-batch-name {
  color: var(--livis-operator-builder-modal-bg) !important;
}

.livis-operator-panel-fullscreen-batch-info-wrap .livis-operator-panel-current-batch {
  color: var(--livis-operator-builder-modal-bg) !important;
}

.livis-operator-panel-fullscreen-batch-info-wrap .livis-operator-panel-expand-more-icon,
.livis-operator-panel-fullscreen-batch-info-wrap .livis-operator-panel-expand-less-icon {
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-fullscreen-info-wrap .livis-operator-panel-accordian-wrap>div {
  background: rgba(31, 31, 31, 0.5) !important;
  border: 1px solid rgba(199, 215, 255, 0.2) !important;
  border-radius: 1rem !important;
}

.livis-operator-panel-fullscreen-info-wrap .livis-operator-panel-accordian-heading {
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-fullscreen-info-wrap .livis-operator-panel-accordian-defects-heading {
  background: var(--livis-operator-panel-fullscreen-info-wrap-bg) !important;
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-fullscreen-info-wrap .livis-operator-panel-accordian-defects-wrap * {
  color: var(--livis-reports-download-btn-color) !important;
}

.livis-operator-panel-fullscreen-info-wrap .livis-operator-panel-defects-grid {
  // background-color: #22282C !important;
  background-color: transparent !important;
}

.livis-operator-panel-fullscreen-details-wrap .livis-stepper-button-grid {
  min-width: fit-content !important;
  margin-right: 0px !important;
  margin-left: 1rem !important;
}

.livis-operator-panel-mini-feed-position-status-wrap {
  position: absolute;
  top: 2rem !important;
  left: 2rem !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.livis-operator-panel-mini-feed-position {
  background: rgba(51, 51, 51, 0.75);
  border-radius: 0.75rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.15px;
  color: var(--livis-reports-download-btn-color);
  padding: 0.5rem 0.75rem !important;
  margin-right: 0.5rem !important;
}

.livis-operator-panel-mini-feed-status {
  border-radius: 0.75rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.15px;
  color: var(--livis-reports-download-btn-color);
  padding: 0.5rem 0.75rem !important;
}

.livis-operator-panel-mini-feed-position-fullscreen {
  position: absolute;
  top: 2rem !important;
  right: 2rem !important;
}

.livis-operator-panel-batch-details-modal-body .livis-input-field {
  margin-top: -25px !important;
}

.livis-landing-footer-info-wrap {
  display: flex;
  align-items: center;
}

.livis-landing-footer-info-wrap>a {
  font-style: normal;
  font-weight: 300;
  font-size: 0.938rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-gray);
  text-decoration: none !important;
}

.livis-operator-panel-inspection-details-modal {
  min-width: 80% !important;
}

.livis-operator-panel-inspection-details-modal .livis-modal-main-content {
  margin: 0px !important;
}

.livis-operator-panel-inspection-details-modal .MuiAccordion-root {
  background: var(--livis-white-color) !important;
  border: 1px solid var(--livis-operator-panel-accordian-border-color) !important;
  box-shadow: 0px0.25rem 0.5rem -4px rgba(168, 168, 168, 0.1),
    0px -2px0.25rem -2px rgba(168, 168, 168, 0.15),
    0px 2px0.25rem -1px rgba(168, 168, 168, 0.15) !important;
  border-radius: 1rem !important;
}

.livis-operator-panel-img-feed-next-image-icon {
  position: absolute;
  top: 50%;
  right: 1.5rem;
  // transform: translate(-50%, -50%);
}

.livis-operator-panel-img-feed-previous-image-icon {
  position: absolute;
  top: 50%;
  left: 1.5rem;
  // transform: translate(-50%, -50%);
}

.livis-operator-panel-details-icon-wrap {
  margin-right: 0.5rem !important;
}

.livis-operator-panel-details-icon-wrap img {
  cursor: pointer;
}

.livis-operator-panel-user-details-modal-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  color: var(--livis-black-font-color);
}

.livis-operator-panel-user-details-modal-table {
  background: var(--livis-operator-panel-user-details-table);
  border-radius: 0.5rem;
  margin-top: 1rem !important;
  margin-bottom: 1.5rem !important;
  padding: 1rem 1.15rem !important;
}

.livis-operator-panel-user-details-modal-table-heading {
  font-style: normal;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 1.25rem;
  letter-spacing: 0.1px;
  color: var(--livis-gray-dark-font-color);
}

.livis-operator-panel-user-details-modal-table-value {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--livis-color-primary-gray);
}

.livis-operator-panel-user-details-modal-accordian-wrap {
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
}


.livis-operator-panel-batch-details-modal-button-wrap-margin {
  margin-top: 50px;
}

.livis-operator-panel-camera-grid {
  margin-top: 20px;
}

.livis-operator-panel-report-detail-container {
  display: flex;
  justify-content: end;
  margin-top: 10%;
}

.livis-operator-panel-batch-details-modal-wrap {
  position: absolute !important;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: red;
}

.livis-operator-panel-main-body-wrap {
  height: 86vh !important;
}

.livis-operator-panel-download-avatar {
  width: 11px !important;
  height: 14px !important;
}

.livis-operator-panel-flag-avatar {
  width: 1rem !important;
  height: 1rem !important;
}

// operator panel css ends


// operator reports css starts

.livis-operator-page-title {
  display: flex;
  justify-content: center;
  align-items: center;

  .livis-operator-page-selected-container {
    display: none;
  }
}

.livis-operator-page-title p {
  font-size: 1.25rem;
  font-family: "Noto Sans Regular";
  letter-spacing: 0.15px;
  color: var(--livis-gray-dark-font-color);
  margin: 0rem;
  margin-right: 1.5rem;
  cursor: pointer;
}

.livis-operator-page-selected {
  font-family: "Noto Sans SemiBold" !important;
  color: var(--livis-color-primary-gray) !important;
}

.livis-operator-header-controller-wrap {
  display: flex;
}

.livis-operator-filter-wrap {
  display: flex;

  & .livis-frequency-select-form {
    margin-right: 1rem;
    margin-top: 0.5rem;
  }

  & .livis-frequency-select-form:nth-child(2) {
    margin-right: 2rem;
  }

  & .MuiOutlinedInput-root {
    height: 100%;

    & fieldset {
      border-color: var(--livis-part-drag-container);
    }
  }

  & .MuiSelect-select {
    color: var(--livis-color-primary-dblue);
    background-color: var(--livis-chip-data-background-color);
  }
}

.livis-download-btn {
  border: 0.0625rem solid var(--livis-color-primary-lblue);
  border-radius: 6rem !important;
  padding: 0.7rem 1.2rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-reports-download-btn-color) !important;
  text-transform: unset !important;
  margin-top: 0.5rem !important;
}

.livis-operator-pagination-frequency {
  margin-right: 1.5rem;
  display: flex;

  & .livis-frequency-select-form {
    margin-right: 1rem;
  }

  & .livis-frequency-select-form:nth-child(2) {
    margin-right: 2rem;
  }

  & .MuiOutlinedInput-root {
    border-radius: none !important;

    & fieldset {
      border-color: var(--livis-part-drag-container);
    }
  }

  & .MuiSelect-select {
    color: var(--livis-color-primary-dblue);
    background-color: var(--livis-chip-data-background-color);
  }
}

.livis-operator-report-data-wrap {
  margin-left: 5.0625rem;
  margin-right: 5.3125rem;
  margin-top: 2rem;
}

.livis-operator-report-card {
  padding-right: 0.625rem;
  padding-left: 0rem;
  border-radius: 0.75rem !important;
  box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.2),
    0 2px 4px -2px rgba(168, 168, 168, 0.15),
    0 -2px 4px -2px rgba(168, 168, 168, 0.25),
    0 8px 1rem -8px rgba(168, 168, 168, 0.25) !important;
}

.livis-operator-preview-wrap {
  width: 90%;
  height: 8rem;
  border: solid 1px var(--livis-border-color);
  background-color: var(--livis-part-box-background);
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.livis-operator-list-data {
  & .livis-operator-title-timestamp {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: var(--livis-color-primary-gray);
    margin-bottom: 0 !important;
  }

  & p:nth-child(2) {
    margin-top: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.15px;
    color: var(--livis-color-primary-dblue);
  }
}

.livis-operator-batch-size {
  font-weight: 500 !important;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
}

.livis-operator-list-action-button-wrap {
  & .livis-operator-list-action-button-view {
    margin: 0 !important;
  }

  & .livis-operator-list-action-button-unflagged {
    margin-left: 15px;
  }

  & .livis-operator-list-action-button-flagged {}
}

.livis-operator-list-header-wrap {
  margin: 0.5rem;
  margin-inline: 0.375rem;
  padding-right: 0.625rem;
  padding-left: 0rem;
}

.livis-operator-column-header-blank {
  width: 12rem;
}

.livis-operator-table-header-title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  color: rgba(0, 0, 0, 0.6);
}

.livis-operator-status-header {
  margin-right: 1rem !important;
}

.livis-operator-operation-header {
  margin-right: 3rem;
}

.livis-operator-report-defect-feature-list {}

.livis-operator-report-type {
  min-width: fit-content !important;
}

.livis-reports-reject-reason-chip {
  margin-block: 0.25rem;
  min-width: calc(100% - 0.5rem) !important;
  justify-content: flex-start !important;
}

.livis-reports-reject-reason-menu {
  .MuiPaper-elevation {
    box-shadow: 0.625rem 0.625rem 0.5rem 0.625rem transparent !important;
    padding: .5rem;
  }
}

.livis-operator-report-type-container {
  display: flex;
  align-items: center !important;
  margin-top: 0.75rem;

  & p {
    margin: 0;
  }
}

.livis-operator-report-list-image {
  height: 100%;
  width: 100%;
}

.livis-operator-status-accepted {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: var(--livis-operator-accepted-color);
}

.livis-operator-status-rejected {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: var(--livis-part-rejected-color);
}

.livis-operator-part-accepted-indicator {
  background-color: var(--livis-operator-accepted-color);
  border-radius: 0.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--livis-reports-download-btn-color);
  }
}

.livis-operator-part-rejected-indicator {
  background-color: var(--livis-part-rejected-color);
  border-radius: 0.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--livis-reports-download-btn-color);
  }
}

.livis-operator-part-image-container {
  height: 46.5625rem;
  border-radius: 1.25rem;
  // background-color: var(--livis-color-primary-blue);
  box-shadow: 0px 8px 1rem -8px rgba(168, 168, 168, 0.25),
    0px -2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2);
  background: var(--livis-color-background-white);
  border: 1px solid rgba(199, 215, 255, 0.6);
}

.livis-operator-part-expanded-container {
  position: relative !important;
  width: 100%;
}

.livis-operator-part-expanded {
  max-width: 100% !important;
  height: 46.5625rem;
  background-color: var(--livis-black-font-color);
  border-radius: 1.25rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & img {
    object-fit: cover !important;
    height: 100% !important;
    width: 100% !important;
  }
}

.livis-operator-report-position-indicator-wrap {
  position: absolute !important;
  top: 1rem !important;
  left: 1.5rem !important;
  background: rgba(31, 31, 31, 0.5);
  border-radius: 1rem;
  padding-block: 0.75rem !important;
  padding-inline: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--livis-white-color);
}

.livis-operator-report-show-hide-switch-wrap {
  position: absolute !important;
  top: 1rem !important;
  right: 5.1875rem !important;
  background: rgba(31, 31, 31, 0.5);
  border-radius: 1rem;
  padding-block: 0.75rem !important;
  padding-inline: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--livis-white-color);
  margin-left: 0 !important;
  padding-left: 0 !important;

  & p {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0 !important;
    margin-left: 0.5rem !important;
  }
}

.livis-operator-report-show-hide-switch-wrap-other {
  position: absolute !important;
  top: 1rem !important;
  right: 1.5rem !important;
  background: rgba(31, 31, 31, 0.5);
  border-radius: 1rem;
  padding-block: 0.75rem !important;
  padding-inline: 1rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--livis-white-color);
  margin-left: 0 !important;
  padding-left: 0 !important;

  & p {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0 !important;
    margin-left: 0.5rem !important;
  }
}

.livis-operator-report-shrink-icon {
  position: absolute !important;
  top: 1rem !important;
  right: 1.5rem !important;
  cursor: pointer;
}

.livis-operator-report-img-feed-next-image-icon {
  position: absolute;
  top: 50%;
  right: 1.5rem;
}

.livis-operator-report-img-feed-previous-image-icon {
  position: absolute;
  top: 50%;
  left: 1.5rem;
}

.livis-operator-report-stats-wrapper {
  position: absolute;
  bottom: 0;
  background: rgba(31, 31, 31, 0.5);
  width: 100%;
  height: 8rem;
  border-bottom-left-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  color: var(--livis-operator-builder-modal-bg);

  & .livis-operator-report-stats-header {
    margin: 0;
    margin-top: 0.75rem;
  }

  & .livis-operator-report-stats-container {
    padding-left: 2.75rem;
  }
}

.livis-operator-report-stats-header {
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.livis-operator-report-stats-content-label {
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 1.375rem !important;
  margin: 0;
}

.livis-operator-part-grid-container {
  overflow: auto;
}

.livis-operator-report-modal {
  & .livis-modal-main-content {
    margin-inline: 0 !important;
  }
}

.livis-operator-report-detail-container .MuiCollapse-wrapper {
  width: 100% !important;
}

.livis-operator-report-detail-container {
  height: 703px !important;
  margin-top: 0.75rem;
  padding: 0 !important;

  & .MuiAccordion-root {
    border-radius: 1rem !important;
    box-shadow: none;
    border: 1px solid var(--livis-operator-panel-accordian-border-color);
    width: 100%;
  }

  & .MuiButtonBase-root {
    height: 4rem !important;
  }

  & .MuiAccordionSummary-content {
    flex-grow: 0 !important;
    margin-right: 0.3rem !important;

    & .MuiTypography-root {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: var(--livis-data-form-header-color);
    }
  }

  & .MuiAccordionDetails-root {
    padding: 0 !important;
  }

  & .MuiCollapse-wrapper {
    margin-bottom: 0.5rem;
  }
}

.livis-operator-panel-modal-img-mini-feed {
  width: 100%;
  height: 207px !important;
  border-radius: 1.25rem !important;
}

.livis-operator-feature-chip {
  background-color: var(--livis-usecase-feature-background) !important;
  height: 1.375rem !important;
  margin-left: 6px !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-success-color) !important;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

.livis-operator-defect-chip {
  background-color: var(--livis-usecase-defect-background) !important;
  height: 1.375rem !important;
  margin-left: 6px !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-error-color) !important;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}

// operator reports css ends


//part header footer css//

.livis-part-filter-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2.43rem;
  margin-right: 5.43rem;
  margin-top: 1.75rem;
  margin-bottom: 1rem;
}

.livis-part-header-wrap {
  // height: 8vh;
  background: var(--livis-white-color);
  padding-bottom: 1.5rem !important;
}

.livis-part-body-wrap {
  height: 88vh !important;
  overflow: auto !important;
  // overflow-y: hidden !important;
}

.livis-part-footer-wrap {
  height: 3vh !important;
  z-index: -1 !important;
}

.livis-part-header {
  // padding-top: 1.6rem;
  // padding-bottom: 1.6rem;
  padding-left: 2.4375rem !important;
  padding-right: 1.5rem !important;
}

.livis-part-logo-wrap {}

.livis-part-logo-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.livis-part-page-title {
  // margin-left: 64px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
}

.livis-part-page-title p {
  margin: 0px;
}

.livis-part-page-title p:nth-child(1) {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 3rem;
  letter-spacing: 0.01rem;
  color: var(--livis-gray-dark-font-color);
  margin-right: 2rem;
}

.livis-part-page-title p:nth-child(2) {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 3rem;
  letter-spacing: 0.01rem;
  color: var(--livis-gray-dark-font-color);
}

.livis-part-header-wrap .livis-search-field input {
  // height: 488px !important;
  // background-color: var(--livis-form-table-background-color);
}

.livis-part-header-wrap .livis-landing-header-btns {
  // padding-right: 1.5rem;
}

.livis-part-header-wrap .livis-landing-header-btns a {
  margin: 0px;
}

.livis-part-header-wrap .livis-landing-header-btns a:nth-child(1) {
  margin-right: 1.5rem;
}

.livis-part-header-wrap .livis-landing-header-btns a:nth-child(2) {
  margin-right: 1.5rem;
}

.livis-part-main-side-wrap {
  background: var(--livis-color-background);
  border-radius: 0px 0px 30px 30px !important;
  position: relative;
}

.livis-sidebar-menu-wrap {
  position: fixed;
  width: 100% !important;
}

.livis-part-main-wrap {
  // height: 100% !important;
  position: absolute;
  right: 0px;
  width: 100% !important;
}

//part header footer css/

//part css starts

.livis-add-part-wrapper {
  margin-bottom: 5.85rem;
}

.livis-part-details-switch-wrapper {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 1rem;
  margin-top: 2.75rem;
  text-decoration: none;
  padding-top: 1.25rem;
  padding-bottom: 1.3125rem;
}

.livis-part-details-switch-content {
  display: flex;
  margin-left: 2rem;
}

.livis-part-controller-plc-wrap {
  margin-bottom: 2rem;
}

.livis-add-part-form-controller-button-wrapper {
  height: 3rem !important;
  width: 100% !important;
  background: var(--livis-part-controller-button-background);
  border-radius: 0.5rem;
  display: flex;
  margin-bottom: 1.375rem;
}

.livis-part-controller-button {
  border-radius: 0.5rem !important;
  height: 2rem !important;
  width: 95% !important;
  margin: 0.5rem !important;
  text-transform: unset !important;
}

.livis-part-controller-button-active {
  background-color: var(--livis-color-primary-dblue) !important;
  color: white !important;
  font-weight: 600 !important;
}

.livis-part-controller-button-inactive {
  color: var(--livis-color-primary-gray) !important;
}

.livis-cobot-controller-preview-wrapper {
  width: 100%;
  height: 25rem;
  background-color: var(--livis-color-primary-gray);
  margin-top: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  position: relative !important;
  display: flex;
  flex-direction: column;

  & .livis-controller-preview-header {
    position: absolute !important;
    padding: 1rem;

    & .livis-controller-preview-count {
      background-color: rgb(51, 51, 51, 0.75);
      margin: 0.2rem;
      border-radius: 0.5rem;
      padding: 0 0.5rem;
      width: fit-content;

      & p {
        margin: 0;
        font-size: 0.81rem;
        font-weight: 600;
        line-height: 1.75rem;
        color: var(--livis-reports-download-btn-color);
      }
    }

    & .livis-controller-barcode-wrap {
      padding: 0.5rem 1rem;
      width: fit-content;
      background-color: rgb(51, 51, 51, 0.75);
      border-radius: 50px;
      display: flex;

      & p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: var(--livis-color-background-white);
      }
    }
  }
}

.livis-create-part-without-edge-btn {
  margin-top: 1rem !important;
  display: flex;
  justify-content: center;
}

.livis--cobot-controller-settings-or-block {
  display: flex;
  justify-content: center;
  width: 100%;
}

.livis-controller-no-camera-selected-wrap {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--livis-white-color);
}

.livis-camera-selection-note {
  font-size: 0.75rem !important;
  font-family: "Noto Sans SemiBold" !important;
  margin-block: 0.5rem 0.5rem !important;
}

.livis-cobot-controller-slide-icon-left {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 20px;
  cursor: pointer;
}

.livis-cobot-controller-slide-icon-right {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0%;
  margin-right: 20px;
  cursor: pointer;
}

.livis-add-parameter-accordian-details {
  padding-inline: 1rem !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.livis-add-parameter-chips-wrap {
  min-height: 11rem !important;
  max-height: 11rem !important;
  overflow: scroll;
}

.livis-add-parameter-accoridan-open.Mui-expanded {
  min-height: calc(100% - 4.5rem) !important;
  max-height: calc(100% - 4.5rem) !important;
}

.livis-parameter-frequency-container {
  border-radius: 16px;
  border: 1px solid var(--livis-parameter-frequency-container-border);
  background: rgba(237, 238, 247, 0.1);
  position: relative;
  // margin-left: 18px;
  // margin-right: 18px;
  // height: 236px;
  // padding-left: 1.5rem;
  position: relative;
  // top: -5.8rem;
  // padding-bottom: 1.5rem !important;
  margin-top: 1rem !important;
  // height: calc(100% - 2rem) !important;

  & .livis-parameter-frequency-tag-value-container {
    height: 70%;
    padding: 2rem !important;
  }

  & .livis-legend-circle-with-frequency {
    width: 1rem;
    height: 1rem;
    border-radius: 2rem;
    background-color: var(--livis-legend-circle-bg);
    margin-right: 4px;
  }

  & .livis-legend-circle-without-frequency {
    width: 1rem;
    height: 1rem;
    border-radius: 2rem;
    background-color: var(--livis-legend-without-frequency-circle);
    margin-right: 4px;
  }

  & .livis-parameter-frequency-disable {
    background-color: rgba(31, 31, 31, 0.3) !important;
    z-index: 2;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  & .livis-controller-barcode-wrap {
    padding: 9px 12px;
    width: fit-content;
    background-color: rgb(51, 51, 51, 0.75);
    border-radius: 50px;
    // margin-top: 9px;
    margin-right: 9px;

    & p {
      font-size: 1rem;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: var(--livis-color-background-white);
    }
  }

  & .livis-parts-frequency-value-wrapper {
    align-items: center;
    display: flex;
    margin-top: 2rem !important;

    & p {
      color: var(--livis-color-primary-gray);
      font-size: 0.938rem;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.1px;
      margin: 0;
      margin-right: 1.25rem;
      align-self: center;
    }

    & input {
      border-radius: 8px;
      border: 1px solid var(--livis-sso-btn-border-color);
      background: var(--livis-white-color);
      height: 2.5rem;
      // width: 12rem;
      // margin-right: 1.5rem;
      // padding-inline: .5rem !important;
      padding: 0px !important;
      margin: 0px !important;
      padding-inline: 0px !important;
      max-width: calc(100% - 1rem) !important;
      padding-left: 1rem !important;
    }

    & input:focus {
      border: 1px solid var(--livis-sso-btn-border-color);
      outline: none !important;
    }

    & input:focus-visible {
      border: 1px solid var(--livis-sso-btn-border-color);
      outline: none !important;
    }
  }

  & .MuiButtonBase-root.MuiTab-root {
    text-transform: unset !important;
    background-color: var(--livis-parts-frequency-value-wrapper-bg) !important;
    color: var(--livis-color-primary-dblue) !important;
    font-weight: 500 !important;
    font-size: 1rem !important;
    height: 3.8rem !important;
  }

  & .Mui-selected {
    color: var(--livis-color-background-white) !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    // background-color: rgb(51, 51, 51) !important;
  }

  & .MuiTabs-indicator {
    color: var(--livis-color-primary-dblue) !important;
    border: 1px solid var(--livis-color-primary-dblue) !important;
    border-radius: 10px !important;
    color: var(--livis-color-background-white) !important;
    background: var(--livis-color-primary-dblue) !important;
  }
}

.livis-cobot-controller-image {
  width: 100%;
  border-radius: 1rem !important;
  height: 100% !important;
  object-fit: contain;
}

// .livis-stepper-form .livis-cobot-controller-position-wrapper>div {
//   padding-top: 0rem !important;
// }

.livis-part-preview-button {
  background-color: var(--livis-preview-button-color) !important;
  color: var(--livis-color-primary-gray) !important;
  border-radius: 1.5rem !important;
  text-transform: unset !important;
  padding: 0.5rem 1rem !important;
}

.livis-preview-header {
  color: white;
  padding: 1rem 2rem;
  font-weight: 600 !important;
  height: 15%;
}

.livis-preview-body {
  height: 70%;
}

.livis-preview-footer {
  height: 10%;
  padding: 0rem 2rem;
}

.livis-preview-header-content {
  display: flex;
}

.livis-part-live-circle {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 1rem;
  background-color: var(--livivs-preview-live-color);
  margin-left: 0.5rem;
  align-self: center;
}

.livis-cobot-controller-settings-wrapper {
  width: 100%;
  min-height: 15rem;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  margin-top: 0.5rem;
  border-radius: 1rem;
  padding-block: 2rem !important;
}

.livis-preview-controller-no-data {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.livis-preview-controller-no-data p:nth-child(2) {
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.7px;
  color: var(--livis-gray-font-color);
  text-align: center !important;
}

.livis-preview-controller-no-data p:nth-child(3) {
  cursor: pointer;
  color: var(--livis-color-primary-blue);
  font-weight: 600;
}

.livis-controller-position-container {
  margin-top: 1rem;
}

.livis-cobot-controller-position-wrapper-label {
  width: 100%;
  border: 1px solid var(--livis-part-box-border);
  border-radius: 1rem;
  padding: 5px;

  & img {
    margin-right: 4px;
  }
}

.livis-cobot-controller-position-wrapper {
  width: 100%;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 1rem;
  padding: 5px;
}

.livis-cobot-controller-position-content {
  background-color: var(--livis-form-table-background-color);
  color: var(--livis-color-primary-gray);
  border-radius: 0.65rem;
  margin-right: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.65rem;
  cursor: pointer;
  min-width: fit-content !important;
  font-size: 0.875rem !important;
  position: relative;
  margin-top: 0.25rem !important;
}

.livis-cobot-controller-position-content-selected {
  background-color: var(--livis-color-primary-dblue);
  color: var(--livis-chip-data-background-color);
  border-radius: 0.65rem;
  margin-right: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.65rem;
  cursor: pointer;
  min-width: fit-content !important;
  font-size: 0.875rem !important;
  position: relative;
  margin-top: 0.25rem !important;
}

.livis-cobot-controller-camera-tabs {
  min-height: fit-content !important;
}

.livis-cobot-controller-parameter-position-wrapper {
  padding: 10px 12px !important;
  border-radius: 12px;
  border: 1px solid var(--livis-part-box-border);
  background-color: var(--livis-part-box-background);
  overflow-y: scroll;
}

.livis-cobot-controller-position-parameter {
  background-color: var(--livis-form-table-background-color);
  color: var(--livis-color-primary-gray);
  border-radius: 1rem;
  margin-right: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem !important;
  width: 133px;
  cursor: pointer;
}

.livis-cobot-controller-position-parameter-selected {
  background-color: var(--livis-color-primary-dblue);
  color: var(--livis-chip-data-background-color);
  border-radius: 1rem;
  margin-right: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem !important;
  width: 133px;
  cursor: pointer;
}

.livis-part-inspection-position-button {
  background-color: var(--livis-hover-color) !important;
  color: var(--livis-autocomplete-chip-button-selected) !important;
  border-radius: 0.5rem !important;
  text-transform: unset !important;
  padding: 0.3rem 1rem !important;
  font-weight: 500 !important;
}

.livis-cobot-preview-coordinate {
  font-weight: 600 !important;
}

.livis-cobot-coordinate-name {
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}

.livis-cobot-coordinates-box {
  border: 1px solid var(--livis-part-box-border);
  border-radius: 1rem;
  padding: 1rem 1.4rem;
}

.livis-part-text-wrap {
  color: var(--livis-color-primary-gray);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.1px;
}

.livis-part-controller-header-text-wrap {
  color: var(--livis-color-primary-gray);
  font-size: 1rem;
  font-weight: 500 !important;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  margin-top: 2rem;
}

.livis-plc-controller-tab {
  display: flex;
  width: 100%;
  overflow: auto !important;
  white-space: nowrap !important;
}

.plc-controller-content-wrap {
  min-width: 15.5rem !important;
  margin: 0.5rem 1rem 0.5rem 0rem;
}

.livis-controller-plc-tab-content {
  border-radius: 0.75rem !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  text-transform: unset !important;
  padding: 1rem 4rem 1rem 0.5rem !important;
  width: 100% !important;
}

.livis-plc-controller-selected {
  border: 1px solid var(--livis-color-primary-dblue) !important;
}

.livis-plc-content-text {
  color: var(--livis-color-primary-gray);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.1px;
}

.livis-plc-content-value {
  border: 1px solid var(--livis-border-color);
  border-radius: 1rem;
  width: 5.45rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-plc-data-wrap {
  height: 3rem;
}

.livis-confirm-close-modal {
  width: 29.75rem !important;
  // height: 13.25rem !important;

  .livis-logout-modal-grid {
    display: flex;
    justify-content: end;
    margin-top: 10%;
  }
}

.livis-confirm-close-modal .livis-modal-main-content {
  margin: 0rem !important;
  font-size: 1rem !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--livis-gray-font-color);
}

.livis-confirm-close-modal .livis-modal-main-content>div {
  margin-top: 5rem !important;
}

.livis-modal-close-btn {
  cursor: pointer;
}

.livis-part-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
}

.livis-part-no-data p:nth-child(2) {
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: 0.7px;
  color: var(--livis-color-primary-gray);
}

.livis-part-no-data p:nth-child(3) {
  font-weight: 400;
  margin: 0rem;
  color: var(--livis-instruction-secondary-color);
}

.livis-part-no-data button {
  margin-top: 2rem;
}

.livis-add-part-icon {
  width: 10rem;
}

.livis-part-card {
  position: relative;
  padding: 0.625rem 0.625rem 1.25rem 0;
  border-radius: 0.75rem !important;
  box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.2),
    0 2px 4px -2px rgba(168, 168, 168, 0.15),
    0 -2px 4px -2px rgba(168, 168, 168, 0.25),
    0 8px 16px -8px rgba(168, 168, 168, 0.25) !important;

  &:hover .livis-part-overlay {
    opacity: 1;
  }

  &:hover .livis-part-overlay-hidden {
    display: none;
  }
}

.livis-part-overlay {
  opacity: 0;
}

.livis-part-overlay-hidden {
  display: block;
}

.livis-part-data-wrap {
  display: flex;
  justify-content: center;
  // margin-right: 2rem;
  height: 68vh !important;
  overflow: auto;
  padding-right: 1rem !important;
}

.livis-part-preview-wrap {
  display: flex !important;
  margin-left: 0.625rem !important;
  width: 100% !important;
  height: 11.25rem !important;
  border-radius: 1.56rem !important;
  border: solid 1px var(--livis-border-color) !important;
  background-color: var(--livis-part-box-background) !important;
  box-shadow: none !important;
  position: relative;
}

.livis-part-card-stat-icon {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-border-color);
  border-radius: 3.125rem;
  padding: 0.5rem;
  z-index: 1;
}

.livis-part-card-stat-hover-icon {
  display: flex;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-border-color);
  border-radius: 0.75rem;
  padding: 0.5rem;
  z-index: 1;
  align-items: center !important;
  cursor: pointer;
}

.livis-part-card-stat-hover-icon img {
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin-right: 0.25rem;
}

.livis-part-list-stat-icon {
  width: 1.25rem;
  height: 1.25rem;
  right: 0.625rem;
  top: 0.625rem;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-border-color);
  border-radius: 3.125rem;
  padding: 0.5rem;
  margin-right: 1rem;
  cursor: pointer;
}

.livis-part-list-upload-icon {
  width: 2.2rem;
  height: 2.2rem;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-border-color);
  border-radius: 3.125rem;
  margin-right: 1rem;
  cursor: pointer;
}

.livis-part-card-upload-icon {
  position: absolute !important;
  bottom: 0.625rem !important;
  left: 0.625rem !important;
  width: 2rem !important;
  height: 2rem !important;
  z-index: 1;
  cursor: pointer;
}

.livis-part-card-size {
  position: absolute !important;
  bottom: 0.437rem !important;
  right: 0.437rem !important;
  z-index: 1;
  display: inline-flex;
  cursor: default;
}

.livis-part-card-size p {
  margin: 0;
  font-size: 0.81rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: var(--livis-reports-download-btn-color);
  background-color: rgb(51, 51, 51, 0.75);
  margin: 0.2rem;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
}

.livis-part-card-thumbnail {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
}

.livis-part-card-thumbnail-placeholder {
  position: absolute;
  transform: translate(-50%, -50%);
  width: 3.75rem;
  height: 3.75rem;
  top: 50%;
  left: 50%;
}

.livis-part-description-wrap {
  margin-left: 0.625rem;
  width: 97.3% !important;
  height: 21.5rem !important;
  border-radius: 1.56rem;
  border: solid 1px var(--livis-border-color);
  background-color: var(--livis-part-box-background);
}

.livis-part-description-header {
  margin: 0rem 1rem;

  & button {
    background-color: transparent !important;
  }
}

.livis-part-description-header p {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  letter-spacing: 0.7px;
}

.livis-part-description-body {
  margin: 0rem 1rem;
  color: var(--livis-gray100-font-color);
  max-width: 90%;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  letter-spacing: 0.15px;
  height: 80% !important;
  overflow: auto !important;
}

.livis-part-details-wrap {
  margin: 1.25rem 0.5rem 1rem 0 !important;
  padding-left: 1rem;
  display: inline-grid;
  width: 100%;
}

.livis-part-status-active {
  border-left: 0.5rem solid var(--livis-active-color);
  // background-color: var(--livis-active-color);
}

.livis-part-status-inactive {
  border-left: 0.5rem solid var(--livis-inactive-color);
  // background-color: var(--livis-inactive-color);
}

.livis-part-detail-content {
  margin: 0;
  margin-left: 0.5rem;
}

.livis-part-title {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-gray);
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 0 !important;
  white-space: nowrap;
  /* Prevents text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100% !important;
}

.livis-part-number-wrap {
  display: flex;
  align-items: center;
}

.livis-part-number {
  white-space: nowrap;
  /* Prevents text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
}

.livis-part-details-wrap p:nth-child(2) {
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--livis-instruction-secondary-color);
  margin-top: 0.2rem;
}

.livis-part-info-icon {
  height: 1.25rem !important;
  color: var(--livis-color-primary-dblue) !important;
}

.livis-part-menu-icon {
  padding: 0 !important;
  padding-left: 1rem !important;
  background-color: transparent !important;
}

.livis-part-stats-value {
  font-weight: 600;
  font-size: 1.12rem;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
  margin: 0;
}

.livis-part-stats-label {
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.15px;
  color: var(--livis-gray-font-color);
  margin: 0;
}

.livis-part-defect-stats {
  border-left: 1px solid var(--livis-color-disabled);
  padding-left: 1rem;
}

.livis-part-view-grid {
  align-items: center;
}

.livis-part-list-data div {
  margin-top: 1rem;
}

.livis-part-list-data p:nth-child(2) {
  margin: 0;
  color: var(--livis-instruction-secondary-color);
  font-size: 0.9rem;
}

.livis-part-list-stats p {
  font-size: 600 !important;
  font-size: 0.7rem;
  margin: 0.2rem;
  background-color: var(--livis-chip-data-background-color);
  color: var(--livis-chip-data-font-color);
  border-radius: 0.5rem;
  padding: 0.3rem;
  width: fit-content;
}

.livis-part-list-preview-wrap {
  // width: 12rem;
  margin-right: 0.25rem !important;
  height: 8rem;
  border: solid 1px var(--livis-border-color);
  background-color: var(--livis-part-box-background);
  position: relative;
}

.livis-part-list-action-button-wrap button {
  margin-right: 0.5rem;
}

.livis-part-list-desc-wrap {
  width: 100%;
  height: 8rem;
  border-radius: 0.75rem !important;
  background-color: var(--livis-part-box-background) !important;
  border: 1px solid var(--livis-border-color);
  box-shadow: 0 4px 8px -4px rgba(168, 168, 168, 0.2),
    0 2px 4px -2px rgba(168, 168, 168, 0.15),
    0 -2px 4px -2px rgba(168, 168, 168, 0.25),
    0 8px 16px -8px rgba(168, 168, 168, 0.25) !important;
}

.livis-part-active {
  padding: 0px !important;
  border-left: 5px solid var(--livis-active-color) !important;
}

.livis-part-inactive {
  padding: 0px !important;
  border-left: 5px solid var(--livis-inactive-color) !important;
}

.livis-part-desc-content {
  height: 100% !important;
}

.livis-part-list-desc-title {
  padding-left: 2.625rem;
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--livis-color-primary-gray);
}

.livis-part-list-description-body {
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--livis-color-primary-gray);
  overflow: auto;
  height: 90%;
}

.livis-part-list-description-body p {
  width: 90%;
}

.livis-part-list-create-update-status {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 0;

  & span {
    font-weight: 600 !important;
  }
}

.livis-part-list-description-close-button-wrap {
  padding: 1rem;
}

.livis-part-data-card-thumbnail {
  height: 100%;
  // max-width: 493px !important;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  // cursor: pointer;
  // object-fit: cover;
}

.livis-part-data-card-top-left {
  height: 2rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;
  border-radius: 6rem !important;
  padding: 8px 9.5px !important;
  font-size: 0.813rem !important;
  font-weight: 600 !important;
  line-height: 1rem !important;
  letter-spacing: normal !important;
  text-align: left !important;
  text-transform: unset !important;
}

.livis-part-data-card-flag-chip {
  background-color: var(--livis-part-data-flag-chip-color) !important;
  color: var(--livis-warning-color) !important;
}

.livis-part-data-card-undefined-chip {
  background-color: var(--livis-form-select-button-background-color) !important;
  color: var(--livis-connection-color) !important;
}

.livis-part-flag-icon {
  height: 1rem !important;
  width: 1rem !important;
}

.livis-part-flagg-icon {
  height: 3rem !important;
  width: 3rem !important;
}

.livis-add-part-data-drag-wrapper {
  border-radius: 15px;
  border: 1px dashed var(--livis-part-drag-container);
  height: 354px;
  width: 95%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1.2rem !important;
}

.livis-add-part-data-drag-wrapper-add-data {
  border-radius: 15px;
  border: 1px dashed var(--livis-part-drag-container);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .livis-part-upload-icon {
    width: 32px !important;
  }

  .livis-upload-part-data-instruction {
    font-size: .9rem !important;
  }

  a {
    font-size: 0.9rem !important;
  }
}

.livis-upload-part-data-wrap div {
  text-align: center;
}

.livis-upload-part-data-wrap p:nth-child(2) {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: center !important;
  color: var(--livis-color-primary-gray);
}

.livis-upload-part-data-wrap p:nth-child(3) {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: center !important;
  color: var(--livis-instruction-secondary-color) !important;
}

.livis-upload-part-data-wrap a {
  color: var(--livis-color-primary-dblue);
  text-decoration: underline;
  cursor: pointer;
}

.livis-upload-part-data-instruction {
  max-width: 80%;
  margin: auto;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
  text-align: center !important;
  color: var(--livis-color-primary-gray);
}

// .livis-part-data-import-images {
//   width: 794px !important;
// }

.livis-part-import-data-button {
  width: 100%;
  border: 0.0625rem solid var(--livis-part-drag-container) !important;
  background-color: var(--livis-part-box-background) !important;
  border-radius: 15px !important;
  padding: 1rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-gray) !important;
  text-transform: unset !important;
  margin-bottom: 1rem !important;
}

.livis-part-upload-icon {
  width: 60px;
}

.livis-part-upload-section {
  cursor: pointer;
}

.livis-part-upload-data-progress-wrap {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 15px;
  // height: 22.125rem;
  display: flex;
  align-items: end;
  justify-content: center;
}

.livis-part-upload-data-progress-content {
  width: 100%;
  margin: 0 3.5625rem;
  margin-top: 6.875rem;

  & p {
    margin: 0;
  }

  & .livis-upload-data-progress-percent {
    margin: 0;
    letter-spacing: 0.1px;
    color: var(--livis-color-primary-gray);
    font-weight: 500;
    font-size: 2rem;
    line-height: 50px;
  }

  & .livis-part-upload-data-icon-progess {
    margin-bottom: 1.9375rem;
  }

  & .livis-upload-data-process-info {
    margin-top: 0.625rem;

    & p {
      letter-spacing: 0.1px;
      color: var(--livis-gray-dark-font-color);
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  & .livis-upload-data-message {
    margin-top: 4.3125rem;
    margin-bottom: 1.875rem;
    font-weight: 400;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    letter-spacing: 0.1px;
    color: var(--livis-gray-font-color);
  }
}

.livis-part-stats-modal-header {
  padding: 1rem;
  border-radius: 8px;
  background: rgba(248, 250, 255, 0.8);
  margin-bottom: 1.5rem;

  & p {
    color: var(--livis-color-primary-dblue);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    // line-height: 1.5rem; /* 171.429% */
  }
}

.livis-part-stats-frequency-wrapper {
  border-radius: 25px;
  border: 1px solid var(--livis-border-color);
  background: var(--livis-white-color);
  margin-bottom: 20px;
  padding: 17px 21px;

  & p {
    margin: 0;
    text-align: center !important;
  }
}

.livis-part-stats-modal-frequency-indicator-wrap {
  & p {
    margin: 0;
    margin-right: 22px;
    margin-left: 0.5rem;
  }
}

.livis-part-stats-modal-frequency-indicator-high {
  height: 1rem;
  width: 1rem;
  background-color: rgba(8, 87, 222, 0.16);
  border-radius: 50%;
  display: inline-block;
}

.livis-part-stats-modal-frequency-indicator-low {
  height: 1rem;
  width: 1rem;
  background-color: var(--livis-modal-freqency-indicator-low);
  border-radius: 50%;
  display: inline-block;
}

.livis-part-disabled-card-frequency {
  background-color: rgba(31, 31, 31, 0.3) !important;
  z-index: 2;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1rem;
  cursor: pointer;
}

.livis-helper-text-wrap {
  // max-width: 90%;
  // min-width: 90%;
  background-color: var(--livis-helper-text-wrap-bg);
  border-radius: 1rem !important;
  border: 1px solid var(--livis-helper-text-wrap-border);
  margin: auto;
  padding: 1rem 2rem !important;
}

.livis-text-info {
  font-size: 0.875rem !important;
  margin-block: 0.5rem !important;
}

.livis-helper-text-title {
  font-family: "Noto Sans Bold" !important;
}


.livis-add-part-parameter-camera-wrap {
  display: flex;
}


.livis-add-part-parameter-position-apply-to-all {
  background: var(--livis-add-part-parameter-position-apply-to-all-bg) !important;
  color: var(--livis-color-primary-dblue) !important;
}

.livis-add-part-parameter-camera-title-container {
  max-width: fit-content !important;
  border-radius: 12px;
  border: 0.5px solid var(--livis-add-part-border-color);
  padding: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem !important;
  font-family: "Noto Sans SemiBold";
  color: var(--livis-color-primary-gray);
  margin-inline: 0.5rem !important;
  cursor: pointer;
  position: relative;
  margin-block: 0.5rem !important;

  img {
    padding-right: 0.25rem !important;
  }
}

.livis-add-part-parameter-select-defect-feature-heading {
  font-size: 1rem !important;
  font-family: "Noto Sans Bold";
  color: var(--livis-color-primary-gray);
  margin-bottom: 1rem;
}

.livis-add-part-parameter-bl-heading {
  margin-bottom: 0px !important;
}

.livis-operator-panel-info-wrap.livis-add-parameter-bl-accordian-wrap {
  padding: 0px !important;
  margin-bottom: 1rem !important;
}

.livis-cobot-controller-preview-wrapper-positionwise {
  flex-direction: row !important;
  overflow: auto !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background: var(--livis-cobot-controller-preview-bg) !important;
}

.livis-bl-positionwise-camera {
  width: calc(33.33% - 0.5rem) !important;
  height: calc(40% - 0.5rem) !important;
  margin: 0.25rem !important;
  position: relative;
}

.livis-bl-positionwise-camera img {
  object-fit: fill !important;
}

.livis-bl-positionwise-camera-switch {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: var(--livis-bl-positionwise-camera-switch);
  padding: 0.15rem 0.5rem !important;
  border-radius: 2rem !important;
  color: var(--livis-white-color);
}

.livis-bl-positionwise-position {
  min-width: calc(100% - 1.5rem) !important;
  margin: 0.75rem !important;
  padding-bottom: 0.5rem !important;
  margin-top: 1rem !important;
  border-bottom: 1px solid var(--livis-operator-panel-accordian-border-color) !important;
}

.livis-bl-camera-wise-disable-card {
  border-radius: 1rem !important;
}

.livis-add-part-parameter-table-wrap {
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
  }

  th {
    background-color: var(--livis-add-part-parameter-table-wrap-bg);
    font-weight: 600;
    font-size: 0.75rem;
    color: var(--livis-color-primary-gray);
  }

  th:last-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
  }

  th {
    border-radius: 0.5rem 0 0 0.5rem;
    padding-block: 1rem;
    border-radius: 0px !important;
  }

  td {
    border-bottom: 1px solid var(--livis-add-part-parameter-table-wrap-border-bottom);
  }

  td.no-border {
    border-bottom: none !important;
  }

  td.no-border img {
    // margin-right: 1rem !important;
    margin-bottom: -0.3rem;
    cursor: pointer;
  }

  td img.livis-add-part-parameter-table-arrow-image {
    // margin-right: 0.5rem !important;
    margin-top: -3rem;
  }

  td img.livis-add-part-parameter-table-tick-image {
    // margin-left: 1rem !important;
    margin-bottom: -0.3rem;
    cursor: pointer;
  }

  td img.livis-add-part-parameter-table-delete-image {
    // margin-left: 1rem !important;
    margin-bottom: -0.3rem;
    cursor: pointer;
  }

  tbody {
    max-height: 10rem !important;
    overflow-y: auto !important;
    display: block !important;
    margin-top: 0.5rem !important;
  }

  tbody td:first-child {
    text-align: end !important;
  }

  tbody td.livis-add-part-review-nodefect-feature-td {
    text-align: center !important;
  }

  tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  .MuiOutlinedInput-root,
  .MuiTextField-root {
    border-radius: 0.5rem !important;
    border-color: var(--livis-mui-overwrite-bg);
    min-width: 75% !important;
    max-width: 75% !important;
    margin: auto !important;

    #demo-simple-select,
    .outlined-basic {
      text-align: start !important;
      font-size: 0.75rem !important;
      padding-block: 0.5rem !important;
    }
  }

  input {
    text-align: center !important;
    font-size: 0.75rem !important;
    padding-block: 0.75rem !important;
  }

  .MuiTextField-root {
    max-width: 80% !important;
  }

  legend {
    display: none !important;
  }

  fieldset {
    top: 0px !important;
  }

  label {
    display: none;
  }

  td,
  th {
    width: 5rem;
  }

  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(5),
  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 18.33% !important;
  }

  td:last-child,
  th:last-child {
    width: 12%;
    // text-align: start !important;
  }

  .add_defect_active {
    display: none;
  }

  td:first-child:hover .add_defect {
    display: none !important;
  }

  td:first-child:hover .add_defect_active {
    display: inline !important;
  }
}

.livis-add-part-parameter-review-table-wrap {
  width: 100% !important;

  tbody td,
  th {
    width: 20% !important;
    text-align: start !important;
  }

  tbody td:first-child,
  tbody td:last-child {
    text-align: start !important;
  }

  table td.livis-add-part-review-nodefect-feature-td {
    text-align: center !important;
  }
}

.livis-add-part-parameter-select-defect-feature-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 100%;
}

.livis-add-part-parameter-table-wrap {
  width: 48%;
  margin-inline: 1%;
  display: grid;
}

//.livis-add-part-parameter-camera-title-container-success

.livis-add-part-parameter-instruction-wrap {
  display: flex;
  align-items: center;
  margin-block: 1rem !important;

  img {
    width: 24px !important;
    margin-right: 0.5rem !important;
  }
}

// .livis-cobot-controller-instruction-wrapper

.livis-add-parameter-camera-tab .MuiTabs-scroller {
  padding-top: 0.5rem !important;
}

.livis-add-parameter-camera-tab .MuiTabs-flexContainer {
  flex-wrap: wrap !important;
}

.livis-add-part-parameter-camera-title-container-top {
  margin-top: 0.5rem !important;
}

.livis-add-part-parameter-class-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.review-defect {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.livis-add-part-parameter-camera-wrap {
  display: flex;
}


.livis-add-part-parameter-camera-title-container {
  max-width: fit-content !important;
  border-radius: 12px;
  border: 0.5px solid var(--livis-add-part-border-color);
  padding: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem !important;
  font-family: "Noto Sans SemiBold";
  color: var(--livis-color-primary-gray);
  margin-inline: 0.5rem !important;
  cursor: pointer;
  position: relative;

  img {
    padding-right: 0.25rem !important;
  }
}

// .livis-add-part-parameter-camera-title-container-error

// .livis-add-part-parameter-camera-title-container-disable

// .livis-add-part-parameter-camera-title-container-active

// .livis-add-part-parameter-position-title-container-img

.livis-peripheral-device-select-part-dropdown {
  margin-top: 1rem !important;

  .MuiOutlinedInput-root {
    border-radius: 12px !important;
  }
}

.livis-peripheral-device-heading {
  font-family: "Noto Sans SemiBold";
  font-size: 1rem;
  margin-bottom: 1rem !important;
}

.livis-peripheral-device-title {
  font-family: "Noto Sans SemiBold";
  font-size: 1rem;
  color: var(--livis-color-primary-gray);
}

.livis-peripheral-device-manual-ride-container {
  display: flex;
  align-items: center;
}

.livis-peripheral-device-input {
  margin-top: 0.65rem !important;
}

.livis-peripheral-device-container {
  min-height: 40vh !important;
  max-height: 40vh !important;
  overflow-y: auto;
  padding-right: 0.5rem !important;
}

.livis-part-stats-padding {
  padding-top: 50px;

  .livis-part-stats-charts-container {
    padding-right: 50px;
    min-height: 20rem;
  }

  .livis-part-stats-chart-container-height {
    min-height: 20rem;
  }
}

.livis-manage-data-tab-grid {
  justify-content: end;
  flex-wrap: wrap;

  .livis-manage-data-tab-grid-tabs {
    flex-wrap: wrap;
  }
}

.livis-part-manage-data-cacelbtn-container {
  display: flex;
  justify-content: end;
  margin-top: 5%;
}

.livis-part-manage-data-discard-container {
  display: flex;
  justify-content: end;
  margin-top: 3%;
}

.livis-part-import-data-modal {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-part-form-grid {
  margin-top: 50px;
  margin-left: 10px;
}

.livis-part-form-control {
  margin-top: 20px;
  display: flex;

  .livis-part-form-switch-label-margin {
    margin-right: 20px;
  }

  .livis-part-form-control-label {
    margin-left: 5px;
    margin-top: 10px;
  }
}

.livis-manage-data-import-preview-header-margin {
  margin-top: 20px;
}

.livis-part-select-margin {
  margin-top: 50px;
}

.livis-part-upload-input-label {
  width: 100%;
  height: 30vh;
}

.livis-helper-text-wrap-margin {
  margin-top: 24px;
}

.livis-part-add-parameters-table {
  max-height: fit-content;
  height: auto;
}

.livis-part-no-border-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.outlined-basic-width {
  min-height: 100%;
  margin-right: 0;
}

.livis-part-add-paramaeters-table {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-add-parameters-table {
  max-height: fit-content;
  height: auto;
}

.livis-add-parameters-table-body {
  max-height: fit-content;
  height: auto;
  min-height: min-content;
}

.livis-part-add-part-without-edge-input {
  width: 100%;
  height: 100%;
}

.livis-part-accordian-details-img {
  width: 100%;
}

.livis-part-form-data-grid {
  justify-content: center;
  align-items: center;
  min-height: 15rem;
  min-width: 100%;
  margin-top: 1rem;
}

.livis-part-helper-text-wrap-box {
  margin: 0px;
}

.livis-part-without-edge-box {
  padding: 20px;
  border: 1px solid var(--livis-add-part-border-color);
  border-radius: 20px;
  margin-bottom: 20px;
}

.livis-part-data-stepper-margin {
  margin: 20px !important;
}

.livis-part-form-header-padding {
  padding-top: 1rem;
}

.livis-part-review-table-grid {
  display: flex;
  justify-content: flex-start;
  padding: 0 0 3% 1.5%;
}

.livis-add-part-data-drag-wrapper-img {
  object-fit: contain;
}

.livis-part-cancel-btn-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 5% !important;
}

.livis-part-import-discard-btn-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 3% !important;
}

.livis-part-manage-data-discard-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 10% !important;
}

.livis-part-manage-data-augmentation-settings-modal-container {
  margin-block: 2% !important;
  justify-content: space-between !important;
}

.livis-part-augmentation-geometric-transformation {
  margin-top: 5% !important;
}

.livis-part-data-augmentation-edit-data-container {
  margin-top: 3% !important;
  margin-left: 5% !important;
}

.livis-part-manage-data-img-augmentation-container {
  padding-right: 1.625rem !important;
}

.livis-part-manage-data-augmentation-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 5% !important;
  // margin-right: 25px !important;
}

.livis-augmentation-data-generating-loader {
  padding-left: 16px !important;
}

.livis-part-augmentation-data-item {
  margin-block: 20px 12px !important;
}

.livis-add-part-btn-avatar {
  width: 32px !important;
  height: 32px !important;
}

.livis-part-cobot-controller-box {
  margin: 16px !important;
}

.livis-part-add-data-drag-input {
  display: none !important;
}

.livis-part-bl-logic-padding-left {
  padding-left: 0.2rem !important;
}

//part css ends

// Data Management module css start

.livis-manage-data-filter-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2.43rem;
  margin-right: 5.43rem;
  margin-top: 1.75rem;
  margin-bottom: 1rem;
}

.livis-legend-circle-labelled {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-labelled-color);
  margin-right: 0.75rem;
}

.livis-legend-circle-unlabelled {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-unlabelled-color);
  margin-right: 0.75rem;
}

.livis-data-management-legend-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: -0.5rem !important;

  & p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: var(--livis-gray100-font-color);
    letter-spacing: 0.1px;
    margin: 0px !important;
    margin-block: 0px !important;
  }

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0 !important;

    & span:nth-child(1) {
      margin-right: 0.5rem !important;
    }

    & span:nth-child(2) {
      margin-right: 0.5rem !important;
    }

    & span:nth-child(3) {
      margin-right: 0.5rem !important;
    }
  }
}

.livis-data-management-legend-wrap p {
  margin-block: 0px !important;
}

.livis-data-management-legend-margin {
  margin-right: 0.5rem !important;
}

.livis-manage-data-no-data {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 8rem;
}

.livis-manage-data-no-data p:nth-child(1) {
  line-height: 2.5rem;
  letter-spacing: 0.7px;
  font-weight: 600;
  font-size: 1.5rem;
}

.livis-manage-data-no-data-grid-content div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.livis-manage-data-no-data-grid-content p {
  padding: 0.125rem 0.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.livis-manage-data-label-wrap {
  display: flex;
  justify-content: space-between;

  & p {
    margin: 20px !important;
    padding-right: 1rem;
  }

  & p:nth-child(2) {
    color: var(--livis-color-primary-blue);
    cursor: pointer;
  }
}

.livis-manage-data-no-data-unsorted {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  margin-top: 8rem;

  & p:nth-child(2) {
    font-weight: 400;
    font-size: 1.5rem !important;
    line-height: 2.5rem !important;
    letter-spacing: 0.7px !important;
    color: var(--livis-color-primary-gray) !important;
  }

  & p:nth-child(3) {
    font-weight: 400;
    font-size: 1.125rem !important;
    line-height: 1.875rem !important;
    letter-spacing: 0.7px !important;
    color: var(--livis-instruction-secondary-color) !important;
    margin: 0 !important;
  }
}

.livis-manage-data-info-delete-button {
  position: absolute !important;
  top: 1.25rem !important;
  right: 1.25rem !important;
  z-index: 2;
  display: flex;
}

.livis-manage-data-info-delete-button img {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  margin-left: 1.5rem;
  cursor: pointer;
}

.livis-manage-data-label-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  z-index: 1;
  background-color: var(--livis-manage-data-label-button-bg) !important;
  color: var(--livis-color-background-white) !important;
  border-color: var(--livis-color-background-white) !important;
  border-radius: 6rem !important;
  text-transform: unset !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  width: 6.375rem;
  height: 3.75rem;
}

.livis-manage-data-card-wrap {
  float: left;
  position: relative;
  width: 100% !important;
  overflow: hidden;
  // padding: .5rem !important;
  height: 16rem !important;

  &:hover .livis-part-data-overlay {
    opacity: 1;
  }

  &:hover .livis-part-data-overlay-hidden {
    opacity: 0;
  }
}

.livis-manage-data-card-wrap .livis-part-data-card-thumbnail {
  width: calc(100% - 0.5rem) !important;
  height: calc(100% - 0.5rem) !important;
  border-radius: 0.5rem;
}

.livis-part-data-overlay {
  opacity: 0;
}

.livis-part-data-overlay-hidden {
  opacity: 1;
}

.livis-manage-data-card-wrap-oncheck {
  border-radius: 0.5rem;
  float: left;
  position: relative;
  width: 100% !important;
  overflow: hidden;
  // padding: .5rem !important;
  height: 16rem !important;

  & .livis-manage-data-checkbox {
    opacity: 1;
  }

  & .livis-manage-data-labeled-unlabeled-icon .livis-manage-data-info-delete-button .livis-manage-data-label-button .livis-part-data-overlay-hidden {
    display: none !important;
  }
}

.livis-report-image-verification-pos-info {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.livis-manage-data-card-wrap-oncheck .livis-part-data-card-thumbnail {
  width: calc(100% - 0.5rem) !important;
  height: calc(100% - 0.5rem) !important;
  border-radius: 0.5rem;
}

.livis-part-preview-data-card-container {
  overflow: auto;
  height: 31rem;

  & .livis-part-preview-data-card-wrap {
    height: 10.1875rem !important;

    & .livis-part-preview-data-card-thumbnail {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
      border-radius: 0.5rem !important;
    }
  }
}

.livis-manage-data-station-container {
  margin-top: 3rem;
  height: 17rem;
  margin-bottom: 1rem;
}

.manage-data-form-details-wrapper {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 1rem;
  margin-top: 0.75rem;
  text-decoration: none;
  padding: 1.3125rem 1.5625rem;
  flex-direction: column;
  align-items: flex-start;
}

.import-data-form-details-wrapper {
  flex-direction: column !important;
  align-items: flex-start !important;
}

.livis-manage-data-station-detail {
  margin-top: 2rem;
}

.livis-manage-data-card-detail-header {
  color: var(--livis-color-primary-gray) !important;
  font-size: 1rem !important;
  line-height: 1.25rem !important;
  font-weight: 500 !important;
  margin-bottom: 0.5rem !important;
}

.livis-manage-data-station-detail p {
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  color: var(--livis-gray100-font-color);
  font-weight: 400;
  margin: 0;
  margin-top: 0.5rem !important;
}

.manage-data-form-usecase-list-wrapper {
  background-color: rgb(232, 233, 249, 0.15);
  border-radius: 1rem;
  width: 100% !important;
  // max-height: 23.6875rem !important;
  // overflow: auto;
  padding-bottom: 1.5rem;
}

.manage-data-form-usecase-list-header {
  padding-left: 1.5rem;
  padding-right: 1.25rem;
  padding-top: 0.6875rem;
}

.manage-data-form-usecase-list-header-text {
  font-weight: 400;
  font-size: 0.9375rem;
  letter-spacing: 0.1px;
  color: var(--livis-data-form-header-color);
}

.manage-data-form-usecase-list-header-search div:nth-child(1) {
  max-width: 16.1875rem;
  border-radius: 50px;
  background-color: var(--livis-menu-selected) !important;
}

.manage-data-form-usecase-card-wrapper {
  margin-left: 24px !important;
  margin-top: 20px;
  // margin-right: 20px !important;
  padding-right: 0 !important;
  max-height: 368px !important;
  overflow: auto;
}

.manage-data-form-usecase-card {
  height: 100%;
  width: calc(50% - 36px) !important;
  margin-right: 6px !important;
  margin-top: 6px !important;
  border-radius: 8px;
  border: 1px solid var(--livis-tag-unselected);
  background-color: white;
  padding: 13px;
  display: flex;
  cursor: pointer;
}

.manage-data-form-usecase-card.active {
  border: 1px solid var(--livis-color-primary-blue);
}

.manage-data-form-usecase-card-preview div {
  background-color: var(--livis-data-manager-img-preview-bg) !important;
  border-radius: 1rem !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage-data-form-usecase-card-preview img {
  border-radius: 6px !important;
}

.manage-data-form-usecase-card-preview-img {
  width: 100%;
  height: 150px !important;
  object-fit: cover !important;
}

.manage-data-form-usecase-card-detail {
  padding-top: 1.125rem;
  padding-left: 1rem;
  padding-bottom: 0.2rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: var(--livis-instruction-secondary-color);

  & p {
    margin: 0 !important;
  }
}

.manage-data-form-usecase-name {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.livis-manage-data-import-preview-wrap {
  margin-bottom: 1rem;
}

.livis-manage-data-import-check-for-overlap-container {
  border: 1px solid var(--livis-border-color);
  border-radius: 15px !important;
  padding: 1rem 0.75rem;
  background-color: var(--livis-part-box-background);
  margin-top: 5rem !important;
  display: flex;
  justify-content: space-between;
  font-family: "Noto Sans Bold";
  align-items: center;
  flex-wrap: wrap !important;
}

.livis-manage-data-augmentation-note {
  border: 1px solid var(--livis-border-color);
  border-radius: 15px !important;
  padding: 1rem;
  background-color: var(--livis-part-box-background);
  margin-top: 2rem !important;
  display: flex;
  justify-content: space-between;
  font-family: "Noto Sans Bold";
  align-items: center;
  flex-wrap: wrap !important;
}

.livis-manage-data-augmentation-note p {
  font-family: "Noto Sans SemiBold";
  margin-bottom: 0.5rem;
}

.livis-manage-data-import-check-for-overlap-btn {
  border: 1px solid var(--livis-color-primary-lblue);
  border-radius: 50px;
  padding: 0.45rem 0.75rem;
  display: flex;
  align-items: center;
  color: var(--livis-color-primary-dblue);
  font-family: "Noto Sans SemiBold";
  font-size: 14px !important;
  cursor: pointer;
}

.livis-manage-data-import-overlap-details-container {
  width: 100% !important;
}

.livis-manage-data-import-overlap-details-container p.error {
  color: var(--livis-error-color) !important;
}

.livis-manage-data-import-overlap-details-container .green {
  color: var(--livis-add-part-camera-title-success) !important;
}

.livis-manage-data-import-overlap-details-container .red {
  color: var(--livis-error-color) !important;
}

.livis-manage-data-import-overlap-details-container p {
  font-family: "Noto Sans SemiBold";
}

.livis-manage-data-import-preview-header {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.livis-manage-data-import-image-preview div {
  background-color: var(--livis-data-manager-img-preview-bg) !important;
  border-radius: 1rem !important;
  width: 100% !important;
  height: 163px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-manage-data-import-image-preview div img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 1rem;
  height: 15rem !important;
}

.manage-data-form-import-wrapper {
  overflow: auto;
}

.livis-flag-label-badge {
  position: absolute !important;
  height: 2rem;
  top: 1rem !important;
  left: 1rem !important;
  border-radius: 6rem !important;
  padding: 8px 9.5px !important;
  font-size: 0.813rem !important;
  font-weight: 600 !important;
  line-height: 1rem !important;
  letter-spacing: normal !important;
  text-align: left !important;
  text-transform: unset !important;
  z-index: 1;
  display: inline-flex;
}

.livis-flagg-label-badge {
  position: absolute !important;
  bottom: 1rem !important;
  left: 1rem !important;
  z-index: 1;
  display: inline-flex;
  height: 2.25rem;
  width: 2.25rem;
}

.livis-manage-data-description-wrap {
  position: absolute !important;
  z-index: 1;
  background-color: var(--livis-manage-data-description-wrap-bg) !important;
  color: var(--livis-color-background-white) !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem !important;
  overflow: auto;
}

.livis-manage-data-desc-header {
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 1.25rem;

  & img {
    cursor: pointer;
  }
}

.livis-manage-data-desc-content {
  padding-left: 2rem;
  padding-right: 1rem;
  word-break: break-all;

  & p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0;
  }
}

.livis-manage-data-desc-content p:nth-child(1) {
  margin-top: 1.0625rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.livis-manage-data-desc-content p:nth-child(2) {
  margin-bottom: 0.9375rem;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.125rem;
}

.livis-manage-data-desc-chip {
  width: 5rem !important;
  height: 1.375rem !important;
  background-color: var(--livis-asseembly-chip-background) !important;
  color: var(--livis-asseembly-chip-text-color) !important;
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  line-height: 1rem !important;
  margin-left: 2rem !important;
  margin-top: 1.25rem;
  margin-bottom: 1rem !important;
}

.livis-manage-data-header-stats-button {
  color: var(--livis-color-primary-dblue) !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  border-radius: 3.125rem !important;
  text-transform: unset !important;
  padding: 0.375rem 0.90625rem !important;
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
}

.livis-manage-data-stats-icon {
  width: 16.67px !important;
  height: 16.67px !important;
}

.livis-manage-data-image-grid-sub-header {
  // padding-right: 84px;
  margin-right: 2rem !important;

  & .livis-manage-data-image-grid-sub-header-content {
    // margin-left: 23px;
    background-color: var(--livis-white-color);
    margin-bottom: 2rem !important;
    padding-block: 0.25rem !important;
    padding-inline: 0.25rem !important;
  }

  & p {
    margin: 20px;
  }
}

.livis-manage-data-checkbox {
  position: absolute !important;
  top: 0.5rem !important;
  left: 0.5rem !important;
  z-index: 2 !important;

  & .livis-manage-data-checkbox-icon {
    width: 2rem;
    height: 2rem;
  }

  & .MuiAvatar-root {
    border-radius: 0% !important;
  }
}

.livis-img-verify-fullscreen-icon {
  position: absolute !important;
  bottom: 1rem !important;
  right: 1rem !important;
  z-index: 2 !important;
  cursor: pointer;
}

.livis-legend-circle-flagged {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-flagged-lengend-circle-color);
  margin-right: 0.75rem;
}

.livis-legend-circle-undefined {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-undefined-lengend-circle-color);
  margin-right: 0.75rem;
}

.livis-manage-data-labeled-unlabeled-icon {
  position: absolute !important;
  top: 1.25rem !important;
  left: 1.25rem !important;
  z-index: 1;
}

.livis-manage-data-mark-unmark-button {
  color: var(--livis-color-primary-dblue) !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  border-radius: 3.125rem !important;
  text-transform: unset !important;
  padding: 0.375rem 0.90625rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
  margin-right: 1rem !important;
}

.livis-manage-data-mark-unmark-button-marked-all {
  color: var(--livis-chip-data-background-color) !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  background-color: var(--livis-color-primary-dblue) !important;
  border-radius: 3.125rem !important;
  text-transform: unset !important;
  padding: 0.375rem 0.90625rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
  margin-right: 1rem !important;
}

.livis-manage-data-image-action-button {
  color: var(--livis-chip-data-background-color) !important;
  border: 1px solid var(--livis-color-primary-dblue) !important;
  background-color: var(--livis-color-primary-dblue) !important;
  border-radius: 3.125rem !important;
  text-transform: unset !important;
  padding: 0.375rem 0.90625rem !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.5rem !important;
  margin-right: 1rem !important;
}

.livis-manage-data-position-container-wrap {
  margin-top: 1.4375rem !important;
  // height: 15rem !important;
  overflow: auto;
}

.livis-manage-data-position-box-wrap-selected {
  border: 1px solid var(--livis-color-primary-dblue);
  width: 100%;
  padding-top: 15%;
  border-radius: 12px;
  position: relative !important;
  cursor: pointer;

  & .livis-manage-data-bucket-text {
    color: var(--livis-color-primary-blue);
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 28px;
    /* 186.667% */
    letter-spacing: 0.1px;
  }
}

.livis-manage-data-position-box-wrap-unselected {
  border: 1px solid var(--livis-tag-unselected);
  width: 100%;
  padding-top: 15%;
  border-radius: 12px;
  position: relative !important;
  cursor: pointer;

  & .livis-manage-data-bucket-text {
    color: var(--livis-color-primary-blue);
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 28px;
    letter-spacing: 0.1px;
  }
}

.livis-manage-data-position-box-wrap-disable {
  border: 1px solid var(--livis-manage-data-wrap-border);
  width: 100%;
  padding-top: 15%;
  border-radius: 12px;
  position: relative !important;
  cursor: pointer;

  & .livis-manage-data-bucket-text {
    color: var(--livis-instruction-secondary-color);
    font-size: 0.938rem;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 28px;
    letter-spacing: 0.1px;
  }
}

.livis-manage-data-file-icon {
  & img {
    display: flex;
    justify-content: center !important;
  }
}

.livis-manage-data-position-checkmark-icon {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  top: 0.3125rem;
  right: 0.3125rem;
}

.livis-manage-data-position-info-card {
  margin-top: 2.25rem;
  width: 100%;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 0.9375rem;
  display: flex;

  & .livis-mange-data-position-thumbnail-wrap {
    // width: 9.75rem !important;
    height: 7.625rem !important;
    margin: 0.4375rem;

    // position: relative;
    & img {
      // position: absolute;
      height: 100%;
      border-radius: 0.75rem;
    }
  }

  & .livis-mange-data-position-details-wrap {
    padding: 1.3125rem 0;
    margin: 0 1.5rem;

    & p {
      margin: 0 !important;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.375rem;
      color: var(--livis-gray100-font-color);
    }

    & p:nth-child(1) {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25rem;
      margin-bottom: 0.5rem !important;
    }
  }
}

.livis-manage-data-position-info-card-none {
  visibility: hidden;
}

.livis-image-filter-wrap {
  display: flex;
}

.livis-manage-data-position-filter-wrap {
  padding-left: 2rem;
}

.livis-manage-data-count {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.5rem !important;
  min-width: 1.5rem !important;
  max-width: 2rem !important;
  height: 1.5rem;
  background: var(--livis-form-table-background-color) !important;
  text-align: center;
  border-radius: 0.3125rem;
  padding: 0.25rem;
}

.livis-manage-data-count p {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--livis-color-primary-dblue);
  width: 100% !important;
}

.livis-manage-data-upload-error-modal-wrapper {
  & .livis-modal-main-content>div {
    margin-top: 1.5rem !important;
  }

  & .livis-modal-main-content {
    & p {
      margin: 0;
      text-align: center;
    }

    & img {
      margin-top: 30px;
      margin-bottom: 54px;
      width: 10rem;
    }

    & .MuiButton-contained {
      margin-left: 0 !important;
    }
  }
}

.livis-upload-progress-bar-modal .livis-modal-main-content {
  margin-top: 0px !important;
}

.livis-upload-progress-bar-modal.livis-modal-slide-small {
  margin-top: 0px !important;
  max-width: 25% !important;
  margin-right: 0px !important;
}

.livis-upload-error-modal-container {
  display: flex;
  justify-content: end;
  margin-top: 10%;
}

.livis-manage-user-add-role-wrap {
  margin-right: 1.5rem !important;
}

.livis-manage-data-same-source-targer-message-box {
  min-height: 50vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.livis-manage-data-include-annotation-form-control-label {
  margin-right: 16px !important;
}

.livis-manage-data-include-annotation-box {
  margin-left: 4px !important;
  margin-top: 8px !important;
}

.livis-manage-data-import-preview-grid {
  margin-top: 16px !important;
}

.livis-manage-data-form-control {
  margin-top: 40px !important;
}

.livis-manage-data-buckets-tabs {
  flex-wrap: wrap !important;
}

.livis-augmentation-data-rotate-div {
  color: var(--livis-color-primary-dblue) !important;
  text-decoration: underline !important;
  text-underline-offset: 3px !important;
  font-size: 0.875rem !important;
  cursor: pointer !important;
  margin-top: 0.5rem;
}

.livis-manage-data-preview-img-container {
  margin-top: 14px !important;
}

.livis-manage-data-preview-img {
  background-color: var(--livis-black-font-color) !important;
  object-fit: contain !important;
  border-radius: 16px !important;
}

.livis-manage-data-import-modal-container {
  min-height: 50vh !important;
}

.livis-manage-data-import-modal-images {
  border-radius: 8px !important;
  height: 100% !important;
  background-color: var(--livis-black-font-color) !important;
}



.livis-augmented-tab-batch {
  position: absolute !important;
  bottom: 15px !important;
  right: 15px !important;
  z-index: 1 !important;
}


.livis-legend-circle-augmented {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-color-secondary-orange);
  margin-right: 0.75rem;
}

.livis-legend-circle-effects {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-color-secondary-yellow);
  margin-right: 0.75rem;
}

.livis-part-manage-data-augmentation-settings-modal-container {
  max-height: 85vh;
  overflow: hidden;
  overflow-y: auto;
}

.show-preview {
  color: var(--livis-color-primary-dblue);
}

.livis-manage-data-augmented-preview-modal {
  max-height: 85vh !important;
  overflow-y: auto !important;
  width: 100% !important;
  margin-top: 0.75rem;
}

.livis-augmented-show-preview-font {
  font-family: Noto Sans SemiBold !important;
}

.livis-train-augmentation-wrap {
  // margin-left: 16px !important;
  margin-top: 1rem !important;
  max-width: 95%;
}

.livis-train-augmentation-settings-modal-footer {
  padding-left: 1rem;
  padding-right: 0rem !important;
  align-content: flex-start !important;
  align-items: flex-start !important;
  justify-content: space-between;
  padding-bottom: 2%;
  max-width: 98%;
}

.livis-part-manage-data-augmentation-settings-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 5% !important;
  margin-right: 1.563rem !important;
}

.livis-augmentation-settings-modal-container {
  margin-top: 0 !important;
  margin-inline: 0 !important;
  padding-right: 0 !important;
}

.livis-augmentation-settings-modal-values-container {
  padding: 0.25rem 0.438rem;
  background-color: var(--livis-augmentation-settings-modal-values-container-bg);
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-augmentation-settings-modal-values {
  color: var(--livis-color-primary-dblue);
  font-weight: bold;
}

.livis-augmentation-settings-modal-min-max-label{
  font-size: 0.95rem !important;
  font-family: 'Noto Sans Regular' !important;
  color: var(--livis-gray100-font-color) !important;
}

.livis-train-augmentation-wrap{
  .MuiSlider-markLabel[data-index="0"] {
    left: 0.75rem !important;
  }
  
  .MuiSlider-markLabel[data-index="1"] {
    right: -0.25rem !important;
    left: auto !important;
  }
  
  .MuiSlider-root.MuiSlider-marked{
    padding-block: 1rem !important;
  }
  
  .livis-training-augmentation-rotate-wrap-no-margin-top .MuiCheckbox-root{
    padding-inline: 0px !important;
    padding-right: 0.25rem !important;
  }
}


.livis-augmentation-preview-modal-values-container {
  padding: 0.25rem 0.438rem;
  background-color: var(--livis-augmentation-settings-modal-values-container-bg);
  border-radius: 0.5rem;
  max-width: fit-content;
  margin-right: 0rem;
  margin-left: auto;
}

.livis-augmentation-modal-main {
  .livis-train-augmented-modal-margin {
    margin-left: 0rem !important;

  }
}

// .livis-augmentation-modal-main {
//   .livis-manage-data-augmented-preview-modal {
//     .css-mhc70k-MuiGrid-root {
//       margin-left: -16px !important;
//     }
//   }
// }

.livis-manage-data-augmented-preview-modal {
  overflow-x: hidden !important;
}

.livis-augmentation-settings-modal-label {
  font-weight: bold;
}

.livis-train-augmentation-grid-container {
  width: 100% !important;
  min-width: 100% !important;
}

.livis-augmentation-data-generating-progress-container {
  background-color: var(--livis-part-box-background) !important;
  border: 1px solid var(--livis-part-drag-container) !important;
  border-radius: 1rem;
  padding: 2rem;
}

.livis-augmentation-progress-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.livis-augmentation-progress-details-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.livis-augmentation-progress-details-wrap span {
  font-family: "Noto Sans SemiBold";
  color: var(--livis-color-primary-gray);
  font-size: 1.25rem !important;
  margin-left: 0.75rem !important;
}

.livis-augmentation-progress-percentage {
  font-family: "Noto Sans SemiBold";
  color: var(--livis-color-primary-gray);
  font-size: 1.5rem !important;
}

.livis-augmentation-progress-bar {
  margin-bottom: 1rem !important;
}

.livis-augmentation-progress-message {
  font-family: "Noto Sans Regular";
  color: var(--livis-gray-font-color);
  font-size: 0.875rem;
}

.livis-data-management-annotation-header {
  background-color: var(--livis-white-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-data-management-annotation-image-count {
  font-size: 1.55rem;
  margin-left: 0rem;
}

.livis-data-management-annotation-unannotated-count {
  font-size: 1.25rem;
  margin-left: 0rem;
  font-family: "Noto Sans Bold";
  margin-right: 0.8rem;
}

.livis-data-management-annotation-image-count-total {
  font-size: 1.75rem;
  font-family: "Noto Sans SemiBold";
  margin-left: 0.1rem;
  margin-right: 1rem;
}

.livis-data-management-annotation-header-title {
  font-size: 1rem !important;
  color: var(--livis-color-primary-gray) !important;
  padding-right: 0.5rem;
  font-family: "Noto Sans SemiBold";
}

.livis-data-management-annotation-header {
  background-color: var(--livis-white-color);
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-data-management-annotation-image-count {
  font-size: 1.75rem;
  margin-left: 1rem;
}

.livis-data-management-annotation-image-count-total {
  font-size: 1.75rem;
  font-weight: 600;
  margin-left: 0.1rem;
}

.livis-data-management-annotation-header-title {
  font-size: 1rem !important;
  color: var(--livis-color-primary-gray) !important;
}

.livis-manage-user-filter.livis-manage-user-sort-by-wrap {
  margin-right: 2rem !important;
}

.livis-inspection-pagination-button-wrap.livis-manage-data-pagination-button-wrap p {
  margin-block: 0px !important;
}

.livis-manage-data-image-operation-type-image {
  cursor: pointer;
}

.livis-manage-data-mark-unmark-btns-section {
  margin-bottom: 1rem !important;
  margin-left: 1.25rem !important;
  // position: absolute !important;
  // bottom: -1rem !important;
  // right: 0rem !important;
  // z-index: 10000000000 !important;
  // background-color: #fff !important;
  // padding: 1.5rem !important;
  // border-radius: 1rem 0 0 0 !important;
}

.livis-synthetic-augmentation-modal {
  z-index: 1000;
  // div{
  //   width: 60%;
  // }
}

// .jss39 {
//   z-index: 10 !important;
// }

// #udt.livis-annoatate-new-udt {
//   overflow: hidden; /* Prevents scroll conflicts */
//   pointer-events: none; /* Ensures the modal captures all interactions */
// }



// Data Management module css end


//sidebar menu css start
.livis-menu-list-wrap {
  margin-top: 1.12rem;
  // margin-left: 36px;
  margin-left: 1.5rem;
}

.livis-usecase-menu-wrap {
  // width: 207px;
  width: 90%;
  // height: 136px;
  background: var(--livis-color-background-white);
  box-shadow: -2px 0px 4px -2px rgba(168, 168, 168, 0.08),
    2px 0px 4px -2px rgba(168, 168, 168, 0.08),
    0px -8px 18px -8px rgba(168, 168, 168, 0.12),
    0px 8px 16px -8px rgba(168, 168, 168, 0.12),
    0px -2px 4px -2px rgba(168, 168, 168, 0.08),
    0px 2px 4px -2px rgba(168, 168, 168, 0.08);
  border-radius: 1rem;
}

.livis-training-menu-wrap {
  // margin-top: 8px;
  margin-top: 0.5rem;
  width: 90%;
  // height: 595px;
  background: var(--livis-color-background-white);
  box-shadow: -2px 0px 4px -2px rgba(168, 168, 168, 0.08),
    2px 0px 4px -2px rgba(168, 168, 168, 0.08),
    0px -8px 18px -8px rgba(168, 168, 168, 0.12),
    0px -2px 4px -2px rgba(168, 168, 168, 0.08),
    0px 8px 16px -8px rgba(168, 168, 168, 0.12),
    0px 2px 4px -2px rgba(168, 168, 168, 0.08);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
  position: relative;
  max-height: 67vh !important;
  overflow: auto !important;
}

.livis-training-menu-wrap.no-scroll {
  overflow: hidden !important;
}

.livis-training-menu-wrap .livis-disabled-card {
  border-radius: 1rem !important;
}

.livis-sidebar-accoridan-desktop {
  height: 100% !important;
  width: 100% !important;
}

.livis-sidebar-accoridan-mobile {
  display: none !important;
  height: 100% !important;
}

.livis-sidebar-accordian {
  margin-top: 1rem !important;
}

.livis-sidebar-accoridan-mobile {
  height: 100% !important;
  display: none;
}

.livis-sidebar-menu-selected {
  border: 1.5px solid var(--livis-color-primary-dblue) !important;
}

.livis-usecasemenu-content {
  padding: 0.75rem;
}

.livis-usecase-menu-header {
  display: flex;
  display: flex;
  justify-content: space-between;

  & p {
    cursor: pointer;
  }
}

.livis-usecase-menu-header p:nth-child(1) {
  font-weight: 600;
  font-size: 0.815rem;
  line-height: 1.25rem;
  letter-spacing: 0.01rem;
  color: var(--livis-color-primary-dblue);
  margin: 0px;
}

.livis-usecase-menu-header p:nth-child(2) {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.01rem;
  color: var(--livis-input-placeholder-color);
  margin: 0px;
  cursor: pointer;
}

.livis-usecase-menu-title {
  margin-top: 0.62rem;


  & p {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 13.75rem;
  }
}

.livis-usecase-menu-title p {
  font-weight: 600;
  font-size: 0.93rem;
  line-height: 1.25rem;
  letter-spacing: 0.01rem;
  color: var(--livis-color-primary-gray);
  margin: 0px;
  word-break: break-all !important;
}

.livis-part-menu-wrap {
  margin-top: 1.5rem;
  margin-left: 0px;
  margin-bottom: 0.6rem;
}

.livis-part-menu-wrap .MuiInputBase-root {
  width: 90%rem !important;
  margin: 0px;
  height: 2.2rem !important;
  padding: 0px;
  border-radius: 0.5rem !important;
  background: var(--livis-chip-data-background-color);
}

.livis-part-menu-wrap .MuiFormLabel-root {
  bottom: 1.4rem !important;
  top: auto;
  color: var(--livis-part-menu-mui-custom-color) !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
}

.livis-sidebar-manage-capture-navigator {
  margin: 0.75rem 0 0.75rem 2.8125rem;
  padding-left: 1.28rem;
  border-left: 1px solid var(--livis-manage-data-wrap-border);

  & div {
    cursor: pointer;
  }

  & p {
    color: var(--livis-gray-dark-font-color);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 0 0.5rem;
  }

  & .navigation-selected {
    color: var(--livis-color-primary-dblue);
  }
}

.livis-traning-menu-item {
  // width: 183px;
  width: 85%;
  // height: 112px;
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 1rem;
  margin-top: 0.75rem;
  text-decoration: none;
  padding: 0px 0px;
  cursor: pointer;
  position: relative;
}

.livis-training-feasibility-alert-wrap {
  background-color: var(--livis-training-feasability-wrap) !important;
  margin-bottom: 1rem !important;
  padding: 1rem 1.5rem !important;
  border-radius: 1rem !important;
}

.livis-training-feasibility-alert-heading {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: var(--livis-white-color) !important;
  margin-top: 2rem;
}

.livis-training-feasibility-alert-title {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: var(--livis-white-color);
}

.livis-training-feasibility-alert-btn {
  border: 1px solid var(--livis-white-color) !important;
  color: var(--livis-white-color) !important;
  border-radius: 3.125rem !important;
  height: 3rem;
  font-weight: 500 !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  text-transform: unset !important;
  box-shadow: none !important;
  padding-inline: 1rem !important;
}

.livis-training-feasibility-alert-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-trash-menu-item {
  width: 85%;
  background: var(--livis-usecase-defect-background);
  border: 1px solid var(--livis-error-color);
  border-radius: 1rem;
  margin-top: 0.75rem;
  text-decoration: none;
  padding: 0px 0px;
  cursor: pointer;
}

.livis-trash-menu-content {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 22px 0;

  & img {
    margin-right: 14px;
    width: 1.5rem;
  }

  & p {
    font-weight: 400;
    font-size: 0.93rem;
    line-height: 1.37rem;
    letter-spacing: 0.01rem;
    color: var(--livis-color-primary-gray);
    text-align: center;
    margin: 0;
  }

  // &img {
  //   margin-top: 1.5rem;
  //   margin-right: 14px;
  //   width: 2rem;
  // }

  // &p {
  //   font-weight: 400;
  //   font-size: 0.93rem;
  //   line-height: 1.37rem;
  //   letter-spacing: 0.01rem;
  //   color: var(--livis-color-primary-gray);
  //   text-align: center;
  // }
}

.livis-traning-data-analytics {
  // width: 183px;
  width: 85%;
  // height: 112px;
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 1rem;
  margin-top: 0.75rem;
  text-decoration: none;
  padding: 0px 0px;
  cursor: pointer;
  position: relative;
}

.livis-training-selected-menu {
  border: 1.5px solid var(--livis-color-primary-dblue);
}

.livis-sidebar-divider {
  margin: 0;
  margin-top: 0.75rem;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
  width: 90% !important;
}

.livis-training-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.livis-training-menu-content img {
  margin-top: 1.5rem;
  width: 2rem;
}

.livis-training-menu-content p {
  font-weight: 400;
  font-size: 0.93rem;
  line-height: 1.37rem;
  letter-spacing: 0.01rem;
  color: var(--livis-color-primary-gray);
  text-align: center;
}

.livis-training-data-status {
  // width: 183px;
  width: 85%;
  // height: 174px;
  background: var(--livis-white-color);
  border-radius: 0.75rem;
  border: 1px solid var(--livis-part-box-border);
  margin-top: 0.75rem;
  // padding: 14px 0px;
}

.livis-training-data-status-content {
  padding: 0.75rem;
  padding-bottom: 0px;
}

.livis-training-data-status-content p:nth-child(1) {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  color: var(--livis-color-primary-gray);
  margin: 0px;
}

.livis-training-data-status-content p:nth-child(2) {
  font-weight: 400;
  font-size: 0.87rem;
  line-height: 1.37rem;
  color: var(--livis-gray-font-color);
  margin: 0px;
  margin-top: 0.62rem;
}

.livis-training-data-status-graph {
  padding: 0.75rem;
}

.livis-training-data-status-graph .MuiLinearProgress-root {
  height: 1.12rem !important;
  // width: 155px !important;
  width: 98% !important;
  border-radius: 1.56rem !important;
  background: var(--livis-form-table-background-color) !important;
  margin: 0px;
}

.livis-training-data-status-graph .MuiLinearProgress-bar {
  background: var(--livis-color-primary-blue) !important;
}

.livis-training-data-status-graph p {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  color: var(--livis-color-primary-blue);
}

.livis-sidebar-item-pagination-button-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-sidebar-item-pagination-button-wrap p {
  margin: 0px !important;
}

.livis-sidebar-flex-wrap {
  width: 100%;
  display: flex;
}


.livis-sidebar-item-pagination-button-wrap-flex {
  display: flex;
  align-items: center;
}

.livis-sidebar-item-pagination-prev-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-right: 40px;
}

.livis-sidebar-item-pagination-next-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

//sidebar menu css end


// Training Module css start

.livis-training-content-wrap {
  margin-top: 1.12rem;
  margin-left: 1.5rem;
  width: 95%;
}

.livis-training-stats-header {
  height: 5.75rem;
  background: var(--livis-color-background-white);
  box-shadow: 0px -2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 8px 16px -8px rgba(168, 168, 168, 0.25),
    0px 2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2);
  border-radius: 0.75rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.livis-training-stats-header-content-box {
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 20px;
  // padding: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    margin: 0;
  }
}

.livis-training-credit-header-content-box {
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 20px;
  padding: 0.5625rem;
  cursor: pointer;

  & p {
    margin: 0;
  }
}

.livis-training-credit-wrapper {
  background: var(--livis-credit-wrapper-bg);
  border-radius: 20px;
  height: 100%;
  position: relative;

  & p {
    margin: 0;
    padding-top: 0.75rem;
    padding-left: 1rem;
  }

  & p:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: var(--livis-color-primary-dblue);
    padding-top: 0;
  }
}

.livis-training-credit-coin-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}

.livis-operator-pagination-frequency {
  & .MuiOutlinedInput-root {
    border-radius: 0.375rem !important;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: var(--livis-chip-data-background-color) !important;
  }

  & .MuiSvgIcon-root {
    margin-right: 0.4375rem !important;
  }
}

.livis-operator-pagination-frequency .MuiOutlinedInput-root * {
  background-color: transparent !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.livis-training-duration-wrapper {
  padding-left: 1rem;
  border-right: 1px solid var(--livis-part-drag-container);

  & p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--livis-gray100-font-color);
  }

  & p:nth-child(2) {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    color: var(--livis-color-primary-dblue);
  }
}

.livis-training-count-wrapper {

  // padding: 0.75rem;
  & p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--livis-instruction-secondary-color);
    text-align: center !important;
  }

  & p:nth-child(2) {
    margin-top: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    color: var(--livis-color-primary-gray);
  }
}

.livis-training-anomaly-supervised-container {
  height: 27.875rem;
  background: var(--livis-color-background-white);
  box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0.25),
    0px 8px 16px -8px rgba(168, 168, 168, 0.2),
    0px 2px 4px -2px rgba(168, 168, 168, 0.35),
    0px -2px 4px -2px rgba(168, 168, 168, 0.25);
  border-radius: 0.75rem;

  & .livis-view-btn {
    font-weight: 600 !important;
  }
}

.livis-training-data-card-container {
  padding: 2rem;
  background: var(--livis-color-background-white);
  box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0.25),
    0px 8px 16px -8px rgba(168, 168, 168, 0.2),
    0px 2px 4px -2px rgba(168, 168, 168, 0.35),
    0px -2px 4px -2px rgba(168, 168, 168, 0.25);
  border-radius: 0.75rem 0.75rem 0rem 0.75rem;

  & .livis-training-id {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: var(--livis-color-primary-dblue);
    margin: 0;
  }

  & .livis-training-header {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 24px;
    color: var(--livis-color-primary-gray);
    margin: 0;
  }

  & .livis-training-card-details {
    padding: 25px 32px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px -2px rgba(168, 168, 168, 0.15);
    border-radius: 20px;
    margin-top: 36px;
    margin-bottom: 2rem;
  }

  & .livis-training-details-subheader {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 16px;
    color: var(--livis-instruction-secondary-color);
    margin: 0;

    & span {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 16px;
      color: var(--livis-color-primary-gray);
    }
  }

  & .livis-training-card-data {
    border: 1px solid var(--livis-part-drag-container);
    border-radius: 12px;
    padding: 12px;
    margin-top: 20px;

    // & .livis-training-selected-summary-content {
    //   padding: 12px 24px 0 24px;
    // }
  }

  & .livis-summary-table-data {
    background: var(--livis-part-box-background) !important;
    border: none !important;
  }

  & .livis-training-data-table-header {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 16px;
    color: var(--livis-instruction-secondary-color);
    margin: 0;
    margin-bottom: 4px;
  }

  & .livis-training-data-mode {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    color: var(--livis-color-primary-dblue);
    margin: 0;
  }

  & .livis-training-data-table-content {
    font-weight: 500;
    // font-size: 16px;
    line-height: 24px;
    color: var(--livis-color-primary-gray);
    margin: 0;
  }
}

.livis-training-details-header {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 24px;
  color: var(--livis-color-primary-gray);
  margin: 0;
  // margin-bottom: 1.5rem;
}

.livis-training-details-header-summary {
  margin-bottom: 0px !important;
}

.livis-training-data-stats-grid-wrap {
  margin-top: 20px !important;
  margin-bottom: 1rem;
}

.livis-training-data-stats-grid-container {
  padding: 2rem;
  background: var(--livis-white-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px -1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 1px 2px -1px rgba(168, 168, 168, 0.35),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2),
    0px 2px 4px -2px rgba(168, 168, 168, 0.25);
  border-radius: 20px;
  position: relative;
  min-height: 9.5rem;

  // height: 50% !important;
  & p {
    margin: 0;
  }

  & .livis-training-data-status-success {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    color: var(--livis-training-status-success-bg);
    margin-top: 4px;
  }

  &.livis-training-data-status-fail {
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    color: var(--livis-color-secondary-red);
    margin-top: 4px;
  }

  & .livis-training-watermak-background {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.livis-traininig-version-header {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 16px;
  color: var(--livis-instruction-secondary-color);

  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.livis-traininig-version {
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  color: var(--livis-color-primary-dblue);
  margin-top: 4px !important;
}

.livis-training-confidence-score {
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 52px;
  color: var(--livis-color-primary-gray);
  margin-top: 23px !important;
  margin-bottom: 4px !important;
}

.livis-training-card-details-summary {
  padding: 25px 32px;
  margin-bottom: 44px;
  background: var(--livis-white-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px -1px 2px -1px rgba(168, 168, 168, 0.25),
    0px 1px 2px -1px rgba(168, 168, 168, 0.35),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2),
    0px 2px 4px -2px rgba(168, 168, 168, 0.25);
  border-radius: 20px;
}

.livis-training-card-footer-wrap {
  display: flex;
  justify-content: flex-end;

  & .livis-training-card-footer-more {
    background: var(--livis-color-background-white);
    width: fit-content;
    padding-inline: 1rem !important;
    box-shadow: 0px 4px 8px -4px rgba(168, 168, 168, 0),
      0px 8px 16px -8px rgba(168, 168, 168, 0),
      0px 2px 4px -2px rgba(168, 168, 168, 0.35),
      0px -2px 4px -2px rgba(168, 168, 168, 0.25);
    border-radius: 0px 0px 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    & p {
      margin: 0;
      margin: 6px 0 6px 0;
      font-weight: 600;
      font-size: 0.813rem;
      line-height: 24px;
      color: var(--livis-gray100-font-color);
    }

    & span {
      display: flex;
      align-items: center;
      margin-left: 4px;
    }
  }
}

.livis-training-data-initialization-container {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 12px;
  padding: 85px 0 85px 68px;
  margin-top: 1rem;

  & .livis-training-loader-header {
    margin: 0;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 24px;
    color: var(--livis-color-primary-gray);
    margin-bottom: 20px;
    text-transform: capitalize !important;
  }

  & p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: var(--livis-gray100-font-color);
    margin: 0;
    margin-bottom: 2rem;
  }
}

.livis-training-data-initialization-container-see-more {

  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 3px 20px 3px;

  & .livis-training-loader-header {
    margin: 0;
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 24px;
    color: var(--livis-color-primary-gray);
    margin-bottom: 20px;
    text-transform: capitalize !important;
  }

  & p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: var(--livis-gray100-font-color);
    margin: 0;
    margin-bottom: 2rem;
  }
}

.livis-training-data-progress-container {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 12px;
  margin-top: 1rem;
  height: 305px;

  & .livis-training-data-progress-content {
    height: 223px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid var(--livis-part-drag-container);
    background-color: var(--livis-color-background-white);
    display: flex;
    align-items: end;
    justify-content: center;
  }

  & .livis-training-data-progress-data {
    padding: 0 25px;
    height: 71px;

    & .livis-training-data-progress-stats {
      margin-bottom: 1rem;

      & p:nth-child(1) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: var(--livis-color-primary-gray);
        margin: 0;
        margin-right: 1.5rem;
      }

      & p:nth-child(2) {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: var(--livis-gray-font-color);
        margin: 0;
      }
    }
  }
}



.livis-training-data-progress-container-see-more {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 12px;
  margin-top: 1rem;
  height: 750px;
  margin-bottom: 1rem;

  .highcharts-no-data {
    text {
      font-weight: normal !important;
    }
  }

  & .livis-training-data-progress-content {
    height: 223px;
    width: 100%;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid var(--livis-part-drag-container);
    background-color: var(--livis-color-background-white);
    display: flex;
    align-items: end;
    justify-content: center;
  }

  & .livis-training-data-progress-data {
    padding: 0 25px;
    height: 71px;

    & .livis-training-data-progress-stats {
      margin-bottom: 1rem;

      & p:nth-child(1) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 30px;
        letter-spacing: 0.1px;
        color: var(--livis-color-primary-gray);
        margin: 0;
        margin-right: 1.5rem;
      }

      & p:nth-child(2) {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: var(--livis-gray-font-color);
        margin: 0;
      }
    }
  }
}

.livis-training-container-no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85%;

  & p {
    margin: 0;
  }

  & .livis-training-heading {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.5rem;
    color: var(--livis-color-primary-gray);
    margin-top: 2rem;
  }

  & .livis-training-detail {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: var(--livis-instruction-secondary-color);
    text-align: center;
    margin: 20px 70px;
  }
}

.livis-training-train-modal {
  overflow-y: auto;
}

.livis-training-summary-container {
  // width: 93%;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 0.9375rem;
  padding: 19px 25px;

  & p {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
    margin-bottom: 0.5rem;
  }

  & .livis-training-summary-content {
    margin: 10px 0;

    & p {
      margin: 0.2rem 0;
      font-weight: 400;
      font-size: 0.938rem;
      line-height: 24px;
      color: var(--livis-gray100-font-color);

      & span {
        color: var(--livis-color-primary-gray);
        font-weight: 600;
      }
    }
  }
}

.livis-training-summary-content p.livis-training-total-data {
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  margin-top: 1rem !important;
}

.livis-training-supervised-tags {
  background: var(--livis-white-color);
  border: 1px solid var(--livis-border-color);
  border-radius: 12px;
  padding: 17px 20px;
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.livis-training-usecase-card-featuere-defect {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column !important;
  gap: 1rem;
}

.livis-training-defect-feature {
  font-weight: 600;
  font-size: 0.938rem;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: var(--livis-gray100-font-color);
  max-width: fit-content !important;
  margin-right: 1rem !important;
}

.livis-training-defect-feature-content {
  display: flex;
  max-width: 34rem;
  overflow-y: auto;
}

.livis-training-recomended-usecase-container {
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 0.9375rem;
  padding: 19px 25px;
  margin-top: 12px;

  & .livis-training-recomended-usecase-header {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
    line-height: 20px;
  }

  & .livis-training-recomended-usecase-subheader {
    margin: 0;
    margin-top: 0.375rem;
    margin-bottom: 1.5rem;
    font-weight: 400;
    font-size: 0.938rem;
    line-height: 20px;
    color: var(--livis-instruction-secondary-color);
  }

  & .livis-training-usecase-card-header-right {
    & p {
      margin: 0;
      font-weight: 400;
      font-size: 1rem;
      line-height: 24px;
      color: var(--livis-instruction-secondary-color);

      & span {
        font-weight: 700;
        color: var(--livis-color-primary-dblue);
      }
    }

    & p:nth-child(2) {
      margin-left: 1.5rem;
    }
  }
}

.livis-training-usecase-summary-card {
  background: var(--livis-white-color);
  border: 1px solid var(--livis-tag-unselected);
  box-shadow: 0px 2px 6px rgba(13, 10, 44, 0.08);
  border-radius: 20px;
  padding: 5px;
  margin-top: 12px;
}

.livis-training-usecase-card {
  background: var(--livis-white-color);
  border: 1px solid var(--livis-tag-unselected);
  box-shadow: 0px 2px 6px rgba(13, 10, 44, 0.08);
  border-radius: 20px;
  padding: 25px 33px;
  margin-top: 12px;

  &:hover {
    border: 1px solid var(--livis-color-primary-dblue) !important;
  }

  & .livis-training-usecase-card-header-left {
    & p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 16px;
      color: var(--livis-color-primary-dblue);
      margin: 0;
      margin-bottom: 4px;
      cursor: pointer;
    }

    & p:nth-child(2) {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 24px;
      color: var(--livis-color-primary-gray);
      margin: 0;
    }
  }

  & .livis-testing-type-estimate-credit {
    & p {
      margin: 0;
      color: var(--livis-color-primary-gray);
      font-size: 0.875rem;
      line-height: 16px;
      font-weight: 400;

      & span {
        font-weight: 600;
      }
    }
  }
}

.livis-training-usecase-card img {
  width: 5rem;
}

.livis-training-usecase-card-selected {
  border: 1px solid var(--livis-color-primary-dblue) !important;
}

.livis-training-usecase-card-content {
  margin-top: 1.5rem;
}

.livis-training-defect-chip {
  background: var(--livis-usecase-defect-background) !important;
  height: 1.5rem !important;
  margin-right: 0.625rem;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-error-color) !important;
    font-weight: 600 !important;
    font-size: 0.813rem !important;
    line-height: 18px !important;
    padding: 0px 12px !important;
  }
}

.livis-training-feature-chip {
  background: var(--livis-usecase-feature-background) !important;
  height: 1.5rem !important;
  margin-right: 0.625rem;
  margin-bottom: 0.5rem;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-success-color) !important;
    font-weight: 600 !important;
    font-size: 0.813rem !important;
    line-height: 18px !important;
    padding: 0px 12px !important;
  }
}

.livis-training-deploy-chip {
  background: var(--livis-form-select-button-background-color) !important;
  height: 1.5rem !important;

  & .MuiChip-label,
  .MuiSvgIcon-root {
    color: var(--livis-connection-color) !important;
    font-weight: 600 !important;
    font-size: 0.813rem !important;
    line-height: 18px !important;
    padding: 0px 12px !important;
  }
}

.livis-training-summary-configure-settings-wrap-anomaly {
  margin-top: 9.3125rem;
}

.livis-training-summary-configure-settings-wrap-supervised {
  margin-top: 5.5625rem;
}

.livis-training-summary-configure-settings-container {
  width: 99%;
  height: 3.75rem;
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 0.9375rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer !important;

  & .livis-training-summary-configure-settings-content {
    margin-left: 17px;
    margin-right: 17px;

    & .livis-training-configure-icon {
      margin-right: 1.5rem;
    }

    & .livis-training-configure-text {
      letter-spacing: 0.1px;
      font-size: 0.938rem;
      line-height: 35px;
      color: var(--livis-color-primary-gray);
    }
  }

  & .livis-training-click-icon {
    cursor: pointer;
  }
}

.livis-training-scratch-learning {
  background: var(--livis-white-color);
  border: 1px solid var(--livis-tag-unselected);
  box-shadow: 0px 2px 6px rgba(13, 10, 44, 0.08);
  border-radius: 20px;
  padding: 41px 33px;
  margin-top: 12px;
  // height: 8rem;

  &:hover {
    border: 1px solid var(--livis-color-primary-dblue) !important;
  }

  & .livis-training-scratch-learning-header {
    margin: 0;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 24px;
    color: var(--livis-color-primary-gray);
  }

  & .livis-training-scratch-learning-content {
    margin: 0;
    margin-top: 1.5rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: var(--livis-instruction-secondary-color);
  }
}

.livis-semi-circle-donut-title-quality {
  font-weight: 700 !important;
  font-size: 1rem !important;
  line-height: 20px !important;
  color: var(--livis-black-font-color) !important;
  text-align: center !important;
}

.livis-semi-circle-donut-title-header {
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 20px !important;
  color: var(--livis-instruction-secondary-color) !important;
  text-align: center !important;
}

.livis_training_configuration-container {
  margin-top: 3.4375rem;
  margin-bottom: 8rem;
}

.livis-training-credit-table {
  .MuiTableCell-root {
    text-align: left !important;
  }

  .MuiTableHead-root {
    border-bottom: 1px solid var(--livis-color-primary-dblue) !important;
  }

  .MuiTableCell-root {
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 24px !important;
    letter-spacing: 0.1px !important;
    color: var(--livis-gray-dark-font-color) !important;
    border: none !important;
    padding: 0 !important;
    padding-top: 0.75rem !important;
  }

  .MuiTableBody-root>.MuiTableRow-root>.MuiTableCell-root:nth-child(1) {
    font-weight: 500 !important;
    font-size: 1rem !important;
    line-height: 24px !important;
    letter-spacing: 0.1px !important;
    color: var(--livis-color-primary-dblue) !important;
  }

  .MuiTableRow-root>.MuiTableCell-root:nth-last-child(1) {
    text-align: right !important;
  }

  .MuiTableRow-head>.MuiTableCell-root {
    padding-bottom: 0.75rem !important;
  }
}

.livis-training-loader-container {
  margin-left: 15px;
}

.livis-dot-loader-container {
  position: relative;
}

.dot-flashing {
  position: absolute;
  left: 0;
  top: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--livis-color-primary-dblue);
  color: var(--livis-color-primary-dblue);
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--livis-color-primary-dblue);
  color: var(--livis-color-primary-dblue);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--livis-color-primary-dblue);
  color: var(--livis-color-primary-dblue);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--livis-color-primary-dblue);
  }

  50%,
  100% {
    background-color: rgba(61, 24, 223, 0.2);
  }
}

.livis-training-version-filter-wrap {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  // align-items: center;

  .livis-training-summary-icon-wrap {
    // margin-right: 0.5rem;
    width: 7rem;

    img {
      margin-top: 0.5rem;
      width: 1.75rem;
    }
  }

  & .MuiOutlinedInput-root {
    width: 8.5rem;
    height: 2.5rem;
    border-color: var(--livis-color-primary-lblue);
  }
}

.livis-train-augmentation-wrap {
  border: 1px solid var(--livis-train-agumentation-border);
  border-radius: 20px !important;
  padding-right: 1rem;
  padding-bottom: 2%;
}

.livis-train-augmentation-container {
  padding-left: 1.438rem;
  padding-right: 0rem !important;
  align-content: flex-start !important;
  align-items: flex-start !important;
  justify-content: space-between;
}

.livis-training-augmentation-rotate-wrap {
  display: flex;
  align-items: flex-start;
}

.livis-training-augmentation-rotate-wrap img {
  margin-left: 1rem !important;
}

.livis-training-augmentation-rotate-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem !important;
}

.livis-training-augmentation-rotate-container:has(.livis-training-augmentation-rotate-wrap-no-margin-top) {
  margin-top: 0 !important;
}

.livis-training-augmentation-rotate-wrap-text {
  padding-left: 1.6rem !important;
  margin-block: calc(1.25rem / 2) !important;
}

.livis-training-augmentation-horizontal-container {
  margin-top: 2rem !important;
}

.livis-training-augmentation-checkbox-text-wrap {
  align-items: center;
}

.livis-training-details-header-spacing {
  margin-top: 2rem;
}


.livis-training-summary-container-margin {
  margin-top: 2rem;
}

.livis-training-available-credits {
  margin-left: 32px;
}

.livis-training-augementation-container {
  margin-top: 5%;
}

.livis-training-augementation-container-block {
  margin-block: 2%;
  justify-content: space-between;
}

.livis-training-augementation-container-margin {
  margin-top: 3%;
  margin-left: 5%;
}

.livis-training-augementation-container-padding {
  padding-right: 2%;
}

.livis-training-start-btn-avatar {
  width: 1rem !important;
  height: 0.875rem !important;
}

.livis-training-selected-icon-avatar {
  width: 50px !important;
  height: 50px !important;
}

.livis-train-data-summary {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 12px;
  padding: 1rem;
  margin-top: 1rem !important;
}

.livis-train-data-summary-configration {
  background: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 12px;
  padding: 1rem;
  margin: 1rem 0rem;

  p {
    color: var(--livis-gray100-font-color);
  }
}

.livis-training-config-text {
  font-size: 1rem;
  font-weight: bold;
  margin-left: 0.1rem;
  color: var(--livis-color-primary-gray);
}

.livis-training-summary-modal-data-mode {
  font-weight: 700;
  color: var(--livis-color-primary-dblue);
  font-size: 1rem;
  margin-bottom: 0;
}

.livis-training-data-summary-modal-table-content {
  font-size: 1rem;
  font-weight: bold;
}

.livis-modal-sub-header-right {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
  position: absolute;
  right: 4.25rem !important;
}

.livis-training-data-summary-modal-table-header {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 16px;
  color: var(--livis-instruction-secondary-color);
  margin: 0;
}

.livis-modal-other-config-text {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.livis-training-selected-summary-modal-content {
  padding: 24px 32px 20px 32px;
  justify-content: space-around !important;
}

.livis-training-supervised-tags-summary-modal {
  background: var(--livis-white-color);
  border: 1px solid var(--livis-border-color);
  border-radius: 12px;
  padding: 17px 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.livis-training-summary-modal-info {
  cursor: pointer;
  margin-left: 0.3rem;
}



.livis-training-summary-feature-chips {
  margin-top: 0.75rem;
  display: flex !important;
  align-items: flex-start;
  flex-wrap: nowrap !important;
}

.livis-training-summary-defects-chips {
  display: flex !important;
  align-items: center !important;
}

.livis-training-summary-modal-line-height {
  line-height: 1rem !important;
}

.livis-training-summary-modal-configuration-box {
  margin: 1.625rem !important;
}

.livis-training-summary-modal-configuration-grid {
  justify-content: space-between !important;
}

.livis-training-config-reset-button-wrap {
  display: flex !important;
  justify-content: flex-end !important;
  width: 100% !important;

  .livis-training-secondary-btn {
    margin-right: 1rem;
    margin-top: 1rem;
  }
}

.livis-anomaly-coming-soon-btn {
  background-color: var(--livis-part-data-flag-chip-color) !important;
  padding-left: 0.6rem !important;
  flex-wrap: nowrap !important;
  display: flex !important;
  border-color: var(--livis-warning-color) !important;
  opacity: 0.8 !important;
}

.livis-anomaly-coming-soon-text {
  color: var(--livis-warning-color);
  font-weight: 600 !important;
  margin-left: 0.2rem !important;
  font-size: 0.875rem !important;
}

.livis-training-anomaly-coming-soon {
  background-color: var(--livis-landing-header-right-routes-hover) !important;

  .livis-training-container-no-data {
    opacity: 50% !important;
  }
}

.livis-training-start-btn-avatar-anomaly-backgroud {
  background-size: cover;
  background-position: center;
  padding: 0.5rem !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
}

.livis-training-info-icon {
  cursor: pointer;
}

.livis-training-info-card {
  background-color: var(--livis-operator-builder-tooltip-color);
  border-radius: 0.5rem;
  padding-inline: 1rem;
  padding-block: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.livis-training-inputLabel {
  margin-bottom: 0.22rem;
}

.livis-training-patience-relative {
  position: relative;

}

.livis-training-patience-Tooltip {
  position: absolute;
  top: -2.5px;
  left: 50px
}


.livis-training-versions-summary-header {
  text-align: center;
}

.livis-training-versions-summary-header-wrap {
  margin-block: 2rem 1rem;
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 0.5rem;
  font-family: "Noto Sans SemiBold";
}

.livis-annotation-inso-accoridan-summary .livis-training-versions-summary-header:first-child {
  font-family: "Noto Sans Bold";

}


// Training Module css end

// Testing module css start

.livis-testing-main-layout {
  margin-top: 1.12rem;
  margin-right: 2rem;
  margin-left: 2rem;
}

.livis-testing-card-grid {
  height: 100% !important;
}

.livis-testing-card-grid {
  height: 100% !important;
}

.livis-testing-main-layout-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.livis-testing-image-upload-wrap {
  background: var(--livis-color-background-white);
  border: 1px dashed var(--livis-part-drag-container);
  border-radius: 15px;
  height: 48rem;
}

.livis-testing-image-list {
  width: 12.75%;
  margin-right: 1.5rem;
  height: 55rem;
  overflow-x: auto;

  & .livis-add-image-list-wrapper {
    background: var(--livis-color-background-white);
    border: 1px dashed var(--livis-part-drag-container);
    border-radius: 15px;
    height: 9.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
  }

  & .livis-add-image-list-wrapper-selected {
    background: var(--livis-color-background-white);
    border: 2px solid var(--livis-part-drag-container);
    border-radius: 15px;
    height: 9.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 10px;
  }

  & .livis-testing-iterable-image-container {
    position: absolute;
    width: 100%;
    min-height: 100%;
    height: 100%;
    border-radius: 15px !important;
    cursor: pointer;
  }
}

.livis-testing-image-wrap {
  width: 85.72%;
  z-index: 2;
}

.livis-testing-image-container {
  background: var(--livis-color-background-white);
  border: 2px dashed var(--livis-part-drag-container);
  border-radius: 1.25rem;
  height: 48rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.livis-testing-image-container2 {
  background: var(--livis-color-background-white);
  border: 2px dashed var(--livis-part-drag-container);
  border-radius: 1.25rem;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.livis-testing-image-container3 {
  background: var(--livis-color-background-white);
  border: 2px dashed var(--livis-part-drag-container);
  border-radius: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 1rem;

  #form-file-upload {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .livis-part-upload-icon {
    width: 2.5rem !important;
  }

}

.livis-testing-image-container3-margin {
  margin-top: 0 !important;
  padding-top: 1rem;
}

.livis-testing-control-wrap {
  width: 100%;
  margin-top: 1rem;
  background: var(--livis-color-background-white);
  border: 1px solid var(--livis-part-drag-container);
  border-radius: 15px;
  // height: 6.375rem;
  padding: 1rem 1rem;

  & .livis-test-control-data-label {
    margin: 0.2rem;
    color: var(--livis-gray100-font-color);
    font-size: 0.938rem;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  & .livis-test-control-data-value {
    margin: 0.2rem;
    color: var(--livis-gray100-font-color);
    font-size: 0.938rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
}

.livis-test-result-info-wrap {
  display: flex;
  flex-wrap: wrap;
}

.test-result-wrap {
  display: flex;
}

.test-result-wrap>div {
  max-width: fit-content;
}

.livis-test-result-info-block {
  display: flex;
  min-width: calc(33.33% - 2%);
  margin-inline: 1%;
}

.add-recipe-part-info-wrap .livis-test-result-info-block {
  min-width: calc(50% - 2%) !important;
  margin-block-end: 1rem !important;
}

.livis-testing-credit-wrap img {
  width: 5rem !important;
}

.livis-test-clear-btn {
  height: 3rem !important;
}

.livis-testing-type-icon {
  width: 68px;
}

.livis-testing-type-card-body-text {
  & p {
    color: var(--livis-instruction-secondary-color);
    font-size: 0.875rem;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
}

.livis-testing-type-card {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.livis-testing-part-menu-wrap {
  margin-top: 1.5rem;
  margin-left: 0px;
  margin-bottom: 2rem;
}

.livis-testing-part-menu-wrap .MuiInputBase-root {
  width: 40% !important;
  margin: 0px;
  height: 3rem !important;
  padding: 0px;
  border-radius: 0.5rem !important;
  background: none !important;
  border: 1px solid var(--livis-tag-unselected) !important;
}

.livis-testing-part-menu-wrap .MuiFormLabel-root {
  bottom: 1.4rem !important;
  top: auto;
  color: var(--livis-part-menu-mui-custom-color) !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
}

.livis-testing-upload-cancel-button {
  position: relative;
  top: -1rem;
  cursor: pointer;
}

.livis-testing-upload-progress-value {
  color: var(--livis-color-primary-gray);
  font-size: 4rem;
  font-weight: 600;
  line-height: 4.375rem;
  letter-spacing: 0.1px;
  margin: 0;
  margin-bottom: 2.6875rem;
}

.livis-testing-test-info-icon {}

.livis-testing-test-history-icon {
  margin-left: 1rem;
  cursor: pointer;
}

.livis-testing-inference-progress-wrapper {
  border-radius: 15px;
  border: 1px solid var(--livis-part-drag-container);
  background: var(--livis-part-box-background);
  padding: 1rem 3.3125rem 0.5rem 3.3125rem;
  margin-bottom: 2rem;
  min-height: 22rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & .livis-inference-progress-loader-container {
    margin-left: 15px;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  & .livis-testing-inference-progress-value {
    color: var(--livis-color-primary-gray);
    font-size: 2rem;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: 0.1px;
    margin: 0;
    margin-bottom: 1rem;
  }
}

.livis-training-inference-result-modal {
  left: 10% !important;
  height: 90% !important;
}

.livis-testing-test-result-tab-container {
  display: flex;
  justify-content: end;
  position: relative;
  right: 10%;
  top: -4.5rem;
}

.livis-testing-inference-body {
  // position: relative;
  // min-height: 77vh;
  // max-height: 77vh;
  // overflow: auto;
  // top: -3.5rem;
}

.livis-testing-inference-result-image-container {
  border-radius: 1.25rem;
  position: relative;
  border-radius: 20px;
  height: 100%;

  & .livis-testing-inference-slide-icon-left {
    position: absolute;
    left: 0.625rem;
    top: 50%;
    z-index: 1;
    cursor: pointer;
    width: 48px;
  }

  & .livis-testing-inference-slide-icon-right {
    position: absolute;
    right: 0.625rem;
    top: 50%;
    z-index: 1;
    cursor: pointer;
    width: 48px;
  }

  & .livis-testing-result-navigate-button-hidden {
    display: none;
  }

  & .livis-testing-inference-result-modifcation-wrapper {
    // width: 100%;
    max-width: fit-content !important;
    position: absolute;
    bottom: 0;
    right: 0rem;
    z-index: 1;
    padding: 20px;
    justify-content: end;
  }
}

.livis-testing-inference-result-home-wrapper {
  position: absolute;
  top: 1rem;
  left: 0px;
  z-index: 1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

// .livis-testing-inference-result-user-resolved-wrapper {
//   position: absolute;
//   top: 1rem;
//   right: 0;
//   z-index: 1;
//   padding: 20px;
// }

.livis-testing-inference-result-home-wrapper button {
  text-transform: capitalize !important;
}

.livis-testing-inference-result-annotate-wrapper {
  position: absolute;
  bottom: 0rem;
  left: 0px;
  z-index: 1;
  padding: 20px;
  max-width: fit-content !important;
}

.livis-inference-annotate-icon {

  // width: 100% !important;
  border-radius: 1.5625rem !important;
  // margin-top: 3rem !important;
  // margin-bottom: 2rem !important;
  // padding: 0rem !important;
  font-family: "Noto Sans SemiBold" !important;
  line-height: 1.56 !important;
  letter-spacing: 0.01125rem !important;
  color: var(--livis-color-background) !important;
  font-size: 1.125rem !important;
  text-transform: none !important;
  background-color: var(--livis-color-primary-blue) !important;
}

.livis-flag-update-input {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid var(--livis-flag-update-input-bg);
  min-height: 30px;
  max-width: 10rem !important;
}

.livis-flag-update-input:focus-visible {
  outline: none !important;
}

.livis-inference-canvas-wrap {
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 1.25rem !important;
}

.livis-testing-inference-result-image {
  border-radius: 20px !important;
  width: 100%;
  height: 100%;
}

.livis-testing-inference-result-image-container {
  min-height: 80vh;
}

.livis-testing-inference-data-wrapper {
  // height: 825px !important;
  overflow: auto !important;
}

.livis-testing-result-threshold-wrapper {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 6px -2px rgba(168, 168, 168, 0.15);
  padding: 1.5rem 1.5rem 0rem 1.5rem;

  & p {
    margin: 0;
    color: var(--livis-gray100-font-color);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
  }

  & p:nth-child(2) {
    color: var(--livis-color-primary-dblue);
    font-size: 1rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.livis-testing-result-data-performance-wrapper {
  margin-top: 1rem;
  border-radius: 20px;
  border: 1px solid var(--livis-part-drag-container);
  box-shadow: 0px 3px 6px -2px rgba(168, 168, 168, 0.15);
  padding: 1.5rem;

  .livis-testing-result-data-performance-data {
    margin-top: 12px;
  }

  & p {
    margin: 0;
    margin-left: 10px;
    color: var(--livis-gray-dark-font-color);
    font-size: 0.875rem;
    line-height: 20px;
  }

  & .livis-testing-result-data-performance-header {
    color: var(--livis-gray100-font-color);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
    margin: 0;
  }
}

.livis-testing-result-precision-recall-wrapper {
  margin-top: 1rem;
  border-radius: 20px;
  border: 1px solid var(--livis-part-drag-container);
  box-shadow: 0px 3px 6px -2px rgba(168, 168, 168, 0.15);
  padding: 1.5625rem 4.125rem 1.5625rem 2.0625rem;

  & .livis-testing-result-precision-recall-value {
    margin: 0;
    color: var(--livis-color-primary-gray);
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 52px;
  }

  & .livis-testing-result-precision-recall-label {
    margin: 0;
    color: var(--livis-gray-font-color);
    font-size: 0.875rem;
    line-height: 16px;
  }
}

.livis-testing-test-score-wrapper {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--livis-white-color);
  box-shadow: 0px 3px 6px -2px rgba(168, 168, 168, 0.15);
  padding: 1.5rem 1.9375rem;

  & p {
    margin: 0;
  }

  & .livis-testing-test-type {
    color: var(--livis-color-primary-dblue);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 16px;
  }

  & .livis-testing-test-result-pass {
    color: var(--livis-training-status-success-bg);
    font-size: 1rem;
    font-weight: 700;
    line-height: 24px;
    margin-top: 4px;
  }

  & .livis-testing-test-result-fail {
    color: var(--livis-color-secondary-red);
    font-size: 1rem;
    font-weight: 700;
    line-height: 24px;
    margin-top: 4px;
  }
}

.livis-inference-btn-flag {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  background-color: var(--livis-part-data-flag-chip-color) !important;
  color: var(--livis-warning-color) !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-flag-submit-btn {
  border-radius: 50px !important;
  background: var(--livis-warning-color) !important;
  color: var(--livis-flag-submit-btn-bg) !important;
}

.livis-inference-back-icon {
  border-radius: 12px !important;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
  background: rgba(31, 31, 31, 0.75) !important;
  color: var(--livis-white-color) !important;
  font-size: 1rem !important;
  line-height: 24px !important;
}

.livis-inference-btn-pass {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  background-color: var(--livis-usecase-feature-background) !important;
  color: var(--livis-success-color) !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-inference-btn-fail {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  background-color: var(--livis-usecase-defect-background) !important;
  color: var(--livis-error-color) !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-inference-btn-passed {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  background-color: var(--livis-inference-btn-passed-bg) !important;
  color: var(--livis-chip-data-background-color) !important;
  // border: 1px solid var(--livis-chip-data-background-color) !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-inference-btn-failed {
  margin-left: 1.25rem !important;
  padding-inline: 1.3rem !important;
  padding-block: 0.5rem !important;
  background-color: var(--livis-color-secondary-red) !important;
  color: var(--livis-chip-data-background-color) !important;
  // border: 1px solid var(--livis-chip-data-background-color) !important;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: unset !important;
  box-shadow: none !important;
}

.livis-testing-features-defects-count-wrapper {
  border-radius: 20px;
  border: 1px solid var(--livis-part-drag-container);
  background: var(--livis-white-color);
  padding: 1.5rem 2.0625rem;
  margin-top: 1rem;

  & p {
    margin: 0;
  }

  & .livis-testing-features-defects-count-container {
    border-radius: 12px;
    border: 1px solid var(--livis-tag-unselected) !important;
    padding: 9px 15px;
  }

  & .livis-testing-features-defects-count {
    color: var(--livis-color-primary-gray);
    font-size: 2rem;
    font-weight: 700;
    line-height: 40px;
  }

  & .livis-testing-features-defects-count-header {
    color: var(--livis-instruction-secondary-color);
    font-size: 0.875rem;
    line-height: 16px;
    margin-bottom: 4px;
  }

  & .livis-testing-features-defects-count-subheader {
    color: var(--livis-color-primary-gray);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 26px;
  }
}

// .livis-testing-inference-history-modal {
//   height: 35rem !important;
//   overflow: auto;
// }

.livis-test-history-list-wrapper {
  height: 31rem;
  overflow: auto;
}

.livis-testing-test-history-card-wrapper {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--neutral-colors-white, --livis-white-color);
  box-shadow: 0px 3px 6px -2px rgba(168, 168, 168, 0.15);
  padding: 2rem 2rem 1.125rem 2rem;
  position: relative;
  cursor: pointer;
}

.livis-testing-test-history-type {
  color: var(--livis-color-primary-dblue);
  font-size: 0.875rem;
  line-height: 16px;
  margin: 0;
}

.livis-testing-test-history-status-pass {
  color: var(--livis-training-status-success-bg);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  margin-top: 4px;
}

.livis-testing-test-history-status-fail {
  color: var(--livis-color-secondary-red);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  margin-top: 4px;
}

.livis-testing-test-history-text-bold {
  color: var(--livis-gray100-font-color);
  font-size: 0.938rem;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
}

.livis-testing-test-history-text-normal {
  color: var(--livis-instruction-secondary-color);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
  margin-top: 4px;
}

.livis-testing-test-history-text-part-name {
  margin: 0;
}

.livis-testing-test-history-text-score {
  color: var(--livis-color-primary-gray);
  font-size: 2.75rem;
  font-weight: 700;
  line-height: 52px;
  margin: 0;
  margin-top: 22px;
}

.livis-testing-test-history-right-align {
  text-align: right;
}

.livis-testing-test-history-credit {
  color: var(--livis-color-primary-gray);
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
}

#form-file-upload {
  height: 100%;
  width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // border-width: 2px;
  // border-radius: 1rem;
  // border-style: dashed;
  // border-color: #cbd5e1;
  // background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: var(--livis-white-color);
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.livis-testing-mini-feed-grid.livis-operator-panel-mini-feed-grid {
  padding: 0px !important;
  // min-height: 100% !important;
}

.livis-testing-overall-result-block {
  border-radius: 20px;
  border: 1px solid var(--livis-add-part-border-color);
  background: rgba(248, 252, 255, 0.6);
  box-shadow: 0px 3px 6px -2px rgba(168, 168, 168, 0.15);
  padding: 1rem 2rem !important;
}

.livis-testing-precision-accuracy-block {
  border-radius: 20px;
  border: 1px solid var(--livis-add-part-border-color);
  background: var(--livis-white-color);
  padding: 1rem 2rem !important;
  margin-top: 0.875rem !important;
}

.livis-testing-overall-result-heading {
  color: var(--livis-gray-font-color);
  font-size: 1.125rem;
  font-family: "Noto Sans Regular";
  line-height: 20px;
  margin-bottom: 24px;
  /* 111.111% */
}

.livis-testing-overall-result-test-type {
  color: var(--livis-color-primary-dblue);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font-family: "Noto Sans Regular";
}

.livis-testing-overall-result-status.rejected {
  color: var(--livis-error-color);
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  font-family: "Noto Sans SemiBold";
  margin-top: 0.5rem !important;
}

.livis-testing-overall-result-status.accepted {
  color: rgba(16, 130, 52, 0.7490196078) !important;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  font-family: "Noto Sans SemiBold";
  margin-top: 0.5rem !important;
}

.livis-testing-overall-result-date {
  color: var(--livis-gray100-font-color);
  text-align: right;
  font-size: 0.938rem;
  font-style: normal;
  line-height: 22px;
  font-family: "Noto Sans Regular";
}

.livis-testing-overall-result-time {
  color: var(--livis-instruction-secondary-color);
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
  font-family: "Noto Sans Regular";
  line-height: 22px;
}

.livis-testing-overall-result-image-count {
  color: var(--livis-color-primary-gray);
  font-size: 2.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  font-family: "Noto Sans SemiBold";
}

.livis-testing-overall-result-image-count-text {
  color: var(--livis-instruction-secondary-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  font-family: "Noto Sans Regular";
}

.livis-testing-overall-result-accept-reject-wrap {
  border-radius: 20px 0px 0px 20px;
  background: rgba(221, 221, 221, 0.25);
  margin-right: -2rem !important;
  padding: 1rem !important;
  display: flex;
  font-family: "Noto Sans Regular";
}

.livis-testing-overall-result-accept-count {
  color: var(--livis-success-color);
  font-size: 2rem;
  line-height: 24px;
  font-family: "Noto Sans Regular";
}

.livis-testing-overall-result-accept-text {
  color: var(--livis-gray100-font-color);
  font-size: 0.875rem;
  line-height: 24px;
  font-family: "Noto Sans Regular";
}

.livis-testing-overall-result-reject-count {
  color: var(--livis-error-color) !important;
  font-size: 2rem;
  line-height: 24px;
  font-family: "Noto Sans Regular";
}

.livis-testing-overall-result-a-r-wrap {
  margin-inline: 1.5rem !important;
}

.livis-testing-overall-result-test-date-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.livis-testing-overall-result-counts-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem !important;
}

.livis-testing-pc-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.livis-testing-precision-accuracy-text {
  color: var(--livis-gray-font-color);
  font-size: 0.875rem;
  line-height: 16px;
  font-family: "Noto Sans Regular";
}

.livis-testing-precision-accuracy-count {
  color: var(--livis-color-primary-gray);
  font-size: 2.25rem;
  line-height: 52px;
  font-family: "Noto Sans SemiBold";
}

.livis-testing-card-credit-wrap {
  border-radius: 50px;
  background: var(--livis-testing-card-credit-wrap);
  padding: 0.2rem 0.5rem !important;
  font-size: 0.813rem !important;
}

.livis-testing-list-time-wrap {
  justify-content: space-between !important;
}

.livis-testing-list-total-images {
  text-align: start !important;
  font-size: 0.875rem !important;
  margin: 0px !important;
}

.livis-inferencing-loader {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-upload-data-form {
  cursor: pointer !important;
}


.livis-disabled-card-border {
  border-radius: 16px;
}

.livis-testing-card-height {
  height: 100%;
}

.livis-testing-modal-buttons {
  display: flex;
  justify-content: end;
  padding-block: 2rem;
}

.livis-tesing-history-modal {
  margin-top: 2%;
  margin-bottom: 2%;
}

.livis-testing-credit-log-modal {
  display: flex;
  justify-content: end;
  margin-top: 2rem;
}

.livis-testing-home-avatar {
  width: 20px !important;
  height: 20px !important;
}

.livis-evaluate-no-data {
  height: 65vh;
}

.livis-evaluate-annotation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2.45rem;
  padding-right: 2.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1rem;
  background-color: var(--livis-white-color);
}

.livis-evaluate-annotation-header-text {
  font-size: 1rem;
  display: flex;
  align-items: center;
}


.livis-evaluate-annotation-header-text-images {
  font-size: 0.825rem !important;
  display: flex;
  align-items: center;
  color: var(--livis-gray-font-color);
}

.livis-evaluate-annotation-flag-image {
  width: 2rem;
  height: 2rem;
  margin-left: 0.8rem;
}

.livis-evalutate-annotation-image-count {
  font-size: 1.75rem;
  margin-left: 1rem;
}

.livis-evalutate-annotation-image-count-total {
  font-size: 1.75rem;
  font-weight: 600;
  margin-left: 0.1rem;
}

.livis-testing-threshold-count-subheader {
  color: var(--livis-color-primary-gray);
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 26px;
}

.livis-evaluate-user-resolved-wrap {
  position: absolute;
  top: 210px;
  left: 2rem;

  button {
    border-radius: 0.75rem;
    font-style: normal;
    font-family: "Noto Sans Regular";
    font-size: 1rem;
    letter-spacing: 0.15px;
    color: var(--livis-reports-download-btn-color);
    padding: 0.5rem 0.75rem !important;
  }
}

// Testing module css end

// Recipe Builder Module start
.livis-recipe-card-bottom-data-wrap {
  margin-bottom: 1.25rem;
  margin-top: 2rem;
  padding-right: 1rem !important;

  & p {
    font-weight: 400;
    font-size: 0.875rem;
    letter-spacing: 0.15px;
    color: var(--livis-gray-font-color);
    margin-top: 0.25em !important;

    & span {
      color: var(--livis-color-primary-gray);
      font-weight: 600 !important;
    }
  }

  // & p:nth-child(2) {
  //   color: var(--livis-gray-font-color);
  //   font-size: 14px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: 20px;
  //   letter-spacing: 0.15px;
  // }
}

.livis-recipe-list-location-wrap {
  & p:nth-child(1) {
    margin: 0;
    color: var(--livis-gray-font-color);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;

    & span {
      color: var(--livis-color-primary-gray);
      font-weight: 600;
    }
  }

  & p:nth-child(2) {
    margin: 0;
    color: var(--livis-gray-font-color);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}

.livis-create-recipe-wrap {
  margin: 0 1rem 0 1rem;
}

.livis-recipe-form-title {
  color: var(--livis-color-primary-gray);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
}

.livis-recipe-part-info-container {
  background-color: var(--livis-part-box-background);
  border: 1px solid var(--livis-part-box-border);
  border-radius: 0.9375rem;
  padding: 19px 25px;
  margin-top: 1.5rem;

  & p {
    margin: 0;
  }

  & .livis-recipe-part-info-header {
    color: var(--livis-color-primary-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 18px;
  }

  & .livis-recipe-part-info-content {
    & p {
      color: var(--livis-gray100-font-color);
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0.1px;
    }

    & span {
      color: var(--livis-gray100-font-color);
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.1px;
    }
  }
}

.livis-recipe-selected-part-wrapper {
  // margin-top: 1rem;
  display: flex;
  overflow-y: auto;
  padding-block: 0.5rem !important;

  & .livis-recipe-part-container {
    margin-right: 1rem;
    padding: 2px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--livis-recipe-part-container-border);
    cursor: pointer;

    & .p {
      color: var(--livis-connection-color);
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 16px;
    }
  }

  & .livis-recipe-part-container-selected {
    margin-right: 1rem;
    padding: 2px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--livis-connection-color);
    background: rgba(231, 242, 248, 0.6);
    cursor: pointer;

    & .p {
      color: var(--livis-connection-color);
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

// .livis-add-recipe-modal-section-titles {
//   margin-top: 5rem !important;
// }

.livis-recipe-usecase-wrapper {
  border-radius: 20px;
  background: rgba(232, 233, 249, 0.15);
  padding: 0.6875rem 1rem 1rem 1rem;

  & p {
    margin: 0;
  }
}

.livis-recipe-usecase-card-container {
  // overflow: auto;

  & .livis-part-form-usecase-name {
    color: var(--livis-color-primary-gray);
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0.375rem;
  }

  & .livis-part-form-usecase-score {
    color: var(--livis-color-primary-dblue);
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 20px;

    & span {
      font-weight: 600 !important;
      font-size: 0.938rem;
    }
  }
}

.livis-recipe-usecase-card-wrapper {
  border-radius: 20px;
  border: 1px solid var(--livis-tag-unselected);
  padding: 0.75rem;

  & .livis-recipe-usecase-card-img {
    border-radius: 1rem;
    height: 100%;
    position: relative !important;
    object-fit: cover !important;
  }
}

.livis-recipe-usecase-card-wrapper:hover {
  border: 1px solid var(--livis-color-primary-dblue);
}

.livis-recipe-usecase-card-wrapper-selected {
  border-radius: 20px;
  border: 1px solid var(--livis-color-primary-dblue);
  padding: 0.75rem;

  & .livis-recipe-usecase-card-img {
    border-radius: 1rem;
    height: 100%;
    position: relative !important;
    object-fit: cover !important;
  }
}

.livis-recipe-usecase-header-container {
  margin-bottom: 0.75rem !important;
}

.livis-recipe-part-selector-wrap {
  // margin-top: 1.5rem;
  margin-left: 0px;
  margin-bottom: 0.6rem;
  min-width: 30%;

  & .MuiInputBase-root {
    width: 100% !important;
    margin: 0px;
    height: 2.5rem !important;
    padding: 0px;
    border-radius: 0.5rem !important;
    background: rgba(232, 233, 249, 0.15) !important;
  }

  & .MuiFormLabel-root {
    bottom: 1.4rem !important;
    top: auto;
    color: var(--livis-part-menu-mui-custom-color) !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.5rem !important;
  }
}

.livis-recipe-part-dropdown-checkmark-container {
  width: 2rem;
  height: 1.5rem;

  & .livis-recipe-part-dropdown-checkmark {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.livis-recipie-usecase-version-selector {
  margin-top: 1.5rem;
  margin-left: 0px;
  // margin-bottom: 0.6rem;

  & .MuiInputBase-root {
    width: 100% !important;
    margin: 0px;
    height: 2rem !important;
    padding: 0px !important;
    border-radius: 1.875rem !important;
    background: var(--livis-chip-data-background-color);
  }

  & .MuiFormLabel-root {
    bottom: 1.4rem !important;
    top: auto;
    color: var(--livis-part-menu-mui-custom-color) !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    line-height: 1.5rem !important;
  }

  & .MuiOutlinedInput-input {
    font-size: 0.625rem !important;
  }

  // & .MuiOutlinedInput-notchedOutline {
  //   border: none;
  // }
}

.livis-recipie-usecase-selector-button-disabled {
  height: 2rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  background: rgba(8, 87, 222, 0.6);
}

.livis-recipie-usecase-selector-button-active {
  height: 2rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  background: var(--livis-inference-btn-passed-bg);
  cursor: pointer;
}

.livis-recipie-usecase-selector-button-active.selected {
  width: 2rem !important;
  background-color: transparent !important;
}

.livis-recipie-usecase-auto-update-button-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.livis-recipie-usecase-auto-update-button-wrap .livis-add-part-parameter-position-title-container {
  margin-right: 1rem !important;
  font-size: 10px !important;
}

.livis-recipie-usecase-auto-update-accordian-heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.livis-recipie-usecase-auto-update-accordian-heading span {
  font-family: "Noto Sans Regular" !important;
}

.livis-recipie-usecase-auto-update-accordian-heading img {
  width: 20px !important;
  margin-right: 0.25rem !important;
  margin-top: -2px !important
}

.livis-recipie-usecase-auto-update-accordian-desc {
  font-size: 0.875rem !important;
}

.livis-recipie-usecase-auto-update-accordian-btn {
  color: var(--livis-color-primary-dblue) !important;
  background-color: var(--livis-add-part-parameter-position-apply-to-all-bg) !important;
  padding: 0.5rem 1.5rem !important;
  border-radius: 0.75rem !important;
  cursor: pointer !important;
  font-size: 12px !important;
}

.livis-recipie-usecase-auto-update-accordian-btn:hover {
  outline: 1px solid var(--livis-recipie-usecase-auto-update-accordian-btn-hover-outline);
}


.livis-recipie-usecase-auto-update-accordian-btn-wrap {
  padding-top: 1rem !important;
  padding-left: 1rem !important;
}

.livis-recipie-usecase-remove-button {
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  background: var(--livis-color-secondary-red);
  z-index: 2;
  cursor: pointer;
}

.livis-recipie-selected-usecase-container {
  border-radius: 1rem;
  border: 1px solid var(--livis-border-color);
  position: relative;
  padding: 1.75rem 0.8125rem 1.0625rem 1.125rem;
  margin-top: 1.5rem;
  position: relative;

  & .livis-recipie-bucket-header {
    border-radius: 12px;
    background: var(--livis-usecase-feature-background);
    position: absolute;
    padding: 5px 13px;
    display: flex;
    top: -1rem;

    & img {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.4375rem;
    }

    & p {
      margin: 0;
    }
  }

  & .livis-recipe-usecase-wrap {
    display: flex;
    overflow: auto;
  }

  & .livis-recipe-selected-usecase-item {
    min-width: 12rem !important;
  }

  & .livis-recipe-selected-usecase {
    border-radius: 0.75rem;
    border: 1px solid rgba(22, 83, 116, 0.2);
    // background: var(--livis-color-background-white);
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    // margin-right: 0.75rem;
    // width: 25%;
    cursor: pointer;

    & p {
      margin: 0;
    }

    & .livis-recipe-selected-usecase-name {
      color: var(--livis-color-primary-gray);
      font-size: 0.8125rem;
      font-weight: 600;
      line-height: 1.375rem;
      margin-bottom: 0.375rem;
    }

    & .livis-recipe-selected-usecase-version {
      color: var(--livis-gray-font-color);
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 1rem;
    }
  }
}

.livis-recipe-details-container {
  max-height: 606px;
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.livis-recipe-details-container-review {
  margin-top: 2rem !important;
}

.livis-recipe-form-usecase-info {
  border-radius: 1.25rem;
  border: 1px solid var(--livis-border-color);
  background: var(--livis-part-box-background);
  padding: 1.3125rem 1.5625rem;
  margin-top: 1rem;

  & p {
    margin: 0;
  }

  & .livis-recipe-usecase-info-header {
    color: var(--livis-color-primary-gray);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: 0.1px;
    margin-bottom: 1rem;
  }

  & .livis-recipie-usecase-info-key {
    color: var(--livis-gray100-font-color);
    font-size: 0.874;
    font-weight: 400;
    line-height: 1.875;
    letter-spacing: 0.1px;
  }

  & .livis-recipie-usecase-info-value {
    color: var(--livis-gray100-font-color);
    font-size: 0.874;
    font-weight: 600;
    line-height: 1.875;
    letter-spacing: 0.1px;
  }
}

.livis-recipe-controller-value-wrap {
  border-radius: 20px;
  border: 1px solid rgba(175, 179, 233, 0.35);
  background: var(--neutral-colors-white, --livis-white-color);
  padding: 1rem 0.5rem 1rem 2rem;
  margin-top: 0.5rem;
}

.livis-recipe-contoller-table-header-wrap {
  border: none;
  padding-top: 0;

  & p {
    margin: 0;
  }
}

.livis-recipe-controller-type {
  color: var(--livis-color-primary-dblue);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0;
}

.livis-recipe-plc-input {
  min-height: 4rem !important;
}

.livis-recipe-configuration-value-input-wrap {
  & input {
    border-bottom: none !important;
    border-radius: 12px !important;
    border: 1px solid var(--livis-recipe-configuration-input-border) !important;
    width: 4.5rem !important;
    height: 100%;
    text-align: center;
    color: var(--livis-color-primary-gray);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.1px;
  }
}

.livis-recipe-configuration-output-wrap {
  margin-top: 2.5rem;
}

.livis-recipe-add-output-button {
  cursor: pointer;
  color: var(--livis-color-primary-blue);
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.livis-recipie-preview-button {
  border-radius: 10px !important;
  border: 1px solid var(--livis-part-drag-container) !important;
  cursor: pointer;
}

.livis-recipe-form-operator-view {
  margin-top: 1rem;
  margin-bottom: 1rem;

  & img {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.livis-recipe-form-operator-list-container {
  // margin-right: 0.5rem !important;
  width: 5rem !important;

  & img {
    // width: 5rem;
    // position: relative;
    // top: 0;
    // left: 0;
    // width: 100%;
  }
}

.livis-recipe-detail-header {
  color: var(--livis-instruction-secondary-color);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  margin: 0 !important;
}

.livis-recipe-detail-data {
  color: var(--livis-color-primary-gray);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 40px;
  margin: 0 !important;
}

.livis-recipe-barcode-ocr-wrap {
  border-left: 1px solid rgba(165, 191, 255, 0.45);
}

.livis-recipe-details-card-wrap {
  margin-bottom: 1rem !important;
  overflow: auto;
}

.livis-recipe-details-part-card {
  border-radius: 20px;
  border: 1px solid var(--livis-border-color);
  padding: 29px 25px;
  position: relative;
  cursor: pointer;

  & p {
    margin: 0;
  }

  & .livis-recipe-details-part-info {
    margin-top: 2rem;
  }

  & .livis-recipe-details-part-header-key {
    color: var(--livis-color-primary-dblue);
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 16px;
  }

  & .livis-recipe-details-part-header-value {
    color: var(--livis-color-primary-gray);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 24px;
    word-break: break-all !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100% !important;
  }

  & .livis-recipe-details-part-footer-key {
    color: var(--livis-instruction-secondary-color);
    font-size: 0.813rem;
    font-weight: 500;
    line-height: 1rem;
  }

  & .livis-recipe-details-part-footer-value {
    color: var(--livis-recipe-details-part-footer-color);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 2rem;
  }

  & .livis-recipe-details-usecase-count {
    color: var(--livis-color-primary-gray);
    font-size: 2rem;
    font-weight: 700;
    line-height: 40px;
  }
}

.livis-recipe-details-part-card-selected {
  border: 1px solid var(--livis-color-primary-dblue) !important;
}

.livis-reciepe-details-card-count {
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
  height: 4rem;
  background-color: rgba(17, 124, 182, 0.07);
  border-radius: 0 20px 0 100px;
  display: flex;
  justify-content: end;
  align-items: start;

  & p {
    color: var(--livis-color-primary-gray);
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 28px;
    /* 100% */
    margin-top: 0.75rem;
    margin-right: 0.75rem;
  }
}

.livis-recipe-details-usecase-list-wrap {
  overflow: auto;
}

.livis-recipe-details-usecase-card {
  border-radius: 12px;
  margin-right: 1.125rem;
  border: 1px solid var(--livis-border-color);
  cursor: pointer;
  margin-block: 1rem !important;

  & .livis-recipe-details-usecase-card-data {
    margin: 1rem 1.25rem;
  }

  & .livis-recipe-details-usecase-card-data-list {
    margin: 1rem 1.25rem 1rem 0.5rem;
  }

  & .livis-recipe-details-usecase-card-number {
    background-color: var(--livis-testing-card-credit-wrap);
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 12px 0 12px 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.livis-recipe-details-usecase-card-selected {
  border: 1px solid var(--livis-color-primary-dblue) !important;
}

.livis-recipe-details-defects-card {
  border-radius: 12px;
  border: 1.2px solid rgba(188, 25, 25, 0.25);
  background: rgba(247, 229, 229, 0.25);
  padding: 0.75rem 1.5rem;
  margin-right: 0.75rem;
  margin-bottom: 0.5rem !important;

  & p {
    margin: 0 !important;
    color: var(--livis-error-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 35px;
  }
}

.livis-recipe-details-features-card {
  border-radius: 12px;
  border: 1.2px solid rgba(14, 93, 39, 0.25);
  background: rgba(230, 243, 234, 0.25);
  padding: 0.75rem 1.5rem;
  margin-right: 0.75rem;
  margin-bottom: 0.5rem !important;

  & p {
    margin: 0 !important;
    color: var(--livis-success-color);
    font-size: 1rem;
    font-weight: 600;
    line-height: 35px;
  }
}

.livis-recipe-list-view {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-recipe-statics-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-recipe-type {
  justify-content: center !important;
}

.livis-list-wrap .livis-recipe-type div:nth-child(1) {
  margin-top: 0px !important;
}

.livis-recipe-operator-slider-wrapper {
  max-width: 100%;
  // & .MuiTabs-scrollButtons.Mui-disabled {
  //   opacity: 0.3;
  // }
}

.livis-recipe-builder-operator-panels-wrap {
  margin-top: 5rem !important;
}

.livis-recipe-builder-usecase-part-dropdown-menuitem-wrap {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.livis-recipe-builder-usecase-part-dropdown-select>div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.livis-recipe-image-container {
  position: relative;
}

.livis-recipe-slider-checkmark {
  position: absolute;
  right: 10px;
  bottom: 6px;
}

.livis-recipe-slider-image-wrap {
  & p {
    margin: 0 !important;
    text-align: center;
  }
}

.livis-recipe-slider-image {
  width: 13rem;
  margin-right: 0.5rem;
  border-radius: 6px;
  cursor: pointer !important;
}

.livis-recipe-operator-selector {
  border: 1px solid var(--livis-color-primary-dblue) !important;
}

.livis-recipe-input-controller-h4 {
  padding: 0;
  margin-bottom: 20px;
  margin-top: 24px;
}

.livis-recipe-container {
  display: flex;
  justify-content: space-between;
}

.livis-recipe-device-status {
  display: flex;
  align-items: center;
}

.livis-recipe-device-status {
  margin-right: 80px;
}

.livis-recipe-add-btn-avatar {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.livis-recipe-add-btn-outlined-avatar {
  width: 32px !important;
  height: 32px !important;
}

.livis-recipe-usecase-card-width {
  margin: 0px !important;
  width: 100% !important;
}

.livis-recipe-part-thumbnail-img-container {
  padding: 0px !important;
}

.livis-recipe-part-thumbnail-img {
  width: 100% !important;
  height: 125px !important;
  object-fit: cover !important;
  border-radius: 1rem !important;
}

.livis-recipe-use-case-selected-container-cursor {
  cursor: pointer !important;
}

// Recipe Builder Module ends

// Trash Module start

.livis-trash-table-container {
  margin-left: 1rem;
  margin-right: 3rem;
  margin-top: 1rem;

  & p {
    // margin: 0;
    color: var(--livis-gray-font-color);
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
}

.livis-trash-calender-btn {
  justify-content: flex-end;
  display: flex;
  max-width: fit-content;
  margin-right: 0px !important;
  margin-left: auto;
}

.livis-trash-date-picker-wrapper {
  z-index: 2;
}

.livis-trash-list-card {
  padding: 0px !important;
}

.livis-trash-card-wrap {
  margin-left: 1rem;
  margin-right: 3rem;
  margin-top: 1rem;
}

.livis-cursor-pointer {
  cursor: pointer;
}

.livis-trash-data-checkbox {
  & .livis-manage-data-checkbox-icon {
    width: 2rem;
    height: 2rem;
  }

  & .MuiAvatar-root {
    border-radius: 0% !important;
  }
}

.livis-trash-grid-wrap {
  margin-top: 1rem;
}

.livis-trash-header-button-container {
  display: flex;
  min-height: 40px !important;
}

.livis-trash-header-delete-button {
  margin-right: 1rem !important;
  margin-left: 0 !important;
}

.livis-trash-image-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.livis-trash-image-desc {
  // position: absolute !important;
  // bottom: 1.25rem !important;
  // right: 1.25rem !important;
  // z-index: 1;

  position: absolute;
  top: 0px !important;
  z-index: 1;
  left: 0px !important;
  width: calc(100% - 2rem);
  height: calc(100% - 3rem);
  overflow: auto;
  padding: 2.5rem 1rem 0.5rem 1rem !important;
  background: rgba(31, 31, 31, 0.9490196078) !important;
  border-radius: 0.5rem !important;

  & p {
    padding: 0;
    color: var(--livis-reports-download-btn-color);
    text-align: right;
    font-size: 0.813rem;
    font-weight: 600 !important;
    line-height: 22px;
    /* 169.231% */
    letter-spacing: 0.1px;
  }
}

.livis-trash-date-picker-avatar {
  width: 24px !important;
  height: 24px !important;
}

.livis-manage-data-filter-wrap-d-none {
  display: none !important;
}

.livis-trash-calender-cancel-btn-margin {
  margin-right: 0.2rem !important;
}

// Trash Module ends

// Annotate Module start

.livis-annotate-header-wrap {
  padding-top: 1.5rem !important;
  padding-left: 2.4375rem !important;
  padding-right: 1.5rem !important;
  padding-bottom: 1.5rem;
}

.livis-annotate-main-body-wrap {
  // height: 100% !important;
  // overflow: hidden;

  // & .div-root-3 {
  //   height: auto !important;
  // }

  // & .div-root-34 {
  //   height: 100% !important;
  // }
}

#udt .fullscreen .jss3 {
  display: none !important;
}

#udt .MuiIconButton-colorPrimary {
  color: var(--livis-color-primary-dblue) !important;
}

.livis-annotation-tool-wrap {
  position: relative;
}

.livis-back-to-manage-btn {
  position: relative;
  top: 4.5rem !important;
  left: 1rem !important;
  max-width: fit-content !important;
  background-color: var(--livis-color-primary-blue);
  padding: 0.5rem 1rem !important;
  border-radius: 12px !important;
  color: var(--livis-white-color);
  cursor: pointer;
}

.livis-annotation-info-wrap {
  width: 100% !important;
  height: calc(100% - 1rem);
}

.livis-annotation-info-wrap>div,
.livis-annotation-info-wrap>div>div {
  height: 100%;
}

.livis-annotation-inso-accoridan-summary {
  width: 100% !important;
  padding-inline: 1rem !important;
  font-family: "Noto Sans SemiBold" !important;
}

.livis-annotation-info-details-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  padding-block: 8px;

  .livis-annotation-info-details-span {
    color: var(--livis-gray-font-color);
  }
}

.livis-annotation-info-details-wrap span:first-child {
  color: var(--livis-gray-font-color);
  font-size: 0.938rem;
}

.livis-annotation-info-details-wrap span:last-child {
  font-family: "Noto Sans Bold";
  font-size: 1rem;
}

.livis-operator-builder-add-feature-modal-body-components-description-wrap .livis-annotation-typography {
  padding: 0.5rem !important;
}

.livis-annotation-renderer-container {
  position: relative;
  display: inline-block;

  .annotated-image {
    width: 100%;
    height: 100%;
  }
}

#udt {
  position: relative;
}

.livis-annonation-upload-error-modal-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 10% !important;

  button {
    margin-top: 1rem !important;
  }
}

.livis-annoatate-new-udt {
  position: relative !important;
}

.livis-annotate-accordian-span {
  color: var(--livis-gray-font-color) !important;
}

.livis-annotate-modal-duplication-confirmation-container {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.livis-annotate-do-not-show-pop-up-span {
  padding-left: 6px !important;
}

//Annotate module ends


//Reports and analytics module css starts

.livis-reports-details-part-date-wrap {
  position: relative !important;
}


.livis-reports-details-verified-tag {
  position: absolute;
  top: 0px !important;
  left: 15% !important;
  background-color: rgba(230, 243, 234, 0.5019607843) !important;
  color: var(--livis-success-color) !important;
  padding-inline: 0.75rem !important;
  padding-block: 0.25rem !important;
  font-family: 'Noto Sans SemiBold' !important;
  border-radius: 0 0 0.25rem 0.25rem;
}

.livis-batch-size-dropdownFilter {
  border-radius: 12px !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  background-color: var(--livis-white-color) !important;
  height: 90%;
  display: flex;
  justify-content: center;
  padding-left: 1rem !important;
  max-width: 95%;
}

.livis-report-reset-filter-btn {
  width: 100% !important;
  border-radius: 1rem !important;
  padding: 2% 0% !important;
  font-family: "Noto Sans SemiBold" !important;
  line-height: 1.56 !important;
  letter-spacing: 0.01125rem !important;
  color: var(--livis-color-background) !important;
  background-color: var(--livis-color-primary-blue) !important;
  font-size: 1.125rem !important;
  text-transform: none !important;
  // max-width: 95%;
}

.livis-resport-rreset-filter-btn-wrap button {
  margin: 0px !important;
}

.livis-report-filter-wrap .livis-frequency-select-form {
  // max-width: calc(100% - 1rem) !important;
}

.reports-card-wrap {
  background-color: transparent;
  padding-top: 1.312rem !important;
  padding-inline: 4.5rem !important;
}

.reports-card {
  border-radius: 1rem !important;
  background: var(--livis-color-background-white);
  box-shadow: 0px 2px 4px -2px rgba(168, 168, 168, 0.35),
    0px -2px 4px -2px rgba(168, 168, 168, 0.15),
    0px 4px 8px -4px rgba(168, 168, 168, 0.15),
    0px 8px 16px -8px rgba(168, 168, 168, 0.15);
  padding: 1rem 1.5rem !important;
}

.livis-reports-tabs-wrap {
  display: flex !important;
  align-items: center !important;
}

.livis-reports-tabs-wrap div {
  width: 50% !important;
  text-align: center !important;
}

.livis-reports-analytics-tab,
.livis-reports-report-tab {
  border-radius: 12px 0px 0px 12px;
  border: 1px solid rgba(140, 146, 224, 0.4);
  background: var(--livis-white-color);
  box-shadow: 0px 2px 4px 0px rgba(168, 168, 168, 0.25);
  padding: 0.75rem 1.5rem !important;
  color: var(--livis-gray-dark-font-color);
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.livis-reports-report-tab {
  border-radius: 0px 12px 12px 0px;
}

.livis-reports-report-tab.active {
  border-radius: 0px 12px 12px 0px;
  border: 1px solid rgba(140, 146, 224, 0.4);
  background: var(--livis-white-color);
  box-shadow: 0px -3px 0px 0px var(--livis-color-primary-dblue) inset,
    0px 2px 4px 0px rgba(168, 168, 168, 0.35),
    0px 1px 2px 0px rgba(168, 168, 168, 0.25);
  color: var(--livis-color-primary-dblue);
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 500;
}

.livis-reports-analytics-tab.active {
  border-radius: 12px 0px 0px 12px;
  border: 1px solid rgba(140, 146, 224, 0.4);
  background: var(--livis-white-color);
  box-shadow: 0px -3px 0px 0px var(--livis-color-primary-dblue) inset,
    0px 2px 4px 0px rgba(168, 168, 168, 0.25),
    0px 2px 4px 0px rgba(168, 168, 168, 0.35);
  color: var(--livis-color-primary-dblue);
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 500;
}

.livis-reports-part-seach-input-wrap {
  border-radius: 12px;
  background: var(--livis-report-part-search-input-bg);
  position: relative;
  margin-left: 1.4rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 0.5rem !important;
  width: 100% !important;
  // padding-block: .5rem !important;
}

.livis-reports-part-search-input,
.livis-reports-part-search-input:focus,
.livis-reports-part-search-input:focus-visible,
.livis-reports-part-search-input:active {
  min-width: 15rem !important;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  // padding: .5rem 1rem .5rem 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--livis-color-primary-dblue);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}



.livis-reports-part-search-input-arrow-wrap {
  border-radius: 8px;
  background: var(--livis-color-background-white);
  padding: 0.25rem 0.5rem !important;
  position: absolute;
  top: 50% !important;
  right: 0.5rem !important;
  transform: translate(0%, -50%);
}

.livis-reports-part-search-input-arrow-wrap img {
  cursor: pointer;
}

// input.livis-reports-part-search-input {
//   padding-block: 1rem !important;
// }

input.livis-reports-batch-search-input {
  padding-block: 0rem !important;
}

.livis-reports-autocomplete {
  padding-top: 0.75rem !important;
  width: 100% !important;
}

.livis-reports-autocomplete-input {
  padding-bottom: 0.75rem !important;
}

.livis-report-select-batch-input {
  // max-width: 60%;
  justify-content: flex-start !important;
  min-width: 100% !important;
  overflow: hidden;
  // margin-left: 3rem;
}

.livis-reports-download-btn {
  border-radius: 50px !important;
  background: var(--livis-color-primary-blue) !important;
  color: var(--livis-reports-download-btn-color) !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  text-transform: capitalize !important;
  padding: 0.75rem 1rem !important;
  /* 150% */
}

.livis-reports-header-divider {
  height: 1px !important;
  width: 100% !important;
  background: var(--livis-form-table-background-color);
  margin-block: 1rem !important;
}

.livis-report-downoad-btn {
  margin: 0px !important;
  padding-inline: 0.5rem !important;
  width: 100% !important;
}

.livis-report-filter-btn {
  display: none !important;
}

.reports-more-filter-wrap {
  border-radius: 12px;
  border: 1px solid var(--livis-color-primary-lblue);
  max-width: fit-content !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem !important;
}

.livis-reports-no-data-wrap {
  padding: 1rem 1.5rem !important;
  height: calc(100% - 15rem) !important;
  max-height: calc(100% - 15rem) !important;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.livis-report-filter-no-data-wrap {
  position: relative !important;

  .livis-no-data-template img {
    margin-top: -30% !important;
  }
}

.livis-reports-data-wrap {
  padding: 1rem 4rem !important;
  height: calc(100% - 15rem) !important;
  max-height: calc(100% - 15rem) !important;
  overflow: auto;
}

.livis-reports-analytics-wrap {
  padding: 1rem 2rem !important;
  height: calc(100% - 4rem) !important;
  max-height: calc(100% - 4rem) !important;
  overflow: auto;
}

.livis-reports-no-data-heading {
  color: var(--livis-color-primary-gray);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 166.667% */
  letter-spacing: 0.7px;
  text-align: center;
  padding-block: 2rem 1rem !important;
}

.livis-reports-no-data-title {
  color: var(--livis-instruction-secondary-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 166.667% */
  letter-spacing: 0.7px;
  text-align: center;
}

.livis-part-name-wrapper {
  min-width: 35% !important;
  max-width: 35% !important;
}

.livis-part-name-wrapper p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.livis-reports-part-number-wrap p:nth-child(1) {
  color: var(--livis-color-primary-gray);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
  /* 133.333% */
  letter-spacing: 0.15px;
  margin: 0px !important;
  word-break: break-all;
}

.livis-reports-part-number-wrap p:nth-child(2) {
  color: var(--livis-gray-font-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 171.429% */
  letter-spacing: 0.15px;
  margin: 0px !important;
}

.livis-reports-part-number-wrap {
  padding-inline: 1rem !important;
}

.livis-reports-header-divider-vertical {
  width: 1px !important;
  height: 50% !important;
  background: var(--livis-manage-data-wrap-border);
}

.livis-report-preview-upper-body-wrap {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--neutral-colors-white, --livis-white-color);
  box-shadow: 0px 2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2),
    0px 1px 2px -1px rgba(168, 168, 168, 0.35),
    0px -1px 2px -1px rgba(168, 168, 168, 0.25);
  padding-bottom: 1rem !important;
  padding-right: 1rem !important;
  margin-inline: 0px !important;
  width: 100% !important;
}

.livis-report-preivew-upper-individual-block {
  border-radius: 20px;
  border: 1px solid rgba(165, 191, 255, 0.45);
  padding: 1rem !important;
  height: 100% !important;
}

.livis-report-preview-part-name-wrap p:nth-child(1) {
  color: var(--livis-gray100-font-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
}

.livis-report-preview-part-name-wrap p:nth-child(2) {
  color: var(--livis-color-primary-dblue);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0px !important;
  word-break: break-all !important;
  word-break: break-all !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100% !important;
}

.livis-report-preview-header-card-items-wrap {
  align-items: center !important;
  display: flex !important;
}

.livis-report-preview-part-no-wrap p:nth-child(1) {
  color: var(--livis-instruction-secondary-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
}

.livis-report-preview-part-no-wrap p:nth-child(2) {
  color: var(--livis-color-primary-gray);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100% !important;
}

.livis-reports-preview-hman-verification-img {
  object-fit: cover !important;
  border-radius: 20px !important;
  height: 5.75rem !important;
}

.livis-reports-preview-hman-verification-img-wrap {
  position: relative;
}

.livis-reports-preview-hman-verification-text {
  position: absolute;
  width: max-content !important;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.livis-operator-panel-info-wrap .livis-reports-preivew-accordian-wrap>div {
  border-radius: 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  background: var(--neutral-colors-white, #ffffff) !important;
  box-shadow: 0px 2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2),
    0px 1px 2px -1px rgba(168, 168, 168, 0.35),
    0px -1px 2px -1px rgba(168, 168, 168, 0.25) !important;
  margin-top: 0px !important;
}

.livis-report-preview-accordian-status-wrap {
  border-radius: 20px;
  border: 1px solid rgba(175, 179, 233, 0.45);
}

.livis-report-preview-accordian-status {
  padding: 1rem 1.5rem !important;
}

.livis-report-preview-accordian-status p:nth-child(1) {
  color: var(--livis-color-primary-dblue);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
}

.livis-report-preview-accordian-status p:nth-child(2).rejected {
  color: var(--livis-color-secondary-red);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 128.571% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
}

.livis-report-preview-accordian-status p:nth-child(2).accepted {
  color: var(--livis-operator-accepted-color);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  /* 128.571% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
}

.livis-report-preview-accordian-confidence {
  border-radius: 20px;
  padding: 1rem 1.5rem 0 0.5rem !important;
  background: var(--livis-credit-wrapper-bg);
}

.livis-report-preview-accordian-confidence p:nth-child(1) {
  color: var(--livis-color-primary-gray);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
}

.livis-report-preview-accordian-confidence p:nth-child(2) {
  color: var(--livis-gray100-font-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 114.286% */
  margin: 0px !important;
  margin-bottom: 0.25rem !important;
  word-break: break-all !important;
}

.livis-report-preivew-camera-feed-wrap {
  height: 63vh !important;
  position: relative;
  // background-color: #00000050;
  margin-top: 1rem !important;
}

.livis-reports-panel-preivew-info-wrap {
  padding-left: 1rem !important;
}

.livis-report-preivew-camera-feed-wrap .livis-report-preview-feed {
  height: 63vh !important;
  object-fit: inherit !important;
  border-radius: 20px !important;
  background-color: var(--livis-black-font-color);
}

.livis-report-preview-mini-feed {
  object-fit: cover !important;
  border-radius: 20px !important;
}

.livis-reports-preview-btn-wrap {
  margin-top: 0px !important;
}

.livis-report-preivew-camera-feed-wrap .livis-report-preview-chevron-left-icon {
  position: absolute;
  width: 48px !important;
  height: 48px !important;
  top: 45%;
  left: 3rem;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.livis-report-preivew-camera-feed-wrap .livis-report-preview-chevron-right-icon {
  position: absolute;
  width: 48px !important;
  height: 48px !important;
  top: 45%;
  right: 3rem;
  transform: translate(50%, -50%);
  cursor: pointer;
}

.livis-report-preview-feed-status.accepted {
  border-radius: 12px;
  background: rgba(16, 130, 52, 0.75);
  padding: 0.5rem 1rem !important;
  color: var(--livis-reports-download-btn-color);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
}

.livis-report-preview-feed-status.rejected {
  border-radius: 12px;
  padding: 0.5rem 1rem !important;
  color: var(--livis-reports-download-btn-color);
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.15px;
  background: rgba(181, 0, 0, 0.75);
}

.livis-report-preview-feed-status-position-wrap {
  position: absolute;
  top: 2rem !important;
  left: 2rem !important;
  display: flex;
}

.livis-report-preview-switch-wrap {
  background-color: var(--livis-flag-update-input-bg);
  color: var(--livis-white-color) !important;
  border-radius: 8px !important;
  display: flex;
  position: absolute;
  top: 2rem !important;
  right: 2rem !important;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem !important;
  padding-block: 0.25rem !important;
}

.livis-report-preview-mini-feed-status {
  position: absolute;
  top: 0.5rem !important;
  left: 0.5rem !important;
  display: flex;
}

.livis-report-preview-mini-feed-status .accepted {
  border-radius: 12px;
  background: rgba(16, 130, 52, 0.75);
  padding: 0.5rem 1rem !important;
  color: var(--livis-reports-download-btn-color);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.15px;
}

.livis-report-preview-mini-feed-status .position {
  border-radius: 8px;
  background: rgba(51, 51, 51, 0.75);
  color: var(--livis-reports-download-btn-color);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.15px;
  padding: 0.3rem 0.75rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem !important;
}

.livis-report-preview-mini-feed-status .rejected {
  border-radius: 12px;
  background: rgba(181, 0, 0, 0.75);
  padding: 0.5rem 1rem !important;
  color: var(--livis-reports-download-btn-color);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.15px;
}

.livis-reports-preview-feed-posiiton-wrap {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 60px;
  background: rgba(31, 31, 31, 0.7);
  padding-block: 0.25rem !important;
  display: flex;
}

.livis-reports-preview-feed-posiiton {
  cursor: pointer;
  border-radius: 60px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(31, 31, 31, 0.8);
  color: var(--livis-white-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0.3rem 1rem !important;
  margin-inline: 0.25rem !important;
}

.livis-reports-preview-feed-posiiton.active {
  border-radius: 60px;
  border: 1px solid var(--livis-white-color);
  background: rgba(8, 87, 222, 0.8);
}

.livis-report-preview-mini-feed-defects-wrap {
  padding-inline: 1rem !important;
  padding-bottom: 1rem !important;
}

.livis-report-preview-mini-feed-wrap {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: var(--neutral-colors-white, --livis-white-color);
  box-shadow: 0px 2px 4px -2px rgba(168, 168, 168, 0.25),
    0px 4px 8px -4px rgba(168, 168, 168, 0.2),
    0px 1px 2px -1px rgba(168, 168, 168, 0.35),
    0px -1px 2px -1px rgba(168, 168, 168, 0.25);
  position: relative;
}

.print-div-hide {
  display: none !important;
}

.livis-result-images-view-btn-wrap {
  position: absolute;
  top: 2rem;
  right: 25%;
  justify-content: center;
  align-items: center;
  padding-inline: 1rem !important;
  padding-block: 0.25rem !important;
  background-color: var(--livis-flag-update-input-bg);
  color: var(--livis-white-color) !important;
  border-radius: 8px !important;
  display: flex;
}

.livis-report-result-image-wrap {
  width: 100%;
  height: 75vh;
  background-color: var(--livis-black-font-color);
  border-radius: 1rem !important;
  position: relative;
}

.livis-resport-result-pagination-wrap {
  position: absolute;
  top: 50%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
}

.livis-resport-result-pagination-wrap img {
  cursor: pointer;
}

.livis-report-result-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.livis-reports-parts-autocomplete {
  overflow-x: hidden !important;
}

.livis-report-more-icon {
  cursor: pointer;
}

.livis-reports-analytics-text-one {
  color: var(--livis-report-analytics-text-one-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.livis-reports-analytics-text-two {
  color: var(--livis-color-primary-gray);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
}

.livis-reports-analytics-text-three {
  color: var(--livis-gray-font-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
}

.livis-total-inspection-counts-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.livis-next-prev-page-icon {
  cursor: pointer;
}

.divider {
  width: 90% !important;
  margin: auto !important;
  height: 1px;
  background-color: var(--livis-dashboard-header-right-box-border) !important;
  margin-block: 1rem !important;
}

.statistics-count-wrap-one {
  position: relative;
  border-radius: 16px;
  border: 1px solid var(--livis-statistics-count-wrap-border);
  background: var(--neutral-colors-white, --livis-white-color);
  box-shadow: 0px 2px 2px -1px rgba(168, 168, 168, 0.08);
}

.statistics-count-wrap-two {
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(11, 144, 54, 0.25);
  background: var(--neutral-colors-white, --livis-white-color);
  box-shadow: 0px 2px 2px -1px rgba(168, 168, 168, 0.08);
}

.statistics-count-wrap-three {
  position: relative;
  border-radius: 16px;
  border: 1px solid rgba(196, 51, 51, 0.25);
  background: var(--neutral-colors-white, --livis-white-color);
  box-shadow: 0px 2px 2px -1px rgba(168, 168, 168, 0.08);
}

.statistics-card {
  padding: 10px 50px 10px 16px !important;
  margin-inline: 0.5rem !important;
}

.statistics-card img {
  position: absolute;
  top: 0px !important;
  right: 0px;
  z-index: 100;
}

.major-pill {
  border-radius: 6px;
  border: 0.5px solid rgba(188, 25, 25, 0.25);
  background: linear-gradient(180deg,
      rgba(247, 229, 229, 0.25) 0%,
      rgba(247, 229, 229, 0) 100%);
  padding: 2px 10px !important;
  color: var(--livis-error-color);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 166.667% */
  margin-inline: 0.25rem !important;
}

.minor-pill {
  border-radius: 6px;
  border: 0.5px solid rgba(76, 57, 247, 0.25);
  background: rgba(76, 57, 247, 0.05);
  padding: 2px 10px !important;
  color: var(--livis-minor-pill-color);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  margin-inline: 0.25rem !important;
  line-height: 20px;
  /* 166.667% */
}

.livis-report-filter-dropdown-date-range {
  border-radius: 12px !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  height: 100% !important;
  background-color: var(--livis-white-color) !important;
  display: flex;
  align-items: center;
  padding-left: 0.875rem !important;
}

.livis-report-filter-dropdown {
  position: relative;
}

.livis-report-filter-cross-wrap {
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-45%);
  cursor: pointer;
}

.livis-report-filter-cross-calender-wrap {
  right: 0.5rem !important;
}

.livis-select-date-text {
  margin-top: 1rem !important;
  width: 100% !important;
  position: relative;
  // padding-right: 1rem !important;
  margin-top: 0px !important;
}

.livis-select-date-text .livis-frequency-select-form {
  cursor: pointer;
  // border: 1px solid var(--livis-color-primary-dblue);
  // margin-right: 1rem !important;
  padding: 0.5rem 1rem !important;
  border-radius: 50px;
  padding-left: 0px !important;
  // color: var(--livis-color-primary-dblue);
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  max-width: 100% !important;
}

.livis-calender-wrap {
  display: flex;
  align-items: center;
}

.livis-calender-wrap .livis-frequency-select-form {
  margin-top: 0.25rem !important;
}

.calender {
  position: absolute;
  top: 4rem !important;
  left: 0rem !important;
  z-index: 100 !important;
}

.livis-calender-btn-wrap {
  display: flex;
  justify-content: end;
}

.livis-calend-btns-wrap {
  background-color: var(--livis-white-color) !important;
  padding-bottom: 2rem !important;
  padding-top: 1rem !important;
  justify-content: flex-end;
  padding-right: 2rem !important;
}

.reports-posiiton-wrap {
  background-color: var(--livis-flag-update-input-bg) !important;
  color: var(--livis-white-color) !important;
  padding: 0.5rem 1rem !important;
  border-radius: 0.5rem !important;
  margin-right: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid;
}

///////MUI css
.livis-report-filter-dropdown .MuiOutlinedInput-root {
  border-radius: 12px !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  background-color: var(--livis-white-color) !important;
}

.livis-reports-preview-hman-verification-verify .MuiInputBase-root {
  height: 100% !important;
  min-height: 5.75rem !important;
}

.livis-report-filter-dropdown .MuiOutlinedInput-root .demo-simple-select-standard {
  padding-block: 1rem !important;
}

.livis-report-dearchable-dropdown label,
.livis-report-dearchable-dropdown fieldset .livis-report-filter-dropdown svg,
.livis-report-dearchable-dropdown .MuiOutlinedInput-notchedOutline,
.livis-report-dearchable-dropdown .MuiSvgIcon-root {
  display: none !important;
}

.livis-reports-preview-hman-verification-verify-wrap,
.livis-reports-preview-hman-verification-verify {
  min-height: 100% !important;
}

.calender .rdrDefinedRangesWrapper,
.calender .rdrDateDisplayWrapper {
  display: none !important;
}

.livis-reports-position-carousal {
  height: 100% !important;
}

.livis-reports-no-feed {
  border-radius: 20px !important;
}

.livis-frequency-select-form.livis-report-filter-dropdown.livis-batch-size-dropdownFilter {
  border-radius: 12px !important;
  border: 1px solid var(--livis-color-primary-lblue) !important;
  background-color: var(--livis-white-color) !important;
  // height: 90%;
  display: flex;
  justify-content: center;
  padding-left: 1rem !important;
  max-width: calc(100% - 16px) !important;
  min-width: calc(100% - 16px) !important;
  // padding-block: 0.65rem !important;
}

.livis-report-preview-position-wise-accordian {
  display: none !important;
}

.livis-report-preview-image-overlay-items {
  display: flex !important;
}

.livis-report-preview-rejected-images-wrap {
  background: var(--livis-preview-rejected-img-bg);
  border: 1px solid var(--livis-report-preview-rejected-images-wrap-border);
  padding: 0.5rem 1rem !important;
  border-radius: 1rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--livis-color-secondary-red);
  cursor: pointer;
}

.livis-report-preview-accepted-images-wrap {
  background: var(--livis-report-preview-accepted-images-wrap-bg);
  border: 1px solid var(--livis-report-preview-accepted-images-wrap-border);
  padding: 0.5rem 1rem !important;
  border-radius: 1rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--livis-success-color);
  cursor: pointer;
}

.livis-rej-acc-image-count {
  font-size: 1.5rem !important;
}

.livis-rej-acc-image-title {
  font-size: 0.875rem !important;
}


.livis-operator-report-card-padding {
  padding: 30px !important;
}

.livis-report-container-padding {
  padding: 30px;
  padding-bottom: 20px;
}


.livis-report-rejected-image {
  margin-top: 80px;
  margin-left: 80px;
  margin-bottom: 40px;
}

.livis-report-verification-img-container {
  margin-bottom: 50px;
}

.livis-modal-cancel-danger-btn-padding {
  margin-left: 0px;
  padding: 5px 15px;
}

.livis-reports-preview-hman-verification-verify-postion {
  position: relative;
}

.livis-report-preview-span {
  padding-right: 16px;
}

.livis-export-report-container {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    text-align: center;
  }
}

.livis-report-preview-span {
  min-width: 310px;
  height: 400px;
  margin: 0 auto;
}

// .livis-report-defects-drilldown-container {
//   flex: 1;
//   text-align: center;
//   position: relative;
//   color: #9d2929;
// }

.livis-reports-defects-heading {
  position: absolute;
  left: 16px;
}

.livis-reports-features-heading {
  position: absolute;
  left: 16px;
}

.livis-reports-header-download-btn-avatar {
  width: 12px !important;
  height: 12px !important;
}

.livis-report-no-preview-background-img {
  width: calc(100% - 0.5rem) !important;
  height: calc(100% - 0.5rem) !important;
  margin: 0.25rem !important;
  border-radius: 0.5rem !important;
}

.livis-report-delete-btn-padding {
  margin-left: 0px;
  padding: 5px 15px;
}

.livis-report-filter-position {
  position: relative !important;
}

.livis-report-livis-view-text {
  padding-right: 16px !important;
}

.livis-report-computer-generated-text-container {
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.livis-report-computer-generated-text {
  text-align: center !important;
}

.livis-report-no-records-found {
  text-align: center !important;
  padding: 225px !important;
}

.livis-report-tooltip-inspections {
  background-color: var(--livis-white-color) !important;
  border: 1px solid var(--livis-analytics-custom-tooltip-borders) !important;
  padding: 10px !important;
  border-radius: 4px !important;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2) !important;
}

.livis-report-tooltip-label {
  font-weight: bold !important;
}

.livis-report-tooltip-desc {
  margin: 0px !important;
  color: var(--livis-analytics-custom-tooltip-desc-color) !important;
}

.livis-report-no-of-inspections {
  text-anchor: middle !important;
}

.livis-report-tooltip-accepted {
  margin: 0px !important;
  color: var(--livis-analytics-custom-tooltip-accepted-color) !important;
}

.livis-report-tooltip-rejected {
  margin: 0px !important;
  color: var(--livis-analytics-custom-tooltip-rejected-color) !important;
}

.livis-report-defect-chart-padding {
  text-align: center !important;
  padding: 175px !important;
}

.livis-report-chart-container {
  flex: 1 !important;
  text-align: center !important;
  position: relative !important;
  color: var(--livis-report-chart-container-color) !important;
}

.livis-reports-chart-defects {
  position: absolute !important;
  left: 16px !important;
}

.livis-report-feature-chart-container {
  flex: 1 !important;
  text-align: center !important;
  position: relative !important;
  color: var(--livis-report-drill-down-color) !important;
}

.livis-report-feature-chart {
  position: absolute !important;
  left: 16px !important;
}

.livis-report-preview-upper-body-wrap {
  margin: 0 !important;
}

.livis-report-preview-part-no-wrap {
  overflow: hidden !important;
}

// Reports Module CSS ends //////////////

// analytics css starts
.livis-analytics-wrapper {
  margin-top: 1rem !important;
  min-height: 70vh;
  margin-right: 2rem !important;
}

.livis-no-label-wrapper {
  min-height: 80vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem !important;
}

.analytics-heading-wrap p {
  text-align: center !important;
  font-family: "Noto Sans SemiBold";
}

.livis-drilldown-graph-heading {
  font-family: "Noto Sans Regular";
  font-size: 1.125rem;
  color: var(--livis-gray-font-color);
}

.livis-drilldown-graph-hamburger {
  font-family: "Noto Sans SemiBold";
  font-size: 1.25rem;
  color: var(--livis-drilldown-graph-color);
  cursor: pointer;
}

.livis-drilldown-graph-hamburger.active {
  color: var(--livis-color-primary-gray);
  font-family: "Noto Sans Bold";
}

.livis-drilldown-defect-feature-graph-wrap {
  display: flex;
  border-radius: 25px !important;
  border: 1px solid var(--livis-drill-down-defect-graph-border) !important;
  margin-bottom: 50px;
}

.livis-drilldown-defect-feature-title {
  position: absolute;
}

.livis-analytics-hichart {
  padding-top: 50px;
  align-items: flex-start;
}


.livis-analytics-no-records-heading {
  text-align: center;
  padding: 225px;
}

.livis-analytics-custom-tooltip {
  background-color: var(--livis-white-color);
  border: 1px solid var(--livis-analytics-custom-tooltip-border);
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

  .label {
    font-weight: bold;
  }

  .desc {
    margin: 0px;
    color: var(--livis-analytics-custom-tooltip-desc-color);
  }

  .accepted {
    margin: 0px;
    color: var(--livis-analytics-custom-tooltip-accepted-color);
  }

  .rejected {
    margin: 0px;
    color: var(--livis-analytics-custom-tooltip-rejected-color);
  }
}

.livis-analytics-bar-chart-label {
  text-anchor: middle;
}

.livis-analytics-defect-chart-no-data {
  text-align: center;
  padding: 175px;
}

.livis-analytics-pie-chart-font {
  font-weight: bold;
}

.livis-analytics-line-chart-defects {
  flex: 1;
  text-align: center;
  position: relative;
  color: var(--livis-report-chart-container-color);
}

.livis-analytics-line-chart-h3 {
  position: absolute;
  left: 16;
}

.livis-analytics-line-chart-features {
  flex: 1;
  text-align: center;
  position: relative;
  color: var(--livis-report-drill-down-color);
}

.livis-legend-circle-green {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-add-part-camera-title-success);
  margin-right: 0.75rem;
}

.livis-legend-circle-red {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-add-part-camera-title-error);
  margin-right: 0.75rem;
}

.livis-legend-circle-yellow {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-color-secondary-yellow);
  margin-right: 0.75rem;
}

.livis-legend-circle-orange {
  width: 1rem;
  height: 1rem;
  border-radius: 2rem;
  background-color: var(--livis-color-secondary-orange);
  margin-right: 0.75rem;
}

.livis-data-analytics-legend-wrap {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0 !important;

    & span:nth-child(1) {
      margin-right: 0.5rem !important;
    }

    & span:nth-child(2) {
      margin-right: 0.5rem !important;
    }

    & span:nth-child(3) {
      margin-right: 0.5rem !important;
    }
  }
}

//analytics css ends

//settings css starts

.livis-settings-sidebar-wrap {
  margin-left: 2.25rem !important;
  margin-top: 1.25rem !important;

  & .livis-settings-menu-wrap {
    width: 100% !important;
  }

  & .livis-settings-card-wrap {
    height: 6rem !important;
  }

  & .livis-settings-menu-wrap-image {
    height: 100% !important;
    object-fit: cover !important;
  }

  & .livis-settings-tab-wrap {
    margin-top: 1rem !important;
    padding: 0px !important;
    padding-bottom: 1.5rem !important;
  }

  & .livis-sidebar-accordian .MuiAccordionSummary-root {
    min-height: fit-content !important;
    margin-inline: 0.75rem !important;
    padding-inline: 0.75rem !important;
  }

  & .livis-sidebar-accordian .MuiAccordionSummary-root.active {
    border: 1px solid var(--livis-color-primary-dblue) !important;
    border-radius: 1rem !important;
    background-color: var(--livis-part-box-background) !important;
  }

  & .livis-settings-menu-item-image {
    padding-block: 1rem !important;
    padding-left: 0rem !important;
  }

  & .livis-operator-panel-accordian-sub-menu-active,
  .livis-operator-panel-accordian-sub-menu {
    margin-left: 3rem !important;
    padding-inline: 0.75rem !important;
  }

  & .livis-operator-panel-single-menu-item,
  .livis-operator-panel-single-menu-item-active {
    margin-inline: 0.75rem !important;
    padding-inline: 0.75rem !important;
    width: calc(100% - 1.5rem) !important;
    max-width: calc(100% - 3rem) !important;
  }

  & .livis-operator-panel-single-menu-item img,
  .livis-operator-panel-single-menu-item-active img {
    padding-left: 0px !important;
  }
}

.livis-settings-tab-wrap>div.livis-settings-heading {
  width: 100% !important;
  display: none !important;
}

.livis-settings-part-main-wrap {
  padding-inline: 3.25rem !important;
  padding-top: 2rem !important;
}

.livis-settings-menu-wrap {
  position: relative;
}

.livis-settings-menu-wrap-image {
  // padding: 1rem !important;
  cursor: pointer;
  width: calc(100%) !important;
  position: absolute;
  top: 0px;
  right: 0px;
}

.livis-settings-menu-heading {
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding: 1rem !important;
  color: var(--livis-black-font-color);
  padding-block: 3rem 1.8rem !important;
}

.livis-settings-menu-wrap .MuiAccordion-root {
  box-shadow: none !important;
  width: 100% !important;
}

.livis-settings-menu-wrap .MuiAccordionDetails-root {
  padding: 0px !important;
}

.livis-settings-menu-wrap .MuiAccordionSummary-content {
  margin-bottom: 0rem !important;
  margin-top: 0rem !important;
}

.livis-settings-menu-wrap .MuiAccordion-root::before {
  height: 0px !important;
}

.livis-settings-menu-item-image {
  padding: 0.5rem !important;
}

.livis-settings-menu-wrap .MuiAccordionSummary-content {
  justify-content: flex-start !important;
}

.livis-settings-menu-wrap .livis-operator-panel-accordian-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 20px;
  color: var(--livis-color-primary-gray);
  text-shadow: 0px 0px 16px var(--livis-white-color);
}

.livis-settings-add-role-avatar {
  width: 32px !important;
  height: 32px !important;
}

//settings css ends

// user management css starts
.livis-hide-grid {
  display: none !important;
}

.livis-user-data-wrap {
  min-height: 75vh !important;
}

.livis-user-no-data {
  margin-top: 0px !important;
  height: 65vh !important;
}

.livis-user-profile-wrap {
  height: 86vh !important;
}

.livis-profile-card-grid {
  min-width: 100% !important;
}

.livis-profile-img>img {
  width: 100% !important;
  height: 270px !important;
}

.livis-information-profile {
  max-height: 270px !important;
}

.livis-profile-text-0 {
  margin: 5px 0px 0px 0px;
}

#container {
  position: relative;
}

.livis-profile-activity {
  padding-top: 10%;
}

.livis-profile-details {
  padding-top: 5%;
}

.livis-credit-btn {
  border: 0.0625rem solid var(--livis-color-primary-lblue);
  border-radius: 6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: unset !important;
  float: right;
}

.livis-profile-header-wrap {
  justify-content: center;
  background-color: var(--livis-color-background-white) !important;
  padding: 1rem 3rem 1.75rem 3rem;
  width: 100%;
}

.livis-edit-profile-btn {
  border: 0.0625rem solid var(--livis-color-primary-lblue);
  border-radius: 6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: unset !important;
}

.livis-input-field-padding {
  padding-right: 10% !important;
}

// ul {
//     columns: 2;
//     -webkit-columns: 2;
//     -moz-columns: 2;
//     margin-top: 2rem !important;
//   }

//.livis-reset-password-instruction

.livis-reset-password-instruction p {
  font-size: 0.9375rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
  margin-bottom: 0.5em;
  margin-top: 0.6rem !important;
}

th,
td {
  width: 150px;
  text-align: center;
  // border:1px solid black;
  padding: 12px;
}

th {
  background-color: var(--livis-color-background) !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
  background-color: var(--livis-color-background);
  border: 1px solid var(--livis-audit-log-wrap-border) !important;
  border-radius: 0.5rem !important;
}

tr {
  background-color: var(--livis-white-color);
  border: 1px;
}

.MuiPaper-root {
  box-shadow: 10px 10px 8px 10px transparent;
}

.MuiToolbar-root {
  // display: none;
}

.MuiTable-root {
  // display: block !important;
}

.livis-reset-password-instruction-adduser p {
  font-size: 0.9375rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
  margin-bottom: 0.5em;
  margin-top: 0rem !important;
}

.livis-add-user-form {
  padding-top: 3%;
  padding-left: 3%;
}

.livis-add-user-form-grid {
  padding-left: 5%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.livis-add-user-form-grid>p {
  justify-content: center;
  display: content;
}

.livis-add-user-form-sign {
  padding-top: "0.3rem";
  padding-right: "0.5rem";
}

.livis-add-user-form-body {
  max-height: 65vh !important;
  // overflow: auto !important;
}

.livis-data-table-operation-button-wrapper>button {
  margin: 0.1rem !important;
}

.livis-usermanagement-profile-img>img {
  box-sizing: border-box;

  width: 132px;
  height: 132px;
  left: 20px;
  top: 16px;

  background: linear-gradient(0deg, var(--livis-part-box-background), var(--livis-part-box-background));
  border: 1px solid var(--livis-border-color);
  border-radius: 16px;
}

.livis-usermanagement-profile-img-list>img {
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  left: 20px;
  top: 16px;

  background: linear-gradient(0deg, var(--livis-part-box-background), var(--livis-part-box-background));
  border: 1px solid var(--livis-border-color);
  // border-radius: 16px;
}

.livis-usermanagement-user-name {
  // font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  // white-space: nowrap !important;
  margin-bottom: 0.5rem !important;
  max-width: 100%;
}

.livis-usermanagement-user-role {
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;

  /* or 125% */
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;

  color: var(--livis-instruction-secondary-color);
}

.livis-user-information-box {
  box-sizing: border-box;
  border: 1px solid var(--livis-border-color);
  border-radius: 16px;
}

.livis-user-information {
  font-weight: 500;
  font-size: 0.938rem;
  line-height: 39px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.livis-user-information span {
  cursor: pointer;
}

.livis-user-information span.disbaled {
  color: var(--livis-user-information-text-color);
  font-size: 0.75rem;
}

.livis-user-information>img {
  padding-right: 8px;
}

.livis-user-active {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-active-color);
}

.livis-user-inactive {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-inactive-color);
}

.livis-user-location-inactive {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-inactive-color);
}

.livis-user-location-active {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-active-color);
}

.livis-usermanagement-user-location {
  padding-top: 1.4rem;
  padding-bottom: 1.2rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
}

.livis-usermanagement-user-activity {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  line-height: 20px !important;
  letter-spacing: 0.15px;
  color: var(--livis-gray-font-color) !important;
}



.livis-inpection-card-list {
  padding: 0 !important;
}

.livis-user-nouser-block {
  background-color: var(--livis-white-color);
  height: 200px;
  width: 200px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 100%;
}

.livis-user-tour-box {
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;

  /* identical to box height, or 188% */
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;

  color: var(--livis-color-primary-blue);
  margin-left: 1rem;
}

.livis-user-add-button {
  width: 8.2rem !important;
}

.livis-stepper-steps {
  padding: 0 !important;
  // margin-left: 16rem;
  // margin-right: 18rem;
}

.livis-user-adduser-form-subheading {
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;

  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;

  color: var(--livis-color-primary-gray);
}

.livis-create-role-permission-block {
  background-color: var(--livis-create-role-permission-block-bg);
  border-radius: 0.5rem !important;
  margin-top: 1.5rem !important;
  padding: 1rem !important;
}

.livis-list-wrap .livis-app-card {
  padding-left: 1rem !important;
}

.livis-app-name {
  font-size: 1.25rem;
  font-family: "Noto Sans SemiBold";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.livis-app-permission-count {
  font-size: 1rem;
  font-family: "Noto Sans SemiBold";
}

.livis-app-permission-heading {
  font-size: 0.875rem;
  font-family: "Noto Sans Regular";
  color: var(--livis-gray-font-color);
}

.livis-app-type-active {
  width: 0.375rem;
  background-color: var(--livis-active-color);
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  height: 10rem !important;
}

.livis-app-type-inactive {
  width: 0.375rem;
  background-color: var(--livis-inactive-color);
  position: absolute;
  top: 0px !important;
  left: 0px !important;
  height: 10rem !important;
}

.livis-create-role-permission-block {
  max-height: 25vh !important;
  overflow: auto;
}

.livis-add-role-modal .livis-stepper-button {
  margin-top: 0rem !important;
}

.livis-permission-app-search-input-wrap {
  max-width: 100% !important;
}

.livis-permission-app-search-input {
  max-width: 100% !important;
  border: none !important;
  border-bottom: 1px solid var(--livis-black-font-color) !important;
  background-color: transparent !important;
  font-family: "Noto Sans SemiBold" !important;
  color: var(--livis-black-font-color) !important;
  font-size: 1rem !important;
  margin-top: 0.4rem !important;
}

.credit-action {
  .livis-usecase-stepper-button-grid-margin {
    margin: 20px !important;
  }
}

.livis-permission-app-search-input:focus,
.livis-permission-app-search-input:hover {
  outline: none !important;
}

.livis-use-case-add-btn-avatar {
  width: 32px !important;
  height: 32px !important;
}

.livis-form-close-caution-container {
  display: flex !important;
  justify-content: end !important;
  margin-top: 10% !important;
}

.livis-use-case-client-details-container {
  display: flex !important;
  justify-content: space-between !important;
}

.livis-user-create-btn-avatar {
  width: 20px !important;
  height: 20px !important;
}

.livis-no-data-template-user-management {
  height: 65vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

// user management css ends


// new user management css starts
.livis-settings-sidebar-wrap {
  margin-left: 2.25rem !important;
  margin-top: 1.25rem !important;

  & .livis-settings-menu-wrap {
    width: 100% !important;
  }

  & .livis-settings-card-wrap {
    height: 6rem !important;
  }

  & .livis-settings-menu-wrap-image {
    height: 100% !important;
    object-fit: cover !important;
  }

  & .livis-settings-tab-wrap {
    margin-top: 1rem !important;
    padding: 0px !important;
    padding-bottom: 1.5rem !important;
  }

  & .livis-sidebar-accordian .MuiAccordionSummary-root {
    min-height: fit-content !important;
    margin-inline: 0.75rem !important;
    padding-inline: 0.75rem !important;
  }

  & .livis-sidebar-accordian .MuiAccordionSummary-root.active {
    border: 1px solid var(--livis-color-primary-dblue) !important;
    border-radius: 1rem !important;
    background-color: var(--livis-part-box-background) !important;
  }

  & .livis-settings-menu-item-image {
    padding-block: 1rem !important;
    padding-left: 0rem !important;
  }

  & .livis-operator-panel-accordian-sub-menu-active,
  .livis-operator-panel-accordian-sub-menu {
    margin-left: 3rem !important;
    padding-inline: 0.75rem !important;
  }

  & .livis-operator-panel-single-menu-item,
  .livis-operator-panel-single-menu-item-active {
    margin-inline: 0.75rem !important;
    padding-inline: 0.75rem !important;
    width: calc(100% - 1.5rem) !important;
    max-width: calc(100% - 3rem) !important;
  }

  & .livis-operator-panel-single-menu-item img,
  .livis-operator-panel-single-menu-item-active img {
    padding-left: 0px !important;
  }
}

.livis-settings-tab-wrap>div.livis-settings-heading {
  width: 100% !important;
  display: none !important;
}

.livis-settings-part-main-wrap {
  padding-inline: 3.25rem !important;
  padding-top: 2rem !important;
}

.livis-user-card {
  padding: 0px !important;
  height: 100% !important;
  border-radius: 0.75rem 0.75rem 0.75rem 0px !important;
}

.livis-user-card-container {
  height: 100% !important;
  align-content: flex-start !important;
}

.livis-user-card-details-main-container {
  height: calc(100% - 10.75rem) !important;
}

.livis-user-card-details-main-wrap {
  justify-content: space-between !important;
  display: flex !important;
  flex-direction: column !important;
}

.livis-user-card-list {
  border-radius: 0rem 0.75rem 0.75rem 0px !important;
}

.livis-user-card-wrap {
  height: 100% !important;
  align-content: space-between !important;
}

.livis-user-card-indicator-wrap.list {
  display: flex;
}

.livis-user-card-top-wrap {
  padding: 1rem 1.25rem 1.5rem 1.25rem !important;
  position: relative;
}

.livis-user-card-profile-name-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end !important;
}

.livis-user-card-profile-name-container {
  padding-left: 1rem !important;
}

.livis-user-card-more-icon {
  position: absolute;
  top: 1rem;
  right: 1.25rem !important;
  cursor: pointer;
}

.livis-user-card-indicator-wrap {
  position: relative;
}

.livis-user-card-indicator {
  width: 0.375rem !important;
  min-width: 0.375rem !important;
  height: 100% !important;
  background-color: var(--livis-complete-color) !important;
  // min-height: 10rem !important;
}

.livis-user-card-indicator.active {
  background-color: var(--livis-active-color) !important;
}

.livis-user-card-details-location-wrap {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.livis-user-card-details-wrap {
  border: 1px solid var(--livis-border-color);
  border-radius: 1rem !important;
  padding: 0.75rem !important;
  margin-bottom: 1.5rem !important;
  // min-height: 54% !important;
}

.livis-user-card-details-wrap.grid {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly;
  // height: -webkit-fill-available
}

.livis-user-card-details-container.grid {
  padding-bottom: 0.75rem !important;
}

.livis-user-card-details-container {
  display: flex;
  align-items: center;
}

.livis-user-card-details-wrap .livis-user-card-details-container:last-child {
  padding-bottom: 0rem !important;
}

.livis-user-card-details-container img {
  padding-right: 0.75rem !important;
}

.livis-user-card-bottom-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.25rem !important;
}

.livis-user-card-bottom-wrap button {
  min-width: 5.6875rem !important;
  margin: 0px !important;
}

.livis-user-card-bottom-details-container {
  padding-right: 1rem !important;
  // padding-bottom: 1.25rem !important;
}

.livis-user-card-name {
  font-size: 1.125rem !important;
  font-family: "Noto Sans SemiBold";
  color: var(--livis-color-primary-gray);
  line-height: 1.375rem !important;
  padding-bottom: 0.25rem !important;
}

.livis-user-card-role {
  font-size: 1rem !important;
  font-family: "Noto Sans Regular";
  color: var(--livis-inspection-type-text-color);
  line-height: 1.25rem !important;
  padding-bottom: 0.5rem !important;
}

.livis-user-card-role-heading {
  font-size: 1rem !important;
  font-family: "Noto Sans SemiBold";
  color: var(--livis-instruction-secondary-color);
}

.livis-user-card-credits {
  padding: 2px 0.5rem !important;
  font-size: 0.8125rem !important;
  font-family: "Noto Sans Bold";
  color: var(--livis-gray100-font-color);
  background-color: var(--livis-testing-card-credit-wrap);
  border-radius: 50px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: fit-content !important;
}

.livis-user-card-credits img {
  padding-right: 0.5rem !important;
}

.livis-user-card-details-container span {
  font-size: 0.9375rem !important;
  font-family: "Noto Sans Semibold";
  color: var(--livis-color-primary-gray);
  word-break: break-all !important;
  padding-left: 0.5rem !important;
}

.livis-user-card-location {
  font-size: 0.875rem !important;
  font-family: "Noto Sans Regular";
  color: var(--livis-color-primary-gray);
  padding-bottom: 0.25rem !important;
}

.livis-user-card-time {
  font-size: 0.75rem !important;
  font-family: "Noto Sans Semibold";
  color: var(--livis-gray-font-color);
}

.livis-manage-user-header {
  margin-bottom: 2rem !important;
  margin-inline: 1.5rem !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.livis-manage-user-filters-wrap,
.livis-manage-user-filter-legend-wrap,
.livis-manage-user-add-pagination-wrap,
.livis-manage-user-pagination-wrap {
  display: flex;
  align-items: center;
}

.livis-manage-data-source-filters-wrap {
  margin-right: 1rem !important
}

.livis-manage-user-filter {
  margin-right: 1rem !important;
  min-width: 8rem !important;
  max-width: 8rem !important;
}

.livis-manage-user-filter.livis-manage-data-filter {
  max-width: fit-content !important;
  min-width: 12rem !important;
}

.livis-autocomplete-filter {
  max-width: 10rem !important;
}

.livis-manage-user-legend-wrap {
  margin-left: 1.5rem !important;
}

.livis-manage-user-list-grid-wrap {
  margin-inline: 5rem !important;
}

.livis-user-card-list-indicator-img-wrap {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.livis-user-card-details-wrap.list {
  display: flex !important;
  flex-wrap: wrap !important;
  max-width: 32rem !important;
}

.livis-user-card-profile-wrap {
  display: flex;
  max-width: 22rem !important;
  min-width: 22rem !important;
}

.livis-user-card-details-wrap.list .livis-user-card-details-container {
  width: 50% !important;
}

.livis-user-card-list-wrap {
  justify-content: space-between;
}

.livis-user-card-list .livis-user-card-details-wrap {
  margin: 1rem !important;
}

.livis-user-card-list .livis-user-card-bottom-details-container {
  align-items: flex-start !important;
  display: flex;
  justify-content: center !important;
  flex-direction: column;
}

.livis-user-card-list-edit-more-wrap {
  align-items: center !important;
  display: flex;
  justify-content: center !important;
}

.livis-user-card-list-edit-more-wrap button {
  margin: 0px !important;
  min-width: 5.6875rem !important;
  margin-right: 1.25rem !important;
}

.livis-user-card-list-edit-more-wrap img {
  margin-right: 1.5rem !important;
}

.livis-user-list-card-grid {
  margin-bottom: 1rem !important;
}

.livis-manage-user-list-grid-wrap .livis-parts-display-wrap {
  display: flex !important;
}

.livis-user-card-not-provided {
  background-color: var(--livis-chip-data-background-color);
  border-radius: 0.45rem !important;
  color: var(--livis-user-card-not-provided-color) !important;
  font-size: 0.9375rem !important;
  font-family: "Noto Sans Semibold";
  padding-inline: 0.75rem 1.25rem !important;
  line-height: 1.5rem !important;
  cursor: pointer;
}

// manage role css
.livis-manage-user-add-role-wrap {
  margin-right: 1.5rem !important;
}

.livis-user-card-wrap-container {
  align-items: flex-start !important;
  align-content: flex-start !important;
  margin-top: 0rem !important;
  margin-left: 0rem !important;
  width: 100% !important;
}

.livis-role-card-list-edit-more-wrap {
  margin-block: 1.75rem !important;
}

.livis-role-card-desc-wrap {
  margin-block: 1.75rem !important;
}

.livis-role-card-profile-wrap {
  min-width: 30rem !important;
  max-width: 30rem !important;
}

.livis-permission-card-profile-wrap {
  min-width: 30rem !important;
  max-width: 30rem !important;
  word-break: break-all !important;
  display: flex;
  align-items: center;
}

.livis-permission-card-profile-wrap.livis-permission-card-profile-wrap.livis-premission-header {
  margin-left: 1.375rem !important;
}

.livis-role-card-desc-wrap {
  min-width: 45rem !important;
  max-width: 45rem !important;
}

.livis-permission-card-desc-wrap {
  min-width: 30rem !important;
  max-width: 30rem !important;
}

.livis-permission-card-app-wrap {
  min-width: 15rem !important;
  max-width: 15rem !important;
  word-break: break-all !important;
}

.livis-role-card-profile-heading-wrap {
  min-width: 30rem !important;
  max-width: 30rem !important;
  display: flex;
  align-items: center;
  margin-left: 1.375rem !important;
}

.livis-permission-card-profile-heading-wrap {
  min-width: 20rem !important;
  max-width: 20rem !important;
  display: flex;
  align-items: center;
  margin-left: 1.375rem !important;
}

.livis-role-card-list-edit-more-heading-wrap {
  min-width: 10rem !important;
  max-width: 10rem !important;
  justify-content: flex-start !important;
}

.livis-user-list-card-grid {
  width: 100% !important;
}

.livis-user-list-card-grid.role-grid {
  padding-right: 0.75rem !important;
}

.livis-create-role-permission-block .livis-add-permission-menu-item {
  padding-block: 1.25rem !important;
}

.livis-add-role-accordian-body-item {
  width: 100% !important;
}

.livis-role-card-bottom-wrap {
  padding-inline: 2.25rem !important;
  position: relative;
  display: flex;
}

.livis-role-card-indicator {
  position: relative;
  left: -2.25rem;
}

.livis-role-card-bottom-desc-wrap {
  font-size: 0.875rem !important;
  color: var(--livis-color-primary-gray) !important;
  line-height: 22px !important;
  width: 100% !important;
  // max-width: 80% !important;
}

.livis-role-card-bottom-desc {
  min-height: 5rem !important;
}

.livis-role-card-grid-edit-btn-wrap {
  display: flex;
  justify-content: flex-end !important;
}

.livis-role-card-grid-edit-btn-wrap button {
  max-width: fit-content !important;
  margin-block: 2rem 1.13rem !important;
  // padding: 0rem 1.5rem !important;
}

//new user management css ends

//new  filter header css starts
.livis-manage-inspection-header {
  margin-inline: 3rem !important;
  margin-block: 1.75rem 1rem !important;
}

.livis-station-header-search-wrap {
  min-width: 25% !important;
}

.livis-station-header-search-wrap-small {
  display: none !important;
}

.livis-manage-part-header {
  margin-top: 1.75rem !important;
}

.livis-manage-testing-header {
  margin-inline: 0rem !important;
}

//new station filter header css ends


// new product header css starts
.livis-landing-header-container {
  width: calc(100% - 3rem) !important;
  background-color: var(--livis-white-color) !important;
  padding: 1rem 1.5rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-landing-header-logo-wrap {
  margin-right: 32px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.livis-landing-header-hamburger-wrap {
  display: none !important;
  cursor: pointer;
}

.livis-landing-header-left-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.livis-landing-header-routes-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-landing-header-routes-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem !important;
  padding: 0.5rem !important;
  position: relative;
  cursor: pointer;
}

.livis-landing-header-routes-wrap .livis-landing-header-routes-text-wrap {
  display: none !important;
}

.livis-landing-header-routes-wrap.active {
  background: var(--livis-color-primary-blue);
  border-radius: 12px !important;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem !important;
}

.livis-landing-header-routes-wrap.active .livis-landing-header-routes-text-wrap {
  display: block !important;
  color: var(--livis-color-background-white);
  font-size: 0.875rem !important;
  font-family: "Noto Sans SemiBold";
}

.livis-landing-header-routes-wrap img {
  width: 40px !important;
  height: 40px !important;
}

.livis-landing-header-routes-wrap.active img {
  margin-right: 0.25rem !important;
}

.livis-landing-header-routes-wrap:hover:not(.active) {
  background: var(--livis-landing-header-routes-wrap-hover-bg);
  padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  cursor: pointer;
  border-radius: 12px !important;
}

.livis-landing-header-route-hover-tooltip-container {
  display: none !important;
}

.livis-landing-header-routes-wrap:hover:not(.active) .livis-landing-header-route-hover-tooltip-container {
  display: block !important;
  position: absolute;
  top: 4.25rem;
  left: 50%;
  transform: translate(-10%, 0%) !important;
  z-index: 1000 !important;
}

.livis-landing-header-route-hover-tooltip-ball {
  background: var(--livis-landing-header-hover-tooltip);
  width: 14px !important;
  height: 10px !important;
  border-radius: 50% 50% 0px 0px !important;
  margin-left: 1rem;
}

.livis-landing-header-route-hover-tooltip-wrap {
  border: 1px solid var(--livis-landing-header-hover-tooltip);
  background: var(--livis-color-background-white);
  padding: 0.5rem !important;
  color: var(--livis-gray100-font-color);
  font-size: 0.875rem !important;
  font-family: "Noto Sans SemiBold";
  border-radius: 0.5rem !important;
  min-width: 10rem !important;
}

.livis-landing-header-right-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .livis-landing-header-routes-wrap,
  .livis-landing-header-routes-wrap:hover {
    background: var(--livis-landing-header-right-routes-hover) !important;
    border-radius: 50% !important;
    margin-inline: 0.5rem !important;
    padding: 0.5rem !important;
  }

  .livis-landing-header-routes-wrap img {
    width: 2rem !important;
    height: 2rem !important;
    margin: 0px !important;
  }

  .livis-landing-header-route-hover-tooltip-container {
    right: -35% !important;
    left: auto !important;
    top: 3.5rem !important;
  }

  .livis-landing-header-route-hover-tooltip-ball {
    margin-right: 1rem !important;
    margin-left: auto !important;
  }

  .livis-landing-header-routes-wrap.profile {
    padding: 0px !important;
  }

  .livis-landing-header-routes-wrap.profile img {
    width: 2.5rem !important;
    height: 2.5rem !important;
  }

  .livis-landing-header-settings-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem !important;
  }
}

.livis-auth-header-right-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .livis-landing-header-language-select-container {
    margin-right: 1rem !important;

    .MuiInputBase-root {
      background-color: transparent !important;
    }

    .livis-auth-header-select-label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .livis-auth-header-language-flag {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }
}

// new product header css ends

//new table component css start
.table-container.livis-custom-table-component {
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.livis-custom-table-component {
  margin-bottom: 5rem !important;

  table {
    border-collapse: collapse;
  }

  *:not(td) {
    border-radius: 0.75rem !important;
  }

  th {
    cursor: pointer;
  }

  tr th:first-child,
  tr td:first-child {
    padding-left: 2rem !important;
  }

  tr th:last-child,
  tr td:last-child {
    padding-right: 2rem !important;
  }
}

//new table component css ends

// dashboard css starts
.livis-dashboard-header-card {
  background: var(--livis-color-background-white);
  box-shadow: 0px 2px 4px -2px var(--livis-dashboard-header-card-box-shadow);
  box-shadow: 0px -2px 4px -2px var(--livis-dashboard-card-box-shadow);
  box-shadow: 0px 4px 8px -4px var(--livis-dashboard-card-box-shadow);
  box-shadow: 0px 8px 16px -8px var(--livis-dashboard-card-box-shadow);
  padding: 1rem 2rem !important;
  border-radius: 1rem !important;
}

.livis-dashboard-card {
  background: var(--livis-color-background-white);
  box-shadow: 0px 2px 4px -2px var(--livis-dashboard-header-card-box-shadow);
  box-shadow: 0px -2px 4px -2px var(--livis-dashboard-card-box-shadow);
  box-shadow: 0px 4px 8px -4px var(--livis-dashboard-card-box-shadow);
  box-shadow: 0px 8px 16px -8px var(--livis-dashboard-card-box-shadow);
  padding: 1rem !important;
  border-radius: 1rem !important;

  .livis-dashboard-card-container {
    padding: 1.5px;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .livis-dashboard-cost-analysis {
    height: 27.5rem;
  }

  .livis-dashboard-production-variance {
    padding: 1.5px;
    padding-top: 10px;
    padding-bottom: 40px;
  }
}

.livis-dasboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin-bottom: 5rem !important;
  background: var(--livis-color-background) !important;
  overflow: scroll;
}

.livis-dashboard-header-wrap {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  padding-block: 0.25rem !important;
}

.livis-dashboard-header-right-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.livis-dashboard-header-right-box-date-refreshed-wrap {
  color: var(--livis-gray-font-color);
  font-family: "Noto Sans Regular";
  font-size: 1rem;
  padding-inline: 1.25rem;
  border-right: 1px solid var(--livis-dashboard-header-right-box-border);
}

.livis-dashboard-header-right-box-sync-max-wrap {
  padding-inline: 1.25rem;
  border-right: 1px solid var(--livis-dashboard-header-right-box-border);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.livis-dashboard-header-right-box-sync-max-wrap img {
  cursor: pointer;
  margin-block: 0.5rem !important;
}

.livis-dashboard-header-right-box-sync-max-wrap img:first-child {
  margin-right: 1.25rem !important;
}

.livis-dashboard-header-right-box-more-wrap {
  padding-left: 1.25rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.livis-dashboard-header-title {
  font-family: "Noto Sans Bold";
  font-size: 1.25rem;
}

.livis-dashboard-dropdown-title {
  font-family: "Noto Sans SemiBold";
  font-size: 1.15rem;
  margin-bottom: 0.5rem !important;
}

.livis-dashboard-mini-card-wrap:not(.livis-dashboard-mini-card-container .livis-dashboard-mini-card-wrap:first-child) {
  margin-top: 1rem !important;
}

.livis-dashboard-dropdown-mini-card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans Regular";
  font-size: 1rem;
  color: var(--livis-gray-font-color);
  margin-bottom: 0.5rem !important;
}

.livis-dashboard-dropdown-mini-card-month-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--livis-gray-font-color);
  margin-bottom: 0.5rem !important;
  font-family: "Noto Sans Regular";
  font-size: 1rem !important;
  white-space: nowrap;
}

.livis-dashboard-text-two-production-variance {
  position: absolute;
  top: -10px;
}

.livis-dashboard-dropdown-mini-card-title img {
  padding-left: 0.5rem !important;
}

.livis-dashboard-dropdown-mini-card-value {
  font-family: "Noto Sans Bold";
  font-size: 1.75rem;
  color: var(--livis-color-primary-gray);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-dashboard-dropdown-mini-card-value img {
  padding-right: 0.5rem !important;
}

.livis-dasboard-card-sub-details-wrap {
  margin-top: 2rem !important;
}

.livis-dasboard-card-sub-details-divider {
  background: var(--livis-dasboard-card-sub-details-divider-bg);
  height: 100%;
  max-width: 1px;
  margin: auto;
}

.livis-dasboard-card-sub-details-title {
  font-size: 0.875rem !important;
}

.livis-dasboard-card-sub-details-value {
  font-size: 1.15rem !important;
}

.livis-dashboard-sub-details-card {
  padding-block: 2rem !important;
}

.livis-dashboard-pie-chart-title {
  font-family: "Noto Sans SemiBold";
  font-size: 1.15rem;
  color: var(--livis-color-primary-gray);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.livis-dashboard-pie-chart-title img {
  margin-left: 0.5rem !important;
  margin-top: 0.25rem !important;
}

.livis-dashboard-mini-card-drodown-wrap.mobile {
  display: none !important;
}

.livis-dashboard-info-icon {
  cursor: pointer;
}

.livis-dashboard-avghours-completion {
  background-color: var(--livis-dashboard-avghours-completion-bg);
  color: var(--livis-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;

  p {
    margin: 0;
  }
}

.livis-dashboard-run-time-down-container {
  display: flex;
  justify-content: flex-end;

  .livis-dashboard-legend-value {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .livis-dashboard-legend-click-value {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }
}

.livis-dashboard-legend-container {
  display: flex;
  justify-content: flex-end;

  .livis-dashboard-legend-value {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .livis-dashboard-legend-click-value {
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }
}

.livis-dashboard-cost-over-time {
  background-color: var(--livis-dashboard-avghours-completion-bg);
  color: var(--livis-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;

  p {
    margin: 0;
  }
}

.livis-dashboard-production-variance-chart {
  background-color: var(--livis-dashboard-avghours-completion-bg);
  color: var(--livis-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;

  p {
    margin: 0;
  }
}

.livis-dashboard-run-down-time {
  background-color: var(--livis-dashboard-avghours-completion-bg);
  color: var(--livis-white-color);
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;

  p {
    margin: 0;
  }
}

.livis-dashboard-top-values-text {
  font-weight: bold;
}

.livis-dashboard-top-values-pie-text {
  font-weight: bold;
  font-size: 1.25rem;
}

.livis-dashboard-no-record-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}


// dashboard css ends

//no access css start

.livis-no-access-container {
  height: 100vh;
}

.livis-no-access-heading {
  font-size: 3rem;
  font-weight: 700;
  line-height: 65.38px;
  letter-spacing: -0.5px;
  text-align: center;
}

.livis-no-access-title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
}

.livis-no-access-route {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 32.69px;
  letter-spacing: -0.5px;
  text-align: left;
  margin-top: 1rem !important;
}

.livis-no-access-route-home {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 32.69px;
  letter-spacing: -0.5px;
  text-align: left;
  color: var(--livis-color-primary-dblue);
  text-decoration: underline;
  cursor: pointer;
}

//no access css end

// tag management css  starts

.livis-usecase-tag-management-container {
  width: 95% !important;
  margin: auto;
  margin-block: 2rem !important;
}

.livis-tag-manage-title {
  font-size: 1.5rem !important;
  font-family: "Noto Sans SemiBold";
}

.livis-tag-manage-table {
  width: 100% !important;
  margin-top: 3rem !important;
  border: 0.5px solid var(--livis-tag-manage-table-border);
  border-radius: 0.75rem !important;
  border-collapse: collapse !important;
  padding: 1rem !important;
}

.livis-tag-manage-table th,
.livis-tag-manage-table td {
  padding-left: 2rem !important;
  padding-block: 1rem !important;
}

.livis-tag-manage-table th:first-child,
.livis-tag-manage-table td:first-child {
  text-align: start !important;
}

.livis-tag-manage-table td:last-child {
  display: flex !important;
  justify-content: space-evenly !important;
}

.livis-tag-manage-table td {
  border-bottom: 1px solid var(--livis-tag-manage-table-border);
  background-color: var(--livis-white-color);
}

.livis-tag-manage-table th:last-child {
  border: none !important;
}

.livis-tag-manage-please-select-tag-box {
  background: var(--livis-credit-wrapper-bg) !important;
  border-radius: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem !important;
  color: var(--livis-gray-dark-font-color);
  font-size: 1rem;
}

.livis-tag-manage-edit-icon {
  cursor: pointer;
}

.livis-tag-seach-container {
  cursor: pointer;
}

.livis-tag-add-new-avatar {
  width: 32px !important;
  height: 32px !important;
}

// tag management css  ends

// new tag manage css start

.livis-tag-management-main {
  background: var(--livis-white-color);
  border-radius: 24px;
  margin-top: 1.12rem;
  margin-right: 1.5rem;
  padding: 1.5rem !important;
  min-height: 80vh;
}

.livis-tag-management-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-tag-management-header-search-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.livis-tag-management-header-search-container {
  margin-right: 1.5rem !important;
  position: relative;
}

.livis-tag-management-header-search-items-wrap {
  margin-top: 0.5rem;
  position: absolute;
  background: var(--livis-color-background-white);
  border-radius: 1rem;
  border: 1px solid var(--livis-tag-management-header-search-items-wrap-border);
  width: 100%;
  box-shadow: 0px 2px 4px -2px #a8a8a866, 0px 4px 8px -4px #a8a8a84d,
    0px 8px 16px -8px #a8a8a840, 0px -2px 4px -2px #a8a8a866,
    -1px 0px 1px -1px #a8a8a866, 1px 0px 1px -1px #a8a8a866,
    0px -4px 8px -4px #a8a8a833;
  z-index: 1000000 !important;
  max-height: 25rem !important;
  overflow-y: auto;
  cursor: pointer;
}

.livis-tag-management-header-search-items {
  padding: 0.75rem 1.5rem !important;
}

.livis-tag-management-header-search-items:hover {
  background: var(--livis-tag-management-header-search-items-hover-bg);
  border-radius: 0.5rem !important;
}

.livis-tag-management-header-search-items-tag {
  padding-bottom: 0.5rem;
  font-family: "Noto Sans Regular";
  font-size: 0.75rem;
  line-height: 18px;
  text-align: left;
  color: var(--livis-color-primary-dblue);
}

.livis-tag-management-header-search-items-name {
  font-family: "Noto Sans SemiBold";
  font-size: 1rem;
  line-height: 22px;
  text-align: left;
  color: var(--livis-gray100-font-color);
}

.livis-tag-management-header-title-wrap {
  color: var(--livis-color-primary-gray);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.livis-tag-management-hamburger-wrap {
  border-bottom: 2px solid var(--livis-tag-management-hamburger-border);
  margin-top: 1.5rem !important;
}

.livis-tag-management-hamburger-industry-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid var(--livis-tag-management-hamburger-border);
  margin-top: 1rem !important;
}

.livis-tag-management-hamburger-type {
  padding: 0 1.5rem 12px 1.5rem;
  margin-bottom: -1.5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--livis-tag-management-hamburger-type-color);
}

.no-padding {
  padding-right: 0.5rem !important;
  color: var(--livis-color-primary-gray);
}

.livis-tag-management-hamburger-gt {
  padding-bottom: 10px;
  padding-inline: 0.5rem;
}

.livis-tag-management-hamburger-gt-mobile {
  display: none !important;
}

.livis-tag-management-hamburger-type.active {
  border-bottom: 2px solid var(--livis-color-primary-dblue);
  color: var(--livis-color-primary-dblue);
  font-family: "Noto Sans SemiBold";
}

.livis-tag-management-hamburger-name {
  background: var(--livis-tag-management-hamburger-name-bg);
  padding: 0.25rem 0.75rem;
  color: var(--livis-color-primary-dblue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-inline: 0.5rem 1rem;
  margin-bottom: 0.5rem !important;
  line-height: 18px;
  padding-block: 0.25rem;
}

.livis-tag-management-hamburger-name img {
  padding-left: 0.5rem !important;
  cursor: pointer;
}

.livis-tag-management-header-search-container .MuiInputBase-root {
  width: 25rem !important;
}

.livis-tag-management-filter-wrap {
  margin-top: 1.5rem !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-tag-management-filter-desc-container,
.livis-tag-management-pagination-wrap,
.livis-tag-management-pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.livis-tag-management-filter-container {
  margin-left: 2rem;
  padding: 0.5rem 1rem;
}

.livis-tag-management-csv-container button {
  margin-right: 2rem;
  background: var(--livis-tag-management-csv-btn-color) !important;
  border: none !important;
}

.livis-tag-management-csv-container-mobile {
  display: none !important;
}

.livis-tag-management-add-new-tag-wrap {
  border: 3px dotted var(--livis-tag-management-add-tags-border);
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 2.25rem);
}

.livis-tag-management-add-new-tag-wrap img {
  width: 50px;
  height: 50px;
}

.livis-tag-management-tags-wrap {
  margin-top: 1.5rem;
}

.livis-tag-management-tag-wrap {
  border: 1px solid var(--livis-tag-management-tag-wrap-border);
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: calc(100% - 2rem) !important;
}

.livis-tag-management-tag-img-wrap {
  width: 100%;
  height: 7.5rem;
  margin: 0px !important;
}

.livis-tag-management-tag-img-wrap img {
  width: 50% !important;
  min-width: 50% !important;
  max-width: 50% !important;
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  object-fit: contain;
  padding: 0px !important;
}

.livis-tag-management-tag-img-wrap img.dummy-image {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.livis-tag-management-tag-info-wrap {
  margin-top: 1rem;
}

.livis-tag-management-tag-name-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.livis-tag-management-tag-name-container {
  font-family: "Noto Sans SemiBold";
  font-size: 1.05rem;
  line-height: 20px;
  text-align: left;
  color: var(--livis-color-primary-gray);
}

.livis-tag-management-tag-created-wrap {
  font-family: "Noto Sans Regular";
  font-size: 0.75rem;
  line-height: 20px;
  text-align: left;
  color: var(--livis-instruction-secondary-color);
}

.livis-tag-management-tag-tags-wrap,
.livis-tag-management-tag-tags-hamburger {
  font-family: "Noto Sans Regular";
  font-size: 0.75rem;
  line-height: 18px;
  text-align: left;
  color: var(--livis-instruction-secondary-color);
  padding-top: 0.75rem;
}

.livis-tag-management-tag-tags-hamburger {
  color: var(--livis-color-primary-dblue) !important;
}

.livis-tag-management-add-new-tag-text {
  font-family: "Noto Sans SemiBold";
  font-size: 1.05rem;
  line-height: 20px;
  text-align: center;
  color: var(--livis-color-primary-gray);
  margin-top: 2rem;
}

// new tag manage css ends

// audit log css start
.livis-audit-download-btn {
  border: 0.0625rem solid var(--livis-color-primary-lblue) !important;
  border-radius: 6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: white !important;
  text-transform: unset !important;
  box-shadow: none !important;
  background-color: var(--livis-color-primary-dblue) !important;
  width: fit-content !important;
}

.livis-audit-log-card {
  width: 100% !important;
  border-radius: 12px !important;
  // padding-inline: 3rem !important;
}

.livis-audit-log-event {
  min-width: 15rem !important;
  max-width: 15rem !important;
}

.livis-audit-log-info {
  min-width: 17.5rem !important;
  max-width: 17.5rem !important;
}

.livis-audit-log-details {
  min-width: 25rem !important;
  max-width: 25rem !important;
  padding-right: 2rem !important;
}

.livis-audit-log-time {
  min-width: 15rem !important;
  max-width: 15rem !important;
}

.livis-audit-log-wrap {
  border-bottom: 1px solid var(--livis-audit-log-wrap-border);
}

.livis-audit-log-wrap:last-child {
  border-bottom: none !important;
}

.livis-audit-log-no-data-wrap {
  height: 65vh;
}

.livis-audit-log-create-btn-avatar {
  width: 24px !important;
  height: 24px !important;
}

.livis-audit-log-download-btn-avatar {
  width: 16px !important;
  height: 16px !important;
}

// audit log css end

//mui css overwrite starts
.livis-menu-item {
  display: block !important;
}

.MuiTabs-indicator {
  display: none !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  display: none !important;
}

.livis-contact-us-wrap .css-bhp9pd-MuiPaper-root-MuiCard-root {
  border-radius: 0.625rem;
}

#demo-simple-select-standard:focus,
#usecase-filter:focus {
  background: transparent !important;
}

.MuiTextField-root>label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: var(--livis-input-placeholder-color);
}

.MuiTextField-root>.MuiFormLabel-root.Mui-livis-cobot-controller-preview-wrapperfocused {
  font-size: 0.875rem;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--livis-color-primary-dblue) !important;
}

.MuiOutlinedInput-root {
  border-radius: 1.875rem !important;
  // background-color: var(--livis-search-background-color) !important;
  background-color: var(--livis-white-color) !important;
  border: none;
  max-height: 3rem;
}

.LivisDatePicker .MuiOutlinedInput-root {
  border-radius: 0.25rem !important;
}

.MuiFormControl-root {
  width: 100% !important;
}

.MuiInputBase-multiline {
  border-radius: 0.25rem !important;
}

.MuiFormHelperText-root {
  margin-right: 0.875rem !important;
  margin-left: auto !important;
}

.MuiTextField-root>label {
  font-family: "Noto Sans Regular" !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  color: var(--livis-input-placeholder-dcolor) !important;
}

.MuiStepLabel-label {
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  color: var(--livis-gray-dark-font-dcolor) !important;
  font-family: "Noto Sans Regular" !important;
}

.MuiStepLabel-label.MuiStepLabel-active {
  font-family: "Noto Sans Regular" !important;
  font-weight: 700 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
  color: var(--livis-color-primary-dblue) !important;
}

.MuiStepLabel-label.MuiStepLabel-completed {
  font-family: "Noto Sans Regular" !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
  color: var(--livis-color-primary-dblue) !important;
}

#basic-menu .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
#basic-menu .css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: none !important;
  background: transparent !important;
}

#basic-menu .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: rgba(58, 92, 211, 0.12) !important;
}

.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  background: var(--livis-color-background) !important;
}

.livis-auth-fields-wrap .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root,
.livis-auth-fields-wrap .MuiIconButton-root {
  margin: 0.625rem !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input,
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font-size: 1.25rem !important;
}

.MuiInputBase-input {
  font-family: "Noto Sans SemiBold" !important;
}

.css-1rv3ei8-MuiFormControl-root-MuiTextField-root:hover .css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-bottom-color: var(--livis-color-primary-dblue) !important;
}

.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-bottom-color: var(--livis-color-primary-dblue) !important;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

.css-1tzgnuz-MuiInputBase-root-MuiFilledInput-root:before,
.css-1tzgnuz-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom: none !important;
}

// .livis-landing-search-wrap .MuiOutlinedInput-root,
// .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
//   width: 34rem !important;
//   max-width: 75% !important;
// }

.livis-landing-search-wrap div {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  max-width: 90% !important;
}

.MuiAccordionSummary-expandIconWrapper {
  // transform: rotate(90deg) !important;
}

.MuiAccordionDetails-root {
  border-top: 0rem solid var(--livis-white-color) !important;
}

.MuiAccordionSummary-content {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
}

// .css-smkl36-MuiCollapse-wrapper {
//   width: 85% !important;
//   margin: auto !important;
// }

.livis-inspection-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled,
  .Mui-error):before,
.livis-inspection-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
.livis-inspection-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-focused:after,
.livis-part-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled,
  .Mui-error):before,
.livis-part-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
.livis-part-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-focused:after,
.livis-image-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled,
  .Mui-error):before,
.livis-image-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
.livis-image-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-focused:after,
.livis-manage-data-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled,
  .Mui-error):before,
.livis-manage-data-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
.livis-manage-data-filter-wrap .css-m5hdmq-MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-focused:after {
  border-bottom: none !important;
}

.css-10edkuc::before {
  border-bottom: none !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper,
.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 1.125rem !important;
  background-color: var(--livis-dropdown-list-background-color) !important;
  // min-width: 150px !important;
  margin-block: 10px !important;
  // box-shadow: 0 1px 2px 0 rgb(0, 0, 0, .2), 0 2px 6px -2px rgb(0, 0, 0, .1);
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper ul {
  padding: 0px !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper ul li {
  padding-block: 10px !important;
  padding-inline: 20px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgba(58, 92, 211, 0.12) !important;
}

.livis-landing-search-wrap .css-1tzgnuz-MuiInputBase-root-MuiFilledInput-root {
  background-color: var(--livis-form-table-background-color) !important;
}

.MuiStepConnector-root {
  max-width: 2.625rem !important;
  margin-inline: 0.25rem !important;
}

.MuiStepLabel-iconContainer {
  padding-right: 0.5rem !important;
}

.livis-manage-data-tab {
  & .MuiButtonBase-root.MuiTab-root {
    text-transform: unset !important;
  }

  & .Mui-selected {
    color: var(--livis-color-primary-dblue) !important;
    font-size: 0.9rem !important;
    font-weight: 600 !important;
    border-bottom: 2px solid var(--livis-color-primary-dblue) !important;
    background-color: var(--livis-color-background) !important;
  }

  & .MuiTabs-indicator {
    color: var(--livis-color-primary-dblue) !important;
    border: 1px solid var(--livis-color-primary-dblue) !important;
    border-radius: 10px !important;
  }
}

.livis-semi-circle-donut {
  .highcharts-credits {
    display: none;
  }
}

.MuiLinearProgress-root {
  height: 10px !important;
  border-radius: 10px !important;

  &.MuiLinearProgress-colorPrimary {
    background-color: var(--livis-custom-css-mui-pop-over-bg) !important;
  }

  &.MuiLinearProgress-barColorPrimary {
    background-color: var(--livis-custom-css-mui-bar-primary-color) !important;
  }
}

.MuiPopover-paper {
  background-color: var(--livis-dropdown-list-background-color) !important;
  border-radius: 1.125rem !important;
  margin-top: 0.5rem;
  // margin-left: 1rem !important;
  // min-width: 10rem !important;

  & .MuiList-root {
    padding: 0 !important;
    max-height: 30rem !important;
    overflow: auto;
    border-radius: 18px;
    background: linear-gradient(0deg,
        rgba(150, 179, 255, 0.08) 0%,
        rgba(150, 179, 255, 0.08) 100%),
      var(--livis-white-color);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2),
      0px 2px 6px -2px rgba(0, 0, 0, 0.1);
  }

  & .MuiMenuItem-root {
    padding-top: 0.6rem !important;
    padding-bottom: 0.5rem !important;
    color: var(--livis-custom-css-mui-menu-item-color);
    font-family: "Noto Sans Regular";
    font-size: 1rem;
    font-style: normal;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.5px;
    padding-inline: 1.5rem !important;
    display: flex !important;
    // justify-content: center !important;

    &:hover {
      // background-color: #e3e0fa !important;
      background: rgba(58, 92, 211, 0.12) !important;
    }
  }
}

.livis-dashboard-data-tab,
.livis-dashboard-data-tab>div,
.livis-dashboard-data-tab>div>div {
  min-height: 24px !important;
  max-height: 24px !important;
}

.livis-dashboard-data-tab {
  margin-top: 1rem !important;

  & .MuiButtonBase-root.MuiTab-root {
    min-height: 24px !important;
    max-height: 24px !important;
    padding: 0px !important;
  }
}

// scrollbar css starts

/* Vertical scrollbar styles (common for both) */
*::-webkit-scrollbar {
  width: 4px;
  background: transparent !important;
  margin-right: 8px;
  /* Add some margin to the right */
}

/* Horizontal scrollbar styles */
*::-webkit-scrollbar:horizontal {
  height: 4px;
  background: transparent !important;
  margin-bottom: 8px;
  /* Add some margin to the bottom */
}

/* Common thumb styles for both scrollbars */
*::-webkit-scrollbar-thumb {
  background: rgba(105, 105, 110, 0.3) !important;
  border-radius: 5px;
}

/* shimmering css*/
.livis-autocomplete-chip-btn.loading {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, var(--livis-black-font-color) 30%, #0005, var(--livis-black-font-color) 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 3.125rem;
  max-width: 200px;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.livis-svg-loader {
  background: var(--livis-white-color);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999 !important;
  position: relative !important;

  .livis-spinner-loader-image {
    width: 250px;
  }
}

.info-container {
  display: grid;
  grid-template-columns: max-content auto;
  gap: 2px;
}

.info-container p {
  margin: 0;
  padding: 4px;
}

.heading {
  font-weight: bold;
}

.value {
  font-style: italic;
  /* Optional: Make values italic */
}

.d-none {
  display: none !important;
}

//.invisible

.livis-reject-reason-text {
  text-align: center;
}

// scrollbar css ends

//profile css starts

.livis-user-change-password-container {
  flex-grow: 1;
}

.livis-add-user-form-body-box {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 3%;
}

.livis-user-change-password-container-padding {
  flex-grow: 1;
  padding: 3%;
}

.livis-add-user-form-body-grid {
  padding-top: 30px;
}

.livis-profile-information-card {
  padding: 32px !important;
  border-radius: 16px !important;
  margin-left: -10px !important;
}

.livis-profile-change-password-padding {
  padding-top: 10%;
}

.livis-profile-change-password-grid {
  padding-top: 7%;
  border-radius: 2rem;
}

.livis-profile-password-requirements {
  background-color: var(--livis-color-background);
  padding-top: 0%;
  border-radius: 2rem;
  border-top-right-radius: 2rem;
}

.livis-change-password-form-flex,
.livis-menu-drawer {
  display: flex;
}

.livis-add-user-form-sign-green {
  color: green($color: #000000);
}

.livis-password-characters-validation {
  padding-top: 0.2rem;
  padding-left: 0.2rem;
}

.livis-set-password-validation {
  border-radius: 2rem;
  padding-top: 1.5rem;
}

.livis-profile-password-requirements-no-bg {
  padding-top: 0%;
  border-radius: 2rem;
  border-top-right-radius: 2rem;
}

.livis-user-profile-grid {
  margin-top: 86px;
}

.livis-usermanagement-user-role {
  .livis-usermanagement-user-role-span-padding {
    padding-right: 6px;
  }

  .livis-usermanagement-user-role-span-color {
    color: var(--livis-inspection-type-text-color);
  }
}

.livis-user-screen-row-padding {
  padding-top: 1.2rem;
}

.livis-user-information-box {
  .livis-user-information-box-grid-margin {
    margin: 16px;
  }
}

.livis-user-screen-details-grid {
  margin-top: 2.5rem;
  margin-left: 1rem;
  line-height: 48px;
}

.livis-user-information-box-margin {
  margin-top: 1rem;

  .livis-user-information-box-padding {
    padding: 0.9rem;
  }
}

.livis-user-screen-edit-btn {
  margin-top: 1.25rem;
}

.livis-usermanagement-profile-img-img {
  max-width: calc(100% - 8px);
  margin-right: 80px;
}

.livis-user-name-div {
  word-break: break-word;
  max-width: 100%;
}

.livis-user-management-details-grid {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.livis-user-management-details-margin {
  margin-top: 3rem;
}

.livis-user-credits-details {
  margin: 20px;
  margin-bottom: 30px;
}

//profile css ends

//myprofile.scss starts

.livis-user-profile-wrap {
  height: 86vh !important;
}

.livis-profile-card-grid {
  min-width: 100% !important;
}

.livis-profile-img>img {
  width: 100% !important;
  height: 16.875rem !important;
  /* 270px = 16.875rem */
}

.livis-information-profile {
  max-height: 16.875rem !important;
  /* 270px = 16.875rem */
}

.livis-profile-text-0 {
  margin: 0.3125rem 0 0 0;
  /* 5px = 0.3125rem */
}

#container {
  position: relative;
}

.livis-profile-activity {
  padding-top: 6.25%;
  /* 10% */
}

.livis-profile-details {
  padding-top: 3.125%;
  /* 5% */
}

.livis-credit-btn {
  border: 0.0625rem solid var(--livis-color-primary-lblue);
  border-radius: 6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: unset !important;
  float: right;
}

.livis-profile-header-wrap {
  justify-content: center;
  background-color: var(--livis-color-background-white) !important;
  padding: 1rem;
  width: 100%;
}

.livis-edit-profile-btn {
  border: 0.0625rem solid var(--livis-color-primary-lblue);
  border-radius: 6rem !important;
  padding: 0.5rem 1rem !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  text-align: left !important;
  color: var(--livis-color-primary-dblue) !important;
  text-transform: unset !important;
  width: 100% !important;
  // margin-bottom: 1rem;
}

.livis-input-field-padding {
  padding-right: 6.25% !important;
  /* 10% */
}

/* ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    margin-top: 2rem !important;
} */

.livis-reset-password-instruction {
  margin-bottom: 2.5rem;
}

.livis-reset-password-instruction p {
  font-size: 0.9375rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
  margin-bottom: 0.5rem;
  margin-top: 0.6rem !important;
}

th,
td {
  width: 9.375rem;
  /* 150px = 9.375rem */
  text-align: center;
  /* border: 1px solid black; */
  padding: 0.3125rem;
  /* 5px = 0.3125rem */
}

th {
  background-color: var(--livis-color-background) !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 0.3125rem !important;
  /* 5px = 0.3125rem */
  background-color: var(--livis-color-background);
}

tr {
  background-color: var(--livis-white-color);
  border: 0.0625rem;
  /* 1px = 0.0625rem */
}

.MuiPaper-root {
  box-shadow: 0.625rem 0.625rem 0.5rem 0.625rem transparent;
  /* 10px = 0.625rem, 8px = 0.5rem */
}

.MuiToolbar-root {
  /* display: none; */
}

.MuiTable-root {
  /* display: block !important; */
}

.livis-reset-password-instruction-adduser p {
  font-size: 0.9375rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
  margin-bottom: 0.5rem;
  margin-top: 0 !important;
}

.livis-add-user-form {
  padding-top: 1.875%;
  /* 3% */
  padding-left: 1.875%;
  /* 3% */
}

.livis-add-user-form-grid {
  padding-left: 3.125%;
  /* 5% */
  padding-top: 1.875%;
  /* 3% */
  padding-bottom: 1.875%;
  /* 3% */
}

.livis-add-user-form-grid>p {
  justify-content: center;
  display: content;
}

.livis-add-user-form-sign {
  padding-top: 0.3rem;
  padding-right: 0.5rem;
}

.livis-add-user-form-body {
  max-height: 40.625rem !important;
  /* 65vh */
  /* overflow: auto !important; */
}

.livis-data-table-operation-button-wrapper>button {
  margin: 0.1rem !important;
}

.livis-usermanagement-profile-img>img {
  box-sizing: border-box;
  width: 8.25rem;
  /* 132px = 8.25rem */
  height: 8.25rem;
  /* 132px = 8.25rem */
  left: 1.25rem;
  /* 20px = 1.25rem */
  top: 1rem;
  /* 16px = 1rem */
  background: linear-gradient(0deg, #f8faff, #f8faff);
  border: 0.0625rem solid var(--livis-border-color);
  /* 1px = 0.0625rem */
  border-radius: 1rem;
  /* 16px = 1rem */
}

.livis-usermanagement-profile-img-list>img {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  left: 1.25rem;
  /* 20px = 1.25rem */
  top: 1rem;
  /* 16px = 1rem */
  background: linear-gradient(0deg, #f8faff, #f8faff);
  border: 0.0625rem solid var(--livis-border-color);
  /* 1px = 0.0625rem */
  /* border-radius: 1rem; */
}

.livis-usermanagement-user-name {
  /* font-family: 'Noto Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  /* 18px = 1.125rem */
  line-height: 1.5rem;
  /* 24px = 1.5rem */
  display: flex;
  align-items: flex-start;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
}

.livis-usermanagement-user-role {
  font-weight: 400;
  font-size: 1rem;
  /* 16px = 1rem */
  line-height: 1.25rem;
  /* 20px = 1.25rem */
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--livis-instruction-secondary-color);
}

.livis-user-information-box {
  box-sizing: border-box;
  border: 0.0625rem solid var(--livis-border-color);
  /* 1px = 0.0625rem */
  border-radius: 1rem;
  /* 16px = 1rem */
}

.livis-user-information {
  font-weight: 500;
  font-size: 0.9375rem;
  /* 15px = 0.9375rem */
  line-height: 2.4375rem;
  /* 39px = 2.4375rem */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
}

.livis-user-information>img {
  padding-right: 0.5rem;
  /* 8px = 0.5rem */
}

.livis-user-active {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-active-color);
}

.livis-user-inactive {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-inactive-color);
}

.livis-user-location-inactive {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-inactive-color);
}

.livis-user-location-active {
  height: 100%;
  width: 0.375rem;
  background-color: var(--livis-active-color);
}

.livis-usermanagement-user-location {
  padding-top: 1.4rem;
  padding-bottom: 1.2rem;
  font-weight: 400;
  font-size: 0.875rem;
  /* 14px = 0.875rem */
  line-height: 1.5rem;
  /* 24px = 1.5rem */
  letter-spacing: 0.15px;
  color: var(--livis-color-primary-gray);
}

.livis-usermanagement-user-activity {
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  /* 12px = 0.75rem */
  line-height: 1.25rem !important;
  /* 20px = 1.25rem */
  letter-spacing: 0.15px;
  color: var(--livis-gray-font-color) !important;
}

.livis-primary-btn {
  margin-top: 1.4rem !important;
  line-height: 2.56 !important;
  margin-bottom: 1.2rem !important;
}

.livis-inpection-card-list {
  padding: 0 !important;
}

.livis-user-nouser-block {
  background-color: var(--livis-white-color);
  height: 12.5rem;
  /* 200px = 12.5rem */
  width: 12.5rem;
  /* 200px = 12.5rem */
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 100%;
}

.livis-user-tour-box {
  font-weight: 400;
  font-size: 1rem;
  /* 16px = 1rem */
  line-height: 1.875rem;
  /* 30px = 1.875rem */
  display: flex;
  align-items: center;
  letter-spacing: 0.7px;
  color: var(--livis-color-primary-blue);
  margin-left: 1rem;
}

.livis-user-add-button {
  width: 8.2rem !important;
}

.livis-stepper-steps {
  padding: 0 !important;
  margin-left: 16px;
  margin-right: 18px;
}

.livis-user-adduser-form-subheading {
  font-weight: 600;
  font-size: 1rem;
  /* 16px = 1rem */
  line-height: 1.5rem;
  /* 24px = 1.5rem */
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: var(--livis-color-primary-gray);
}

.livis-profile-update-password-input-wrap {
  padding-right: 2rem !important;
}

.livis-profile-credit-wrap {
  max-width: 15rem !important;
  margin-top: 2rem !important;
  height: 5rem !important;
}

/* @media(max-width: 81.25rem) {
    .livis-profile-info-wrapper {
        min-width: 100% !important;
        background-color: red !important;
    }
} */

//myprofile.scss ends

//Css for specificity starts

//Training css starts

.livis-training-summary-icon-wrap .livis-back-btn {
  margin-top: 0.120rem !important;
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
  padding-top: 0.400rem !important;
  padding-bottom: 0.300rem !important;

}

//Training css ends


//Css for specificity ends